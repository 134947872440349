import config from '../../services/config';
import globals from '../../services/globals';
import utils from '../../services/utils';
import logger from '../../services/logger';
import DashboardConfiguration from './dashboardConfiguration/dashboardConfiguration';
import LZString from '../../assets/scripts/lz-string-1.3.3.min';
import ReportView from './reportView';
import { shallowRef, computed } from 'vue';


const $ = window.$;
var TScMessenger = window.TScMessenger;
var CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;

var User = function () {
    TScMessenger.writeDebugMessage("At top of new version of model.user...");
    var
        //private properties
        firstName,
        lastName,
        address,
        username,
        emailAddress,
        password,
        machineCode,
        cdg,
        cultureCode,
        reminders,
        // securityToken,
        // validUntil,
        // validUntilOffline,
        offlineUsage,
        lastUsed,
        position,
        directNumber,
        mobileNumber,
        companyNumber,
        palette,
        permissions,
        appOptionItems,
        reportGraphics,
        decimalPlaces,
        reminderDate,
        customerDistributionGroups,
        accessibleApplications,
        accessibleVehicleTypes,
        defaultApplication,
        defaultVehicleType,
        reminderDateObv = shallowRef(),
        reminderCheck = shallowRef(),
        cdgArray = shallowRef([]),
        onRoadCostsCosts,
        onRoadCostsDiscounts,
        // onRoadCostsVersion,
        // databaseAttributeIds,
        languageId,
        trainingUrl,
        translations,
        legislations = [],
        tolls = [],
        // vehicleSelectionWizardOrder,
        supportEmailAddress,
        supportTelephoneNumber,
        supportTelephoneNumberForLink,
        supportWebsite,
        countryName,
        countryAbbreviation,
        // user settings
        availableLegislations = [],
        activeLegislation = {},
        activeLegislationId = 0,
        activeMeasurementSystem,
        activeMeasurementSystemId,
        lengthIncrement,
        massIncrement,
        percentageIncrement,
        availableMeasurementSystems = [],
        colourDrawings = true,
        showComplianceScorecard = false,
        showTips = false,
        reportViewLayout = config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED,
        reportViews = [],
        specifyWheelbaseAs = '',
        loggedInUsingEmail = false,
        emailVerifiedStatus = config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED,
        userType = config.USER_TYPES.UNKNOWN,
        trialCalculationsRemaining = 0,
        firstLogin = false,
        trialDaysRemaining = 0,
        billingDetails = {},
        appUpdateAvailable = false,
        licenceTransferCodeRequired = false,
        suppressQuickSearchHelperToast = false,
        lastUrlHash = '',
        intercomUserId = '',
        companyId = 0,
        companyName = '',
        expiryDate = '',
        firstLoginDate = '',
        latestLoginDate = '',
        userPlan = '',
        authenticationMethod = '',
        accessCode = '',
        _vehicleSelectionSearchHistory = {},
        numberOfUsesBeforeReferralPrompt = 0,
        numberOfUsesSinceLastPrompt = 0,
        availableManufacturerMakesArray = [],
        availableManufacturerRangesArray = [],
        availableManufacturerAxleLayoutsArray = [],
        availableManufacturerVehicleTypesArray = [],
        availableManufacturerStatusesArray = [],
        availableManufacturerCountriesArray = [],
        availableGenericAxleLayoutsArray = [],
        availableGenericVehicleTypesArray = [],
        integrations = [],
        specifyCabDimensionsAs = '',
        specifyChassisLengthAs = '',
        specifyFuelConsumptionAs = '',
        specifyLicencingRegionAs = 0,
        specifyAxleRatingAs = '',
        menuItemsHistoryOnSelectionScreen = [],
        numberOfOffersFromServer = 0,
        numberOfBodiesFromServer = 0,
        numberOfAccessoriesFromServer = 0,
        numberOfTrailersFromServer = 0,
        numberOfPayloadsFromServer = 0,
        defaultReportPdfPageSize,
        reportLogo,
        userLicenceInstanceId = 0,
        dataPoints = [],
        dDRTranslations = [],
        accessoryTranslations = [],
        dashboardConfiguration = null,
        isMemberOfDistributorCompany = false,
        companyAdditionalNote = '',
        specifyBodyPositionAs = '',
        specifyEquipmentPositionAs = '',
        edition = '',
        product = '',
        appRefreshUnderway = false,
        nteaUserValidationType,
        companyInterfaceAttributes,
        
        //userUsageUses = 0,

        //public observable properties
        displayReminders = shallowRef(false),
        displayReminderValue = computed(function () {
            return globals.compareDateToTodayInDays(reminderDateObv.value);
        }),
        // Variables used in user menu
        displayNameObv = shallowRef(),
        userProfileTooltipObv = shallowRef(),
        displayEmailAddressObv = shallowRef(),
        editionAndVersionObv = shallowRef(),
        productNameObv = shallowRef(),
        isGoogleUserObv = shallowRef(),
        canShowChangePasswordRef = shallowRef(),
        canShowLogoutRef = shallowRef(),
        allowOpenTsvFileObv = shallowRef(),
        allowUserAccessToChargebeePortalObv = shallowRef(),
        allowDataUploadObv = shallowRef(),
        displayEmailVerificationMenuOptionObv = shallowRef(false),
        microsoftLoggedInUserAccessTokenAsPassword = '',
        canShowShareButtonRef = shallowRef(),
        canShowPreparedForSectionRef = shallowRef(),

        //public properties
        // getSecurityToken = function () {
        //     return securityToken;
        // },
        // getValidUntil = function (isOnline) {
        //     if (isOnline && isOnline === true) {
        //         return validUntil;
        //     } else {
        //         return validUntilOffline;
        //     }

        // },
        getUserName = function () {
            return username;
        },
        getEmailAddress = function () {
            return emailAddress;
        },
        getPassword = function () {
            return password;
        },
        getMachineCode = function () {
            return machineCode;
        },
        getCdg = function () {
            return cdg !== undefined && cdg !== '' ? cdg : 0;
        },
        getCustomerDistributionGroups = function () {
            return customerDistributionGroups;
        },
        getCultureCode = function () {
            return cultureCode;
        },
        getAddress = function () {
            return address;
        },
        getDisplayName = function () {
            return firstName + ' ' + lastName;
        },
        getFirstName = function () {
            return firstName;
        },
        getLastName = function () {
            return lastName;
        },
        getOfflineUsage = function () {
            //return offlineUsage;
            return parseInt(offlineUsage);
        },
        getPosition = function () {
            return position;
        },
        getDirectNumber = function () {
            return directNumber;
        },
        getMobileNumber = function () {
            return mobileNumber;
        },
        /**
         * Returns phone number to be used in report. If direct number is not available then return mobile number
         * @returns {string} - Phone number to use in report
         */
        getPhoneNumberForReport = function () {
            if (typeof directNumber === 'string' && directNumber !== '') {
                return directNumber;
            } else {
                return mobileNumber;
            }
        },
        getCompanyNumber = function () {
            return companyNumber;
        },
        getPalette = function () {
            if (palette === undefined || palette === '') {
                setPaletteDefault();
            }
            return palette;
        },
        getPermissions = function () {
            return permissions;
        },
        // getAppOptionItems = function () {
        //     return appOptionItems;
        // },
        getReportGraphics = function () {
            if(reportGraphics && reportGraphics.landscapeReportHeaderLeftURL) {
                return reportGraphics;
            }
            return {
                reportHeaderURL: config.defaultReportHeaderURL(),
                reportFooterURL: config.defaultReportFooterURL(),
                landscapeReportHeaderLeftURL: config.defaultReportHeaderURL()
            };
        },
        getDecimalPlaces = function () {
            return decimalPlaces;
        },
        getReminderDate = function () {
            return reminderDate;
        },
        getAccessibleApplications = function () {
            return accessibleApplications;
        },
        getAccessibleVehicleTypes = function () {
            return accessibleVehicleTypes;
        },
        getDefaultApplication = function () {
            return defaultApplication;
        },
        getDefaultVehicleType = function () {
            return defaultVehicleType;
        },
        getOnRoadCostsCosts = function () {
            return onRoadCostsCosts !== undefined ? onRoadCostsCosts : [];
        },
        getOnRoadCostsDiscounts = function () {
            return onRoadCostsDiscounts !== undefined ? onRoadCostsDiscounts : [];
        },
        // getDatabaseAttributeIds = function () {
        //     return databaseAttributeIds !== undefined ? databaseAttributeIds : [];
        // },
        // getOnRoadCostsVersion = function () {
        //     return onRoadCostsVersion;
        // },
        getLanguageId = function () {
            return languageId;
        },
        getTrainingUrl = function () {
            return trainingUrl;
        },
        // getTranslations = function () {
        //     return translations !== undefined ? translations : [];
        // },
        // getLegislations = function () {
        //     return legislations !== undefined ? legislations : [];
        // },
        getTolls = function () {
            return tolls !== undefined ? tolls : [];
        },
        getActiveLegislation = function () {
            var cdgDefaultLegislation = availableLegislations.filter(function (legislation) {
                return legislation.isDefault;
            })[0];
            var userLicenceDefault = availableLegislations.filter(function (legislation) {
                return legislation.id === activeLegislationId;
            })[0];
            if (userLicenceDefault !== undefined) {
                return userLicenceDefault;
            } else {
                if (cdgDefaultLegislation !== undefined) {
                    return cdgDefaultLegislation;
                } else {
                    //setActiveLegislation(availableLegislations[0])
                    activeLegislation = availableLegislations[0];
                    activeLegislationId = activeLegislation.id;
                    return availableLegislations[0];
                }

            }
        },
        getSupportEmailAddress = function () {
            return supportEmailAddress !== undefined ? supportEmailAddress : '';
        },
        getSupportTelephoneNumber = function () {
            return supportTelephoneNumber !== undefined ? supportTelephoneNumber : '';
        },
        getSupportTelephoneNumberForLink = function () {
            return supportTelephoneNumberForLink !== undefined ? supportTelephoneNumberForLink : '';
        },
        getSupportWebsite = function () {
            return supportWebsite !== undefined ? supportWebsite : '';
        },
        getCountryName = function () {
            return countryName !== undefined ? countryName : '';
        },
        getCountryAbbreviation = function () {
            return countryAbbreviation !== undefined ? countryAbbreviation : '';
        },
        // getVehicleSelectionWizardOrder = function () {
        //     return vehicleSelectionWizardOrder !== undefined ? vehicleSelectionWizardOrder : [];
        // },
        getAvailableLegislations = function () {
            return availableLegislations !== undefined ? availableLegislations : [];
        },
        getSortedAvailableLegislations = function () {
            var userCountryAbbreviation = globals.getCountryCodeUsingCountryName(countryName);
            return globals.sortLegislationsArray(availableLegislations, userCountryAbbreviation);
        },
        getActiveLegislationId = function () {
            return activeLegislationId;
        },
        getActiveMeasurementSystem = function () {
            return activeMeasurementSystem;
        },
        getActiveMeasurementSystemId = function () {
            return activeMeasurementSystemId;
        },
        getLengthIncrement = function () {
            //return lengthIncrement;
            if (lengthIncrement === 0) {
                lengthIncrement = getActiveMeasurementSystem().defaultLengthIncrement;
            }
            return lengthIncrement;
        },
        setLengthIncrement = function (newValue) {
            lengthIncrement = newValue;
            updateUser({
                lengthIncrement: lengthIncrement
            });
        },
        getMassIncrement = function () {
            //return massIncrement;
            if (massIncrement === 0) {
                massIncrement = getActiveMeasurementSystem().defaultMassIncrement;
            }
            return massIncrement;
        },
        setMassIncrement = function (newValue) {
            massIncrement = newValue;
            updateUser({
                massIncrement: massIncrement
            });
        },
        getPercentageIncrement = function () {
            //return percentageIncrement;
            if (percentageIncrement === 0) {
                percentageIncrement = getActiveMeasurementSystem().defaultPercentageIncrement;
            }
            return percentageIncrement;
        },
        setPercentageIncrement = function (newValue) {
            percentageIncrement = newValue;
            updateUser({
                percentageIncrement: percentageIncrement
            });
        },
        getAvailableMeasurementSystems = function () {
            return availableMeasurementSystems;
        },
        getColourDrawings = function () {
            return colourDrawings;
        },
        getShowComplianceScorecard = function () {
            return showComplianceScorecard;
        },
        getShowTips = function () {
            return showTips;
        },
        getReportViewLayout = function () {
            return reportViewLayout;
        },
        setReportViewLayout = function (newValue) {
            reportViewLayout = newValue;
        },
        getReportViews = function () {
            return reportViews;
        },        
        setReportViews = function (newValue) {
            if (newValue === null) {
                reportViews = [];
            } else {
                if(Array.isArray(newValue)) {
                    // clear the array
                    reportViews.splice(0, reportViews.length);
                    if (newValue.length > 0) {
                        newValue.forEach(function (reportView) {
                            reportViews.push(new ReportView(reportView));
                        });
                    } else {
                        reportViews = [];
                    }
                }                
            }
        },
        getReportViewsObjects = function () {
            var returnArray = [];
            if(Array.isArray(reportViews) === true) {
                reportViews.forEach(function (item) {
                    returnArray.push(item.getObjectToBeSaved());
                });
            }
            return returnArray;
        },
        replaceReportViewsUsingArray = function (newValue) {
            if (Array.isArray(newValue)) {
                reportViews.splice(0, reportViews.length);
                newValue.forEach(function (reportView) {
                    reportViews.push(new ReportView(reportView));
                });
            }
        },
        getSpecifyWheelbaseAs = function () {
            return specifyWheelbaseAs;
        },
        getLoggedInUsingEmail = function () {
            return loggedInUsingEmail;
        },
        getEmailVerifiedStatus = function () {
            return emailVerifiedStatus;
        },
        getUserType = function () {
            return userType;
        },
        getTrialCalculationsRemaining = function () {
            return trialCalculationsRemaining;
        },
        getNumberOfUsesBeforeReferralPrompt = function () {
            return numberOfUsesBeforeReferralPrompt;
        },
        getNumberOfUsesSinceLastPrompt = function () {
            return numberOfUsesSinceLastPrompt;
        },
        setNumberOfUsesSinceLastPrompt = function (newValue) {
            numberOfUsesSinceLastPrompt = newValue;
        },
        getFirstLogin = function () {
            return firstLogin;
        },
        getTrialDaysRemaining = function () {
            return trialDaysRemaining;
        },
        getBillingDetails = function () {
            return billingDetails;
        },
        getAppUpdateAvailable = function () {
            return appUpdateAvailable;
        },
        getLicenceTransferCodeRequired = function () {
            return licenceTransferCodeRequired;
        },
        getSuppressQuickSearchHelperToast = function () {
            return suppressQuickSearchHelperToast;
        },
        getLastUrlHash = function () {
            return lastUrlHash;
        },
        getIntercomUserId = function () {
            return intercomUserId;
        },
        getCompanyId = function () {
            return companyId;
        },
        getCompanyName = function () {
            return companyName;
        },
        getExpiryDate = function () {
            //return Date.parse(expiryDate);
            return expiryDate;
        },
        getExpiryDateFormatted = function () {
            //return globals.getDatabaseDateFormattedDDMMYYYY(expiryDate);
            //return new Date(expiryDate).getTime() / 1000;
            return getUserType() === config.USER_TYPES.FULL_USER_CANCELLED ? Math.round(new Date(expiryDate).getTime() / 1000) : null;
        },
        getFirstLoginDateFormatted = function () {
            //return globals.getDatabaseDateFormattedDDMMYYYY(firstLoginDate);
            //return new Date(firstLoginDate).getTime() / 1000;
            return Math.round(new Date(firstLoginDate).getTime() / 1000);
        },
        getLatestLoginDateFormatted = function () {
            //return globals.getDatabaseDateFormattedDDMMYYYY(latestLoginDate);
            //return new Date(latestLoginDate).getTime() / 1000;
            return Math.round(new Date(latestLoginDate).getTime() / 1000);
        },
        getUserPlan = function () {
            if (isTrialUser()) {
                return null;
            }
            // 14-Day/Monthly/Annual
            return getBillingDetails().Plan;
        },
        // getUserPlanActivatedAt = function () {
        //     if (isTrialUser()) {
        //         return null;
        //     }
        //     return getBillingDetails().SubscriptionActivatedAt;
        // },
        // getUserSubscriptionCreatedAt = function () {
        //     if (isTrialUser()) {
        //         return null;
        //     }
        //     return getBillingDetails().SubscriptionCreatedAt;
        // },
        // getUserSubscriptionCurrentTermEnd = function () {
        //     if (isTrialUser()) {
        //         return null;
        //     }
        //     return getBillingDetails().SubscriptionCurrentTermEnd;
        // },
        // getUserSubscriptionCurrentTermStart = function () {
        //     if (isTrialUser()) {
        //         return null;
        //     }
        //     return getBillingDetails().SubscriptionCurrentTermStart;
        // },
        getUserSubscriptionNextBillingAt = function () {
            if (isTrialUser()) {
                return null;
            }
            return getBillingDetails().SubscriptionNextBillingAt;
        },
        getUserSubscriptionStartedAt = function () {
            if (isTrialUser()) {
                return null;
            }
            return getBillingDetails().SubscriptionStartedAt;
        },
        //getUserUsageUses = function () {
        //    return userUsageUses;
        //},
        getUserStatus = function () {
            if (getUserType() === config.USER_TYPES.FULL_USER_ARREARS) {
                //return 'Account In Arrears';
                return config.USER_STATUS.ACCOUNT_IN_ARREARS;
            }
            if (getUserType() === config.USER_TYPES.FULL_USER_CANCELLED) {
                //return 'Account Cancelled';
                return config.USER_STATUS.ACCOUNT_CANCELLED;
            }
            //return 'Active';
            return config.USER_STATUS.ACTIVE;
        },
        getAuthenticationMethod = function () {
            return authenticationMethod;
        },
        getAccessCode = function () {
            return accessCode;
        },
        getUserProfileTooltipText = function () {
            // Tested in Chrome/FF/IE/Edge
            return getDisplayName() + '\n' + getEmailAddress();
        },
        getProduct = function () {
            //return 'Axle Weight Calculator';
            return product;
        },
        getEdition = function () {
            //return 'Professional';
            return edition;
        },
        getEditionAndVersionText = function () {
            //return getEdition() + '\n' + config.getAppVersion();
            //return 'Professional' + ' - ' + config.getAppVersion();
            return getEdition() + ' ' + config.getAppVersion();
        },
        getIntegrations = function () {
            return integrations !== undefined ? integrations : [];
        },
        getIntegrationObjectUsingType = function (type) {
            var returnObject = getIntegrations().find(function (element) {
                return element.Type === type;
            });
            return returnObject !== undefined ? returnObject : null;
        },
        displayNteaOptionsToUser = function () {
            //return config.getCountryNameUsingAbbreviation('US') === getCountryName() || config.getCountryNameUsingAbbreviation('CA') === getCountryName();
            return doesCountryCodeMatchUserCountryAbbreviation('US') || doesCountryCodeMatchUserCountryAbbreviation('CA');
        },
        displaySalesToolOptionsToUser = function () {
            return doesCountryCodeMatchUserCountryAbbreviation('ZA');
        },
        isGoogleUser = function () {
            return getAuthenticationMethod() === config.AUTHENTICATION_METHOD.GOOGLE;
        },
        canShowChangePassword = function() {
            return getAuthenticationMethod() !== config.AUTHENTICATION_METHOD.GOOGLE && hasPermission(config.PERMISSIONS.GENERAL_CHANGE_PASSWORD.Code);
        },
        canShowLogout = function() {
            return hasPermission(config.PERMISSIONS.GENERAL_LOGOUT.Code);
        },
        getSpecifyLicencingRegionAs = function () {
            return specifyLicencingRegionAs;
        },
        getNumberOfOffersFromServer = function () {
            return numberOfOffersFromServer;
        },
        setNumberOfOffersFromServer = function (newValue) {
            numberOfOffersFromServer = newValue;
            updateUser({ numberOfOffersFromServer: numberOfOffersFromServer });
        },
        getNumberOfBodiesFromServer = function () {
            return numberOfBodiesFromServer;
        },
        setNumberOfBodiesFromServer = function (newValue) {
            numberOfBodiesFromServer = newValue;
            updateUser({ numberOfBodiesFromServer: numberOfBodiesFromServer });
        },
        getNumberOfAccessoriesFromServer = function () {
            return numberOfAccessoriesFromServer;
        },
        setNumberOfAccessoriesFromServer = function (newValue) {
            numberOfAccessoriesFromServer = newValue;
            updateUser({ numberOfAccessoriesFromServer: numberOfAccessoriesFromServer });
        },
        getNumberOfTrailersFromServer = function () {
            return numberOfTrailersFromServer;
        },
        setNumberOfTrailersFromServer = function (newValue) {
            numberOfTrailersFromServer = newValue;
            updateUser({ numberOfTrailersFromServer: numberOfTrailersFromServer });
        },
        getNumberOfPayloadsFromServer = function () {
            return numberOfPayloadsFromServer;
        },
        setNumberOfPayloadsFromServer = function (newValue) {
            numberOfPayloadsFromServer = newValue;
            updateUser({ numberOfPayloadsFromServer: numberOfPayloadsFromServer });
        },
        getDefaultReportPdfPageSize = function () {
            return defaultReportPdfPageSize;
        },
        setDefaultReportPdfPageSize = function (newValue) {
            var values = Object.values(config.PDFMAKE_PAGE_SIZE_OPTIONS);
            if (values.includes(newValue) === true) {
                defaultReportPdfPageSize = newValue;
            } else {
                defaultReportPdfPageSize = config.PDFMAKE_PAGE_SIZE_OPTIONS.A4;
            }
        },
        getReportLogo = function () {
            // return reportLogo;
            if (typeof reportLogo === 'string' && reportLogo.length > 0) {
                if (reportLogo.includes('base64,')) {
                    return reportLogo;
                } else {
                    return 'data:image/png;base64,' + reportLogo;
                }
            } else {
                return getReportGraphics().landscapeReportHeaderLeftURL;
            }
        },
        setReportLogo = function (newValue) {
            reportLogo = newValue;
        },
        getIsMemberOfDistributorCompany = function () {
            return isMemberOfDistributorCompany
        },
        // getReportLogoForReport = function () {
        //     if (typeof reportLogo === 'string' && reportLogo.length > 0) {
        //         if (reportLogo.includes('base64,')) {
        //             return reportLogo;
        //         } else {
        //             return 'data:image/png;base64,' + reportLogo;
        //         }
        //     } else {
        //         return getReportGraphics().landscapeReportHeaderLeftURL;
        //     }

        //     //if (globals.user.getReportLogo() !== '') {
        //     //    return globals.user.getReportLogo();
        //     //} else {
        //     //    return globals.user.getReportGraphics().landscapeReportHeaderLeftURL;
        //     //}
        // },
        getCompanyAdditionalNote = function () {
            return companyAdditionalNote;
        },
        setCompanyAdditionalNote = function (newValue) {
            companyAdditionalNote = newValue
        },
        getNteaUserValidationType = function () {
            return nteaUserValidationType;
        },
        setNteaUserValidationType = function (newValue) {
            if (Object.values(config.NTEA_USER_VALIDATION_TYPES).includes(newValue) === true) {
                nteaUserValidationType = newValue;
            } else {
                nteaUserValidationType = config.NTEA_USER_VALIDATION_TYPES.NTEA;
            }
        },
        /**
         * Returns all available data points for user
         * @returns {object[]} - Array of data points
         * */
        getDataPoints = function () {
            return Array.isArray(dataPoints) === true ? dataPoints : [];
        },
        /**
         * Returns all dashboard data points that can be selected by user to be pinned to dashboard
         * @returns {object[]} - Array of dashboard data points
         * */
        getDashboardDataPoints = function () {
            var returnArray = [];

            getDataPoints().forEach(function (dataPoint) {
                if (dataPoint.IsAvailableToDashboard === true) {
                    returnArray.push({
                        id: dataPoint.Id,
                        description: dataPoint.Translation,
                        name: dataPoint.Name,
                        code: dataPoint.Code,
                        unitFormat: dataPoint.UnitFormat
                    });
                }
            });

            returnArray.sort(globals.sortDataPointsArray);

            return returnArray;
        },
        /**
         * Updates dashboard data points on dashboard configuration using array of DataPointId numbers
         * @param {number[]} - Array of dashboard data points
         * */
        replaceDashboardDataPointsUsingArrayOfIds = function (newValue) {
            if (Array.isArray(newValue)) {
                getDashboardConfiguration().replaceDashboardDataPointsUsingArrayOfIds(newValue);
            }
        },
        /**
         * Updates dashboard data points on dashboard configuration using array of data point objects
         * @param {object[]} - Array of dashboard data points
         * */
        replaceInternalStandardsUsingArray = function (newValue) {
            if (Array.isArray(newValue)) {
                getDashboardConfiguration().replaceInternalStandardsUsingArray(newValue);
            }
        },
        /**
         * Updates dashboard legislations on dashboard configuration using array of legislation objects
         * @param {object[]} - Array of dashboard legislation objects
         * */
        replaceDashboardLegislationsUsingArray = function (newValue) {
            if (Array.isArray(newValue)) {
                getDashboardConfiguration().replaceDashboardLegislationsUsingArray(newValue);
            }
        },
        /**
         * Returns array of internal standards that can be selected by the user
         * @param {object[]} - Array of dashboard legislation objects
         * @returns {object[]} - Array of data points
         * */
        getInternalStandards = function () {
            var returnArray = [];

            getDataPoints().forEach(function (dataPoint) {
                if (dataPoint.IsAvailableToInternalStandard === true) {
                    returnArray.push(dataPoint);
                }
            });

            return returnArray;
        },
        getTranslationForDataPoint = function (id) {
            var dataPointTranslation = getDataPoints().find(function (dataPoint) {
                return dataPoint.DataPointId === id;
            });

            if (!dataPointTranslation) {
                return '';
            } 
            return dataPointTranslation;
        },

         /**
         * Returns all available ddr translations for user
         * @returns {object[]} - Array of ddr translations
         * */
         getDDRTranslations = function () {
            return Array.isArray(dDRTranslations) === true ? dDRTranslations : [];
        },

        getTranslationForDDRMessageCode = function (messageCode) {
            var ddrMessageCodeTranslation = getDDRTranslations().find(function (translation) {
                return translation.Code.toUpperCase() === messageCode;
            });

            if (!ddrMessageCodeTranslation) {
                return '';
            } 
            return ddrMessageCodeTranslation.Translation;
            // return 'this is hardcoded test text';
        },
        getAccessoryTranslations = function () {
            return Array.isArray(accessoryTranslations) === true ? accessoryTranslations : [];
        },
        getTranslationForAccessoryCode = function (type, subType) {
            var accessoryTranslation = getAccessoryTranslations().find(function (translation) {
                return translation.AccessoryType.toUpperCase() === type.toUpperCase() && translation.AccessorySubType.toUpperCase() === subType.toUpperCase();
            });

            if (!accessoryTranslation) {
                return '';
            }
            return accessoryTranslation.Translation;
        },
        getUserLicenceInstanceId = function () {
            return userLicenceInstanceId;
        },
        getDashboardConfiguration = function () {
            return dashboardConfiguration;
        },
        setDashboardConfiguration = function (newValue) {
            if (newValue === null) {
                dashboardConfiguration = null;
            } else {
                if (dashboardConfiguration === null) {
                    dashboardConfiguration = new DashboardConfiguration(newValue);
                    // check if the legislations selected are in available legislations
                    
                } 
                if (getDashboardConfiguration().areAllLegislationsInAvailableLegislations(globals.user.getAvailableLegislations()) === false) {
                    getDashboardConfiguration().updateDashboardLegislationsWithDefaultsFromAvailableLegislations(globals.user.getAvailableLegislations());
                }
            }
        },
        getCompanyInterfaceAttributes = function () {
            return companyInterfaceAttributes || [];
        },
        //private functions
        setDefaults = function () {
            firstName = '';
            lastName = '';
            address = '';
            username = '';
            emailAddress = '';
            password = '';
            machineCode = undefined;
            cdg = 0;
            cultureCode = config.defaultCultureCode;
            reminders = '';
            // securityToken = '';
            // validUntil = '';
            // validUntilOffline = '';
            offlineUsage = 0;
            lastUsed = 0;
            // #############################################################################################################
            //new attributes
            setPositionDefault();
            setDecimalPlacesDefault();
            setDirectNumberDefault();
            setMobileNumberDefault();
            setCompanyNumberDefault();
            setPaletteDefault();
            setPermissionsDefault();
            // setReportGraphicsDefault();
            setReminderDateDefault();
            setAccessibleApplicationsDefault();
            setAccessibleVehicleTypesDefault();
            setDefaultApplication();
            setDefaultVehicleType();
            languageId = 0;
            // onRoadCostsVersion = 0;
            setDefaultOnRoadCostsCosts();
            setDefaultOnRoadCostsDiscounts();
            setDefaultTranslations();
            trainingUrl = '';
            supportEmailAddress = '';
            supportTelephoneNumber = '';
            supportTelephoneNumberForLink = '';
            supportWebsite = '';
            countryName = '';
            countryAbbreviation = '';
            setLengthIncrementDefault();
            setMassIncrementDefault();
            setPercentageIncrementDefault();
            setAvailableMeasurementSystemsDefault();
            setActiveMeasurementSystemDefault();
            colourDrawings = true;
            showComplianceScorecard = false;
            showTips = false;
            specifyWheelbaseAs = '';
            loggedInUsingEmail = false;
            emailVerifiedStatus = config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED;
            userType = config.USER_TYPES.UNKNOWN;
            trialCalculationsRemaining = 0;
            numberOfUsesBeforeReferralPrompt = 0;
            numberOfUsesSinceLastPrompt = 0;
            firstLogin = false;
            trialDaysRemaining = 0;
            billingDetails = {};
            appUpdateAvailable = false;
            licenceTransferCodeRequired = false;
            suppressQuickSearchHelperToast = false;
            lastUrlHash = '';
            activeLegislationId = 0;
            setAppOptionItemsDefault();
            intercomUserId = '';
            companyId = 0;
            companyName = '';
            expiryDate = '';
            firstLoginDate = '';
            latestLoginDate = '';
            userPlan = '';
            //userUsageUses = 0;
            clearVehicleSelectionSearchHistory();
            setDefaultMultiselectFilters();
            integrations = [];
            specifyCabDimensionsAs = config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.BBC;
            specifyChassisLengthAs = config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE;
            specifyFuelConsumptionAs = config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE;
            specifyLicencingRegionAs = 0;
            specifyAxleRatingAs = config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED;
            menuItemsHistoryOnSelectionScreen = [];
            numberOfOffersFromServer = 0;
            numberOfBodiesFromServer = 0;
            numberOfAccessoriesFromServer = 0;
            numberOfTrailersFromServer = 0;
            numberOfPayloadsFromServer = 0;
            defaultReportPdfPageSize = config.PDFMAKE_PAGE_SIZE_OPTIONS.A4;
            reportLogo = '';
            userLicenceInstanceId = 0;
            dashboardConfiguration = null;
            isMemberOfDistributorCompany = false;
            companyAdditionalNote = '';
            specifyBodyPositionAs = config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB;
            specifyEquipmentPositionAs = config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB;
            edition = '';
            product = '';
            nteaUserValidationType = config.NTEA_USER_VALIDATION_TYPES.NTEA;
            //specifyCabDimensionsAsOption = config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC;
            //specifyChassisLengthAsOption = config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA;
            // #############################################################################################################
        },

        // setValidUntil = function (validUntilVal, onlineOfflineFlag) {
        //     if (onlineOfflineFlag && onlineOfflineFlag === "online") {
        //         validUntil = validUntilVal;
        //     } else {
        //         validUntilOffline = validUntilVal;
        //     }

        // },

        setActiveMeasurementSystemDefault = function () {
            //activeMeasurementSystem = getAvailableMeasurementSystems()[0];
            if (activeMeasurementSystem === undefined) {
                // Need to set the default to the country default
                activeMeasurementSystem = getAvailableMeasurementSystems()[0];
            } 
        },

        setActiveMeasurementSystem = function (newValue) {
            var temp,
                returnObj;
            if (getAvailableMeasurementSystems().length === 0) {
                setAvailableMeasurementSystemsDefault();
            }
            if (typeof newValue === 'string' || typeof newValue === 'number') {
                temp = parseInt(newValue);
                returnObj = getAvailableMeasurementSystems().filter(function (system) {
                    return system === temp;
                })[0];
            }
            if (typeof newValue === 'object') {
                returnObj = newValue;
            }
            if (returnObj !== undefined) {
                activeMeasurementSystem = returnObj;
                activeMeasurementSystemId = returnObj.id;
            } else {
                activeMeasurementSystem = '';
                activeMeasurementSystemId = 0;
            }
        },

        setActiveMeasurementSystemUsingId = function (id) {
            var temp,
                returnObj;
            if (getAvailableMeasurementSystems().length === 0) {
                setAvailableMeasurementSystemsDefault();
            }
            if (typeof id === 'string' || typeof id === 'number') {
                temp = parseInt(id);
                returnObj = getAvailableMeasurementSystems().filter(function (system) {
                    return system.id === temp;
                })[0];
            }
            if (returnObj === undefined) {
                returnObj = getAvailableMeasurementSystems()[0];
            }
            activeMeasurementSystem = returnObj;
            activeMeasurementSystemId = returnObj.id;
        },

        // setActiveLegislationDefault = function () {
        //     setActiveLegislation(getAvailableLegislations()[0]);
        // },

        setActiveLegislation = function (newValue) {
            var temp,
                returnObj;
            if (typeof newValue === 'string' || typeof newValue === 'number') {
                temp = parseInt(newValue);
                if (temp === 0) {
                    returnObj = getAvailableLegislations()[0];
                } else {
                    returnObj = getAvailableLegislations().filter(function (system) {
                        return system === temp;
                    })[0];
                }
            }
            if (typeof newValue === 'object') {
                returnObj = newValue;
            }
            activeLegislation = returnObj;

            if (returnObj !== undefined && returnObj.id !== undefined) {
                activeLegislationId = returnObj.id;
                availableLegislations.forEach(function (legislation) {
                    if (legislation.id === activeLegislationId) {
                        legislation.isDefault = true;
                    } else {
                        legislation.isDefault = false;
                    }
                });
            } else {
                activeLegislationId = 0;
            }
        },

        setLengthIncrementDefault = function () {
            lengthIncrement = 100;
        },

        setMassIncrementDefault = function () {
            massIncrement = 100;
        },

        setPercentageIncrementDefault = function () {
            percentageIncrement = 5;
        },

        setPositionDefault = function () {
            position = '';
        },

        setDirectNumberDefault = function () {
            directNumber = '';
        },

        setMobileNumberDefault = function () {
            mobileNumber = '';
        },

        setCompanyNumberDefault = function () {
            companyNumber = '';
        },

        setPaletteDefault = function () {
            palette = {
                colour1: config.defaultColour1,
                colour2: config.defaultColour2,
                colour3: config.defaultColour3,
                colour4: config.defaultColour4
            };
        },

        setPermissionsDefault = function () {
            permissions = [];
            for (var prop in config.PERMISSIONS) {
                var permission = config.PERMISSIONS[prop];
                if (permission.Default === 'Y') {
                    permissions.push(permission.Code);
                }
            }
        },

        setAppOptionItemsDefault = function () {
            return [];
        },

        setReportGraphicsDefault = function () {
            reportGraphics = {
                reportHeaderURL: config.defaultReportHeaderURL(),
                reportFooterURL: config.defaultReportFooterURL(),
                landscapeReportHeaderLeftURL: config.defaultReportHeaderURL()
            };
        },

        setDecimalPlacesDefault = function () {
            decimalPlaces = {
                decimalsHighPricedItems: config.defaultDecimalsHighPricedItems,
                decimalsMediumPricedItems: config.defaultDecimalsMediumPricedItems,
                decimalsLowPricedItems: config.defaultDecimalsLowPricedItems
            };
        },

        setReminderDateDefault = function () {
            reminderDate = '';
            reminderDateObv.value = reminderDate;
        },

        setAccessibleApplicationsDefault = function () {
            var temp = [];
            temp.push({
                Code: 'CONSTRUCTION',
                Default: false
            });
            temp.push({
                Code: 'DISTRIBUTION',
                Default: true
            });
            temp.push({
                Code: 'LONGDISTANCE',
                Default: false
            });
            temp.push({
                Code: 'OTHER',
                Default: false
            });
            temp.push({
                Code: 'TRANSPORTATION',
                Default: false
            });
            accessibleApplications = temp;
        },

        setAccessibleVehicleTypesDefault = function () {
            var temp = [];
            temp.push({
                Code: 'RIGID',
                Default: true
            });
            temp.push({
                Code: 'TRUCKTRACTOR',
                Default: false
            });
            temp.push({
                Code: 'BUS',
                Default: false
            });
            accessibleVehicleTypes = temp;
        },

        setDefaultApplication = function () {
            defaultApplication = 'DISTRIBUTION';
        },

        setDefaultVehicleType = function () {
            defaultVehicleType = 'RIGID';
        },

        refreshObservables = function () {
            if (reminders === 'Y') {
                displayReminders.value = true;
            } else {
                displayReminders.value = false;
            }

            reminderDateObv.value = reminderDate;
            reminderCheck.value = globals.compareDateToTodayInDays(reminderDateObv.value);
        },

        setDefaultOnRoadCostsCosts = function () {
            var temp = [];
            temp.push({
                Name: 'REGISTRATIONFEE',
                Type: 'COST',
                ValueType: 'PERCENTAGE',
                Value: 2,
                Order: 1,
                PercentageOf: 'totalNetPrice'
            });
            temp.push({
                Name: 'NUMBERPLATE',
                Type: 'COST',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 2,
                PercentageOf: ''
            });
            temp.push({
                Name: 'INSURANCE',
                Type: 'COST',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 3,
                PercentageOf: ''
            });
            temp.push({
                Name: 'VEHICLECERTIFICATIONFEE',
                Type: 'COST',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 4,
                PercentageOf: ''
            });
            temp.push({
                Name: 'REGISTRATIONSERVICEFEE',
                Type: 'COST',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 5,
                PercentageOf: ''
            });
            temp.push({
                Name: 'ROADTAX',
                Type: 'COST',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 6,
                PercentageOf: ''
            });

            onRoadCostsCosts = temp;
        },

        setDefaultOnRoadCostsDiscounts = function () {
            var temp = [];
            temp.push({
                Name: 'DISCOUNT',
                Type: 'DISCOUNT',
                ValueType: 'CONSTANT',
                Value: 0,
                Order: 1,
                PercentageOf: ''
            });
            onRoadCostsDiscounts = temp;
        },

        setDefaultTranslations = function () {
            var temp = [],
                tempInner = [];
            tempInner.push({
                Code: "REGISTRATIONFEE",
                Translation: "Registration Fee"
            });
            tempInner.push({
                Code: "INSURANCEFEE",
                Translation: "Insurance"
            });
            tempInner.push({
                Code: "NUMBERPLATE",
                Translation: "Number Plate"
            });
            tempInner.push({
                Code: "REGISTRATIONSERVICE",
                Translation: "Registration Service"
            });
            tempInner.push({
                Code: "VEHICLECERTIFICATION",
                Translation: "Vehicle Certification"
            });
            tempInner.push({
                Code: "ROADTAX",
                Translation: "Road Tax"
            });
            tempInner.push({
                Code: "DISCOUNT",
                Translation: "Discount"
            });

            temp.push({
                Id: 373,
                Translations: tempInner
            });

            translations = temp;
        },

        setOnRoadCosts = function (onRoadCosts) {
            if (onRoadCosts.onRoadCostsCosts !== undefined) {
                onRoadCostsCosts = onRoadCosts.onRoadCostsCosts;
            } else {
                setDefaultOnRoadCostsCosts();
            }
            if (onRoadCosts.onRoadCostsDiscounts !== undefined) {
                onRoadCostsDiscounts = onRoadCosts.onRoadCostsDiscounts;
            } else {
                setDefaultOnRoadCostsDiscounts();
            }
        },

        // setDefaultDatabaseAttributeIds = function () {
        //     onRoadCostsDiscounts = [config.databaseAttributeActionTextCode, config.databaseAttributeOnRoadCostCode];
        // },
        setAvailableMeasurementSystemsDefault = function () {
            availableMeasurementSystems = [];
            for (var i = 1; i < 4; i++) {
                //availableMeasurementSystems.push(new ApplicationSettingsMeasurementSystem(i));
                availableMeasurementSystems.push(new globals.getMeasurementSystemUsingType(i));
            }
        },
        setAuthenticationMethod = function (newVal) {
            authenticationMethod = newVal;
            localStorage.setItem('authMethod', authenticationMethod);
            
        },
        setEmail = function (newValue) {
            emailAddress = newValue;
        },

        refreshUser = function (user) {

            var userData;

            try {

                // userData = localStorage.getItem(config.localStorageUser + ':' + user);
                if(localStorage.getItem('localStorageState') === 'ENCRYPTED') {
                    userData = utils.decrypt(localStorage.getItem(config.localStorageUser + ':' + user));
                } else {
                    userData = localStorage.getItem(config.localStorageUser + ':' + user);
                }
                
                // userData = LZString.decompressFromUTF16(localStorage.getItem(config.localStorageUser + ':' + user));

                if (userData === null) {
                    //the user key does not exist, so set the defaults
                    setDefaults();
                } else {
                    //the user data was successfully retrieved
                    userData = JSON.parse(userData);
                    //now update local variables
                    firstName = userData.firstName;
                    lastName = userData.lastName;
                    address = userData.address;
                    username = userData.username;
                    emailAddress = userData.emailAddress;
                    password = userData.password;
                    machineCode = userData.machineCode;
                    if (cdg === undefined || cdg === 0 || cdg === '') {
                        cdg = userData.cdg;
                    }

                    cultureCode = userData.cultureCode;
                    reminders = userData.reminders;
                    // securityToken = userData.securityToken;

                    // validUntil = userData.validUntil;
                    // validUntilOffline = userData.validUntilOffline;
                    offlineUsage = userData.offlineUsage;

                    displayNameObv.value = getDisplayName();

                    userProfileTooltipObv.value = getUserProfileTooltipText();
                    displayEmailAddressObv.value = getEmailAddress();
                    editionAndVersionObv.value = getEditionAndVersionText();
                    productNameObv.value = getProduct();
                    isGoogleUserObv.value = isGoogleUser();
                    canShowChangePasswordRef.value = canShowChangePassword();
                    canShowLogoutRef.value = canShowLogout();
                    allowOpenTsvFileObv.value = allowOpenTsvFile();
                    allowUserAccessToChargebeePortalObv.value = allowUserAccessToChargebeePortal();
                    allowDataUploadObv.value = allowDataUpload();
                    displayEmailVerificationMenuOptionObv.value = displayEmailVerificationMenuOption();
                    canShowShareButtonRef.value = isUserAllowedToShareCalculations();
                    canShowPreparedForSectionRef.value = isUserAllowedToAddPreparedForDetails();
                    //cdgArray(userData.customerDistributionGroups);

                    lastUsed = userData.lastUsed;

                    //check for the existence of the new user attributes, otherwise the default value will be applied
                    if (userData.position !== undefined) {
                        position = userData.position;
                    } else {
                        setPositionDefault()
                    }
                    if (userData.directNumber !== undefined) {
                        directNumber = userData.directNumber;
                    } else {
                        setDirectNumberDefault();
                    }
                    if (userData.mobileNumber !== undefined) {
                        mobileNumber = userData.mobileNumber;
                    } else {
                        setMobileNumberDefault();
                    }
                    if (userData.companyNumber !== undefined) {
                        companyNumber = userData.companyNumber;
                    } else {
                        setCompanyNumberDefault();
                    }
                    if (userData.palette !== undefined) {
                        palette = userData.palette;
                    } else {
                        setPaletteDefault();
                    }
                    if (userData.permissions !== undefined) {
                        permissions = userData.permissions;
                    } else {
                        setPermissionsDefault();
                    }
                    if (userData.appOptionItems !== undefined) {
                        appOptionItems = userData.appOptionItems;
                    } else {
                        setAppOptionItemsDefault();
                    }
                    if (userData.reportGraphics !== undefined && userData.reportGraphics.reportHeaderURL !== undefined) {
                        reportGraphics = userData.reportGraphics;
                        //if (reportGraphics.reportHeaderURL && reportGraphics.reportHeaderURL.indexOf('data') === -1) {
                        //    dataManager.replaceReportImageUrls(reportGraphics);
                        //}
                    } else {
                        // setReportGraphicsDefault();
                    }
                    if (userData.decimalPlaces !== undefined) {
                        decimalPlaces = userData.decimalPlaces;
                    } else {
                        setDecimalPlacesDefault();
                    }
                    if (userData.reminderDate !== undefined) {
                        reminderDate = userData.reminderDate;
                        reminderDateObv.value = reminderDate;
                    } else {
                        setReminderDateDefault();
                    }
                    if (userData.customerDistributionGroups !== undefined) {
                        customerDistributionGroups = userData.customerDistributionGroups;
                    } else {
                        //default
                        customerDistributionGroups = [];
                    }
                    // cdgArray.value = customerDistributionGroups;
                    globals.clearShallowRefArrayAndAddItems(cdgArray, customerDistributionGroups);
                    if (userData.accessibleApplications !== undefined) {
                        accessibleApplications = userData.accessibleApplications;
                    } else {
                        setAccessibleApplicationsDefault();
                    }
                    if (userData.accessibleVehicleTypes !== undefined) {
                        accessibleVehicleTypes = userData.accessibleVehicleTypes;
                    } else {
                        setAccessibleVehicleTypesDefault();
                    }
                    if (userData.defaultApplication !== undefined) {
                        defaultApplication = userData.defaultApplication;
                    } else {
                        setDefaultApplication();
                    }
                    if (userData.defaultVehicleType !== undefined) {
                        defaultVehicleType = userData.defaultVehicleType;
                    } else {
                        setDefaultVehicleType();
                    }
                    if (userData.onRoadCostsCosts !== undefined) {
                        onRoadCostsCosts = userData.onRoadCostsCosts;
                    } else {
                        setDefaultOnRoadCostsCosts();
                    }
                    if (userData.onRoadCostsDiscounts !== undefined) {
                        onRoadCostsDiscounts = userData.onRoadCostsDiscounts;
                    } else {
                        setDefaultOnRoadCostsDiscounts();
                    }
                    // if (userData.onRoadCostsVersion !== undefined) {
                    //     onRoadCostsVersion = userData.onRoadCostsVersion;
                    // }
                    if (userData.translations !== undefined) {
                        translations = userData.translations;
                    } else {
                        setDefaultTranslations();
                    }
                    if (userData.legislations !== undefined) {
                        legislations = userData.legislations;
                    }
                    if (userData.tolls !== undefined) {
                        tolls = userData.tolls;
                    }
                    if (userData.integrations !== undefined) {
                        integrations = userData.integrations;
                    }
                    if (userData.trainingUrl !== undefined) {
                        trainingUrl = userData.trainingUrl;
                    }
                    if (userData.supportEmailAddress !== undefined) {
                        supportEmailAddress = userData.supportEmailAddress;
                    }
                    if (userData.supportTelephoneNumber !== undefined) {
                        supportTelephoneNumber = userData.supportTelephoneNumber;
                    }
                    if (userData.supportTelephoneNumberForLink !== undefined) {
                        supportTelephoneNumberForLink = userData.supportTelephoneNumberForLink;
                    }
                    if (userData.supportWebsite !== undefined) {
                        supportWebsite = userData.supportWebsite;
                    }
                    if (userData.countryName !== undefined) {
                        countryName = userData.countryName;
                    }
                    if (userData.countryAbbreviation !== undefined) {
                        countryAbbreviation = userData.countryAbbreviation;
                    }
                    if (userData.availableLegislations !== undefined) {
                        availableLegislations = userData.availableLegislations;
                    }
                    //setAvailableMeasurementSystemsDefault();
                    //setAvailableApplicationTypesDefault();
                    if (userData.activeLegislation !== undefined && (userData.activeLegislation.id !== undefined || typeof userData.activeLegislation === 'string' || typeof userData.activeLegislation === 'number')) {
                        setActiveLegislation(userData.activeLegislation);
                    }
                    if (userData.activeMeasurementSystem !== undefined) {
                        setActiveMeasurementSystem(userData.activeMeasurementSystem);
                    }
                    if (userData.lengthIncrement !== undefined) {
                        lengthIncrement = userData.lengthIncrement;
                    }
                    if (userData.massIncrement !== undefined) {
                        massIncrement = userData.massIncrement;
                    }
                    if (userData.percentageIncrement !== undefined) {
                        percentageIncrement = userData.percentageIncrement;
                    }
                    if (userData.colourDrawings !== undefined) {
                        colourDrawings = userData.colourDrawings;
                    }
                    if (userData.showComplianceScorecard !== undefined) {
                        showComplianceScorecard = userData.showComplianceScorecard;
                    }
                    if (userData.showTips !== undefined) {
                        showTips = userData.showTips;
                    }
                    if (userData.specifyWheelbaseAs !== undefined) {
                        specifyWheelbaseAs = userData.specifyWheelbaseAs;
                    }
                    if (userData.loggedInUsingEmail !== undefined) {
                        loggedInUsingEmail = userData.loggedInUsingEmail;
                    }
                    if (userData.emailVerifiedStatus !== undefined) {
                        emailVerifiedStatus = userData.emailVerifiedStatus;
                    }
                    if (userData.userType !== undefined) {
                        userType = userData.userType;
                    }
                    if (userData.trialCalculationsRemaining !== undefined) {
                        if (userData.trialCalculationsRemaining < 0) {
                            trialCalculationsRemaining = 0;
                        } else {
                            trialCalculationsRemaining = userData.trialCalculationsRemaining;
                        }

                    }
                    if (userData.numberOfUsesBeforeReferralPrompt !== undefined) {
                        //if (userData.numberOfUsesBeforeReferralPrompt < 0) {
                        //    numberOfUsesBeforeReferralPrompt = 0;
                        //} else {
                        //    numberOfUsesBeforeReferralPrompt = userData.numberOfUsesBeforeReferralPrompt;
                        //}
                        numberOfUsesBeforeReferralPrompt = userData.numberOfUsesBeforeReferralPrompt;
                    }
                    if (userData.numberOfUsesSinceLastPrompt !== undefined) {
                        //if (userData.numberOfUsesSinceLastPrompt < 0) {
                        //    numberOfUsesSinceLastPrompt = 0;
                        //} else {
                        //    numberOfUsesSinceLastPrompt = userData.numberOfUsesSinceLastPrompt;
                        //}
                        numberOfUsesSinceLastPrompt = userData.numberOfUsesSinceLastPrompt;
                    }
                    if (userData.firstLogin !== undefined) {
                        firstLogin = userData.firstLogin;
                    }
                    if (userData.trialDaysRemaining !== undefined) {
                        trialDaysRemaining = userData.trialDaysRemaining;
                    }
                    if (userData.billingDetails !== undefined) {
                        billingDetails = userData.billingDetails;
                    }
                    if (userData.appUpdateAvailable !== undefined) {
                        appUpdateAvailable = userData.appUpdateAvailable;
                    }
                    if (userData.licenceTransferCodeRequired !== undefined) {
                        licenceTransferCodeRequired = userData.licenceTransferCodeRequired;
                    }
                    if (userData.suppressQuickSearchHelperToast !== undefined) {
                        suppressQuickSearchHelperToast = userData.suppressQuickSearchHelperToast;
                    }
                    if (userData.lastUrlHash !== undefined) {
                        lastUrlHash = userData.lastUrlHash;
                    }
                    if (userData.intercomUserId !== undefined) {
                        intercomUserId = userData.intercomUserId;
                    }
                    if (userData.companyId !== undefined) {
                        companyId = userData.companyId;
                    }
                    if (userData.companyName !== undefined) {
                        companyName = userData.companyName;
                    }
                    if (userData.expiryDate !== undefined) {
                        expiryDate = userData.expiryDate;
                    }
                    if (userData.firstLoginDate !== undefined) {
                        firstLoginDate = userData.firstLoginDate;
                    }
                    if (userData.latestLoginDate !== undefined) {
                        latestLoginDate = userData.latestLoginDate;
                    }
                    if (userData.userPlan !== undefined) {
                        userPlan = userData.userPlan;
                    }
                    if (userData.authenticationMethod !== undefined) {
                        authenticationMethod = userData.authenticationMethod;
                    }
                    if (userData.accessCode !== undefined) {
                        accessCode = userData.accessCode;
                    }
                    if (userData.specifyCabDimensionsAs !== undefined) {
                        specifyCabDimensionsAs = userData.specifyCabDimensionsAs;
                    }
                    if (userData.specifyChassisLengthAs !== undefined) {
                        specifyChassisLengthAs = userData.specifyChassisLengthAs;
                    }
                    if (userData.specifyFuelConsumptionAs !== undefined) {
                        specifyFuelConsumptionAs = userData.specifyFuelConsumptionAs;
                    }
                    if (userData.specifyLicencingRegionAs !== undefined) {
                        specifyLicencingRegionAs = userData.specifyLicencingRegionAs;
                    }
                    if (userData.specifyAxleRatingAs !== undefined) {
                        specifyAxleRatingAs = userData.specifyAxleRatingAs;
                    }
                    if (userData.menuItemsHistoryOnSelectionScreen !== undefined) {
                        menuItemsHistoryOnSelectionScreen = userData.menuItemsHistoryOnSelectionScreen;
                    }
                    if (userData.numberOfOffersFromServer !== undefined) {
                        numberOfOffersFromServer = userData.numberOfOffersFromServer;
                    }
                    if (userData.numberOfBodiesFromServer !== undefined) {
                        numberOfBodiesFromServer = userData.numberOfBodiesFromServer;
                    }
                    if (userData.numberOfAccessoriesFromServer !== undefined) {
                        numberOfAccessoriesFromServer = userData.numberOfAccessoriesFromServer;
                    }
                    if (userData.numberOfTrailersFromServer !== undefined) {
                        numberOfTrailersFromServer = userData.numberOfTrailersFromServer;
                    }
                    if (userData.numberOfPayloadsFromServer !== undefined) {
                        numberOfPayloadsFromServer = userData.numberOfPayloadsFromServer;
                    }
                    if (userData.defaultReportPdfPageSize !== undefined) {
                        //defaultReportPdfPageSize = userData.defaultReportPdfPageSize;
                        setDefaultReportPdfPageSize(userData.defaultReportPdfPageSize);
                    }
                    if (userData.reportLogo !== undefined) {
                        setReportLogo(userData.reportLogo);
                    }
                    if (userData.userLicenceInstanceId !== undefined) {
                        userLicenceInstanceId = userData.userLicenceInstanceId;
                    }
                    if (userData.dataPoints !== undefined) {
                        dataPoints = userData.dataPoints;
                    }
                    if (userData.dDRTranslations !== undefined) {
                        dDRTranslations = userData.dDRTranslations;
                    }
                    if (userData.accessoryTranslations !== undefined) {
                        accessoryTranslations = userData.accessoryTranslations;
                    }
                    if (userData.companyInterfaceAttributes !== undefined) {
                        companyInterfaceAttributes = userData.companyInterfaceAttributes;
                    }
                    if (userData.dashboardConfiguration !== undefined) {
                        setDashboardConfiguration(userData.dashboardConfiguration);
                    }
                    if (userData.isMemberOfDistributorCompany !== undefined) {
                        isMemberOfDistributorCompany = userData.isMemberOfDistributorCompany;
                    }
                    if (userData.companyAdditionalNote !== undefined) {
                        companyAdditionalNote = userData.companyAdditionalNote;
                    }
                    if (userData.specifyBodyPositionAs !== undefined) {
                        specifyBodyPositionAs = userData.specifyBodyPositionAs;
                    }
                    if (userData.specifyEquipmentPositionAs !== undefined) {
                        specifyEquipmentPositionAs = userData.specifyEquipmentPositionAs;
                    }
                    if (userData.edition !== undefined) {
                        edition = userData.edition;
                    }
                    if (userData.product !== undefined) {
                        product = userData.product;
                    }                    
                    if (userData.reportViewLayout !== undefined) {
                        reportViewLayout = userData.reportViewLayout;
                    }
                    if (userData.reportViews !== undefined) {
                        setReportViews(userData.reportViews);
                    }
                    if(userData.nteaUserValidationType !== undefined) {
                        setNteaUserValidationType(userData.nteaUserValidationType);
                    }
                }

            } catch (e) {
                //private browsing is enabled so can't get user info, so set defaults
                setDefaults();
            }

        },

        //public functions

        // setLocalStorageEncryptionStateFlag = function () {
        //     var latestTime = 0;
        //     var latestUser = '';
        //     var userData, userDataUnknownState;

        //     try {

        //         for (var k = 0; k < localStorage.length; k++) {

        //             var keyStartsWith = localStorage.key(k).split(':');

        //             if (keyStartsWith.length > 0) {
        //                 if (keyStartsWith[0] === config.localStorageUser) {
        //                     // userData = localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]);
        //                     userDataUnknownState = localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]);
        //                     // userData = LZString.decompressFromUTF16(localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]));
        //                     userData = JSON.parse(userData);
        //                     if (userData.lastUsed > latestTime) {
        //                         latestTime = userData.lastUsed;
        //                         latestUser = userData.username;
        //                     }
        //                 }
        //             }
        //         }

        //         //now we have the latest user used
        //         refreshUser(latestUser);

        //         //refresh the observables associated to this user
        //         refreshObservables();

        //     } catch (e) {
        //         //private browsing is enabled so can't get user info, so set defaults
        //         setDefaults;
        //     }
        // },

        getMostRecentUser = function () {

            var latestTime = 0;
            var latestUser = '';
            var userData;

            try {

                for (var k = 0; k < localStorage.length; k++) {

                    var keyStartsWith = localStorage.key(k).split(':');

                    if (keyStartsWith.length > 0) {
                        if (keyStartsWith[0] === config.localStorageUser) {
                            if(localStorage.getItem('localStorageState') === 'ENCRYPTED') {
                                userData = utils.decrypt(localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]));
                            } else {
                                userData = localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]);
                            }
                            // userData = LZString.decompressFromUTF16(localStorage.getItem(config.localStorageUser + ':' + keyStartsWith[1]));
                            userData = JSON.parse(userData);
                            if (userData.lastUsed > latestTime) {
                                latestTime = userData.lastUsed;
                                latestUser = userData.username;
                            }
                        }
                    }
                }

                //now we have the latest user used
                refreshUser(latestUser);

                //refresh the observables associated to this user
                refreshObservables();

            } catch (e) {
                //private browsing is enabled so can't get user info, so set defaults
                setDefaults();
            }
        },

        updateUser = function (userDetails) {

            //if the username is provided, try to refresh that user from storage
            if (userDetails.username !== undefined) {
                username = userDetails.username;
            }

            //if username is not explicitly provided, assume the current username is still valid
            refreshUser(username);
            

            //now update whats in memory with what has been provided
            if (userDetails.firstName !== undefined) {
                firstName = userDetails.firstName;
            }
            if (userDetails.lastName !== undefined) {
                lastName = userDetails.lastName;
            }
            if (userDetails.address !== undefined) {
                address = userDetails.address;
            }
            if (userDetails.username !== undefined) {
                username = userDetails.username;
            }
            if (userDetails.emailAddress !== undefined) {
                emailAddress = userDetails.emailAddress;
            }
            if (userDetails.password !== undefined) {
                password = userDetails.password;
            }
            if (userDetails.machineCode !== undefined) {
                machineCode = userDetails.machineCode;
            }
            if (userDetails.cdg !== undefined) {
                cdg = userDetails.cdg;
            }
            if (userDetails.cultureCode !== undefined) {
                cultureCode = userDetails.cultureCode;
            }
            if (userDetails.reminders !== undefined) {
                if (userDetails.reminders === 'Y' || userDetails.reminders === true) {
                    reminders = 'Y';
                } else {
                    reminders = 'N';
                }
            }
            // if (userDetails.securityToken !== undefined) {
            //     securityToken = userDetails.securityToken;
            // }
            // if (userDetails.validUntil !== undefined) {
            //     validUntil = userDetails.validUntil;
            // }
            // if (userDetails.validUntilOffline !== undefined) {
            //     validUntilOffline = userDetails.validUntilOffline;
            // }
            if (userDetails.offlineUsage !== undefined) {
                offlineUsage = userDetails.offlineUsage;
            }
            if (userDetails.lastUsed !== undefined) {
                lastUsed = userDetails.lastUsed;
            } else {
                lastUsed = Math.round(Date.now() / 1000);
            }
            if (userDetails.position !== undefined) {
                position = userDetails.position;
            }
            if (userDetails.directNumber !== undefined) {
                directNumber = userDetails.directNumber;
            }
            if (userDetails.mobileNumber !== undefined) {
                mobileNumber = userDetails.mobileNumber;
            }
            if (userDetails.companyNumber !== undefined) {
                companyNumber = userDetails.companyNumber;
            }
            if (userDetails.palette !== undefined) {
                palette = userDetails.palette;
            }
            if (userDetails.permissions !== undefined) {
                permissions = userDetails.permissions;
            }
            if (userDetails.appOptionItems !== undefined) {
                appOptionItems = userDetails.appOptionItems;
            }
            if (userDetails.reportGraphics !== undefined) {
                reportGraphics = userDetails.reportGraphics;
            }
            if (userDetails.decimalPlaces !== undefined) {
                decimalPlaces = userDetails.decimalPlaces;
            }
            if (userDetails.reminderDate !== undefined) {
                reminderDate = userDetails.reminderDate;
            }
            if (userDetails.customerDistributionGroups !== undefined) {
                customerDistributionGroups = userDetails.customerDistributionGroups;
            }
            if (userDetails.accessibleApplications !== undefined) {
                accessibleApplications = userDetails.accessibleApplications;
            }
            if (userDetails.accessibleVehicleTypes !== undefined) {
                accessibleVehicleTypes = userDetails.accessibleVehicleTypes;
            }
            if (userDetails.defaultApplication !== undefined) {
                defaultApplication = userDetails.defaultApplication;
            }
            if (userDetails.defaultVehicleType !== undefined) {
                defaultVehicleType = userDetails.defaultVehicleType;
            }
            if (userDetails.onRoadCostsCosts !== undefined) {
                onRoadCostsCosts = userDetails.onRoadCostsCosts;
            }
            if (userDetails.onRoadCostsDiscounts !== undefined) {
                onRoadCostsDiscounts = userDetails.onRoadCostsDiscounts;
            }
            if (userDetails.trainingUrl !== undefined) {
                trainingUrl = userDetails.trainingUrl;
            }
            if (userDetails.translations !== undefined) {
                translations = userDetails.translations;
            }
            if (userDetails.legislations !== undefined) {
                legislations = userDetails.legislations;
            }
            if (userDetails.tolls !== undefined) {
                tolls = userDetails.tolls;
            }
            if (userDetails.integrations !== undefined) {
                integrations = userDetails.integrations;
            }
            // if (userDetails.onRoadCostsVersion !== undefined) {
            //     onRoadCostsVersion = userDetails.onRoadCostsVersion;
            // }
            if (userDetails.supportEmailAddress !== undefined) {
                supportEmailAddress = userDetails.supportEmailAddress;
            }
            if (userDetails.supportTelephoneNumber !== undefined) {
                supportTelephoneNumber = userDetails.supportTelephoneNumber;
            }
            if (userDetails.supportTelephoneNumberForLink !== undefined) {
                supportTelephoneNumberForLink = userDetails.supportTelephoneNumberForLink;
            }
            if (userDetails.supportWebsite !== undefined) {
                supportWebsite = userDetails.supportWebsite;
            }
            if (userDetails.countryName !== undefined) {
                countryName = userDetails.countryName;
            }
            if (userDetails.countryAbbreviation !== undefined) {
                countryAbbreviation = userDetails.countryAbbreviation;
            }
            if (userDetails.availableLegislations !== undefined) {
                availableLegislations = userDetails.availableLegislations;
            }
            if (userDetails.activeLegislation !== undefined && (userDetails.activeLegislation.id !== undefined || typeof userDetails.activeLegislation === 'string' || typeof userDetails.activeLegislation === 'number')) {
                if (userDetails.activeLegislation.id !== undefined && userDetails.activeLegislation.id === 0) {
                    activeLegislation = {};
                    activeLegislationId = 0;
                } else {
                    setActiveLegislation(userDetails.activeLegislation);
                }

            }
            if (userDetails.activeMeasurementSystem !== undefined) {
                setActiveMeasurementSystem(userDetails.activeMeasurementSystem);
            }
            if (userDetails.activeLegislationId !== undefined) {
                activeLegislationId = userDetails.activeLegislationId;
            }
            if (userDetails.activeMeasurementSystemId !== undefined) {
                setActiveMeasurementSystemUsingId(userDetails.activeMeasurementSystemId);
                //activeMeasurementSystemId = userDetails.activeMeasurementSystemId;
            }
            if (userDetails.lengthIncrement !== undefined) {
                if (userDetails.lengthIncrement !== 0) {
                    lengthIncrement = userDetails.lengthIncrement;
                } else {
                    lengthIncrement = getActiveMeasurementSystem().defaultLengthIncrement;
                }
            }
            if (userDetails.massIncrement !== undefined) {
                if (userDetails.massIncrement !== 0) {
                    massIncrement = userDetails.massIncrement;
                } else {
                    massIncrement = getActiveMeasurementSystem().defaultMassIncrement;
                }
            }
            if (userDetails.percentageIncrement !== undefined) {
                if (userDetails.percentageIncrement !== 0) {
                    percentageIncrement = userDetails.percentageIncrement;
                } else {
                    percentageIncrement = getActiveMeasurementSystem().defaultPercentageIncrement;
                }
            }
            if (userDetails.colourDrawings !== undefined) {
                colourDrawings = userDetails.colourDrawings;
            }
            if (userDetails.showComplianceScorecard !== undefined) {
                showComplianceScorecard = userDetails.showComplianceScorecard;
            }
            if (userDetails.showTips !== undefined) {
                showTips = userDetails.showTips;
            }
            if (userDetails.specifyWheelbaseAs !== undefined) {
                specifyWheelbaseAs = userDetails.specifyWheelbaseAs;
            }
            if (userDetails.loggedInUsingEmail !== undefined) {
                loggedInUsingEmail = userDetails.loggedInUsingEmail;
            }
            if (userDetails.emailVerifiedStatus !== undefined) {
                emailVerifiedStatus = userDetails.emailVerifiedStatus;
            }
            if (userDetails.userType !== undefined) {
                userType = userDetails.userType;
            }
            if (userDetails.trialCalculationsRemaining !== undefined) {
                if (userDetails.trialCalculationsRemaining < 0) {
                    trialCalculationsRemaining = 0;
                } else {
                    trialCalculationsRemaining = userDetails.trialCalculationsRemaining;
                }
            }
            if (userDetails.numberOfUsesBeforeReferralPrompt !== undefined) {
                //if (userDetails.numberOfUsesBeforeReferralPrompt < 0) {
                //    numberOfUsesBeforeReferralPrompt = 0;
                //} else {
                //    numberOfUsesBeforeReferralPrompt = userDetails.numberOfUsesBeforeReferralPrompt;
                //}
                numberOfUsesBeforeReferralPrompt = userDetails.numberOfUsesBeforeReferralPrompt;
            }
            if (userDetails.numberOfUsesSinceLastPrompt !== undefined) {
                //if (userDetails.numberOfUsesSinceLastPrompt < 0) {
                //    numberOfUsesSinceLastPrompt = 0;
                //} else {
                //    numberOfUsesSinceLastPrompt = userDetails.numberOfUsesSinceLastPrompt;
                //}
                numberOfUsesSinceLastPrompt = userDetails.numberOfUsesSinceLastPrompt;
            }
            if (userDetails.firstLogin !== undefined) {
                firstLogin = userDetails.firstLogin;
            }
            if (userDetails.trialDaysRemaining !== undefined) {
                trialDaysRemaining = userDetails.trialDaysRemaining;
            }
            if (userDetails.billingDetails !== undefined) {
                billingDetails = userDetails.billingDetails;
            }
            if (userDetails.appUpdateAvailable !== undefined) {
                appUpdateAvailable = userDetails.appUpdateAvailable;
            }
            if (userDetails.licenceTransferCodeRequired !== undefined) {
                licenceTransferCodeRequired = userDetails.licenceTransferCodeRequired;
            }
            if (userDetails.suppressQuickSearchHelperToast !== undefined) {
                suppressQuickSearchHelperToast = userDetails.suppressQuickSearchHelperToast;
            }
            if (userDetails.lastUrlHash !== undefined) {
                lastUrlHash = userDetails.lastUrlHash;
            }
            if (userDetails.intercomUserId !== undefined) {
                intercomUserId = userDetails.intercomUserId;
            }
            if (userDetails.companyId !== undefined) {
                companyId = userDetails.companyId;
            }
            if (userDetails.companyName !== undefined) {
                companyName = userDetails.companyName;
            }
            if (userDetails.expiryDate !== undefined) {
                expiryDate = userDetails.expiryDate;
            }
            if (userDetails.firstLoginDate !== undefined) {
                firstLoginDate = userDetails.firstLoginDate;
            }
            if (userDetails.latestLoginDate !== undefined) {
                latestLoginDate = userDetails.latestLoginDate;
            }
            if (userDetails.userPlan !== undefined) {
                userPlan = userDetails.userPlan;
            }
            if (userDetails.authenticationMethod !== undefined) {
                authenticationMethod = userDetails.authenticationMethod;
                localStorage.setItem('authMethod', authenticationMethod);
            }
            if (userDetails.accessCode !== undefined) {
                accessCode = userDetails.accessCode;
            }
            if (userDetails.availableManufacturerMakesArray !== undefined) {
                availableManufacturerMakesArray = userDetails.availableManufacturerMakesArray;
            }
            if (userDetails.availableManufacturerRangesArray !== undefined) {
                availableManufacturerRangesArray = userDetails.availableManufacturerRangesArray;
            }
            if (userDetails.availableManufacturerAxleLayoutsArray !== undefined) {
                availableManufacturerAxleLayoutsArray = userDetails.availableManufacturerAxleLayoutsArray;
            }
            if (userDetails.availableManufacturerVehicleTypesArray !== undefined) {
                availableManufacturerVehicleTypesArray = userDetails.availableManufacturerVehicleTypesArray;
            }
            if (userDetails.availableManufacturerStatusesArray !== undefined) {
                availableManufacturerStatusesArray = userDetails.availableManufacturerStatusesArray;
            }
            if (userDetails.availableManufacturerCountriesArray !== undefined) {
                availableManufacturerCountriesArray = userDetails.availableManufacturerCountriesArray;
            }
            if (userDetails.availableGenericAxleLayoutsArray !== undefined) {
                availableGenericAxleLayoutsArray = userDetails.availableGenericAxleLayoutsArray;
            }
            if (userDetails.availableGenericVehicleTypesArray !== undefined) {
                availableGenericVehicleTypesArray = userDetails.availableGenericVehicleTypesArray;
            }
            if (userDetails.specifyCabDimensionsAs !== undefined) {
                specifyCabDimensionsAs = userDetails.specifyCabDimensionsAs;
            }
            if (userDetails.specifyChassisLengthAs !== undefined) {
                specifyChassisLengthAs = userDetails.specifyChassisLengthAs;
            }
            if (userDetails.specifyFuelConsumptionAs !== undefined) {
                specifyFuelConsumptionAs = userDetails.specifyFuelConsumptionAs;
            }
            if (userDetails.specifyLicencingRegionAs !== undefined) {
                specifyLicencingRegionAs = userDetails.specifyLicencingRegionAs;
            }
            if (userDetails.specifyAxleRatingAs !== undefined) {
                specifyAxleRatingAs = userDetails.specifyAxleRatingAs;
            }
            if (userDetails.menuItemsHistoryOnSelectionScreen !== undefined) {
                menuItemsHistoryOnSelectionScreen = userDetails.menuItemsHistoryOnSelectionScreen;
            }
            if (userDetails.numberOfOffersFromServer !== undefined) {
                numberOfOffersFromServer = userDetails.numberOfOffersFromServer;
            }
            if (userDetails.numberOfBodiesFromServer !== undefined) {
                numberOfBodiesFromServer = userDetails.numberOfBodiesFromServer;
            }
            if (userDetails.numberOfAccessoriesFromServer !== undefined) {
                numberOfAccessoriesFromServer = userDetails.numberOfAccessoriesFromServer;
            }
            if (userDetails.numberOfTrailersFromServer !== undefined) {
                numberOfTrailersFromServer = userDetails.numberOfTrailersFromServer;
            }
            if (userDetails.numberOfPayloadsFromServer !== undefined) {
                numberOfPayloadsFromServer = userDetails.numberOfPayloadsFromServer;
            }
            if (userDetails.defaultReportPdfPageSize !== undefined) {
                //defaultReportPdfPageSize = userDetails.defaultReportPdfPageSize;
                setDefaultReportPdfPageSize(userDetails.defaultReportPdfPageSize);
            }
            if (userDetails.reportLogo !== undefined) {
                setReportLogo(userDetails.reportLogo);
            }
            if (userDetails.userLicenceInstanceId !== undefined) {
                userLicenceInstanceId = userDetails.userLicenceInstanceId;
            }
            if (userDetails.dataPoints !== undefined) {
                dataPoints = userDetails.dataPoints;
            }
            if (userDetails.dDRTranslations !== undefined) {
                dDRTranslations = userDetails.dDRTranslations;
            }
            if (userDetails.accessoryTranslations !== undefined) {
                accessoryTranslations = userDetails.accessoryTranslations;
            }
            if (userDetails.companyInterfaceAttributes !== undefined) {
                companyInterfaceAttributes = userDetails.companyInterfaceAttributes;
            }
            if (userDetails.dashboardConfiguration !== undefined) {
                setDashboardConfiguration(null);
                setDashboardConfiguration(userDetails.dashboardConfiguration);
            }
            if (userDetails.isMemberOfDistributorCompany !== undefined) {
                isMemberOfDistributorCompany = userDetails.isMemberOfDistributorCompany;
            }
            if (userDetails.companyAdditionalNote !== undefined) {
                companyAdditionalNote = userDetails.companyAdditionalNote;
            }
            if (userDetails.specifyBodyPositionAs !== undefined) {
                specifyBodyPositionAs = userDetails.specifyBodyPositionAs;
            }
            if (userDetails.specifyEquipmentPositionAs !== undefined) {
                specifyEquipmentPositionAs = userDetails.specifyEquipmentPositionAs;
            }
            if (userDetails.edition !== undefined) {
                edition = userDetails.edition;
            }
            if (userDetails.product !== undefined) {
                product = userDetails.product;
            }
            if (userDetails.reportViewLayout !== undefined) {
                reportViewLayout = userDetails.reportViewLayout;
            }
            if (userDetails.reportViews !== undefined) {
                setReportViews(null);
                setReportViews(userDetails.reportViews);
            }
            if(userDetails.nteaUserValidationType !== undefined) {
                setNteaUserValidationType(userDetails.nteaUserValidationType);
            }

            var machineCodeValForStorage = machineCode === undefined ? '' : machineCode;
            var dashboardConfigurationForStorage = getDashboardConfiguration() !== null ? JSON.stringify(getDashboardConfiguration().getObjectToBeSaved()) : null;
            var reportViewsForStorage = JSON.stringify(getReportViewsObjects());

            var passwordToWrite = '"' + password + '"';
            if (password.indexOf("\"") !== -1 || password.indexOf("\\") !== -1) {
                passwordToWrite = JSON.stringify(password);
            }
            //var companyAdditionalNoteToSave = JSON.stringify(companyAdditionalNote);
            var companyAdditionalNoteToSave = companyAdditionalNote;
            if (typeof companyAdditionalNote === 'string' && companyAdditionalNote.includes('\n')) {
                //companyAdditionalNoteToSave = JSON.stringify(companyAdditionalNote).replaceAll('""', '"');
                companyAdditionalNoteToSave = JSON.stringify(companyAdditionalNote).slice(1, -1);
            }

            //now try to save this to local storage
            var userData = JSON.parse('{ ' +
                '"firstName":"' + firstName + '", ' +
                '"lastName":"' + lastName + '", ' +
                '"position":"' + position + '", ' +
                '"directNumber":"' + directNumber + '", ' +
                '"mobileNumber":"' + mobileNumber + '", ' +
                '"companyNumber":"' + companyNumber + '", ' +
                '"address":"' + address + '", ' +
                '"username":"' + username + '", ' +
                '"emailAddress":"' + emailAddress + '", ' +
                '"password":' + passwordToWrite + ', ' +
                '"machineCode":"' + machineCodeValForStorage + '", ' +
                '"cdg":' + Number(cdg) + ', ' +
                '"cultureCode":"' + cultureCode + '", ' +
                '"reminders":"' + reminders + '", ' +
                // '"securityToken":"' + securityToken + '", ' +
                // '"validUntil":"' + validUntil + '", ' +
                // '"validUntilOffline":"' + validUntilOffline + '", ' +
                '"offlineUsage":"' + offlineUsage + '", ' +
                '"lastUsed":"' + lastUsed + '", ' +
                '"palette":' + JSON.stringify(palette) + ', ' +
                '"permissions":' + JSON.stringify(permissions) + ', ' +
                '"appOptionItems":' + JSON.stringify(appOptionItems || []) + ', ' +
                '"reportGraphics":' + JSON.stringify(reportGraphics || {}) + ', ' +
                '"decimalPlaces":' + JSON.stringify(decimalPlaces) + ', ' +
                '"customerDistributionGroups":' + JSON.stringify(customerDistributionGroups || []) + ', ' +
                '"accessibleApplications":' + JSON.stringify(accessibleApplications) + ', ' +
                '"accessibleVehicleTypes":' + JSON.stringify(accessibleVehicleTypes) + ', ' +
                '"defaultApplication":"' + defaultApplication + '", ' +
                '"defaultVehicleType":"' + defaultVehicleType + '", ' +
                '"onRoadCostsCosts":' + JSON.stringify(onRoadCostsCosts) + ', ' +
                '"onRoadCostsDiscounts":' + JSON.stringify(onRoadCostsDiscounts) + ', ' +
                '"translations":' + JSON.stringify(translations) + ', ' +
                '"legislations":' + JSON.stringify(legislations) + ', ' +
                '"tolls":' + JSON.stringify(tolls) + ', ' +
                '"integrations":' + JSON.stringify(integrations) + ', ' +
                '"availableLegislations":' + JSON.stringify(availableLegislations) + ', ' +
                '"activeLegislation":' + JSON.stringify(activeLegislation || {}) + ', ' +
                '"activeLegislationId":' + Number(activeLegislationId) + ', ' +
                '"activeMeasurementSystem":' + JSON.stringify(activeMeasurementSystem) + ', ' +
                '"activeMeasurementSystemId":' + Number(activeMeasurementSystemId || 0) + ', ' +
                '"trainingUrl":"' + trainingUrl + '", ' +
                // '"onRoadCostsVersion":"' + onRoadCostsVersion + '", ' +
                '"supportEmailAddress":"' + supportEmailAddress + '", ' +
                '"supportTelephoneNumber":"' + supportTelephoneNumber + '", ' +
                '"supportTelephoneNumberForLink":"' + supportTelephoneNumberForLink + '", ' +
                '"supportWebsite":"' + supportWebsite + '", ' +
                '"countryName":"' + countryName + '", ' +
                '"countryAbbreviation":"' + countryAbbreviation + '", ' +
                '"lengthIncrement":' + Number(lengthIncrement) + ', ' +
                '"massIncrement":' + Number(massIncrement) + ', ' +
                '"percentageIncrement":' + Number(percentageIncrement) + ', ' +
                '"reminderDate":"' + reminderDate + '", ' +
                '"colourDrawings":' + colourDrawings + ', ' +
                '"showComplianceScorecard":' + showComplianceScorecard + ', ' +
                '"showTips":' + showTips + ', ' +
                '"specifyWheelbaseAs":"' + specifyWheelbaseAs + '", ' +
                '"loggedInUsingEmail":' + loggedInUsingEmail + ', ' +
                '"emailVerifiedStatus":"' + emailVerifiedStatus + '", ' +
                '"userType":"' + userType + '",' +
                '"trialCalculationsRemaining":' + Number(trialCalculationsRemaining) + ', ' +
                '"numberOfUsesBeforeReferralPrompt":' + Number(numberOfUsesBeforeReferralPrompt) + ', ' +
                '"numberOfUsesSinceLastPrompt":' + Number(numberOfUsesSinceLastPrompt) + ', ' +
                '"firstLogin":' + firstLogin + ', ' +
                '"trialDaysRemaining":' + Number(trialDaysRemaining) + ', ' +
                '"billingDetails":' + JSON.stringify(billingDetails || {}) + ', ' +
                '"appUpdateAvailable":' + appUpdateAvailable + ', ' +
                '"licenceTransferCodeRequired":' + licenceTransferCodeRequired + ', ' +
                '"suppressQuickSearchHelperToast":' + suppressQuickSearchHelperToast + ', ' +
                '"lastUrlHash":"' + lastUrlHash + '", ' +
                '"intercomUserId":"' + intercomUserId + '", ' +
                '"companyId":"' + companyId + '", ' +
                '"companyName":"' + companyName + '", ' +
                '"expiryDate":"' + expiryDate + '", ' +
                '"firstLoginDate":"' + firstLoginDate + '", ' +
                '"latestLoginDate":"' + latestLoginDate + '", ' +
                '"authenticationMethod":"' + authenticationMethod + '", ' +
                '"accessCode":"' + accessCode + '", ' +
                '"availableManufacturerMakesArray":' + JSON.stringify(availableManufacturerMakesArray) + ', ' +
                '"availableManufacturerRangesArray":' + JSON.stringify(availableManufacturerRangesArray) + ', ' +
                '"availableManufacturerAxleLayoutsArray":' + JSON.stringify(availableManufacturerAxleLayoutsArray) + ', ' +
                '"availableManufacturerVehicleTypesArray":' + JSON.stringify(availableManufacturerVehicleTypesArray) + ', ' +
                '"availableManufacturerStatusesArray":' + JSON.stringify(availableManufacturerStatusesArray) + ', ' +
                '"availableManufacturerCountriesArray":' + JSON.stringify(availableManufacturerCountriesArray) + ', ' +
                '"availableGenericAxleLayoutsArray":' + JSON.stringify(availableGenericAxleLayoutsArray) + ', ' +
                '"availableGenericVehicleTypesArray":' + JSON.stringify(availableGenericVehicleTypesArray) + ', ' +
                '"specifyCabDimensionsAs":"' + specifyCabDimensionsAs + '", ' +
                '"specifyChassisLengthAs":"' + specifyChassisLengthAs + '", ' +
                '"specifyFuelConsumptionAs":"' + specifyFuelConsumptionAs + '", ' +
                '"specifyLicencingRegionAs":' + Number(specifyLicencingRegionAs) + ', ' +
                '"specifyAxleRatingAs":"' + specifyAxleRatingAs + '", ' +
                '"menuItemsHistoryOnSelectionScreen":' + JSON.stringify(menuItemsHistoryOnSelectionScreen) + ', ' +
                '"numberOfOffersFromServer":' + Number(numberOfOffersFromServer) + ', ' +
                '"numberOfBodiesFromServer":' + Number(numberOfBodiesFromServer) + ', ' +
                '"numberOfAccessoriesFromServer":' + Number(numberOfAccessoriesFromServer) + ', ' +
                '"numberOfTrailersFromServer":' + Number(numberOfTrailersFromServer) + ', ' +
                '"numberOfPayloadsFromServer":' + Number(numberOfPayloadsFromServer) + ', ' +
                '"defaultReportPdfPageSize":"' + defaultReportPdfPageSize + '", ' +
                '"userPlan":"' + userPlan + '", ' +
                '"userLicenceInstanceId":' + Number(userLicenceInstanceId) + ', ' +
                '"dataPoints":' + JSON.stringify(dataPoints || []) + ', ' +
                '"dDRTranslations":' + JSON.stringify(dDRTranslations || []) + ', ' +
                '"accessoryTranslations":' + JSON.stringify(accessoryTranslations || []) + ', ' +
                '"companyInterfaceAttributes":' + JSON.stringify(companyInterfaceAttributes || []) + ', ' +
                '"dashboardConfiguration":' + dashboardConfigurationForStorage + ', ' +
                '"isMemberOfDistributorCompany":' + isMemberOfDistributorCompany + ', ' +
                '"specifyBodyPositionAs":"' + specifyBodyPositionAs + '", ' +
                '"specifyEquipmentPositionAs":"' + specifyEquipmentPositionAs + '", ' +
                '"edition":"' + edition + '", ' +
                '"position":"' + position + '", ' +
                '"nteaUserValidationType":"' + nteaUserValidationType + '", ' +
                '"reportViewLayout":"' + reportViewLayout + '", ' +
                '"reportViews":' + reportViewsForStorage + ', ' +
                '"companyAdditionalNote":"' + companyAdditionalNoteToSave + '"' +
                //'"userUsageUses":"' + userUsageUses + '" ' +
                '}');

            

            try {
                if(globals.getStorageKey() !== null && globals.getStorageKey() !== '' && globals.getStorageKey() !== undefined) {
                    localStorage.setItem(config.localStorageUser + ':' + username, utils.encrypt(JSON.stringify(userData)));
                    localStorage.setItem('localStorageState', 'ENCRYPTED');    
                }else {
                    localStorage.setItem(config.localStorageUser + ':' + username, JSON.stringify(userData));
                }
                // localStorage.setItem(config.localStorageUser + ':' + username, LZString.compressToUTF16(JSON.stringify(userData)));
                
            } catch (e) {
                //private browsing is enabled so can't store the user
                logger.log("ERROR - Error when saving user to localStorage", undefined, 'model.user', true, undefined, config.LOG_MESSAGE_TYPE.ERROR);
                logger.log('ERROR - ' + e.errorMessage, undefined, 'model.user', false, undefined, config.LOG_MESSAGE_TYPE.ERROR);
            }

            //now refresh the observables
            refreshObservables();
        },

        clearUser = function () {

            var keysToBeCleared = new Array();
            var k;
            //identify all keys that need clearing from the cache
            for (k = 0; k < localStorage.length; k++) {

                var keyStartsWith = localStorage.key(k).split(':');

                if (keyStartsWith.length > 0) {
                    keyStartsWith = keyStartsWith[0] + ':' + keyStartsWith[1];
                    if (keyStartsWith === config.localStorageExpiry + ':' + username || keyStartsWith === config.localStorageData + ':' + username) {
                        keysToBeCleared.push(localStorage.key(k));
                    }
                }
            }

            //now clear them
            for (k = 0; k < keysToBeCleared.length; k++) {
                localStorage.removeItem(keysToBeCleared[k]);
            }

            if(localStorage.getItem('localStorageState') === 'ENCRYPTED') {
                localStorage.removeItem(config.localStorageUser + ':' + username);
            }else {
                //clear out everything except the username, lastUsed, machineCode, authenticationMethod and cultureCode 
                updateUser({
                    firstName: '',
                    lastName: '',
                    emailAddress: '',
                    address: '',
                    password: '',
                    cdg: 0,
                    cultureCode: config.defaultCultureCode,
                    reminders: '',
                    // securityToken: '',
                    // validUntil: '',
                    // validUntilOffline: '',
                    offlineUsage: '',
                    position: '',
                    directNumber: '',
                    mobileNumber: '',
                    companyNumber: '',
                    palette: '',
                    permissions: [],
                    appOptionItems: [],
                    reportGraphics: {},
                    decimalPlaces: [],
                    reminderDate: '',
                    customerDistributionGroups: [],
                    accessibleApplications: [],
                    accessibleVehicleTypes: [],
                    defaultApplication: '',
                    defaultVehicleType: '',
                    onRoadCostsCosts: [],
                    onRoadCostsDiscounts: [],
                    trainingUrl: '',
                    translations: [],
                    legislations: [],
                    tolls: [],
                    integrations: [],
                    // onRoadCostsVersion: '',
                    supportEmailAddress: '',
                    supportTelephoneNumber: '',
                    supportTelephoneNumberForLink: '',
                    supportWebsite: '',
                    countryName: '',
                    countryAbbreviation: '',
                    availableLegislations: [],
                    activeLegislation: { id: 0 },
                    activeMeasurementSystem: {},
                    activeLegislationId: 0,
                    activeMeasurementSystemId: 0,
                    lengthIncrement: '',
                    massIncrement: '',
                    percentageIncrement: '',
                    colourDrawings: true,
                    showComplianceScorecard: false,
                    showTips: false,
                    specifyWheelbaseAs: '',
                    loggedInUsingEmail: false,
                    emailVerifiedStatus: config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED,
                    userType: config.USER_TYPES.UNKNOWN,
                    trialCalculationsRemaining: 0,
                    numberOfUsesBeforeReferralPrompt: 0,
                    numberOfUsesSinceLastPrompt: 0,
                    firstLogin: false,
                    trialDaysRemaining: 0,
                    billingDetails: {},
                    appUpdateAvailable: false,
                    licenceTransferCodeRequired: false,
                    suppressQuickSearchHelperToast: false,
                    lastUrlHash: '',
                    intercomUserId: '',
                    companyId: 0,
                    companyName: '',
                    expiryDate: '',
                    firstLoginDate: '',
                    latestLoginDate: '',
                    authenticationMethod: '',
                    userPlan: '',
                    availableManufacturerMakesArray: [],
                    availableManufacturerRangesArray: [],
                    availableManufacturerAxleLayoutsArray: [],
                    availableManufacturerVehicleTypesArray: [],
                    availableManufacturerStatusesArray: [],
                    availableManufacturerCountriesArray: [],
                    availableGenericAxleLayoutsArray: [],
                    availableGenericVehicleTypesArray: [],
                    specifyCabDimensionsAs: config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.BBC,
                    specifyChassisLengthAs: config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE,
                    specifyFuelConsumptionAs: config.SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS.VOLUME_PER_DISTANCE,
                    specifyLicencingRegionAs: 0,
                    specifyAxleRatingAs: config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED,
                    menuItemsHistoryOnSelectionScreen: [],
                    numberOfOffersFromServer: 0,
                    numberOfBodiesFromServer: 0,
                    numberOfAccessoriesFromServer: 0,
                    numberOfTrailersFromServer: 0,
                    numberOfPayloadsFromServer: 0,
                    defaultReportPdfPageSize: config.PDFMAKE_PAGE_SIZE_OPTIONS.A4,
                    userLicenceInstanceId: 0,
                    dataPoints: [],
                    dDRTranslations: [],
                    accessoryTranslations: [],
                    dashboardConfiguration: null,
                    isMemberOfDistributorCompany: false,
                    companyAdditionalNote: '',
                    specifyBodyPositionAs: config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB,
                    specifyEquipmentPositionAs: config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB,
                    edition: '',
                    product: '',
                    reportViewLayout: config.REPORT_VIEW_LAYOUT_OPTIONS.SIMPLIFIED,
                    reportViews: null,
                    nteaUserValidationType: config.NTEA_USER_VALIDATION_TYPES.NTEA
                });
            }
            

        },

        
        // getCachedData = function (request, validityPeriod) {

        //     var cachedData, cachedDataExpiry;

        //     try {
        //         //first try to retrieve the data from local storage
        //         cachedDataExpiry = localStorage.getItem(config.localStorageExpiry + ':' + username + ':' + request);
        //         if (cachedDataExpiry !== null) {
        //             cachedDataExpiry = parseInt(cachedDataExpiry);
        //             cachedData = JSON.parse(LZString.decompressFromUTF16(localStorage.getItem(config.localStorageData + ':' + username + ':' + request)));
        //             if (cachedData !== null && cachedDataExpiry !== null) {
        //                 if (Date.now() / 1000 > cachedDataExpiry + validityPeriod) {
        //                     cachedData = null;
        //                     // cached = null;
        //                 }
        //             } else {
        //                 cachedData = null;
        //                 cachedDataExpiry = null;
        //             }
        //         } else {
        //             cachedData = null;
        //             cachedDataExpiry = null;
        //         }
        //     } catch (e) {
        //         //if this is unsuccessful, get it from the server
        //         cachedData = null;
        //         cachedDataExpiry = null;
        //     }

        //     return cachedData;

        // },

        // getCachedDataUsingPromise = function (request, validityPeriod) {

        //     var dfd = $.Deferred();

        //     function getData() {
        //         var cachedData = getCachedData(request, validityPeriod);
        //         //dfd.resolve({ cachedData: cachedData });
        //         dfd.resolve(cachedData);
        //     }

        //     getData();

        //     return dfd.promise();

        // },

        // setCachedData = function (request, data) {

        //     var processed = false;

        //     while (!processed) {

        //         try {
        //             localStorage.setItem(config.localStorageData + ':' + username + ':' + request, LZString.compressToUTF16(JSON.stringify(data)));
        //             localStorage.setItem(config.localStorageExpiry + ':' + username + ':' + request, Math.round(Date.now() / 1000));
        //             processed = true;
        //         } catch (e) {

        //             //we have run out of space, so find the oldest cached data entry, remove it, and try again
        //             var oldestTime, oldestKey, currentTime;

        //             oldestTime = 0;

        //             for (var k = 0; k < localStorage.length; k++) {

        //                 var keyStartsWith = localStorage.key(k).split(':');

        //                 if (keyStartsWith.length > 0) {
        //                     keyStartsWith = keyStartsWith[0];
        //                     if (keyStartsWith === config.localStorageExpiry) {
        //                         currentTime = localStorage.getItem(localStorage.key(k));
        //                         if (currentTime < oldestTime || oldestTime === 0) {
        //                             oldestTime = currentTime;
        //                             oldestKey = localStorage.key(k);
        //                         }
        //                     }
        //                 }
        //             }

        //             if (oldestTime > 0) {
        //                 //we should now have the oldest key, so now try to delete it and its paired data key
        //                 localStorage.removeItem(oldestKey);
        //                 localStorage.removeItem(oldestKey.replace(config.localStorageExpiry, config.localStorageData));
        //             } else {
        //                 //we have not found anything to replace, so cannot store this item
        //                 processed = true;
        //             }
        //         }
        //     }

        //     return true;

        // },

        doNotAllowBuyNowButtonToBeDisplayed = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_HIDE_BUY_NOW.Code);
        },

        displayFuelConsumptionAs = function () {
            return hasPermission(config.PERMISSIONS.PERFORMANCE.Code) || hasPermission(config.PERMISSIONS.COSTING.Code);
        },

        displayDashboard = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.WELCOME_FULL) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.WELCOME_TRIAL);
        },

        displaySelectionProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SELECTION_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.SELECTION_NEW_FEATURES_TOUR);
        },

        displaySelectionOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SELECTION_OVERVIEW_TOUR);
        },

        displaySelectionNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SELECTION_NEW_FEATURES_TOUR);
        },

        displayConfigurationProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.CONFIGURATION_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.CONFIGURATION_NEW_FEATURES_TOUR);
        },

        displayConfigurationOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.CONFIGURATION_OVERVIEW_TOUR);
        },

        displayConfigurationNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.CONFIGURATION_NEW_FEATURES_TOUR);
        },

        displayCostingProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.COSTING_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.COSTING_NEW_FEATURES_TOUR);
        },

        displayCostingOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.COSTING_OVERVIEW_TOUR);
        },

        displayCostingNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.COSTING_NEW_FEATURES_TOUR);
        },

        displayPerformanceProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.PERFORMANCE_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.PERFORMANCE_NEW_FEATURES_TOUR);
        },

        displayPerformanceOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.PERFORMANCE_OVERVIEW_TOUR);
        },

        displayPerformanceNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.PERFORMANCE_NEW_FEATURES_TOUR);
        },

        displaySpecificationProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SPECIFICATION_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.SPECIFICATION_NEW_FEATURES_TOUR);
        },

        displaySpecificationOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SPECIFICATION_OVERVIEW_TOUR);
        },

        displaySpecificationNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SPECIFICATION_NEW_FEATURES_TOUR);
        },

        displaySummaryProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SUMMARY_OVERVIEW_TOUR) || hasAppOptionItemObject(config.APP_OPTION_ITEMS.SUMMARY_NEW_FEATURES_TOUR);
        },

        displaySummaryOverviewProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SUMMARY_OVERVIEW_TOUR);
        },

        displaySummaryNewFeaturesProductTour = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.SUMMARY_NEW_FEATURES_TOUR);
        },

        displayTrialWelcomeMessage = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.WELCOME_TRIAL);
        },

        displayRecommendedBrowserMessage = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.RECOMMENDED_BROWSER_DONT_ASK_ME_AGAIN);
        },

        displayQuickSearchHelperMessage = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.QUICK_SEARCH_HELPER_DONT_ASK_ME_AGAIN);
        },

        displayStandardItemMessage = function () {
            return hasAppOptionItemObject(config.APP_OPTION_ITEMS.STANDARD_ITEM_DONT_ASK_ME_AGAIN);
        },

        allowReferrals = function () {
            return !isTrialUser();
        },

        allowReferralPrompt = function () {
            // full user and 
            return allowReferrals() && hasAppOptionItemObject(config.APP_OPTION_ITEMS.REFERRAL_DONT_ASK_ME_AGAIN) && getNumberOfUsesSinceLastPrompt() > getNumberOfUsesBeforeReferralPrompt();
        },

        allowDataUpload = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_UPLOAD_CSV_FILE.Code);
        },

        /**
         * Allow users from certain countries to open TSV files
         * Valid Countries = South Africa (ZA), Eswatini/Swaziland (SZ), Namibia (NA), Botswana (BW), Zimbabwe (ZW), Mozambique (MZ), Zambia (ZM), Malawi (MW), Kenya (KE)
         * @returns {boolean} - Returns legislation description if found and null if not found
         */
        allowOpenTsvFile = function () {
            //return hasPermission(config.PERMISSIONS.GENERAL_OPEN_TSV_FILES.Code);
            return doesCountryCodeMatchUserCountryAbbreviation('ZA') || doesCountryCodeMatchUserCountryAbbreviation('SZ') || doesCountryCodeMatchUserCountryAbbreviation('NA')
                || doesCountryCodeMatchUserCountryAbbreviation('BW') || doesCountryCodeMatchUserCountryAbbreviation('ZW') || doesCountryCodeMatchUserCountryAbbreviation('MZ')
                || doesCountryCodeMatchUserCountryAbbreviation('ZM') || doesCountryCodeMatchUserCountryAbbreviation('MW') || doesCountryCodeMatchUserCountryAbbreviation('KE');
        },

        /**
         * Allow users from certain countries to access the Specification module
         * Valid Countries = South Africa (ZA), Eswatini/Swaziland (SZ), Namibia (NA), Botswana (BW), Zimbabwe (ZW), Mozambique (MZ), Zambia (ZM), Malawi (MW), Kenya (KE)
         * @returns {boolean} - Returns true or false
         */
        allowSpecificationModule = function () {
            //return hasPermission(config.PERMISSIONS.GENERAL_OPEN_TSV_FILES.Code);
            return doesCountryCodeMatchUserCountryAbbreviation('ZA') || doesCountryCodeMatchUserCountryAbbreviation('SZ') || doesCountryCodeMatchUserCountryAbbreviation('NA')
                || doesCountryCodeMatchUserCountryAbbreviation('BW') || doesCountryCodeMatchUserCountryAbbreviation('ZW') || doesCountryCodeMatchUserCountryAbbreviation('MZ')
                || doesCountryCodeMatchUserCountryAbbreviation('ZM') || doesCountryCodeMatchUserCountryAbbreviation('MW') || doesCountryCodeMatchUserCountryAbbreviation('KE');
        },

        showAfterframe = function () {
            return !doesCountryCodeMatchUserCountryAbbreviation('ZA');
        },

        dismissAppOptionItem = function (appOptionItemCode) {
            if (typeof appOptionItems === 'string') {
                // Remove string
                var indexOfItemToDismiss = appOptionItems.indexOf(appOptionItemCode);
                appOptionItems.splice(indexOfItemToDismiss, 1);
                updateUser({
                    appOptionItems: appOptionItems
                });
            } else {
                // Remove object
                var newArray = appOptionItems.filter(function (appOptionItem) {
                    return appOptionItem.Code !== appOptionItemCode;
                });
                updateUser({
                    appOptionItems: newArray
                });
            }

        },

        setCdg = function (value) {
            cdg = value;
        },

        setLanguageId = function (value) {
            languageId = value;
        },

        getVehicleSelectionWizardSetup = function () {
            return config.selectionMakeRangeTypeAxleLayout;
        },

        displayConfigurationMenu = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU.Code);
        },

        displayConfigurationMenuDisplay = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_DISPLAY.Code);
        },

        displayConfigurationMenuElevation = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_ELEVATION.Code);
        },

        displayConfigurationMenuViewType = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_VIEWTYPE.Code);
        },

        displayConfigurationMenuViewDetail = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_VIEWDETAIL.Code);
        },

        displayPerformance = function () {
            return hasPermission(config.PERMISSIONS.PERFORMANCE.Code);
        },

        displayOfferModuleOtherThanConfigAndSummary = function () {
            return hasPermission(config.PERMISSIONS.PERFORMANCE.Code) || hasPermission(config.PERMISSIONS.COSTING.Code) || hasPermission(config.PERMISSIONS.SPECIFICATION.Code);
        },

        /**
         * disable for Essentials Edition, show visual clue (?) and tooltip text (?) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedToUploadLogo = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_UPLOAD_LOGO.Code);
        },

        /**
         * uncheck all Favourites for Essentials Edition when the buy process has been completed
         * disable all 'Favourite Measurements' for Essentials Edition and show upgrade dialog when selected from the dropdown
         * @returns {boolean}
         */
        isUserAllowedFullAccessToSettingsFavourites  = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_FAVOURITES.Code);
        },
                        
        /**
         * uncheck all Internal Standards for Essentials Edition when the buy process has been completed
         * disable all 'Internal Standards' for Essentials Edition and show upgrade dialog when selected from the dropdown
         * @returns {boolean}
         */
        isUserAllowedFullAccessToSettingsInternalStandards = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_INTERNAL_STANDARDS.Code);
        },

        /**
         * set Applied Regulation for Essentials Edition to default regulation obtained from user's common pack when the buy process has been completed
         * disable all other regulations for Essentials Edition and show upgrade dialog when selected from the dropdown
         * when opening shared or saved calculations, do not change the Applied Regulation
         * @returns {boolean}
         */
        isUserAllowedFullAccessToSettingsRegulations = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_REGULATIONS.Code);
        },

        /**
         * disable textbox for Essentials Edition, show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedFullAccessSettingsCompanyNotes = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_COMPANY_NOTES.Code);
        },

        /**
         * add permission for Manage Account and hide if not available
         * @returns {boolean}
         */ 
        isUserAllowedToManageAccount = function () {
            return hasPermission(config.PERMISSIONS.USER_MENU_MANAGE_ACCOUNT.Code);
        },

        /**
         * disable 'Pusher' and 'Tag' for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedFullAccessToConfigurationAddingAxles = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_ADD_AXLES.Code);
        },

        /**
         * disable 'Smallest' & 'Regulation' for Essentials Edition, show tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedFullAccessToConfigurationTurningCircle = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_TURNING_CIRCLE.Code);
        },

        /**
         * disable for Essentials Edition and show upgrade dialog when selected under body, equipment or payload
         * @returns {boolean}
         */
        isUserAllowedFullAccessToGeneralImportDxf = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_IMPORT_DXF.Code);
        },

        /**
         * disable 'Edit & Overwrite' for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedFullAccessToShareCalculations = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_SHARE_CALCULATIONS_EDIT.Code);
        },

        /**
         * on datagrid menu, disable for Essentials Edition, show upgrade dialog when selected under body, equipment, payload & trailer
         * on save dialog, disable for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedFullAccessToTeamLibrary = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_TEAM_LIBRARY.Code);
        },

        /**
         * For Essentials user, do not allow request vehicle on Selection or request body/accessory/payload/trailer on Configuration
         * @returns {boolean}
         */ 
        isUserAllowedFullAccessToRequestData = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_REQUEST_DATA.Code);
        },
        
        /**
         * disable 'Pusher' and 'Tag' for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedToPublishToDistributorLibrary = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_PUBLISH_TO_DISTRIBUTOR.Code);
        },

        /**
         * disable add to report button on Configuration for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * disable report view layout select on settings modal for Essentials Edition, show visual clue and tooltip text (nice to have) and show upgrade dialog when selected
         * @returns {boolean}
         */
        isUserAllowedToChangeReportViewLayout = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_REPORT_VIEW_LAYOUT.Code);
        },

         /**
         * controls access to email report button on PDF
         * @returns {boolean}
         */
         isUserAllowedToEmailReport = function () {
            return hasPermission(config.PERMISSIONS.SUMMARY_EMAIL_REPORT.Code);
        },

         /**
         * controls access to preparedForSection on PDF
         * @returns {boolean}
         */
         isUserAllowedToAddPreparedForDetails = function () {
            return hasPermission(config.PERMISSIONS.SUMMARY_ADD_PREPARED_FOR_DETAILS.Code);
        },

         /**
         * controls access to preparedForSection on PDF
         * @returns {boolean}
         */
         isUserAllowedToShareCalculations = function () {
            return hasPermission(config.PERMISSIONS.GENERAL_SHARE_CALCULATIONS.Code);
        },

        isTrialUser = function () {
            return getUserType() === config.USER_TYPES.TRIAL_USER_OK || getUserType() === config.USER_TYPES.TRIAL_USER_FORMER || getUserType() === config.USER_TYPES.TRIAL_USER_VIP_OK;
        },

        isVipTrialUser = function () {
            return getUserType() === config.USER_TYPES.TRIAL_USER_VIP_OK;
        },

        isFullUser = function () {
            return getUserType() === config.USER_TYPES.TRIAL_USER_OK;
        },

        isUserIntegratedWithNTEA = function () {
            var returnValue = false;
            integrations.forEach(function (element) {
                if (element.Type === config.INTEGRATION_TYPES.NTEA) {
                    returnValue = true;
                }
            });
            return returnValue;
        },

        getNTEAIntegrationObject = function () {
            var nteaObject = integrations.find(function (element) {
                //if (element.Type === config.INTEGRATION_TYPES.NTEA.toUpperCase()) {
                //    returnValue = true;
                //}
                return element.Type === config.INTEGRATION_TYPES.NTEA.toUpperCase();
            });
            return nteaObject !== undefined ? nteaObject : null;
        },

        displayEmailVerificationMenuOption = function () {
            return getEmailVerifiedStatus() === config.EMAIL_VERIFICATION_STATUS_OPTIONS.NOT_VERIFIED;
        },

        isLoadingAdministrator = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code);
        },

        isLoadingAddAxlesAdministrator = function () {
            return hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_ADD_AXLES.Code);
        },

        getSupportPlanType = function () {
            if (hasPermission(config.PERMISSIONS.GENERAL_REQUEST_DATA.Code)) {
                return config.SUPPORT_PLAN_TYPE.FULL;
            } else {
                return config.SUPPORT_PLAN_TYPE.NONE;
            }
        },

        allowedToEditCompanyDetails = function () {
            return hasPermission(config.PERMISSIONS.SETTINGS_EDIT_COMPANY_DETAILS.Code);
        },

        hasFullSupportPlan = function () {
            return getSupportPlanType() === config.SUPPORT_PLAN_TYPE.FULL;
        },

        doesNotHaveSupport = function () {
            return getSupportPlanType() === config.SUPPORT_PLAN_TYPE.NONE;
        },

        overrideDefaultMenuDislpay = function (vehicleDBUnladenTotal) {
            if (vehicleDBUnladenTotal !== undefined) {
                return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_DISPLAY_DETAILED.Code) && vehicleDBUnladenTotal === 0;
            } else {
                return hasPermission(config.PERMISSIONS.CONFIGURATION_MENU_DISPLAY_DETAILED.Code);
            }

        },

        hasPermission = function (permissionCodeToCheck) {
            return ($.inArray(permissionCodeToCheck, permissions) !== -1);
        },

        // hasAppOptionItem = function (appOptionItemCodeToCheck) {
        //     return ($.inArray(appOptionItemCodeToCheck, appOptionItems) !== -1);
        // },

        hasAppOptionItemObject = function (appOptionItemObjectToCheck) {
            //return ($.inArray(appOptionItemCodeToCheck, appOptionItems) !== -1);
            var temp = appOptionItems.filter(function (appOptionItem) {
                if (typeof appOptionItem === 'string') {
                    return appOptionItem === appOptionItemObjectToCheck.Code;
                } else {
                    // return appOptionItem.Code === appOptionItemObjectToCheck.Code && appOptionItem.Type === appOptionItemObjectToCheck.Type && CustomTruckScienceRequirements.getMetaTagContent('app-version-number').includes(appOptionItem.ValidForSprint);
                    return appOptionItem.Code === appOptionItemObjectToCheck.Code && appOptionItem.Type === appOptionItemObjectToCheck.Type && config.appVersionNumber.includes(appOptionItem.ValidForSprint);
                }
            });
            return temp.length > 0;
        },

        getDatabaseAttributeValueTranslation = function (id, code) {
            var temp = translations.filter(function (obj) {
                return obj.Id === id;
            });
            var translate = temp[0].Translations.filter(function (obj) {
                return obj.Code === code;
            });
            if (translate.length > 0) {
                return translate[0].Translation !== undefined ? translate[0].Translation : '';
            } else {
                return '';
            }
        },

        checkIfLegislationAvailable = function (id) {
            var check = availableLegislations.filter(function (legislation) {
                return legislation.id = id;
            });
            if (check.length > 0) {
                return true;
            } else {
                return false;
            }
        },

        getCustomerDistributionGroupById = function (id) {
            return customerDistributionGroups.filter(function (cdg) {
                return cdg.Id === id;
            })[0];
        },

        allowGoogleAnalyticsCall = function () {
            if (!globals.isValidEmailAddressForGoogleAnalytics(getEmailAddress())) {
                return false;
            }

            if (!globals.isGoogleAnalyticsKeyAvailable()) {
                return false;
            }

            return true;
        },

        getVehicleSelectionSearchHistory = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory;
        },

        getVehicleSelectionSearchHistorySearchTerms = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.searchTerms;
            //return _vehicleSelectionSearchHistory.searchTerms !== undefined ? _vehicleSelectionSearchHistory.searchTerms : '';
        },

        getVehicleSelectionSearchHistoryActiveMenuItem = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.activeMenuItem;
            //return _vehicleSelectionSearchHistory.activeMenuItem !== undefined ? _vehicleSelectionSearchHistory.activeMenuItem : config.SELECTION_MENU_ITEM_TYPE.DASHBOARD;
        },

        getVehicleSelectionSearchHistoryActiveSort = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.activeSort;
        },

        getVehicleSelectionSearchHistoryPath = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.path;
        },

        getVehicleSelectionSearchHistoryPathActiveStepIndex = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.path.activeStepIndex;
        },

        getVehicleSelectionSearchHistoryPathFiltersArray = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.path.filtersArray;
        },

        getVehicleSelectionSearchHistoryMultiSelectFilters = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.multiSelectFilters;
        },

        getVehicleSelectionSearchHistoryDisplayBreadcrumbs = function () {
            checkVehicleSelectionSearchHistoryObject();
            return _vehicleSelectionSearchHistory.displayBreadcrumbs;
        },

        clearVehicleSelectionSearchHistory = function () {
            _vehicleSelectionSearchHistory = {
                searchTerms: '',
                activeMenuItem: config.SELECTION_MENU_ITEM_TYPE.MANUFACTURER,
                path: {
                    activeStepIndex: 0,
                    filtersArray: []
                },
                activeSort: {
                    itemToSort: null,
                    sortType: null
                },
                multiSelectFilters: [],
                displayBreadcrumbs: true
            };
        },

        checkVehicleSelectionSearchHistoryObject = function () {
            if (_vehicleSelectionSearchHistory.searchTerms === undefined || _vehicleSelectionSearchHistory.activeMenuItem === undefined || _vehicleSelectionSearchHistory.path === undefined || _vehicleSelectionSearchHistory.activeSort === undefined) {
                clearVehicleSelectionSearchHistory();
            }
        },

        updateVehicleSelectionSearchHistorySearchTerms = function (newSearchTerms) {
            _vehicleSelectionSearchHistory.searchTerms = newSearchTerms;
        },

        updateVehicleSelectionSearchHistoryMultiSelectFilters = function (newMultiselectFilters) {
            _vehicleSelectionSearchHistory.multiSelectFilters = newMultiselectFilters;
        },

        updateVehicleSelectionSearchHistoryDisplayBreadcrumbs = function (newDisplayBreadcrumbs) {
            _vehicleSelectionSearchHistory.displayBreadcrumbs = newDisplayBreadcrumbs;
        },

        updateVehicleSelectionSearchHistoryActiveSort = function (activeSort) {
            _vehicleSelectionSearchHistory.activeSort.itemToSort = activeSort.itemToSort;
            _vehicleSelectionSearchHistory.activeSort.sortType = activeSort.sortType.value;
        },

        updateVehicleSelectionSearchHistoryActiveMenuItem = function (newActiveMenuItem) {
            clearVehicleSelectionSearchHistory();
            _vehicleSelectionSearchHistory.activeMenuItem = newActiveMenuItem;
        },

        updateVehicleSelectionSearchHistoryPath = function (activeStepIndex, filtersArray) {
            _vehicleSelectionSearchHistory.path = {
                activeStepIndex: activeStepIndex,
                filtersArray: filtersArray
            };
        },

        getMenuItemsHistoryOnSelectionScreen = function () {
            return menuItemsHistoryOnSelectionScreen;
        },

        getMenuItemHistoryOnSelectionScreenUsingId = function (id) {
            if (menuItemsHistoryOnSelectionScreen.length === 0) {
                return null;
            }
            var returnObject = menuItemsHistoryOnSelectionScreen.find(function (hi) {
                return hi.id === id;
            });

            return returnObject === undefined ? null : returnObject;
        },

        setMenuItemsHistoryOnSelectionScreen = function (newValue) {
            menuItemsHistoryOnSelectionScreen = newValue;
            updateUser({ menuItemsHistoryOnSelectionScreen: menuItemsHistoryOnSelectionScreen });
        },

        clearMenuItemsHistoryOnSelectionScreen = function () {
            menuItemsHistoryOnSelectionScreen = [];
        },

        getAvailableManufacturerMakesArray = function () {
            return availableManufacturerMakesArray;
        },

        setAvailableManufacturerMakesArray = function (newValue) {
            availableManufacturerMakesArray = newValue;
            updateUser({ availableManufacturerMakesArray: availableManufacturerMakesArray });
        },

        checkAvailableManufacturerMakesArray = function () {
            return getAvailableManufacturerMakesArray().length > 0;
        },

        getAvailableManufacturerRangesArray = function () {
            return availableManufacturerRangesArray;
        },

        setAvailableManufacturerRangesArray = function (newValue) {
            availableManufacturerRangesArray = newValue;
            updateUser({ availableManufacturerRangesArray: availableManufacturerRangesArray });
        },

        checkAvailableManufacturerRangesArray = function () {
            return getAvailableManufacturerRangesArray().length > 0;
        },

        getAvailableManufacturerAxleLayoutsArray = function () {
            return availableManufacturerAxleLayoutsArray;
        },

        setAvailableManufacturerAxleLayoutsArray = function (newValue) {
            availableManufacturerAxleLayoutsArray = newValue;
            updateUser({ availableManufacturerAxleLayoutsArray: availableManufacturerAxleLayoutsArray });
        },

        checkAvailableManufacturerAxleLayoutsArray = function () {
            return getAvailableManufacturerAxleLayoutsArray().length > 0;
        },

        getAvailableManufacturerVehicleTypesArray = function () {
            return availableManufacturerVehicleTypesArray;
        },

        setAvailableManufacturerVehicleTypesArray = function (newValue) {
            availableManufacturerVehicleTypesArray = newValue;
            updateUser({ availableManufacturerVehicleTypesArray: availableManufacturerVehicleTypesArray });
        },

        checkAvailableManufacturerVehicleTypesArray = function () {
            return getAvailableManufacturerVehicleTypesArray().length > 0;
        },

        getAvailableManufacturerStatusesArray = function () {
            return availableManufacturerStatusesArray;
        },

        setAvailableManufacturerStatusesArray = function (newValue) {
            availableManufacturerStatusesArray = newValue;
            updateUser({ availableManufacturerVehicleTypesArray: availableManufacturerVehicleTypesArray });
        },

        getDefaultManufacturerMultiselectFilters = function () {
            var defaultMultiselectFilters = [];
            if (getAvailableManufacturerStatusesArray().length > 1) {
                defaultMultiselectFilters.push({
                    property: 'Status',
                    // values: [config.getTranslationText('4930')]
                    values: ['CURRENT']
                });
            }

            return defaultMultiselectFilters;
        },

        checkAvailableManufacturerStatusesArray = function () {
            return getAvailableManufacturerStatusesArray().length > 0;
        },

        getAvailableManufacturerCountriesArray = function () {
            return availableManufacturerCountriesArray;
        },

        setAvailableManufacturerCountriesArray = function (newValue) {
            availableManufacturerCountriesArray = newValue;
            updateUser({ availableManufacturerVehicleTypesArray: availableManufacturerVehicleTypesArray });
        },

        checkAvailableManufacturerCountriesArray = function () {
            return getAvailableManufacturerCountriesArray().length > 0;
        },

        getAvailableGenericAxleLayoutsArray = function () {
            return availableGenericAxleLayoutsArray;
        },

        setAvailableGenericAxleLayoutsArray = function (newValue) {
            availableGenericAxleLayoutsArray = newValue;
            updateUser({ availableGenericAxleLayoutsArray: availableGenericAxleLayoutsArray });
        },

        checkAvailableGenericAxleLayoutsArray = function () {
            return getAvailableGenericAxleLayoutsArray().length > 0;
        },

        getAvailableGenericVehicleTypesArray = function () {
            return availableGenericVehicleTypesArray;
        },

        setAvailableGenericVehicleTypesArray = function (newValue) {
            availableGenericVehicleTypesArray = newValue;
            updateUser({ availableGenericVehicleTypesArray: availableGenericVehicleTypesArray });
        },

        checkAvailableGenericVehicleTypesArray = function () {
            return getAvailableGenericVehicleTypesArray().length > 0;
        },

        clearSelectionArrays = function () {
            setAvailableManufacturerMakesArray([]);
            setAvailableManufacturerRangesArray([]);
            setAvailableManufacturerAxleLayoutsArray([]);
            setAvailableManufacturerVehicleTypesArray([]);
            setAvailableManufacturerStatusesArray([]);
            setAvailableManufacturerCountriesArray([]);
            setAvailableGenericAxleLayoutsArray([]);
            setAvailableGenericVehicleTypesArray([]);
        },

        setDefaultMultiselectFilters = function () {
            //var defaultMultiselectFilters = [];
            //if (getAvailableManufacturerStatusesArray().length > 1) {
            //    defaultMultiselectFilters.push({
            //        property: 'Status',
            //        values: ['CURRENT']
            //    });
            //}
            //_vehicleSelectionSearchHistory.multiSelectFilters = defaultMultiselectFilters;
            _vehicleSelectionSearchHistory.multiSelectFilters = getDefaultManufacturerMultiselectFilters();
        },

        getCustomerDistributionGroupName = function () {
            var cdg = getCustomerDistributionGroupById(getCdg());

            return cdg !== undefined ? cdg.DisplayName : undefined;
        },

        /**
         * Used to determine whether to show/hide Manage Account option on user menu
         * @returns {boolean}
         */ 
        allowUserAccessToChargebeePortal = function () {
            var localBillingDetails = getBillingDetails();
            if (typeof localBillingDetails === 'object') {
                if (typeof localBillingDetails.ChargebeeCustomerId === 'string' && localBillingDetails.ChargebeeCustomerId.length > 0 && isUserAllowedToManageAccount() === true) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        getSpecifyCabDimensionsAs = function () { return specifyCabDimensionsAs; },
        getSpecifyChassisLengthAs = function () { return specifyChassisLengthAs; },
        getSpecifyFuelConsumptionAs = function () { return specifyFuelConsumptionAs; },
        getSpecifyAxleRatingAs = function () { return specifyAxleRatingAs; },
        getSpecifyBodyPositionAs = function () { return specifyBodyPositionAs; },
        getSpecifyEquipmentPositionAs = function () { return specifyEquipmentPositionAs; },
        setAppRefreshUnderway = function (newValue) {
            
            localStorage.setItem('appRefreshUnderway', newValue);
        },
        getAppRefreshUnderway = function () {
            return localStorage.getItem('appRefreshUnderway');
        },
        removeAppRefreshUnderway = function () {
            localStorage.removeItem('appRefreshUnderway');
        },
        getUserDetailsAsJSONOBject = function () {
            return {
                'FirstName': getFirstName(),
                'LastName': getLastName(),
                'Country': getCountryName(),
                'Email': getEmailAddress(),
                'Company': getCompanyName(),
                'ApplicationType': config.applicationIdentityType,
                'CountryAbbreviation': getCountryAbbreviation() 
            };
        },
        isUSOrCanadaBasedUser = function () {
            return doesCountryCodeMatchUserCountryAbbreviation('US') || doesCountryCodeMatchUserCountryAbbreviation('CA');
        }
        ,
        getMicrosoftLoggedInUserAccessTokenAsPassword = function () {
            return microsoftLoggedInUserAccessTokenAsPassword;
        },

        setMicrosoftLoggedInUserAccessTokenAsPassword = function (newValue) {
            microsoftLoggedInUserAccessTokenAsPassword = newValue;
        },

        getCompanyInterfaceAttributeByName = function (attributeName) {
            let foundAttribute = null;
            getCompanyInterfaceAttributes().every(function (interfaceAttribute) {
                if(attributeName === interfaceAttribute.Name) {
                    foundAttribute = interfaceAttribute;
                    return false;
                }
            });
            return foundAttribute;
        },

        getCompanyInterfaceAttributeByTruckScienceName = function (truckScienceAttributeName) {
            let foundAttribute = null;
            getCompanyInterfaceAttributes().every(function (interfaceAttribute) {
                if(truckScienceAttributeName === interfaceAttribute.TruckScienceName) {
                    foundAttribute = interfaceAttribute;
                    return false;
                }
            });
            return foundAttribute;
        }
        ;
        setDefaults();

    return {
        getMostRecentUser: getMostRecentUser,
        updateUser: updateUser,
        clearUser: clearUser,
        displayReminders: displayReminders,
        // getSecurityToken: getSecurityToken,
        // getValidUntil: getValidUntil,
        // setValidUntil: setValidUntil,
        getUserName: getUserName,
        getEmailAddress: getEmailAddress,
        getPassword: getPassword,
        getMachineCode: getMachineCode,
        getCdg: getCdg,
        getCultureCode: getCultureCode,
        getAddress: getAddress,
        getDisplayName: getDisplayName,
        getFirstName: getFirstName,
        getLastName: getLastName,
        getOfflineUsage: getOfflineUsage,
        getPosition: getPosition,
        getDirectNumber: getDirectNumber,
        getMobileNumber: getMobileNumber,
        getPhoneNumberForReport: getPhoneNumberForReport,
        getCompanyNumber: getCompanyNumber,
        getPalette: getPalette,
        getPermissions: getPermissions,
        getReportGraphics: getReportGraphics,
        getDecimalPlaces: getDecimalPlaces,
        // getCachedData: getCachedData,
        // getCachedDataUsingPromise: getCachedDataUsingPromise,
        // setCachedData: setCachedData,
        displayReminderValue: displayReminderValue,
        getReminderDate: getReminderDate,
        reminderCheck: reminderCheck,
        displayNameObv: displayNameObv,
        displayEmailAddressObv: displayEmailAddressObv,
        getCustomerDistributionGroups: getCustomerDistributionGroups,
        cdgArray: cdgArray,
        setCdg: setCdg,
        getAccessibleApplications: getAccessibleApplications,
        getAccessibleVehicleTypes: getAccessibleVehicleTypes,
        getDefaultApplication: getDefaultApplication,
        getDefaultVehicleType: getDefaultVehicleType,
        hasPermission: hasPermission,
        getOnRoadCostsCosts: getOnRoadCostsCosts,
        getOnRoadCostsDiscounts: getOnRoadCostsDiscounts,
        getLanguageId: getLanguageId,
        setLanguageId: setLanguageId,
        getDatabaseAttributeValueTranslation: getDatabaseAttributeValueTranslation,
        // getOnRoadCostsVersion: getOnRoadCostsVersion,
        // setOnRoadCosts: setOnRoadCosts,
        getTrainingUrl: getTrainingUrl,
        getVehicleSelectionWizardSetup: getVehicleSelectionWizardSetup,
        getSupportEmailAddress: getSupportEmailAddress,
        getSupportTelephoneNumber: getSupportTelephoneNumber,
        getSupportTelephoneNumberForLink: getSupportTelephoneNumberForLink,
        getSupportWebsite: getSupportWebsite,
        getSupportPlanType: getSupportPlanType,
        hasFullSupportPlan: hasFullSupportPlan,
        doesNotHaveSupport: doesNotHaveSupport,
        getCountryName: getCountryName,
        getCountryAbbreviation: getCountryAbbreviation,
        getActiveLegislation: getActiveLegislation,
        displayConfigurationMenu: displayConfigurationMenu,
        displayConfigurationMenuDisplay: displayConfigurationMenuDisplay,
        displayConfigurationMenuElevation: displayConfigurationMenuElevation,
        displayConfigurationMenuViewType: displayConfigurationMenuViewType,
        displayConfigurationMenuViewDetail: displayConfigurationMenuViewDetail,
        getAvailableLegislations: getAvailableLegislations,
        getSortedAvailableLegislations: getSortedAvailableLegislations,
        getActiveMeasurementSystem: getActiveMeasurementSystem,
        getLengthIncrement: getLengthIncrement,
        getMassIncrement: getMassIncrement,
        getPercentageIncrement: getPercentageIncrement,
        setLengthIncrement: setLengthIncrement,
        setMassIncrement: setMassIncrement,
        setPercentageIncrement: setPercentageIncrement,
        getAvailableMeasurementSystems: getAvailableMeasurementSystems,
        getActiveLegislationId: getActiveLegislationId,
        getActiveMeasurementSystemId: getActiveMeasurementSystemId,
        checkIfLegislationAvailable: checkIfLegislationAvailable,
        setAvailableMeasurementSystemsDefault: setAvailableMeasurementSystemsDefault,
        isLoadingAdministrator: isLoadingAdministrator,
        isLoadingAddAxlesAdministrator: isLoadingAddAxlesAdministrator,
        overrideDefaultMenuDislpay: overrideDefaultMenuDislpay,
        getColourDrawings: getColourDrawings,
        getShowComplianceScorecard: getShowComplianceScorecard,
        getShowTips: getShowTips,
        getReportViewLayout: getReportViewLayout,
        setReportViewLayout: setReportViewLayout,
        getReportViews: getReportViews,
        setReportViews: setReportViews,
        getReportViewsObjects: getReportViewsObjects,
        replaceReportViewsUsingArray: replaceReportViewsUsingArray,
        getSpecifyWheelbaseAs: getSpecifyWheelbaseAs,
        getLoggedInUsingEmail: getLoggedInUsingEmail,
        getEmailVerifiedStatus: getEmailVerifiedStatus,
        getUserType: getUserType,
        getTrialCalculationsRemaining: getTrialCalculationsRemaining,
        getNumberOfUsesBeforeReferralPrompt: getNumberOfUsesBeforeReferralPrompt,
        getNumberOfUsesSinceLastPrompt: getNumberOfUsesSinceLastPrompt,
        setNumberOfUsesSinceLastPrompt: setNumberOfUsesSinceLastPrompt,
        getFirstLogin: getFirstLogin,
        getTrialDaysRemaining: getTrialDaysRemaining,
        getBillingDetails: getBillingDetails,
        getAppUpdateAvailable: getAppUpdateAvailable,
        getLicenceTransferCodeRequired: getLicenceTransferCodeRequired,
        getSuppressQuickSearchHelperToast: getSuppressQuickSearchHelperToast,
        getLastUrlHash: getLastUrlHash,
        displayEmailVerificationMenuOption: displayEmailVerificationMenuOption,
        isTrialUser: isTrialUser,
        displayDashboard: displayDashboard,
        displayTrialWelcomeMessage: displayTrialWelcomeMessage,
        dismissAppOptionItem: dismissAppOptionItem,
        getIntercomUserId: getIntercomUserId,
        getCompanyName: getCompanyName,
        getCompanyId: getCompanyId,
        getExpiryDate: getExpiryDate,
        getExpiryDateFormatted: getExpiryDateFormatted,
        getUserStatus: getUserStatus,
        getFirstLoginDateFormatted: getFirstLoginDateFormatted,
        getLatestLoginDateFormatted: getLatestLoginDateFormatted,
        getUserPlan: getUserPlan,
        // getUserPlanActivatedAt: getUserPlanActivatedAt,
        // getUserSubscriptionCreatedAt:getUserSubscriptionCreatedAt,
        // getUserSubscriptionCurrentTermEnd: getUserSubscriptionCurrentTermEnd,
        // getUserSubscriptionCurrentTermStart: getUserSubscriptionCurrentTermStart,
        getUserSubscriptionNextBillingAt: getUserSubscriptionNextBillingAt,
        getUserSubscriptionStartedAt: getUserSubscriptionStartedAt,
        getCustomerDistributionGroupName: getCustomerDistributionGroupName,
        displaySelectionProductTour: displaySelectionProductTour,
        displaySelectionOverviewProductTour: displaySelectionOverviewProductTour,
        displaySelectionNewFeaturesProductTour: displaySelectionNewFeaturesProductTour,
        displayConfigurationProductTour: displayConfigurationProductTour,
        displayCostingProductTour: displayCostingProductTour,
        displayCostingOverviewProductTour: displayCostingOverviewProductTour,
        displayCostingNewFeaturesProductTour: displayCostingNewFeaturesProductTour,
        displayPerformanceProductTour: displayPerformanceProductTour,
        displayPerformanceOverviewProductTour: displayPerformanceOverviewProductTour,
        displayPerformanceNewFeaturesProductTour: displayPerformanceNewFeaturesProductTour,
        displaySpecificationProductTour: displaySpecificationProductTour,
        displaySpecificationOverviewProductTour: displaySpecificationOverviewProductTour,
        displaySpecificationNewFeaturesProductTour: displaySpecificationNewFeaturesProductTour,
        displayConfigurationOverviewProductTour: displayConfigurationOverviewProductTour,
        displayConfigurationNewFeaturesProductTour: displayConfigurationNewFeaturesProductTour,
        displaySummaryProductTour: displaySummaryProductTour,
        displaySummaryOverviewProductTour: displaySummaryOverviewProductTour,
        displaySummaryNewFeaturesProductTour: displaySummaryNewFeaturesProductTour,
        displayPerformance: displayPerformance,
        allowGoogleAnalyticsCall: allowGoogleAnalyticsCall,
        getAuthenticationMethod: getAuthenticationMethod,
        getAccessCode: getAccessCode,
        displayOfferModuleOtherThanConfigAndSummary: displayOfferModuleOtherThanConfigAndSummary,
        getVehicleSelectionSearchHistory: getVehicleSelectionSearchHistory,
        getVehicleSelectionSearchHistorySearchTerms: getVehicleSelectionSearchHistorySearchTerms,
        getVehicleSelectionSearchHistoryActiveMenuItem: getVehicleSelectionSearchHistoryActiveMenuItem,
        updateVehicleSelectionSearchHistoryActiveMenuItem: updateVehicleSelectionSearchHistoryActiveMenuItem,
        clearVehicleSelectionSearchHistory: clearVehicleSelectionSearchHistory,
        updateVehicleSelectionSearchHistorySearchTerms: updateVehicleSelectionSearchHistorySearchTerms,
        updateVehicleSelectionSearchHistoryActiveSort: updateVehicleSelectionSearchHistoryActiveSort,
        updateVehicleSelectionSearchHistoryPath: updateVehicleSelectionSearchHistoryPath,
        getVehicleSelectionSearchHistoryPath: getVehicleSelectionSearchHistoryPath,
        getVehicleSelectionSearchHistoryPathActiveStepIndex: getVehicleSelectionSearchHistoryPathActiveStepIndex,
        getVehicleSelectionSearchHistoryPathFiltersArray: getVehicleSelectionSearchHistoryPathFiltersArray,
        isFullUser: isFullUser,
        allowReferralPrompt: allowReferralPrompt,
        isGoogleUser: isGoogleUser,
        canShowChangePassword: canShowChangePassword,
        canShowLogout: canShowLogout,
        canShowChangePasswordRef: canShowChangePasswordRef,
        canShowLogoutRef: canShowLogoutRef,
        allowReferrals: allowReferrals,
        getVehicleSelectionSearchHistoryActiveSort: getVehicleSelectionSearchHistoryActiveSort,
        getTolls: getTolls,
        getUserProfileTooltipText: getUserProfileTooltipText,
        userProfileTooltipObv: userProfileTooltipObv,
        editionAndVersionObv: editionAndVersionObv,
        productNameObv: productNameObv,
        isGoogleUserObv: isGoogleUserObv,
        allowOpenTsvFileObv: allowOpenTsvFileObv,
        allowUserAccessToChargebeePortalObv: allowUserAccessToChargebeePortalObv,
        allowDataUploadObv: allowDataUploadObv,
        displayEmailVerificationMenuOptionObv: displayEmailVerificationMenuOptionObv,
        getAvailableManufacturerMakesArray: getAvailableManufacturerMakesArray,
        setAvailableManufacturerMakesArray: setAvailableManufacturerMakesArray,
        checkAvailableManufacturerMakesArray: checkAvailableManufacturerMakesArray,
        getAvailableManufacturerRangesArray: getAvailableManufacturerRangesArray,
        setAvailableManufacturerRangesArray: setAvailableManufacturerRangesArray,
        checkAvailableManufacturerRangesArray: checkAvailableManufacturerRangesArray,
        getAvailableManufacturerAxleLayoutsArray: getAvailableManufacturerAxleLayoutsArray,
        setAvailableManufacturerAxleLayoutsArray: setAvailableManufacturerAxleLayoutsArray,
        checkAvailableManufacturerAxleLayoutsArray: checkAvailableManufacturerAxleLayoutsArray,
        getAvailableManufacturerVehicleTypesArray: getAvailableManufacturerVehicleTypesArray,
        setAvailableManufacturerVehicleTypesArray: setAvailableManufacturerVehicleTypesArray,
        checkAvailableManufacturerVehicleTypesArray: checkAvailableManufacturerVehicleTypesArray,
        getAvailableManufacturerStatusesArray: getAvailableManufacturerStatusesArray,
        setAvailableManufacturerStatusesArray: setAvailableManufacturerStatusesArray,
        checkAvailableManufacturerStatusesArray: checkAvailableManufacturerStatusesArray,
        getAvailableManufacturerCountriesArray: getAvailableManufacturerCountriesArray,
        setAvailableManufacturerCountriesArray: setAvailableManufacturerCountriesArray,
        checkAvailableManufacturerCountriesArray: checkAvailableManufacturerCountriesArray,
        getAvailableGenericAxleLayoutsArray: getAvailableGenericAxleLayoutsArray,
        setAvailableGenericAxleLayoutsArray: setAvailableGenericAxleLayoutsArray,
        checkAvailableGenericAxleLayoutsArray: checkAvailableGenericAxleLayoutsArray,
        getAvailableGenericVehicleTypesArray: getAvailableGenericVehicleTypesArray,
        setAvailableGenericVehicleTypesArray: setAvailableGenericVehicleTypesArray,
        checkAvailableGenericVehicleTypesArray: checkAvailableGenericVehicleTypesArray,
        clearSelectionArrays: clearSelectionArrays,
        getVehicleSelectionSearchHistoryMultiSelectFilters: getVehicleSelectionSearchHistoryMultiSelectFilters,
        getDefaultManufacturerMultiselectFilters: getDefaultManufacturerMultiselectFilters,
        getVehicleSelectionSearchHistoryDisplayBreadcrumbs: getVehicleSelectionSearchHistoryDisplayBreadcrumbs,
        updateVehicleSelectionSearchHistoryMultiSelectFilters: updateVehicleSelectionSearchHistoryMultiSelectFilters,
        updateVehicleSelectionSearchHistoryDisplayBreadcrumbs: updateVehicleSelectionSearchHistoryDisplayBreadcrumbs,
        allowDataUpload: allowDataUpload,
        allowOpenTsvFile: allowOpenTsvFile,
        allowSpecificationModule: allowSpecificationModule,
        displayRecommendedBrowserMessage: displayRecommendedBrowserMessage,
        displayQuickSearchHelperMessage: displayQuickSearchHelperMessage,
        displayStandardItemMessage: displayStandardItemMessage,
        setDefaultMultiselectFilters: setDefaultMultiselectFilters,
        isUserIntegratedWithNTEA: isUserIntegratedWithNTEA,
        isVipTrialUser: isVipTrialUser,
        getNTEAIntegrationObject: getNTEAIntegrationObject,
        getIntegrations: getIntegrations,
        getIntegrationObjectUsingType: getIntegrationObjectUsingType,
        displayNteaOptionsToUser: displayNteaOptionsToUser,
        displaySalesToolOptionsToUser: displaySalesToolOptionsToUser,
        allowUserAccessToChargebeePortal: allowUserAccessToChargebeePortal,
        doNotAllowBuyNowButtonToBeDisplayed: doNotAllowBuyNowButtonToBeDisplayed,
        getSpecifyCabDimensionsAs: getSpecifyCabDimensionsAs,
        getSpecifyChassisLengthAs: getSpecifyChassisLengthAs,
        getSpecifyFuelConsumptionAs: getSpecifyFuelConsumptionAs,
        displayFuelConsumptionAs: displayFuelConsumptionAs,
        getSpecifyLicencingRegionAs: getSpecifyLicencingRegionAs,
        getSpecifyAxleRatingAs: getSpecifyAxleRatingAs,
        getMenuItemsHistoryOnSelectionScreen: getMenuItemsHistoryOnSelectionScreen,
        setMenuItemsHistoryOnSelectionScreen: setMenuItemsHistoryOnSelectionScreen,
        clearMenuItemsHistoryOnSelectionScreen: clearMenuItemsHistoryOnSelectionScreen,
        getMenuItemHistoryOnSelectionScreenUsingId: getMenuItemHistoryOnSelectionScreenUsingId,
        getNumberOfOffersFromServer: getNumberOfOffersFromServer,
        setNumberOfOffersFromServer: setNumberOfOffersFromServer,
        getNumberOfBodiesFromServer: getNumberOfBodiesFromServer,
        setNumberOfBodiesFromServer: setNumberOfBodiesFromServer,
        getNumberOfAccessoriesFromServer: getNumberOfAccessoriesFromServer,
        setNumberOfAccessoriesFromServer: setNumberOfAccessoriesFromServer,
        getNumberOfTrailersFromServer: getNumberOfTrailersFromServer,
        setNumberOfTrailersFromServer: setNumberOfTrailersFromServer,
        getNumberOfPayloadsFromServer: getNumberOfPayloadsFromServer,
        setNumberOfPayloadsFromServer: setNumberOfPayloadsFromServer,
        getDefaultReportPdfPageSize: getDefaultReportPdfPageSize,
        getReportLogo: getReportLogo,
        setReportLogo: setReportLogo,
        // getReportLogoForReport: getReportLogoForReport,
        getUserLicenceInstanceId: getUserLicenceInstanceId,
        getDashboardConfiguration: getDashboardConfiguration,
        setDashboardConfiguration: setDashboardConfiguration,
        getDashboardDataPoints: getDashboardDataPoints,
        replaceDashboardDataPointsUsingArrayOfIds: replaceDashboardDataPointsUsingArrayOfIds,
        getInternalStandards: getInternalStandards,
        replaceInternalStandardsUsingArray: replaceInternalStandardsUsingArray,
        replaceDashboardLegislationsUsingArray: replaceDashboardLegislationsUsingArray,
        getTranslationForDataPoint: getTranslationForDataPoint,
        getIsMemberOfDistributorCompany: getIsMemberOfDistributorCompany,
        getLegislationUsingId: getLegislationUsingId,
        getLegislationDescriptionUsingId: getLegislationDescriptionUsingId,
        getCompanyAdditionalNote: getCompanyAdditionalNote,
        setCompanyAdditionalNote: setCompanyAdditionalNote,
        allowedToEditCompanyDetails: allowedToEditCompanyDetails,
        getLegislationStubObjectUsingId: getLegislationStubObjectUsingId,
        getSpecifyBodyPositionAs: getSpecifyBodyPositionAs,
        getSpecifyEquipmentPositionAs: getSpecifyEquipmentPositionAs,
        isUserAllowedToUploadLogo: isUserAllowedToUploadLogo,
        isUserAllowedFullAccessToSettingsFavourites: isUserAllowedFullAccessToSettingsFavourites,
        isUserAllowedFullAccessToSettingsInternalStandards: isUserAllowedFullAccessToSettingsInternalStandards,
        isUserAllowedFullAccessToSettingsRegulations: isUserAllowedFullAccessToSettingsRegulations,
        isUserAllowedFullAccessSettingsCompanyNotes: isUserAllowedFullAccessSettingsCompanyNotes,
        isUserAllowedToManageAccount: isUserAllowedToManageAccount,
        isUserAllowedFullAccessToConfigurationAddingAxles: isUserAllowedFullAccessToConfigurationAddingAxles,
        isUserAllowedFullAccessToConfigurationTurningCircle: isUserAllowedFullAccessToConfigurationTurningCircle,
        isUserAllowedFullAccessToGeneralImportDxf: isUserAllowedFullAccessToGeneralImportDxf,
        isUserAllowedFullAccessToShareCalculations: isUserAllowedFullAccessToShareCalculations,
        isUserAllowedFullAccessToTeamLibrary: isUserAllowedFullAccessToTeamLibrary,
        isUserAllowedFullAccessToRequestData: isUserAllowedFullAccessToRequestData,
        getEdition: getEdition,
        getProduct: getProduct,
        setAppRefreshUnderway: setAppRefreshUnderway,
        getAppRefreshUnderway: getAppRefreshUnderway,
        removeAppRefreshUnderway: removeAppRefreshUnderway,
        isUserAllowedToPublishToDistributorLibrary: isUserAllowedToPublishToDistributorLibrary,
        getTranslationForDDRMessageCode: getTranslationForDDRMessageCode,
        getTranslationForAccessoryCode: getTranslationForAccessoryCode,
        doesCountryCodeMatchUserCountryAbbreviation: doesCountryCodeMatchUserCountryAbbreviation,
        getUserDetailsAsJSONOBject: getUserDetailsAsJSONOBject,
        isUSOrCanadaBasedUser: isUSOrCanadaBasedUser,
        isReportViewOnUser: isReportViewOnUser,
        addReportViewToUser: addReportViewToUser,
        removeReportViewFromUser: removeReportViewFromUser,
        isUserAllowedToChangeReportViewLayout: isUserAllowedToChangeReportViewLayout,
        getMicrosoftLoggedInUserAccessTokenAsPassword: getMicrosoftLoggedInUserAccessTokenAsPassword,
        setMicrosoftLoggedInUserAccessTokenAsPassword: setMicrosoftLoggedInUserAccessTokenAsPassword,
        setAuthenticationMethod: setAuthenticationMethod,
        setEmail: setEmail,
        showAfterframe: showAfterframe,
        getNteaUserValidationType: getNteaUserValidationType,
        getCompanyInterfaceAttributes: getCompanyInterfaceAttributes,
        getCompanyInterfaceAttributeByName: getCompanyInterfaceAttributeByName,
        getCompanyInterfaceAttributeByTruckScienceName: getCompanyInterfaceAttributeByTruckScienceName,
        isUserAllowedToEmailReport: isUserAllowedToEmailReport,
        isUserAllowedToAddPreparedForDetails: isUserAllowedToAddPreparedForDetails,
        isUserAllowedToShareCalculations: isUserAllowedToShareCalculations,
        canShowShareButtonRef: canShowShareButtonRef,
        canShowPreparedForSectionRef: canShowPreparedForSectionRef,

    };

    /**
     * Return legislation object from getAvailableLegislations() based on id parameter
     * @param {number} id - Compund ID of the legislation
     * @returns {object|null} - Returns legislation object if found and null if not found
     */
    function getLegislationUsingId(id) {
        if (typeof id === 'number') {
            var legislationToUse = getAvailableLegislations().find(function (availableLegislation) {
                return availableLegislation.id === id;
            });
            if (legislationToUse !== undefined) {
                return legislationToUse;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Return legislation description from getAvailableLegislations() based on id parameter
     * @param {number} id - Compund ID of the legislation
     * @returns {string|null} - Returns legislation description if found and null if not found
     */
    function getLegislationDescriptionUsingId(id) {
        if (typeof id === 'number') {
            var legislationToUse = getLegislationUsingId(id);
            if (legislationToUse !== null) {
                return legislationToUse.description;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Return legislation stub object from getAvailableLegislations() based on id parameter
     * @param {number} id - Compund ID of the legislation
     * @returns {object|null} - Returns legislation description if found and null if not found
     */
    function getLegislationStubObjectUsingId(id) {
        if (typeof id === 'number') {
            var legislationToUse = getLegislationUsingId(id);
            if (legislationToUse !== null) {
                return legislationToUse;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    /**
     * Function to check if country code is the same as the country abbreviation on the user
     * @param {string} codeToCheck - country code to check
     * @returns {boolean} - True if codes match, False otherwise
     */
    function doesCountryCodeMatchUserCountryAbbreviation(codeToCheck) {
        return codeToCheck.toUpperCase() === getCountryAbbreviation().toUpperCase();
    }

    /**
     * Checks if a view with these details is on the user's report views
     * @param {string} viewType 
     * @param {string} viewDetail 
     * @param {string} elevation 
     * @param {string} display 
     * @returns {boolean}
     */
    function isReportViewOnUser(viewType, viewDetail, elevation, display) {
        var returnValue = false;
        if(typeof viewType !== 'string' || typeof viewDetail !== 'string' || typeof elevation !== 'string' || typeof display !== 'string') {
            return returnValue;
        }
        var reportViews = getReportViewsObjects();
        if (reportViews !== undefined) {
            var reportView = reportViews.find(function (rv) {
                return rv.ViewType === viewType && rv.ViewDetail === viewDetail && rv.Elevation === elevation && rv.Display === display;
            });
            if (reportView !== undefined) {
                returnValue = true;
            }
        }
        return returnValue;
    }
    /**
     * Checks if a view with these details is on the user's report views
     * @param {string} viewType 
     * @param {string} viewDetail 
     * @param {string} elevation 
     * @param {string} display 
     */
    function addReportViewToUser(viewType, viewDetail, elevation, display, isActive) {
        var reportViewsToUse = getReportViewsObjects();
        if (reportViewsToUse !== undefined) {
            var reportView = reportViewsToUse.find(function (rv) {
                return rv.ViewType === viewType && rv.ViewDetail === viewDetail && rv.Elevation === elevation && rv.Display === display;
            });
            if (reportView === undefined) {
                var sortedByOrder = reportViewsToUse.toSorted(function (a, b) {
                    return b.Order - a.Order;
                });
                reportViewsToUse.push({
                    IsActive: typeof isActive === 'boolean' ? isActive : true,
                    ViewType: viewType,
                    ViewDetail: viewDetail,
                    Elevation: elevation,
                    Display: display,
                    Order: sortedByOrder.length > 0 ? sortedByOrder[0].Order + 1 : 1
                });
                setReportViews(reportViewsToUse);
            }
        }
    }

    /**
     * Checks if a view with these details is on the user's report views
     * @param {string} viewType
     * @param {string} viewDetail
     * @param {string} elevation
     * @param {string} display
     */
    function removeReportViewFromUser(viewType, viewDetail, elevation, display) {
        var reportViewsToUse = getReportViewsObjects();
        if (reportViewsToUse !== undefined) {
            var reportView = reportViewsToUse.find(function (rv) {
                return rv.ViewType === viewType && rv.ViewDetail === viewDetail && rv.Elevation === elevation && rv.Display === display;
            });
            if (reportView !== undefined) {
                reportViewsToUse.splice(reportViewsToUse.indexOf(reportView), 1);
                setReportViews(reportViewsToUse);
            }
        }	
    }
};

export default User;
  