<template>
    <popup-modal ref="popup">
        <div id="modal" class="messageBox">
            <div class="modal-header modal-header-text">
                <h2 class="modal-title reset-password-title" id="myModalLabel"><span>{{ getTranslationText('147') }}</span></h2>
            </div>
            <div class="modal-body modal-body-text">
                <span class="forgot-pwd-msg-text">{{ getTranslationText('146') }}</span><br /><br />
                <div class="input-group login-input-group">
                    <span class="input-group-addon glyphicon glyphicon-envelope"></span>
                    <input class="form-control login-input"
                        required=""
                        type="email"
                        v-model="email"
                        :placeholder="getTranslationText('124')" />
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience btn-primary" @click="ok" @touchstart="ok"><span >{{ getTranslationText('457') }}</span></button>
                <button class="btn btn-default btn-truckscience" @click="close" @touchstart="close"><span >{{ getTranslationText('65') }}</span></button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import security from '@/services/security';
import dataManager from '@/services/dataManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import PopupModal from '@/components/modals/PopupModal.vue';
import { ref, nextTick, toRef } from 'vue';

const $ = window.$;

export default {
    name: 'ForgotPasswordModal',
    components: { PopupModal },
    setup: (props, context) => {
        // Private variables
        const popup = ref(null);
        const opts = toRef(props, "propsForModal");
        let resolvePromise = undefined,
        rejectPromise = undefined,
        email = ref(''),
        sendingEmail = ref(false),
        getTranslationText = config.getTranslationText;

        function show(opts = {}) {
            // Once we set our config, we tell the popup modal to open
            popup.value.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }

        function forgottenPassword () {
            globals.showSpinner();
            sendingEmail.value = true;

            $.when(security.forgottenPassword(email.value)).then(
                function (success) {
                    if (success.message.ReturnCode > 0) {
                        // globals.showDialog(new CustomMessageBox(config.getMessageText('1552', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1552')));
                        sendingEmail.value = false;
                        email.value = '';
                        globals.hideSpinner();
                        close();
                        // nextTick(globals.showDialog(new CustomMessageBox(config.getMessageText('1552', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1552'))));
                        nextTick(function(){
                            globals.showDialog(new CustomMessageBox(config.getMessageText('1552', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1552')));
                        });
                    } else if (success.message.ReturnCode === -3 || success.message.ReturnCode === -4) {
                        var messageCode = success.message.ReturnCode === -3 ? '1563' : '1564';
                        // globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageCode)));
                        sendingEmail.value = false;
                        email.value = '';
                        globals.hideSpinner();
                        close();
                        nextTick(function(){
                            globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageCode)))
                        });
                    } else {
                        globals.hideSpinner();
                        sendingEmail.value = false;
                        close();
                        if (success.message.ReturnCode === -1 || success.message.ReturnCode === -5) {
                            nextTick(function(){
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1551', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1551')))
                            });
                        } else {
                            dataManager.log("forgottenPassword, security.forgottenPassword, success, 1571, ReturnCode: " + success.message.ReturnCode, success.message.Message, 'forgotPasswordModal', config.LOG_MESSAGE_TYPE.ERROR);
                            nextTick(function(){
                                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                            });
                        }
                    }
                },

                function (error) {
                    sendingEmail.value = false;
                    globals.hideSpinner();
                    dataManager.log("forgottenPassword, security.forgottenPassword, error, 1571", error, 'forgotPasswordModal', config.LOG_MESSAGE_TYPE.ERROR);
                    close();
                    nextTick(function(){
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')))
                    });
                }
            );
        }
        function ok() {
            if(globals.isValidEmailAddress(email.value)) {
                forgottenPassword();
            } else {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1553', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1553')));
            }
        }
        function close() {
            popup.value.close();
            resolvePromise();
        }

        return {
            getTranslationText,
            ok,
            close,
            show,
            popup,
            email,
            sendingEmail
        };

        
    },
    
}
</script>
