<template>
    <popup-modal ref="popup">
        <div id="modal" 
            class="messageBox swap-item-modal" 
            :class="modalCssClasses">
            <div class="modal-header modal-header-text">
                <h4 class="modal-title">
                    <span data-bind="text: title">{{title}}</span>
                </h4>
            </div>
            <div class="modal-body modal-body-text">
                <div class="configuration-wizard-input-container-div">
                    <MenuContainer :menuFromParent="activeMenu"></MenuContainer>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-default btn-truckscience"
                    @click="close()"
                    data-bind="click: close, touchstart: close">
                    <span data-bind="text: closeButtonText">{{closeButtonText}}</span>
                </button>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import { toRef, ref, shallowRef, onBeforeMount, onMounted, nextTick } from 'vue';
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import offerManager from '@/services/offerManager';

import Menu from '@/services/menu/menu';
import MultiSelect from "@/services/multiselect/multiselect";

import MenuPartBuilder from "@/services/menu/menuPartBuilder";
import WizardBuilderHelper from "@/services/wizard/wizardBuilderHelper";
import MultiSelectBuilder from "@/services/multiselect/multiselectBuilder";

import PopupModal from '@/components/modals/PopupModal.vue';
import MenuContainer from '@/components/menu/MenuContainer.vue';

export default {
    name: 'SwapItemModal',
    components: { PopupModal, MenuContainer },
    props: {
        propsForModal: Object
    },
    setup(props, context) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        const activeMenu = shallowRef(null);
        const $ = window.$;
        const TScMessenger = window.TScMessenger;

        let resolvePromise = undefined,
            // title = config.getTranslationText('65'),
            title = config.getTranslationText('4634'),
            closeButtonText = config.getTranslationText('65');
        
        function show(opts = {}) {
           // Once we set our config, we tell the popup modal to open
            this.popup.open()
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                this.rejectPromise = reject
            })
        }
        function close(param) {
            popup.value.close();
            resolvePromise(param);
        }

        onBeforeMount(() => {
            activeMenu.value = createVehicleSwapMenu();
        });
        // onMounted(() => {
        //     activeMenu.value = createVehicleSwapMenu();
        //     // select.dataToRender.value.initializeMultiSelects();
        // });

        // #region Shared Functions
        function getMultiSelectArray(type, array) {
            var multiselectArray = [],
                partialMultiSelectId,
                partialMultiSelectElementId,
                buttonWidth = 150;

            if (type === 'VEHICLE') {
                partialMultiSelectId = 'MULTISELECT-VEHICLE';
                partialMultiSelectElementId = 'multiselect-vehicle';
            } else if (type === 'BODY') {
                partialMultiSelectId = 'MULTISELECT-BODY';
                partialMultiSelectElementId = 'multiselect-body';
            } else if (type === 'ACCESSORY') {
                partialMultiSelectId = 'MULTISELECT-ACCESSORY';
                partialMultiSelectElementId = 'multiselect-accessory';
            } else if (type === 'TRAILER') {
                partialMultiSelectId = 'MULTISELECT-TRAILER';
                partialMultiSelectElementId = 'multiselect-trailer';
            } else {
                partialMultiSelectId = 'MULTISELECT-PAYLOAD';
                partialMultiSelectElementId = 'multiselect-payload';
            }

            TScMessenger.writeDebugMessage('Type: ' + type);
            if (type === 'VEHICLE') {
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-STATUS')
                        .selectElementId(partialMultiSelectElementId + '-status-id')
                        .enableHTML()
                        .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.STATUS)
                        .userSelectionOptions(globals.user.getAvailableManufacturerStatusesArray())
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('Status')
                        .setNonSelectedText(config.getTranslationText('4006'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .build()
                ));
                TScMessenger.writeDebugMessage('AvailableManufacturerCountriesArray: ' + globals.user.getAvailableManufacturerCountriesArray().length);
                if (globals.user.getAvailableManufacturerCountriesArray().length > 1) {
                    multiselectArray.push(new MultiSelect(
                        new MultiSelectBuilder(partialMultiSelectId + '-COUNTRY')
                            .selectElementId(partialMultiSelectElementId + '-country-id')
                            .enableHTML()
                            .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.COUNTRY)
                            .userSelectionOptions(globals.user.getAvailableManufacturerCountriesArray())
                            .enableSearch()
                            .enableClearButton()
                            .objectPropertyToFilterOn('CountryName')
                            .setNonSelectedText(config.getTranslationText('4018'))
                            .setButtonWidth(buttonWidth)
                            .setMaxHeight(300)
                            .build()
                    ));
                }

                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-MAKE')
                        .selectElementId(partialMultiSelectElementId + '-make-id')
                        .enableHTML()
                        .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_MAKE)
                        .userSelectionOptions(globals.user.getAvailableManufacturerMakesArray())
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('VehicleMake')
                        .setNonSelectedText(config.getTranslationText('4007'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .addOnChangeExtraCallback(function () {
                            var newArray = [],
                                makeMultiSelect = this,
                                currentSelectionWizard = activeMenu.value.activeMenuStep.value.menuStepItems.value[0].dataToRender.value,
                                rangeMultiSelect = currentSelectionWizard.getMultiSelectByPartialName('RANGE');
                            if (rangeMultiSelect !== null) {
                                var indexToUse = currentSelectionWizard.multiSelectFilters.findIndex(getVehicleMakeIfItExists);
                                if (indexToUse >= 0) {
                                    var filterObject = currentSelectionWizard.multiSelectFilters[indexToUse];
                                    var arrayFilteredByMake = currentSelectionWizard.rows.filter(function (vehicle) {
                                        var returnValue = false;
                                        for (var i = 0; i < filterObject.values.length; i++) {
                                            if (filterObject.values[i] === vehicle.VehicleMake) {
                                                returnValue = true;
                                            }
                                        }
                                        return returnValue;
                                    });
                                    // filter array to get individual range values
                                    var arrayToUse = globals.getUniqueObjectsFromArrayUsingProperty(arrayFilteredByMake, 'VehicleRange');
                                    arrayToUse.forEach(function (element) {
                                        newArray.push({
                                            description: element['VehicleRange'],
                                            value: element['VehicleRange']
                                        });
                                    });
                                    newArray.sort(function (a, b) {
                                        if (a.description > b.description) {
                                            return 1;
                                        }
                                        if (a.description < b.description) {
                                            return -1;
                                        }
                                        return 0;
                                    });
                                    rangeMultiSelect.updateOptionsArray(newArray);
                                } else {
                                    rangeMultiSelect.updateOptionsArray([]);
                                }
                                // refresh rangeMultiSelect
                                nextTick(function(){
                                    rangeMultiSelect.rebuildMultiSelect();
                                });
                            }
                            function getVehicleMakeIfItExists(element, index, array) {
                                return element.property === 'VehicleMake';
                            }
                        })
                        .addOnDeselectAllExtraCallback(function () {
                            var makeMultiSelect = this,
                                rangeMultiSelect = activeMenu.value.activeMenuStep.value.menuStepItems.value[0].dataToRender.value.getMultiSelectByPartialName('RANGE');
                            if (rangeMultiSelect !== null) {
                                rangeMultiSelect.updateOptionsArray([]);
                                nextTick(function(){
                                    rangeMultiSelect.rebuildMultiSelect();
                                });
                            }
                        })
                        .build()
                ));
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-RANGE')
                        .selectElementId(partialMultiSelectElementId + '-range-id')
                        .enableHTML()
                        .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_RANGE)
                        .userSelectionOptions([])
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('VehicleRange')
                        .setNonSelectedText(config.getTranslationText('4008'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .disableIfEmpty(config.getTranslationText('4008'))
                        .build()
                ));
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-AXLE-LAYOUT')
                        .selectElementId(partialMultiSelectElementId + '-axle-layout-id')
                        .enableHTML()
                        .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.AXLE_LAYOUT)
                        .userSelectionOptions(globals.user.getAvailableManufacturerAxleLayoutsArray())
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('AxleLayout')
                        .setNonSelectedText(config.getTranslationText('4009'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .build()
                ));
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-VEHICLE-TYPE')
                        .selectElementId(partialMultiSelectElementId + '-vehicle-type-id')
                        .enableHTML()
                        .objectPropertyToFilterOn(config.SELECTION_DISTINCT_PROPERTY_NAME.VEHICLE_TYPE)
                        .userSelectionOptions(globals.user.getAvailableManufacturerVehicleTypesArray())
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('VehicleTypeTranslation')
                        .setNonSelectedText(config.getTranslationText('4010'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .build()
                ));
            } else {
                if (type === 'TRAILER') {
                    buttonWidth = 150;
                }
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-STATUS')
                        .selectElementId(partialMultiSelectElementId + '-status-id')
                        .enableHTML()
                        .objectPropertyToFilterOn('getStatusForMultiSelect')
                        .userSelectionOptions([])
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('getStatusForMultiSelect')
                        .setNonSelectedText(config.getTranslationText('4006'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .build()
                ));
                multiselectArray.push(new MultiSelect(
                    new MultiSelectBuilder(partialMultiSelectId + '-MAKE')
                        .selectElementId(partialMultiSelectElementId + '-make-id')
                        .enableHTML()
                        .objectPropertyToFilterOn('getMakeForMultiSelect')
                        .userSelectionOptions([])
                        .enableSearch()
                        .enableClearButton()
                        .objectPropertyToFilterOn('getMakeForMultiSelect')
                        .setNonSelectedText(config.getTranslationText('4007'))
                        .setButtonWidth(buttonWidth)
                        .setMaxHeight(300)
                        .build()
                ));

                if (type === 'TRAILER') {
                    multiselectArray.push(new MultiSelect(
                        new MultiSelectBuilder(partialMultiSelectId + '-BODY-TYPE')
                            .selectElementId(partialMultiSelectElementId + '-body-type-id')
                            .enableHTML()
                            .objectPropertyToFilterOn('getBodyTypeText')
                            .userSelectionOptions([])
                            .enableSearch()
                            .enableClearButton()
                            .objectPropertyToFilterOn('getBodyTypeText')
                            .setNonSelectedText(config.getTranslationText('4026'))
                            .setButtonWidth(buttonWidth)
                            .setMaxHeight(300)
                            .build()
                    ));
                }
            }


            return multiselectArray;
        }
        // #endregion Shared Functions 

        // #region Menu
        function createMenu() {
            var menuToUse = new Menu();
            menuToUse.createMenu(createVehicleMenuStructure());
            return menuToUse;
        }
        function createVehicleMenuStructure() {
            var vehicleMenuStructureRootPath = config.CHASSIS_OBJECT_TYPES.VEHICLE;
            return getMenuStructure(vehicleMenuStructureRootPath);
        }
        function getMenuStructure(path) {
            return new MenuPartBuilder(path)
                .newMenuStep(config.getTranslationText('4901'), 0, offerManager.getActiveOffer().getRig().getVehicle().getDescription())
                .displayAccordionOnStep()
                .addToBreadCrumbs()
                .items(getMenuItems(path))
                .build();
        }
        function getMenuItems(path) {
            var items = [];
            items.push(getVehicleSelectionWizard(path));
            return items;
        }
        // #endregion Menu

        // #region Vehicle Swap
        function getVehicleSelectionWizard(path) {
            return new MenuPartBuilder(path)
                .newMenuItem(config.ITEM_TYPE.SELECTIONWIZARD)
                .label(config.getTranslationText('1069'))
                .items([getVehicleSelectionWizardObjectForMenu(path)])
                .build();
        }
        function getVehicleSelectionWizardObjectForMenu(path) {
            function getSourceRequestItemFunction(itemsArray, filtersArray) {
                var returnObj = {};
                var filterItem = filtersArray.find(function (filter) {
                    return filter.property === 'Source';
                });
                // get the first accessory
                var vehicle;
                if (filterItem !== undefined) {
                    vehicle = itemsArray.find(function (item) {
                        return item.Source === filterItem.value;
                    });
                } else {
                    vehicle = itemsArray[0];
                }
                returnObj.requestItemTitle = config.getTranslationText('1073');
                returnObj.requestItemType = config.CHASSIS_OBJECT_TYPES.VEHICLE;
                returnObj.intercomEventType = config.INTERCOM_EVENT.REQUESTED_VEHICLE;
                return returnObj;
            }

            function getWizardTitleFunction(itemsArray, filtersArray) {
                return config.getTranslationText('1074');
            }

            var vehicleDatagridColumns = [
                new WizardBuilderHelper.DatagridColumnBuilder().name('Source').nameDisplayed(false).sortable(false).headerClass('datagrid-header-icon').headerThClass('datagrid-icon-th').build(),
                new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('4002')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('Description').build(),        
                new WizardBuilderHelper.DatagridColumnBuilder().name(globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE ? 'Wheelbase' : 'CA/CT').nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort(getWheelbaseOrCabToAxleItemToSortOn).headerThClass(globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE ? 'datagrid-column-th-wheelbase' : 'datagrid-column-th-cact').build(),
                new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('4568')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GVW').headerThClass('datagrid-th-gvw').build(),
                new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('4569')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GCW').headerThClass('datagrid-th-gcw').build(),
                new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('4005')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('SpecDate').headerThClass('datagrid-column-th-spec-date').build()
            ];

            var addFunctionWrappedToAddContainerRepositionToChain = function (itemData) {
                swapCurrentVehicle(itemData);
            };

            var datagridTabs = [];
            datagridTabs.push(new WizardBuilderHelper.StepTabBuilder().displayName(config.getTranslationText('17')).iconClass('datagrid-icon-all')
                .filterObject(new WizardBuilderHelper.TabFilterObjectBuilder().property('Source').value(config.VEHICLE_SOURCE_TYPES.ALL).build())
                .build());
            datagridTabs.push(new WizardBuilderHelper.StepTabBuilder().displayName(config.getTranslationText('476')).iconClass('fa fa-file')
                .filterObject(new WizardBuilderHelper.TabFilterObjectBuilder().property('Source').value(config.VEHICLE_SOURCE_TYPES.TEMPLATE).build())
                .build());
            datagridTabs.push(new WizardBuilderHelper.StepTabBuilder().displayName(config.getTranslationText('235')).iconClass('fa fa-file-alt')
                .filterObject(new WizardBuilderHelper.TabFilterObjectBuilder().property('Source').value(config.VEHICLE_SOURCE_TYPES.STANDARD).build())
                .build());

            var vehicleSelectionWizardSteps = [
                new WizardBuilderHelper.SelectionWizardStepBuilder().displayName(config.getTranslationText('1074')).displayInBreadcrumb(false).displayQuickSearch(true).displayMultiSelect(true)
                    .sortFunction(vehicleSwapSortFunction)
                    .filterFunction(vehicleSwapFilterFunction)
                    .clickFunction(addFunctionWrappedToAddContainerRepositionToChain)
                    .renderAs(config.RENDERED_AS_OPTIONS.DATAGRID)
                    .rowTemplateName(getManufacturerVehiclesDatagridTemplate)
                    // .columns(vehicleDatagridColumns)
                    .columns(getManufacturerVehiclesDatagridColumns())
                    .limitResults(config.WIZARD_INCREMENT.SELECTION)
                    .tabs(datagridTabs)
                    .requestItemFunction(getSourceRequestItemFunction)
                    .setWizardTitleFunction(getWizardTitleFunction)
                    .alwaysDisplayStep()
                    .bypassAutoItemSelectWhenOnlyOne()
                    .build()
            ];

            var vehicleSelectionWizard = new WizardBuilderHelper.SelectionWizardBuilder().path(path)
                .placeholderText(config.getTranslationText('4022'))
                .addTitleButDoNotDisplay(config.getTranslationText('1074'))
                .steps(vehicleSelectionWizardSteps)
                .rows(offerManager.getNewAndSavedOffersForVehicleSelectionInMenu())
                // .rowType(config.WIZARD_ROW_TYPE.VEHICLETYPE)
                .rowType(config.WIZARD_ROW_TYPE.SELECTION)
                .containerId('vehicle-container')
                .searchTermsChangeCallbackFunction(vehicleSearchTermsCallback, config.INTERCOM_EVENT.SEARCHED_FOR_VEHICLE)
                .openDialogClickListenerCallback(openDialogCallback)
                .displayDialogAsOverlay()
                .alwaysHideBreadcrumbs()
                .filterOption(config.WIZARD_FILTER_OPTIONS.MANUFACTURER_VEHICLE_SELECTION)
                .addMultiSelects(getMultiSelectArray('VEHICLE'))
                .alwaysHideBreadcrumbs()
                .alwaysHideSearchResultsBreadcrumb()
                .build();

            function openDialogCallback() {
                var metadataObject = {};
                metadataObject[config.INTERCOM_METADATA_ITEM.SOURCE] = config.CONSIDERED_REQUEST_SOURCES.LINK;
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.CONSIDERED_VEHICLE_REQUEST, metadataObject));
            }

            return vehicleSelectionWizard;
        }
        function swapCurrentVehicle(vehicleDetails) {
            globals.showSpinner();
            $.when(dataManager.getNewOfferDetails(vehicleDetails.Id, vehicleDetails.VehicleDistributionOptionId, vehicleDetails.VehicleDistributionGroupId), dataManager.getLicenceCategories())
                .done(function (newOfferDetails, licenceCategories) {
                    try {
                        if (newOfferDetails.offer.Offer.DataAvailability.ActiveSpecification === true && (globals.user.hasPermission(config.PERMISSIONS.SPECIFICATION.Code) || globals.user.allowSpecificationModule())) {
                            //now get the specification details in the background
                            offerManager.preloadNewSpecForSwap(vehicleDetails.Id, vehicleDetails.VehicleDistributionOptionId, vehicleDetails.VehicleDistributionGroupId, newOfferDetails.offer.Offer.LanguageId, newOfferDetails.offer.Offer.CurrencySymbolMajor).then(function (specDetails) {
                                doRestOfCode(vehicleDetails, newOfferDetails, specDetails, licenceCategories);
                            });
                        } else {
                            doRestOfCode(vehicleDetails, newOfferDetails, undefined, licenceCategories);
                        }
                    } catch (error) {
                        globals.hideSpinner();
                    }
                })
                .fail(function (error) {
                    globals.hideSpinner();
                })

            function doRestOfCode(vehicleDetails, newOfferDetails, specDetails, licenceCategories) {
                globals.hideSpinner();
                offerManager.swapCurrentVehicle(vehicleDetails, newOfferDetails, specDetails, licenceCategories);
                // offerManager.invalidatePdfAndDrawing();
                close();
            }
        }
        function vehicleSwapSortFunction(arrayToSort, columns) {
            return globals.sortArrayInOrderCustomTemplateStandard(arrayToSort, columns);
        }
        function vehicleSwapFilterFunction(row, searchTermsArray, filtersArray, showAll, multiSelectFilters) {
            var i, returnVal = true;
            if (!row.isSavedOffer && !row.isSharedOffer && row.rowType !== config.ROW_TYPE.FOLDER && row.rowType !== config.ROW_TYPE.VIRTUAL_FOLDER) {
                var descriptionWithNoSpecialChars = row.Description.replace(/[^a-zA-Z0-9 ]/g, ""),
                    cultureCode = globals.user.getCultureCode().toLowerCase();
                var numberOfMatches = 0;
                var wheelbaseTheoreticalConvertedValueString = row.wheelbaseTheoreticalConvertedValueString();
                for (i = 0; i < searchTermsArray.length; i++) {
                    switch (cultureCode) {
                        case config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase():
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || descriptionWithNoSpecialChars.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                        case config.LANGUAGE_PACK.ENGLISH.AU.cultureCode.toLowerCase():
                        case config.LANGUAGE_PACK.ENGLISH.NZ.cultureCode.toLowerCase():
                        case config.LANGUAGE_PACK.ENGLISH.SA.cultureCode.toLowerCase():
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.ModelCode.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || wheelbaseTheoreticalConvertedValueString.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || descriptionWithNoSpecialChars.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                        default:
                            if (row.Description.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || row.AxleLayout.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || wheelbaseTheoreticalConvertedValueString.toUpperCase().includes(searchTermsArray[i].toUpperCase()) || descriptionWithNoSpecialChars.toUpperCase().includes(searchTermsArray[i].toUpperCase())) {
                                numberOfMatches += 1;
                            }
                            break;
                    }
                }
                returnVal = numberOfMatches === searchTermsArray.length;
                if (returnVal) {
                    for (i = 0; i < filtersArray.length; i++) {
                        if (filtersArray[i].value !== config.ACCESSORY_SOURCE_TYPES.ALL) {
                            if (row[filtersArray[i].property] !== filtersArray[i].value) {
                                return false;
                            } else {
                                if (filtersArray[i].property === 'getAccessLevel' && filtersArray[i].value === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL) {
                                    if (row.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                    if (multiSelectFilters.length > 0) {
                        var count = 0;
                        multiSelectFilters.forEach(function (ms) {
                            ms.values.forEach(function (filterValue) {
                                if (row[ms.property] === filterValue) {
                                    count++;
                                }
                            });
                        });
                        if (count !== multiSelectFilters.length) {
                            return false;
                        }
                    }
                }
            } else {
                return false;
            }
            return returnVal;
        }
        function getWheelbaseOrCabToAxleItemToSortOn() {
            if (globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE) {
                return globals.getWheelbaseItemToSortOn(globals.user.getSpecifyWheelbaseAs());
            } else {
                return globals.getCabToAxleItemToSortOn(globals.user.getSpecifyWheelbaseAs());
            }
        }
        function vehicleSearchTermsCallback(newValue) {
            if (newValue.sendToIntercom) {
                var intercomObject = {};
                intercomObject[config.INTERCOM_METADATA_ITEM.SEARCH_TERM] = newValue.searchTerms;
                intercomObject[config.INTERCOM_METADATA_ITEM.SEARCH_LOCATION] = 'configuration';
                dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(newValue.searchTermsIntercomEvent, intercomObject));
            }
        }
        function getManufacturerVehiclesDatagridTemplate() {
            if (globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase() || globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.CA.cultureCode.toLowerCase() || globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase()) {
                return 'template-selection-manufacturer-datagrid-row';
            } else {
                return 'template-selection-datagrid-row';
            }
        }
        function getManufacturerVehiclesDatagridColumns() {
            if (globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.US.cultureCode.toLowerCase() || globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.ENGLISH.CA.cultureCode.toLowerCase() || globals.user.getCultureCode().toLowerCase() === config.LANGUAGE_PACK.FRENCH.CA.cultureCode.toLowerCase()) {
                return getDatagridColumnsForUS();
            } else {
                return getDatagridColumns();
            }

            function getDatagridColumnsForUS() {
                var arrayToReturn = [];

                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name('').nameDisplayed(false).sortable(false).headerClass('datagrid-header-icon').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('110')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('Description').headerClass('datagrid-column datagrid-column-large datagrid-description').headerThClass('datagrid-description').isActiveSort().build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('676')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).headerClass('datagrid-column').itemToSort(function() { return globals.getWheelbaseItemToSortOn(globals.user.getSpecifyWheelbaseAs()); }).headerClass('datagrid-column datagrid-wheelbase').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('1426')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).headerClass('datagrid-column').itemToSort(function() { return globals.getCabToAxleItemToSortOn(globals.user.getSpecifyWheelbaseAs()); }).headerClass('datagrid-column datagrid-ca').headerThClass('datagrid-th-ca').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('1457')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GVW').headerClass('datagrid-column datagrid-gvw').headerThClass('datagrid-th-gvw').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('1458')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GCW').headerClass('datagrid-column datagrid-gcw').headerThClass('datagrid-th-gcw').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('211')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('AxleLayout').headerClass('datagrid-column datagrid-axle-layout').headerThClass('datagrid-th-axle-layout').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('512')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('VehicleTypeTranslation').headerClass('datagrid-column datagrid-vehicle-type').headerThClass('datagrid-th-vehicle-type').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('443')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.DESC).itemToSort('SpecDate').headerClass('datagrid-column datagrid-spec-date').headerThClass('datagrid-th-spec-date').build());
                
                return arrayToReturn;
            }

            function getDatagridColumns() {
                var arrayToReturn = [];

                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name('').nameDisplayed(false).sortable(false).headerClass('datagrid-header-icon').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('110')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('Description').headerClass('datagrid-column datagrid-column-large datagrid-description').headerThClass('datagrid-th-description').isActiveSort().build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('905')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).headerClass('datagrid-column').itemToSort('ModelCode').headerClass('datagrid-column datagrid-model-code').headerThClass('datagrid-th-model-code').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('676')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).headerClass('datagrid-column').itemToSort(function() { return globals.getWheelbaseItemToSortOn(globals.user.getSpecifyWheelbaseAs()); }).headerClass('datagrid-column datagrid-wheelbase datagrid-th-wheelbase').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('211')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('AxleLayout').headerClass('datagrid-column datagrid-axle-layout').headerThClass('datagrid-th-axle-layout').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('512')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('VehicleTypeTranslation').headerClass('datagrid-column datagrid-vehicle-type').headerThClass('datagrid-th-vehicle-type').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('1457')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GVW').headerClass('datagrid-column datagrid-gvw').headerThClass('datagrid-th-gvw').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('1458')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.ASC).itemToSort('GCW').headerClass('datagrid-column datagrid-gcw').headerThClass('datagrid-th-gcw').build());
                arrayToReturn.push(new WizardBuilderHelper.DatagridColumnBuilder().name(config.getTranslationText('443')).nameDisplayed(true).sortable(true).sortType(config.SORT_OPTIONS.DESC).itemToSort('SpecDate').headerClass('datagrid-column datagrid-spec-date').headerThClass('datagrid-th-spec-date').build());
                
                return arrayToReturn;
            }
        }
        // #endregion Vehicle Swap
        function createVehicleSwapMenu() {
            var vehicleSwapMenu = createMenu();
            vehicleSwapMenu.activeMenuStep.value.menuStepItems.value[0].dataToRender.value.setMultiSelectFilters(globals.getDefaultManufacturerSelectionWizardMultiSelectFilterObject());
            vehicleSwapMenu.activeMenuStep.value.menuStepItems.value[0].dataToRender.value.updateItemsToDisplayArray();

            return vehicleSwapMenu;
        }

        return {
            popup,
            // Variables
            activeMenu,
            // Functions
            show,
            close,
            // Text
            title,
            closeButtonText
        }
    }
}
</script>

<style lang="scss" scoped>
.swap-item-modal .selection-wizard-template {
    height: 500px;
}

.swap-item-modal .configuration-wizard-input-container-div td {
    display: table-cell;
}

.swap-item-modal .datagrid-container-div {
    height: unset;
}
</style>