<template>
    <popup-modal ref="popup">
        <div v-if="configurationWizard !== null"
            :class="getPageContainerClass()"
            id="itemConfigurationWizardModal-id" 
            class="messageBox itemConfigurationWizardModal-container" 
            data-bind="with: configurationWizard, css: getPageContainerClass()">
            <div class="modal-header">
                <div class="wizard-progress-tracker-container" data-bind="foreach: wizardSteps">
                    <template v-for="(wizardStep, index) in configurationWizard.wizardSteps" :key="wizardStep">
                        <div :class="{'active-wizard-step': wizardStep.isActive.value === true, 'complete-wizard-step': wizardStep.isComplete.value === true }"
                            class="wizard-progress-tracker-container" 
                            data-bind="css: { 'active-wizard-step': isActive(), 'complete-wizard-step': isComplete() }">
                            <div class="progress-tracker-connection-div"></div>
                            <div class="progress-tracker-number-div">
                                <span class="progress-tracker-number" data-bind="text: $index() + 1">{{index + 1}}</span>
                            </div>
                        </div>
                    </template>
                </div>
                <h4 class="modal-title" data-bind="text: activeWizardStep().title">{{configurationWizard.activeWizardStep.value.title}}</h4>
            </div>
            <div class="modal-body">
                <div :class="configurationWizard.activeWizardStep.value.customContainerCss.value"
                    class="configuration-wizard-body-container" 
                    data-bind="css: activeWizardStep().customContainerCss">
                    <!-- <div class="configuration-wizard-radio-group flex-item" data-bind="template: { name: 'template-itemConfigurationWizardModal-radio-group', data: activeWizardStep }"></div> -->
                    <RadioGroup :activeWizardStepFromParent="configurationWizard.activeWizardStep"></RadioGroup>
                    <!-- <div class="configuration-wizard-dxf-input-container flex-item" data-bind="template: { name: 'template-itemConfigurationWizardModal-dxf-input-container', data: activeWizardStep().fileInputForm }"></div> -->
                    <DxfInputContainer :fileInputFormFromParent="configurationWizard.activeWizardStep.value.fileInputForm"></DxfInputContainer>
                    <div class="configuration-wizard-canvas-container flex-item">
                        <div id="itemConfigurationWizardDrawingArea" class="configuration-wizard-canvas-div">
                        </div>
                    </div>
                    <!-- <div class="configuration-wizard-input-container flex-item" data-bind="template: { name: 'template-itemConfigurationWizardModal-input-container', data: activeWizardStep }"></div> -->
                    <InputContainer :activeWizardStepFromParent="configurationWizard.activeWizardStep"></InputContainer>
                    <!-- <div class="configuration-wizard-results-table-container flex-item" data-bind="template: { name: 'template-itemConfigurationWizardModal-results-table-container', data: activeWizardStep }"></div> -->
                    <ResultsTableContainer :activeWizardStepFromParent="configurationWizard.activeWizardStep"></ResultsTableContainer>
                </div>
            </div>
            <div :class="{ 'modal-footer-space-between': configurationWizard.displayFooterCheckbox === true }"
                class="modal-footer" 
                data-bind="css: { 'modal-footer-space-between': displayFooterCheckbox === true }">
                <div v-if="configurationWizard.displayFooterCheckbox === true" 
                    @click="configurationWizard.toggleFooterCheckbox"
                    class="tsc-checkbox-with-message" 
                    data-bind="click: toggleFooterCheckbox, touchstart: toggleFooterCheckbox">
                    <div class="tsc-icon-container">
                        <i :class="{ 'fa-check-square': configurationWizard.isFooterCheckboxChecked === true, 'fa-square': configurationWizard.isFooterCheckboxChecked === false }"
                            class="far fa-lg" 
                            data-bind="css: { 'fa-check-square': isFooterCheckboxChecked(), 'fa-square': !isFooterCheckboxChecked() }"></i>
                    </div>
                    <span data-bind="text: footerCheckboxText">{{configurationWizard.footerCheckboxText}}</span>
                </div>
                <div>
                    <button v-if="configurationWizard.displayCancelButton.value === true"
                        @click="cancelButtonClick"
                        :class="{ 'btn-inactive': configurationWizard.isCancelButtonActive.value === false }"
                        class="btn btn-default btn-truckscience" 
                        data-bind="click: isCancelButtonActive() ? clickOnCancelButton : null, touchstart: isCancelButtonActive() ? clickOnCancelButton : null, css: { 'btn-inactive': !isCancelButtonActive() }">
                        <span data-bind="text: cancelButtonText">{{configurationWizard.cancelButtonText}}</span>
                    </button>
                    <button v-if="configurationWizard.displayBackButton.value === true"
                        @click="backButtonClick"
                        :class="{ 'btn-inactive': configurationWizard.isBackButtonActive.value === false }"
                        class="btn btn-default btn-truckscience" 
                        data-bind="click: isBackButtonActive() ? clickOnBackButton : null, touchstart: isBackButtonActive() ? clickOnBackButton : null, css: { 'btn-inactive': !isBackButtonActive() }">
                        <span data-bind="text: backButtonText">{{configurationWizard.backButtonText}}</span>
                    </button>
                    <button v-if="configurationWizard.displayNextButton.value === true"
                        @click="nextButtonClick"
                        :class="{ 'btn-inactive': configurationWizard.isNextButtonActive.value === false }"
                        class="btn btn-default btn-truckscience" 
                        data-bind="click: isNextButtonActive() ? clickOnNextButton : null, touchstart: isNextButtonActive() ? clickOnNextButton : null, css: { 'btn-inactive': !isNextButtonActive() }">
                        <span data-bind="text: nextButtonText">{{configurationWizard.nextButtonText}}</span>
                    </button>
                    <button v-if="configurationWizard.displayDoneButton.value === true"
                        @click="doneButtonClick"
                        :class="{ 'btn-inactive': configurationWizard.isDoneButtonActive.value === false }"
                        class="btn btn-default btn-truckscience btn-primary" 
                        data-bind="click: isDoneButtonActive() ? clickOnDoneButton : null, touchstart: isDoneButtonActive() ? clickOnDoneButton : null, css: { 'btn-inactive': !isDoneButtonActive() }">
                        <span data-bind="text: doneButtonText">{{configurationWizard.doneButtonText}}</span>
                    </button>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>
import config from '@/services/config';
import globals from '@/services/globals';
import dataManager from '@/services/dataManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import graphicsManager from '@/services/graphicsManager';
import offerManager from '@/services/offerManager';
import utils from '@/services/utils';
import PopupModal from '../PopupModal.vue';
import WizardHelper from '@/services/wizard/wizardHelper';
import WizardBuilderHelper from '@/services/wizard/wizardBuilderHelper';
import MenuPartBuilder from '@/services/menu/menuPartBuilder';
import MenuItemBuilder from '@/services/menu/menuItemBuilder';
import Menu from '@/services/menu/menu';
import UIInputBuilder from '@/services/uiInput/uiInputBuilder';
import UIRadioGroup from '@/services/uiInput/ui.radioGroup';
import UIFileInputForm from '@/services/uiInput/ui.fileInputForm';
import { ref, shallowRef, toRef, computed, onUpdated, onBeforeMount, onMounted, watch, nextTick } from 'vue';
import RadioGroup from './RadioGroup.vue';
import DxfInputContainer from './DxfInputContainer.vue';
import InputContainer from './InputContainer.vue';
import ResultsTableContainer from './ResultsTableContainer.vue';

const $ = window.$;
const TScMessenger = window.TScMessenger;
const WebuiPopovers = window.WebuiPopovers;

export default {
    name: 'ItemConfigurationWizardModal',
    components: { PopupModal, RadioGroup, DxfInputContainer, InputContainer, ResultsTableContainer },
    props: {
        propsForModal: Object
    },
    setup: (props, context) => {

        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        let resolvePromise = undefined,
                rejectPromise = undefined;
        const configurationWizard = shallowRef(null);
        
        let callback,
            wizardType,
            wizardTypeToUse,
            templatesArray,
            configurationObject,
            originalConfigurationObject,
            accessoryType,
            accessorySubType,
            path,
            parentPath,
            chassisObjectType,
            modalPathForAttrObvGroup,
            customCssClassForTextInput,
            selectMenuWidth = 150,
            heightTooltipTextComputed = null,
            lengthTooltipTextComputed = null,
            widthTooltipTextComputed = null,
            includeSelectionWizardStep = false,
            includeSelectEquipmentStep = false,
            hideSubmitButton = 'display-radio-group display-drawing-only',
            showSubmitButton = hideSubmitButton + ' display-dxf-file-input',
            reviewAndFinalize = hideSubmitButton + ' display-review-and-finalize',
            isCompositionComplete,
            userDrawingDetails;


        onBeforeMount(() => {

            

            callback = opts.value.callback;

            modalPathForAttrObvGroup = 'configurationModal' + '.' + opts.value.modalObject.accessoryType;
            customCssClassForTextInput = 'configuration-wizard-text-input';
            wizardTypeToUse = opts.value.modalObject.wizardType;
            templatesArray = opts.value.modalObject.templatesArray;

            if (wizardTypeToUse === config.WIZARD_TYPES.ADD_NEW) {
                if (!Array.isArray(templatesArray)) {
                    throw 'templatesArray not passed into itemConfigurationWizardModal';
                }
            } else if (wizardTypeToUse === config.WIZARD_TYPES.EDIT_EXISTING) {
                if (typeof opts.value.modalObject.configurationObject !== 'object' || opts.value.modalObject.configurationObject === null) {
                    throw 'configurationObject not passed into itemConfigurationWizardModal';
                }
            } else {
                throw 'Unknown wizardType'
            }
            isCompositionComplete = false;
            wizardType = wizardTypeToUse;
            chassisObjectType = opts.value.modalObject.chassisObjectType;
            parentPath = opts.value.modalObject.path;
            path = modalPathForAttrObvGroup//path;
            accessoryType = opts.value.modalObject.accessoryType;
            accessorySubType = typeof opts.value.modalObject.accessorySubType === 'string' ? opts.value.modalObject.accessorySubType : '';
            configurationWizard.value = null;
            includeSelectionWizardStep = false;
            includeSelectEquipmentStep = false;
            templatesArray = opts.value.modalObject.templatesArray;
            configurationObject = typeof opts.value.modalObject.configurationObject === 'object' && opts.value.modalObject.configurationObject !== null ? opts.value.modalObject.configurationObject : null;
            userDrawingDetails = {
                dxf: {
                    side: null,
                    top: null
                },
                template: {}
            };
            graphicsManager.notify('Reset_InitialiseCalled_Flag');
            
            if (opts.value.modalObject.configurationObject !== undefined && opts.value.modalObject.configurationObject !== null) {
                configurationObject = opts.value.modalObject.configurationObject.clone();
                if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                    if (configurationObject.getWeightType() === config.PAYLOAD_WEIGHT_TYPES.WEIGHT) {
                        configurationObject.setPayload(configurationObject.getMass() / configurationObject.getDensity());
                        //configurationObject.setPayload(configurationObject.getMass() / configurationObject.getDensity());
                    }
                }
                createAttributeObservableGroupForModal(modalPathForAttrObvGroup, configurationObject);
                originalConfigurationObject = configurationObject.clone();
                if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY &&
                    (configurationObject.getType() === config.BODY_TYPES.DROPSIDE || configurationObject.getType() === config.BODY_TYPES.BEAVERTAIL ||
                        configurationObject.getType() === config.BODY_TYPES.FLATDECK || configurationObject.getType() === config.BODY_TYPES.ROLLBACK ||
                        configurationObject.getType() === config.BODY_TYPES.TIPPER || configurationObject.getType() === config.BODY_TYPES.TIMBER)) {

                    configurationObject.setHeight(Math.max(configurationObject.getHeadboardHeight(), configurationObject.getTailboardHeight()));
                    if (configurationObject.getType() === config.BODY_TYPES.DROPSIDE || configurationObject.getType() === config.BODY_TYPES.BEAVERTAIL) {
                        configurationObject.setDropsideHeight(originalConfigurationObject.getActualInternalHeight());
                    }
                }
                offerManager.setConfigurationWizardItems(configurationObject, originalConfigurationObject);
            }
            activate();
            
        });

        onMounted(() => {
            popup.value.open();
            nextTick(()=> {
                // $.when(activate()).done(function(){
                    // setTimeout(() => compositionComplete(), 1000);
                    compositionComplete();
                // });
            });
            
        });

        function show() {
            

            popup.value.open();
           
            // Once we set our config, we tell the popup modal to open
            
            // Return promise so the caller can get results
            return new Promise((resolve, reject) => {
                resolvePromise = resolve
                rejectPromise = reject
            })
        }

        // function close() {
        //     this.popup.close();
        // }

        // function cancelButtonClick() {
        //     if(configurationWizard.value.isCancelButtonActive.value === true) {
        //         configurationWizard.value.clickOnCancelButton();
        //     }
        // }

        // function backButtonClick() {
        //     if(configurationWizard.value.isBackButtonActive.value === true) {
        //         configurationWizard.value.clickOnBackButton();
        //     }
        // }
        
        // function nextButtonClick() {
        //     if(configurationWizard.value.isNextButtonActive.value === true) {
        //         configurationWizard.value.clickOnNextButton();
        //     }
        // }
        
        // function doneButtonClick() {
        //     if(configurationWizard.value.isDoneButtonActive.value === true) {
        //         configurationWizard.value.clickOnDoneButton();
        //     }
        // }



        onUpdated(() => {
            TScMessenger.writeDebugMessage('');
        })
        


function getPageContainerClass() {
    if (accessoryType === config.ACCESSORY_TYPES.BODY) {
        return globals.getPageContainerClass() + ' body-configuration-wizard-container';
    } else {
        return globals.getPageContainerClass();
    }
}

// #region Durandal Lifecycle Functions
function activate() {
    // var activateDfd = $.Deferred();
    if (wizardTypeToUse === config.WIZARD_TYPES.ADD_NEW) {
        if (Array.isArray(templatesArray) && templatesArray.length > 1 && templatesArray[0].getAccessorySubType && templatesArray[0].getAccessorySubType() !== undefined && templatesArray[0].getAccessorySubType() !== '') {
            // create the selection wizard on the configuration wizard
            includeSelectionWizardStep = true;
            includeSelectEquipmentStep = true;
        }
        var templateArrayOrItemToPassIntoCreate = templatesArray;
        if (Array.isArray(templatesArray) && templatesArray.length === 0) {
            templateArrayOrItemToPassIntoCreate = {
                getAccessoryType: function () {
                    return accessoryType;
                }
            };
        }
        createItemObject(templateArrayOrItemToPassIntoCreate, offerManager.getRigOps().getChassisTypeUsingPath(parentPath), undefined, createConfigurationWizardCallback);
    } else {
        configurationWizard.value = createConfigurationWizard(wizardTypeToUse);
        // activateDfd.resolve({});
    }

    // return activateDfd.promise();
}

function compositionComplete() {
    // $(document).ready(function () {
        if (configurationObject && configurationObject.getGraphicBlob() !== null && utils.itemHasGraphic(configurationObject)) {
            setupGM(config.PREVIEW_TYPE.STANDARD);
            configurationWizard.value.isNextButtonActive.value = true;
            configurationWizard.value.activeWizardStep.value.setIsNextButtonActiveWhenStepIsActive(true);
        }
        isCompositionComplete = true;
    // });
}
// #endregion Durandal Lifecycle Functions

function setupGM(previewType) {
        var initialGraphicAdjustment;
        var initialNegativeGraphicAdjustment;

        if (userDrawingDetails.dxf.side) {
            initialGraphicAdjustment = {
                leftMost: userDrawingDetails.dxf.side.leftMostX,
                bottomMost: userDrawingDetails.dxf.side.bottomMostY
            };
            setupGMForStep();

        } else if (configurationObject && configurationObject.getGraphicBlob() !== null && utils.itemHasGraphic(configurationObject)) {
            if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                initialNegativeGraphicAdjustment = {
                    leftMost: -originalConfigurationObject.getMechanismLength(),//leftMostBottomMostPoint.leftMost,
                    bottomMost: -originalConfigurationObject.getMechanismHeight()//leftMostBottomMostPoint.bottomMost
                }
            }
            setupGMForStep();
        }

        function setupGMForStep() {
            if (previewType !== config.PREVIEW_TYPE.STANDARD) {
                graphicsManager.cleanUp();
            }
            // graphicsManager.cleanUp();
            // Removing canvas element because it sometimes causes issues with container element's size
            var canvas = $("#itemConfigurationWizardDrawingArea #drawing-area-canvas");
            if(canvas.length > 0) {
                $("#itemConfigurationWizardDrawingArea #drawing-area-canvas").remove();
            }

            var drawingArea = $("#itemConfigurationWizardDrawingArea").get();
            var allocatedHeight = $("#itemConfigurationWizardDrawingArea").height();
            var allocatedWidth = $("#itemConfigurationWizardDrawingArea").width();

            var fontName = window.getComputedStyle(document.body, null).getPropertyValue('font-family');
            var gmConfig = {
                renderMode: config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY,
                objectToRender: configurationObject,
                previewType: previewType,
                elevation: config.ELEVATION.SIDE,//utils.getElevationFromItemConfigurationWizardStep(self.configurationWizard.activeWizardStep().stepType),
                fontName: fontName,
                initialGraphicAdjustment: initialGraphicAdjustment,
                initialNegativeGraphicAdjustment: initialNegativeGraphicAdjustment
            };

            graphicsManager.renderDrawing(drawingArea[0], allocatedWidth, allocatedHeight, gmConfig);
        }
    }


function createItemObject(itemStub, pathRoot, pmse, onSuccessCallback) {
    try {
        var itemPath,
            itemObject,
            itemAsJSON,
            itemStubToUse = Array.isArray(itemStub) ? itemStub[0] : itemStub;

        switch (itemStubToUse.getAccessoryType()) {
            case config.ACCESSORY_TYPES.BODY:
                itemPath = pathRoot + '.' + config.ACCESSORY_TYPES.BODY;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.BODY;
                itemAsJSON.Type = accessorySubType;
                break;
            case config.ACCESSORY_TYPES.CRANE:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.CRANE;
                break;
            case config.ACCESSORY_TYPES.OTHER:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.OTHER;
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.FRIDGE;
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.TAILLIFT;
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.FIFTH_WHEEL;
                break
            case config.ACCESSORY_TYPES.HITCH:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.HITCH;
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                itemPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.HOOKLIFT;
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                itemPath = pathRoot + '.' + config.ACCESSORY_TYPES.PAYLOAD;
                itemAsJSON = config.NEW_CUSTOM_ITEM_OBJECT_JSON.PAYLOAD;
                itemAsJSON.UnitOfMeasure = globals.user.getActiveMeasurementSystem().unitMass;
                itemAsJSON.Density = (itemAsJSON.Density / globals.user.getActiveMeasurementSystem().massConversion).toFixed(4);
                break;
            default:
                itemPath = 'ERROR_IN_PATH_WHEN_ADDING_ACCESSORY_CONFIGURATION_WIZARD_MODAL_JS';
        }
        itemObject = offerManager.createItemObject(itemAsJSON, undefined, pathRoot, 0);
        createAttributeObservableGroupForModal(modalPathForAttrObvGroup, itemObject);
        configurationObject = itemObject;
        if (itemStubToUse.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || itemStubToUse.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
            parentPath = parentPath + '.' + itemObject.getId();
        }
        if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            if (configurationObject.getType() === config.BODY_TYPES.HOOKLIFT) {
                configurationObject.setSubType(config.BODY_SUB_TYPES.BODY_ONLY);
            }
            if (configurationObject.getType() === config.BODY_TYPES.HOOKLIFT && configurationObject.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
                configurationObject.setLengthType(config.BODY_MEASUREMENT_TYPES.INTERNAL);
                configurationObject.setHeightType(config.BODY_MEASUREMENT_TYPES.INTERNAL);
                configurationObject.setWidthType(config.BODY_MEASUREMENT_TYPES.INTERNAL);
            }
            if (configurationObject.getType() === config.BODY_TYPES.FLATDECK ||
                configurationObject.getType() === config.BODY_TYPES.ROLLBACK ||
                configurationObject.getType() === config.BODY_TYPES.TIMBER ||
                configurationObject.getType() === config.BODY_TYPES.OTHER) {

                configurationObject.setHeightType(config.BODY_MEASUREMENT_TYPES.INTERNAL);
            }
            
        }
        originalConfigurationObject = configurationObject.clone();
        offerManager.setConfigurationWizardItems(configurationObject, originalConfigurationObject);
        onSuccessCallback();

        if (pmse !== undefined) {
            pmse.resolve();
        }
    } catch (error) {
        
        dataManager.log("createItemObject, itemConfigurationWizardModal, fail, 1571", error, 'itemConfigurationWizardModal', config.LOG_MESSAGE_TYPE.ERROR);
        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));

        if (pmse !== undefined) {
            pmse.reject();
        }
    }
}

// #region Configuration Wizard
/**
 * 
 * @param {string} wizardType 
 * @param {boolean} excludeMenus
 */
function createConfigurationWizard(wizardType, includeSelectionWizardStep) {
    return new WizardHelper.createConfigurationWizard(
        new WizardBuilderHelper.ConfigurationWizardBuilder(createConfigurationWizardSteps(wizardType, includeSelectionWizardStep))
            .clickOnCancelButtonCallback(closeDialog)
            .clickOnDoneButtonCallback(closeAndSaveItem)
            .updateDoneButtonText(wizardType === config.WIZARD_TYPES.EDIT_EXISTING ? config.getTranslationText('1406') : config.getTranslationText('1405'))
            .build()
    );
}
function closeDialog() {
    close();
}
function closeAndSaveItem() {
    ok();
}
/**
 * 
 * @param {string} wizardType
 * @param {boolean} excludeMenus
 */
function createConfigurationWizardSteps(wizardType, excludeMenus) {
    switch (wizardType) {
        case config.WIZARD_TYPES.ADD_NEW:
            return createAddNewWizardSteps(false);
        case config.WIZARD_TYPES.EDIT_EXISTING:
            return createEditExistingWizardSteps();
        default:
            throw 'Unknown wizardType';
    }

    /**
     * 
     * @param {boolean} excludeMenus
     */
    function createAddNewWizardSteps(excludeMenus) {
        switch (accessoryType) {
            case config.ACCESSORY_TYPES.BODY:
                return createAddNewBodyWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.CRANE:
                return createAddNewCraneWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return createAddNewFifthWheelWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.HITCH:
                return createAddNewHitchWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.TAILLIFT:
                return createAddNewTailliftWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.FRIDGE:
                return createAddNewFridgeWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.OTHER:
                return createAddNewOtherWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return createAddNewHookliftWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.TRAILER:
                return createAddNewTrailerWizardSteps(excludeMenus);
            case config.ACCESSORY_TYPES.PAYLOAD:
                return createAddNewPayloadWizardSteps(excludeMenus);
            default:
                throw 'Unknown wizardType';
        }
    }

    function createEditExistingWizardSteps() {
        switch (accessoryType) {
            case config.ACCESSORY_TYPES.BODY:
                return createEditExistingBodyWizardSteps();
            case config.ACCESSORY_TYPES.CRANE:
                return createEditExistingCraneWizardSteps();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return createEditExistingFifthWheelWizardSteps();
            case config.ACCESSORY_TYPES.HITCH:
                return createEditExistingHitchWizardSteps();
            case config.ACCESSORY_TYPES.TAILLIFT:
                return createEditExistingTailliftWizardSteps();
            case config.ACCESSORY_TYPES.FRIDGE:
                return createEditExistingFridgeWizardSteps();
            case config.ACCESSORY_TYPES.OTHER:
                return createEditExistingOtherWizardSteps();
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return createEditExistingHookliftWizardSteps();
            case config.ACCESSORY_TYPES.TRAILER:
                return createEditExistingTrailerWizardSteps();
            case config.ACCESSORY_TYPES.PAYLOAD:
                return createEditExistingPayloadWizardSteps();
            default:
                throw 'Unknown wizardType';
        }
    }

    function getSelectEquipmentTypeStep(objectsForOptions) {
        //return createWizardStep(WIZARD_STEP_TYPES.SELECT_EQUIPMENT_TYPE, 'Select <equipment> type', '', self.WIZARD_LAYOUT_TYPES.BUTTONS_ONLY, true, false);
        var text = '';
        switch(accessoryType) {
            case config.ACCESSORY_TYPES.HITCH:
                text = config.getTranslationText('1339');
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                text = config.getTranslationText('1340');
                break;
            default:
                text = config.getTranslationText('1339');
                break;
        }
        return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.SELECT_EQUIPMENT_TYPE, text, config.WIZARD_LAYOUT_TYPES.BUTTONS_ONLY)
            .isActive()
            .hideBackButton()
            .addRadioGroupInput(createRadioGroup(templatesArray, onEquipmentTypeSelected, 'image-card-container', 'image-card'))
            .addCustomContainerCssClass('display-radio-group')
            .clickOnNextButtonCallbackForStep(setTypeForEquipment)
            .build();
    }

    function onEquipmentTypeSelected(newValue) {
        configurationWizard.value.activeWizardStep.value.isNextButtonActiveWhenStepIsActive = true;
        configurationWizard.value.isNextButtonActive.value = true;
    }

    function setTypeForEquipment() {
        configurationObject.setType(configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption.value);
        if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.HITCH) {
            configurationObject.setHeightFromGround(offerManager.getHitchHeightFromGround(configurationObject.getType()));
        }
    }

    function getTemplateFromCloudServices() {
        var pmseToUse = $.Deferred(),
            updateFunction,
            getFromCloudServiceFunction;

        
        switch (accessoryType) {
            case config.ACCESSORY_TYPES.HITCH:
                updateFunction = updateConfigurationWizardForHitch;
                getFromCloudServiceFunction = getHitchTemplateFromCloudServices;
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                updateFunction = updateConfigurationWizardForTaillift;
                getFromCloudServiceFunction = getTailliftTemplateFromCloudServices;
                break;
            default:
                updateFunction = updateConfigurationWizardForHitch;
                getFromCloudServiceFunction = getHitchTemplateFromCloudServices;
                break;
        }

        if (getFromCloudServiceFunction()) {
            var accessoryStub = templatesArray.find(function (template) {
                return template.getAccessorySubType() === configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption();
            });
            updateFunction(accessoryStub);
        }

        pmseToUse.resolve();

        function updateConfigurationWizard() {
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_SIDE_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_TOP_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_SIDE_VIEW).addMenuToStep(addAdjustCogMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_TOP_VIEW).addMenuToStep(addAdjustCogMenu());
        }

        function updateConfigurationWizardForHitch(hitchStub) {
            configurationObject.setType(hitchStub.getAccessorySubType());
        }

        function updateConfigurationWizardForTaillift(tailliftStub) {
            configurationObject.setType(tailliftStub.getAccessorySubType());
        }

        function getHitchTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            if (configurationObject.getType() !== configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption()) {
                return true;
            }
            return false;
        }

        function getTailliftTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            if (configurationObject.getType() !== configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption()) {
                return true;
            }
            return false;
        }

        function getItemTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            return false;
        }

        return pmseToUse.promise();
    }

    function setAccessorySubType() {
        var pmseToUse = $.Deferred(),
            updateFunction,
            getFromCloudServiceFunction;


        switch (accessoryType) {
            case config.ACCESSORY_TYPES.HITCH:
                updateFunction = updateConfigurationWizardForHitch;
                getFromCloudServiceFunction = getHitchTemplateFromCloudServices;
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                updateFunction = updateConfigurationWizard;
                getFromCloudServiceFunction = getTailliftTemplateFromCloudServices;
                break;
            default:
                updateFunction = updateConfigurationWizardForHitch;
                getFromCloudServiceFunction = getHitchTemplateFromCloudServices;
                break;
        }

        if (getFromCloudServiceFunction()) {
            var accessoryStub = templatesArray.find(function (template) {
                return template.getAccessorySubType() === configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption();
            });
            getAccessoryFromCloudServices(accessoryStub, 'VEHICLE', pmseToUse, updateFunction);
        } else {
            pmseToUse.resolve();
        }


        function updateConfigurationWizard() {
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_SIDE_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_TOP_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_SIDE_VIEW).addMenuToStep(addAdjustCogMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_TOP_VIEW).addMenuToStep(addAdjustCogMenu());
        }

        function updateConfigurationWizardForHitch() {
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_SIDE_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_SCALE_TOP_VIEW).addMenuToStep(addAdjustScaleMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_SIDE_VIEW).addMenuToStep(addAdjustCogMenu());
            configurationWizard.value.getWizardStepUsingStepType(config.WIZARD_STEP_TYPES.ADJUST_COG_TOP_VIEW).addMenuToStep(addAdjustCogMenu());
        }

        function getHitchTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            if (configurationObject.getType() !== configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption()) {
                return true;
            }
            return false;
        }

        function getTailliftTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            if (configurationObject.getType() !== configurationWizard.value.activeWizardStep.value.radioGroupInput.checkedOption()) {
                return true;
            }
            return false;
        }

        function getItemTemplateFromCloudServices() {
            if (configurationObject === null) {
                return true;
            }
            return false;
        }

        function updateConfigurationWizardForTaillift() {
            TScMessenger.writeDebugMessage('');
        }

        return pmseToUse.promise();
    }

    function getSelectSideViewDrawingTypeStep() {
        return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.DRAWING_TYPE_SIDE_VIEW, config.getTranslationText('1356'), config.WIZARD_LAYOUT_TYPES.DRAWING_ONLY)
            .addCustomContainerCssClass(showSubmitButton)
            .addFileInputForm(createDxfFileInput())
            .activateWizardStepCallback(setupForDxfIfCheckedOnActivate)
            .build();
    }

    

    function getSpecifySideViewOriginStep() {
        return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.SPECIFY_SIDE_VIEW_ORIGIN, config.getTranslationText('1355'), config.WIZARD_LAYOUT_TYPES.DRAWING_ONLY)
            .addCustomContainerCssClass('display-drawing-only')
            .nextButtonIsActive()
            .activateWizardStepCallback(setupGMForAdjustOriginStep)
            .build();
    }

    function setupGMForAdjustOriginStep() { 

        if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            var itemLengthAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMLENGTH);
            var itemHeightAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMHEIGHT);

            if (itemLengthAOG.override.value) {
                itemLengthAOG.override.value = false;
            }
            if (itemHeightAOG.override.value) {
                itemHeightAOG.override.value = false;
            }
        } else {
            var itemDrawingLengthAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
            var itemDrawingHeightAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);

            if (itemDrawingLengthAOG.override.value) {
                itemDrawingLengthAOG.override.value = false;
            }
            if (itemDrawingHeightAOG.override.value) {
                itemDrawingHeightAOG.override.value = false;
            }
        }

        if (configurationObject.setLength) {
            if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (configurationObject.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    configurationObject.setLength(offerManager.getOriginalConfigurationWizardItemSize().getLength() - configurationObject.getRampLength() - configurationObject.getRampPlatesProtrusion());
                } else {
                    configurationObject.setLength(offerManager.getOriginalConfigurationWizardItemSize().getLength() - configurationObject.getHeadboardThickness() - configurationObject.getTailboardThickness() - configurationObject.getRampLength() - configurationObject.getRampPlatesProtrusion());
                }
            } else {
                configurationObject.setLength(offerManager.getOriginalConfigurationWizardItemSize().getLength());
            }
            
        }
        if (configurationObject.setHeight) {
            if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                if (configurationObject.getType() !== config.BODY_TYPES.SERVICE) {
                    if (configurationObject.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                        configurationObject.setHeight(offerManager.getOriginalConfigurationWizardItemSize().getHeight() - configurationObject.getSubframeHeight());
                    } else {
                        configurationObject.setHeight(offerManager.getOriginalConfigurationWizardItemSize().getHeight() - configurationObject.getSubframeHeight() - configurationObject.getSubstructureHeight() - configurationObject.getRoofThickness());
                    }

                } else {
                    configurationObject.setHeight(offerManager.getOriginalConfigurationWizardItemSize().getHeight());
                }
            }
        }
        if (configurationObject.setWidth) {
            configurationObject.setWidth(offerManager.getOriginalConfigurationWizardItemSize().getWidth());
        }
        switch (configurationObject.getAccessoryType()) {
            case config.ACCESSORY_TYPES.CRANE:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.OTHER:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.HITCH:
                configurationObject.setFunnelLength(originalConfigurationObject.getFunnelLength());
                
                configurationObject.setMountingLength(originalConfigurationObject.getMountingLength());
                var mountingLengthAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMMOUNTINGLENGTH);
                mountingLengthAOG.value.value = originalConfigurationObject.getMountingLength();

                configurationObject.setCouplingOffset(originalConfigurationObject.getCouplingOffset());
                var couplingOffsetAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMCOUPLINGOFFSET);
                couplingOffsetAOG.value.value = originalConfigurationObject.getCouplingOffset();

                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                configurationObject.setPlatformProtrusion(originalConfigurationObject.getPlatformProtrusion());

                configurationObject.setMechanismLength(originalConfigurationObject.getMechanismLength());
                var mechanismLengthAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMMECHANISMLENGTH);
                mechanismLengthAOG.value.value = originalConfigurationObject.getMechanismLength();

                configurationObject.setPlatformHeight(originalConfigurationObject.getPlatformHeight());
                configurationObject.setMechanismHeight(originalConfigurationObject.getMechanismHeight());
                var mechanismHeightAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMMECHANISMHEIGHT);
                mechanismHeightAOG.value.value = originalConfigurationObject.getMechanismHeight()
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                configurationObject.setProtrusion(originalConfigurationObject.getProtrusion());
                var protrusionAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMPROTRUSION);
                protrusionAOG.value.value = originalConfigurationObject.getProtrusion();
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                configurationObject.setAFrame(originalConfigurationObject.getAFrame());
                var aFrameAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMAFRAME);
                aFrameAOG.value.value = originalConfigurationObject.getAFrame();

                configurationObject.setHookOffset(originalConfigurationObject.getHookOffset());
                var hookOffsetAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHOOKOFFSET);
                hookOffsetAOG.value.value = originalConfigurationObject.getHookOffset();

                configurationObject.setCentreOfRoller(originalConfigurationObject.getCentreOfRoller());
                var centreOfRollerAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMCENTREOFROLLER);
                centreOfRollerAOG.value.value = originalConfigurationObject.getCentreOfRoller();

                configurationObject.setPlatformHeight(originalConfigurationObject.getPlatformHeight());
                var platformHeightAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMPLATFORMHEIGHT);
                platformHeightAOG.value.value = originalConfigurationObject.getPlatformHeight();

                break;
            default:
                break;
        }

        setupGM(config.PREVIEW_TYPE.ADJUST_ORIGIN);
    }
    
    function setupForDxfIfCheckedOnActivate() {
        if (isCompositionComplete) {
            setupGM(config.PREVIEW_TYPE.STANDARD);
        }
        // Show/hide submit button
        
        if (this.radioGroupInput === null || this.radioGroupInput.checkedOption() === config.WIZARD_DRAWING_TYPE_STEP_TYPES.DXF) {
            // Show
            this.customContainerCss.value = showSubmitButton;
        } else {
            // Hide
            this.customContainerCss.value = hideSubmitButton;
        }
    }
    
    function getReviewAndFinalizeStep(excludeMenus) {
        if (excludeMenus === true) {
            return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.REVIEW_AND_FINALIZE, config.getTranslationText('1347'), config.WIZARD_LAYOUT_TYPES.DRAWING_ONLY)
                .addCustomContainerCssClass('display-review-and-finalize')
                .doneButtonIsActive()
                .hideNextButton()
                .activateWizardStepCallback(adjustMarginForDescription)
                .build();
        } else {
            var reviewAndFinaliseWizardStep = new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.REVIEW_AND_FINALIZE, config.getTranslationText('1347'), config.WIZARD_LAYOUT_TYPES.DRAWING_ONLY)
                .addCustomContainerCssClass('display-review-and-finalize')
                .addMenu(addDescriptionMenu())
                .preNextValidationCallback(preDoneStepValidation)
                .activateWizardStepCallback(adjustMarginForDescription)
                .hideNextButton()
                .doneButtonIsActive();
            
            return reviewAndFinaliseWizardStep.build();
        }

        function preDoneStepValidation() {
            
            var descriptionAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDESCRIPTION);
            if (descriptionAOG.value.value === '') {
                descriptionAOG.errorMessage.value = config.getTranslationText('1402');
                offerManager.displayErrorOnMenu(descriptionAOG.getInputName());
                return false;
            } else {
                // offerManager.showShellSpinner()(true);
                globals.showSpinner();
                return true;
            }
        }

        function adjustMarginForDescription() {
            
            setupGM(config.PREVIEW_TYPE.REVIEW_AND_FINALISE);

            var resultsTableWidth = $('.display-review-and-finalize .configuration-wizard-results-table-container').width();
            $('.display-review-and-finalize .configuration-wizard-input-container-div').css('margin-right', resultsTableWidth + 'px')
        }
    }

    function addDescriptionMenu() {
        var descriptionMenu = new Menu();
        descriptionMenu.createMenu(createDescriptionMenuStep());

        return descriptionMenu;
    }

    /**
     * 
     * @param {object[]} objectsForOptions
     * @param {function} onChangeCallback
     * @param {string} [radioGroupCustomCss]
     * @param {string} [radioGroupOptionCustomCss]
     */
    function createRadioGroup(objectsForOptions, onChangeCallback, radioGroupCustomCss, radioGroupOptionCustomCss) {
        if (typeof radioGroupCustomCss === 'string') {
            return new UIRadioGroup(new UIInputBuilder.RadioGroupBuilder('drawing-type', '', 'drawing-type', createRadioGroupOptions(objectsForOptions, radioGroupOptionCustomCss))
                .onChangeCallback(onChangeCallback)
                .addCustomCss(radioGroupCustomCss)
                .build());
        } else {
            return new UIRadioGroup(new UIInputBuilder.RadioGroupBuilder('drawing-type', '', 'drawing-type', createRadioGroupOptions(objectsForOptions, radioGroupOptionCustomCss))
                .onChangeCallback(onChangeCallback)
                .build());
        }
        
    }

    function updateReviewAndFinalizeDrawingViewFromRadioButtons(newValue) {
        // notify graphicsManager

        // Show/hide submit button
        if (newValue === config.WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES.SIDE) {
            // Show side view in canvas
            
        } else {
            // Show top view in canvas
            
        }
    }

    /**
     * 
     * @param {object[]} [objectsForOptions]
     * @param {string} [radioGroupOptionCustomCss]
     */
    function createRadioGroupOptions(objectsForOptions, radioGroupOptionCustomCss) {
        var options = [];
        if (Array.isArray(objectsForOptions) && objectsForOptions[0].getAccessorySubType && objectsForOptions[0].getAccessorySubType() !== '' && objectsForOptions[0].getAccessorySubType() !== undefined) {
            objectsForOptions.forEach(function (option) {
                if (typeof radioGroupOptionCustomCss === 'string') {
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder(option.getAccessorySubType() + '-id', option.getAccessorySubTypeTranslation(), option.getAccessorySubType())
                        .addCustomCss(radioGroupOptionCustomCss)
                        .build());
                } else {
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder(option.getAccessorySubType() + '-id', option.getAccessorySubTypeTranslation(), option.getAccessorySubType())
                        .build());
                }
            });
        } else {
            if (typeof radioGroupOptionCustomCss === 'string') {
                if (radioGroupOptionCustomCss.includes('review-and-finalize')) {
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder('side-option', config.getTranslationText('1350'), config.WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES.SIDE)
                        .addCustomCss(radioGroupOptionCustomCss)
                        .isChecked()
                        .build());
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder('top-option', config.getTranslationText('1351'), config.WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES.TOP)
                        .addCustomCss(radioGroupOptionCustomCss)
                        .build());
                } else {
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder('dxf-option', config.getTranslationText('1353'), config.WIZARD_DRAWING_TYPE_STEP_TYPES.DXF)
                        .addCustomCss(radioGroupOptionCustomCss)
                        .isChecked()
                        .build());
                    options.push(new UIInputBuilder.RadioGroupOptionBuilder('template-option', config.getTranslationText('1352'), config.WIZARD_DRAWING_TYPE_STEP_TYPES.TEMPLATE)
                        .addCustomCss(radioGroupOptionCustomCss)
                        .build());
                }
            } else {
                options.push(new UIInputBuilder.RadioGroupOptionBuilder('dxf-option', config.getTranslationText('1353'), config.WIZARD_DRAWING_TYPE_STEP_TYPES.DXF)
                    .isChecked()
                    .build());
                options.push(new UIInputBuilder.RadioGroupOptionBuilder('template-option', config.getTranslationText('1352'), config.WIZARD_DRAWING_TYPE_STEP_TYPES.TEMPLATE)
                    .build());
            }
            
        }
        
        return options;
    }

    function createDxfFileInput() {
        return new UIFileInputForm(new UIInputBuilder.FileInputFormBuilder('drawing-type', '', 'test-name')
            .addCustomInputContainerCss('center-vertical')
            .fileTypes(config.VALID_FILE_INPUT_EXTENSIONS.DXF)
            .onChangeCallback(submitDxfFileCallback)
            .build());
    }

    function submitDxfFileCallback() {
        var fileIsValid = true,
            errorObject = null;
        var form = document.getElementById(this.getId());
        var fileSelect = document.getElementById(this.getInputId());

        var files = fileSelect.files;
        var formData = new FormData();

        for (var i = 0; i < files.length; i++) {
            var file = files[i],
                extension = file.name.slice(-4).toLowerCase();
            // Check the file type.
            //if (!file.type.match('image.*')) {
            if (extension !== config.VALID_FILE_INPUT_EXTENSIONS.DXF) {                            
                fileIsValid = false;
                errorObject = {
                    messageCode: '1677'
                };
                continue;
            }

            // if (!globals.checkFileSizeDoesNotExceedMaximumMB(file, config.maxDxfFileSizeInMB)) {
            //     fileIsValid = false;
            //     errorObject = {
            //         messageCode: '1678'
            //     };
            //     continue;
            // }

            if (fileIsValid === true) {
                // Add the file to the request.
                formData.append('dxfFile', file, file.name);
                let userDetails = globals.user.getUserDetailsAsJSONOBject();
                userDetails.ImportType = accessoryType;
                formData.append('userDetails', JSON.stringify(userDetails));
            }
        }
        if (fileIsValid === true) {
            // offerManager.showShellSpinner()(true);
            globals.showSpinner();
            dataManager.postDXFFileData(formData)
                .then(function (data) {
                    // offerManager.showShellSpinner()(false);
                    globals.hideSpinner();
                    TScMessenger.writeDebugMessage();
                    if (data.result.returnCode > 0) {
                        var drawingArea = $("#itemConfigurationWizardDrawingArea").get();

                        var allocatedHeight = $("#itemConfigurationWizardDrawingArea").height();
                        var allocatedWidth = $("#itemConfigurationWizardDrawingArea").width();

                        userDrawingDetails.dxf.side = data.graphic;
                        configurationObject.setGraphicBlob(data.graphic.drawingObjects);
                        utils.translateGraphicToXmlDoc(data.graphic.drawingObjects).then(function (sideElevationXmlDoc) {
                            configurationObject.setDecompressedGraphicBlob(sideElevationXmlDoc);
                            finishDXFSubmitResponseHandling(data);
                        }).catch(function (){
                            finishDXFSubmitResponseHandling(data);
                        });
                        
                    } else {
                        var serverErrorMessageCode;
                        switch (data.result.returnCode) {
                            case -1:
                            case -2:
                                serverErrorMessageCode = '1571';
                                break;
                            case -3:
                                //-3    Invalid DXF file format
                                serverErrorMessageCode = '1680';
                                break;
                            case -4:
                                //-4    DXF file contains no entities
                                serverErrorMessageCode = '1681';
                                break;
                            case -5:
                                //-5    Uploaded file too large
                                serverErrorMessageCode = '1678';
                                break;
                            case -6:
                                //-6    Uploaded file exceeds MaxNumberOfDrawingObjectsForDXFImport
                                serverErrorMessageCode = '4679';
                                break;
                            default:
                                serverErrorMessageCode = '1571';
                                break;
                        }
                        var metaDataObject = {};
                        metaDataObject[config.INTERCOM_METADATA_ITEM.TYPE] = accessoryType;
                        metaDataObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE] = wizardType;
                        metaDataObject[config.INTERCOM_METADATA_ITEM.MESSAGE_CODE] = serverErrorMessageCode;
                        metaDataObject[config.INTERCOM_METADATA_ITEM.INTERCOM_MESSAGE] = '';
                        dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.FAILED_TO_IMPORT_DXF, metaDataObject));
                        let messageText = '';
                        if(serverErrorMessageCode === '1680') {
                            messageText = config.getMessageText(serverErrorMessageCode, globals.user.getCultureCode(), [data.result.message]);
                        }else if(serverErrorMessageCode === '4679') { 
                            messageText = config.getMessageText(serverErrorMessageCode, globals.user.getCultureCode(), [data.graphic.noOfDrawingObjects, data.graphic.noOfDrawingObjectsLimit]);
                        }else {
                            messageText = config.getMessageText(serverErrorMessageCode, globals.user.getCultureCode());
                        }
                        // app.showDialog(new CustomMessageBox(config.getMessageText(serverErrorMessageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(serverErrorMessageCode)));
                        globals.showDialog(new CustomMessageBox(messageText, config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(serverErrorMessageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    }
                }).
                fail(function (error) {
                    // offerManager.showShellSpinner()(false);
                    globals.hideSpinner();

                    if (error.errorMessage === 'Offline') {
                        // app.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')));
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    } else {
                        // dataManager.log("handleFileSelect, dataManager.postCSVFileData, fail, 1571", error, system.getModuleId(itemConfigurationWizardModal), config.LOG_MESSAGE_TYPE.ERROR);
                        // app.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')));
                        globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    }
                });
        } else {
            var metaDataObject = {};
            metaDataObject[config.INTERCOM_METADATA_ITEM.TYPE] = accessoryType;
            metaDataObject[config.INTERCOM_METADATA_ITEM.WIZARD_OPERATION_TYPE] = wizardType;
            metaDataObject[config.INTERCOM_METADATA_ITEM.MESSAGE_CODE] = errorObject.messageCode;
            if(errorObject.messageCode === '1678') {
                metaDataObject[config.INTERCOM_METADATA_ITEM.INTERCOM_MESSAGE] = `The file size exceeds the maximum allowed size of ${config.maxDxfFileSizeInMB}MB. The file size is ${globals.getFileSizeInMBAsString(file)}.`;
            } else {
                metaDataObject[config.INTERCOM_METADATA_ITEM.INTERCOM_MESSAGE] = '';
            }
            dataManager.sendInfoToIntercom(config.OPERATION.SendIntercomEventData, globals.getIntercomObject(config.INTERCOM_EVENT.FAILED_TO_IMPORT_DXF, metaDataObject));

            if (errorObject !== null && errorObject.variable !== undefined) {
                // app.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
            } else {
                // app.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)));
                globals.showDialog(new CustomMessageBox(config.getMessageText(errorObject.messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(errorObject.messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
            }
        }
    }

    function finishDXFSubmitResponseHandling(data) {
        offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).value.value = data.graphic.overallLength;
        offerManager.getActiveConfigurationWizardItemSize().setLength(data.graphic.overallLength);
        offerManager.getOriginalConfigurationWizardItemSize().setLength(data.graphic.overallLength);
        
        //self.configurationObject.setLength(data.Graphic.OverallLength);
        offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).value.value = data.graphic.overallHeight;
        offerManager.getActiveConfigurationWizardItemSize().setHeight(data.graphic.overallHeight);
        offerManager.getOriginalConfigurationWizardItemSize().setHeight(data.graphic.overallHeight);

        if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            offerManager.getActiveConfigurationWizardItem().setLength(offerManager.getActiveConfigurationWizardItemSize().getLength());
            offerManager.getActiveConfigurationWizardItem().setHeight(offerManager.getActiveConfigurationWizardItemSize().getHeight() - offerManager.getActiveConfigurationWizardItem().getSubframeHeight());
            offerManager.getOriginalConfigurationWizardItem().setLength(offerManager.getOriginalConfigurationWizardItemSize().getLength());
            offerManager.getOriginalConfigurationWizardItem().setHeight(offerManager.getOriginalConfigurationWizardItemSize().getHeight() - offerManager.getActiveConfigurationWizardItem().getSubframeHeight());
            offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMLENGTH).value.value = offerManager.getActiveConfigurationWizardItem().getLength();
            offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMHEIGHT).value.value = offerManager.getActiveConfigurationWizardItem().getHeight();
        }
        
        setupGM(config.PREVIEW_TYPE.STANDARD);
        

        if (data.result.returnCode === 2) {
            var unsupportedObjectString = '';
            if (data.graphic.unsupportedObjects.length > 0) {
                var listItems = '';
                data.graphic.unsupportedObjects.forEach(function (unsupportedObject) {
                    listItems += '<li>' + unsupportedObject + '</li>'
                });
                unsupportedObjectString += '<ul>' + listItems + '</ul>'
            }
            // app.showDialog(new CustomMessageBox(config.getMessageText('1679', globals.user.getCultureCode(), unsupportedObjectString), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1679')));
            globals.showDialog(new CustomMessageBox(config.getMessageText('1679', globals.user.getCultureCode(), unsupportedObjectString), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1679')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
        }
        configurationWizard.value.isNextButtonActive.value = true;
        // update the next button on the step
        configurationWizard.value.activeWizardStep.value.setIsNextButtonActiveWhenStepIsActive(true);
    }

    function getAdjustScaleForSideViewStep(excludeMenus) {
        if (excludeMenus === true) {
            return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.ADJUST_SCALE_SIDE_VIEW, config.getTranslationText('1343'), config.WIZARD_LAYOUT_TYPES.DRAWING_AND_INPUT_PANES)
                .addCustomContainerCssClass('display-drawing-and-input')
                .nextButtonIsActive()
                .build();
        } else {
            return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.ADJUST_SCALE_SIDE_VIEW, config.getTranslationText('1343'), config.WIZARD_LAYOUT_TYPES.DRAWING_AND_INPUT_PANES)
                .addCustomContainerCssClass('display-drawing-and-input')
                .nextButtonIsActive()
                .addMenu(addAdjustScaleMenu())
                .activateWizardStepCallback(setupGMForScaleStep)
                .preNextValidationCallback(scaleStepValidation)
                .build();
        }
        
    }

    
    function setupGMForScaleStep() {
        setupGM(config.PREVIEW_TYPE.ADJUST_SCALE);
        this.menuToIncludeOnStep.updateJsElements();
    }

    function scaleStepValidation() {
        var widthAOG = accessoryType === config.ACCESSORY_TYPES.BODY ? offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMWIDTH) : offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMDRAWINGWIDTH);
        if (widthAOG.value.value === 0) {
            if (accessoryType === config.ACCESSORY_TYPES.BODY) {
                $(".accordion").accordion("option", "active", 2);
                widthAOG.errorMessage.value = config.getTranslationText('1401');
                setTimeout(function () {
                    offerManager.displayErrorOnMenu(widthAOG.getInputName());
                }, 500);
            } else {
                widthAOG.errorMessage.value = config.getTranslationText('1401');
                offerManager.displayErrorOnMenu(widthAOG.getInputName());
            }
            return false;
        } else {
            return true;
        }
    }

    function addAdjustScaleMenu() {
        var adjustScaleMenu = new Menu();
        adjustScaleMenu.createMenu(createAdjustScaleMenuStep());

        return adjustScaleMenu;
    }
    
    function getAdjustCogForSideViewStep(excludeMenus) {
        if (excludeMenus === true) {
            return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.ADJUST_COG_SIDE_VIEW, config.getTranslationText('1345'), config.WIZARD_LAYOUT_TYPES.DRAWING_AND_INPUT_PANES)
                .addCustomContainerCssClass('display-drawing-and-input')
                .nextButtonIsActive()
                .build();
        } else {
            return new WizardBuilderHelper.ConfigurationWizardStepBuilder(config.WIZARD_STEP_TYPES.ADJUST_COG_SIDE_VIEW, config.getTranslationText('1345'), config.WIZARD_LAYOUT_TYPES.DRAWING_AND_INPUT_PANES)
                .addCustomContainerCssClass('display-drawing-and-input')
                .nextButtonIsActive()
                .addMenu(addAdjustCogMenu())
                .activateWizardStepCallback(setupGMForCOGStep)
                .preNextValidationCallback(cogStepValidation)
                .build();
        }
    }

    function setupGMForCOGStep() {
        if (accessoryType !== config.ACCESSORY_TYPES.BODY) {
            if (configurationObject.setLength) {
                configurationObject.setLength(offerManager.getActiveConfigurationWizardItemSize().getLength());
            }
            if (configurationObject.setHeight) {
                configurationObject.setHeight(offerManager.getActiveConfigurationWizardItemSize().getHeight());
            }
            if (configurationObject.setWidth) {
                configurationObject.setWidth(offerManager.getActiveConfigurationWizardItemSize().getWidth());
            }
        } else {
            if (configurationObject.getType() === config.BODY_TYPES.TIMBER || configurationObject.getType() === config.BODY_TYPES.ROLLBACK || configurationObject.getType() === config.BODY_TYPES.FLATDECK) {
                if (configurationObject.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    configurationObject.setHeadboardHeight(configurationObject.getHeight() - configurationObject.getSubstructureHeight());
                } else {
                    configurationObject.setHeadboardHeight(configurationObject.getHeight());
                }
            }
        }
        switch (configurationObject.getAccessoryType()) {
            case config.ACCESSORY_TYPES.CRANE:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.OTHER:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.HITCH:
                configurationObject.setFunnelLength(offerManager.getActiveConfigurationWizardItemSize().getLength() - (configurationObject.getMountingLength() + configurationObject.getCouplingOffset()));
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                configurationObject.setPlatformProtrusion(offerManager.getActiveConfigurationWizardItemSize().getLength() - (configurationObject.getMechanismLength()));
                configurationObject.setPlatformHeight(offerManager.getActiveConfigurationWizardItemSize().getHeight() - (configurationObject.getMechanismHeight()));
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                // do nothing
                break;
            default:
                break;
        }
        setupGM(config.PREVIEW_TYPE.ADJUST_COG);
        this.menuToIncludeOnStep.updateJsElements();
    }

    function cogStepValidation() {
        if (accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
            var payloadAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMPAYLOAD);
            if (payloadAOG.value.value === 0) {
                payloadAOG.errorMessage.value = config.getTranslationText('1400');
                offerManager.displayErrorOnMenu(payloadAOG.getInputName());
                return false;
            } else {
                return true;
            }
        } else {
            var massAOG = offerManager.getAttributeGroup(modalPathForAttrObvGroup + '.' + config.VALUE_TYPE.ITEMMASS);
            if (massAOG.value.value === 0) {
                massAOG.errorMessage.value = config.getTranslationText('1400');
                if (accessoryType === config.ACCESSORY_TYPES.BODY) {
                    $(".accordion").accordion("option", "active", 0);
                    setTimeout(function () {
                        offerManager.displayErrorOnMenu(massAOG.getInputName());
                    }, 500); 
                } else {
                    offerManager.displayErrorOnMenu(massAOG.getInputName());
                }
                return false;
            } else {
                return true;
            }
        }
        
    }

    function addAdjustCogMenu() {
        var adjustCogMenu = new Menu();
        adjustCogMenu.createMenu(createAdjustCogMenuStep());

        return adjustCogMenu;
    }

    function getBodyLengthMenuTabItems(bodyType) { 
        var items = [];

        var isOldHookliftBody = bodyType === config.BODY_TYPES.HOOKLIFT && configurationObject.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMLENGTHTYPE)
            .label(config.getTranslationText('1493'))
            .array(offerManager.getBodyLengthTypeOptions())
            .update()
            .width(selectMenuWidth)
            .build());

        lengthTooltipTextComputed = computed(function () {
            try {
                // offerManager.refreshData()();
                return globals.getBodyLengthTooltipText(configurationObject.getLengthType(), globals.user.getCultureCode());
            } catch (e) {
                TScMessenger.writeDebugMessage('In lengthTooltipTextComputed error');
                return 0;
            }
        });

        //menuItems.push(getLengthMenuItem());
        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMLENGTH)
            .label(isOldHookliftBody ? config.getTranslationText('1544') : config.getTranslationText('217'))
            .tooltip(lengthTooltipTextComputed, true)
            .customCssClasses(customCssClassForTextInput)
            .addFocusCallback()
            .build());

        if (isOldHookliftBody) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMSUBFRAMELENGTH)
                .label(config.getTranslationText('2915'))
                .tooltip(config.getTranslationText('1792'), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        

        if (bodyType !== config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMHEADBOARDTHICKNESS)
                .label(isOldHookliftBody ? config.getTranslationText('2910') : config.getTranslationText('2774'))
                .tooltip(config.getTooltipText(isOldHookliftBody ? '2911' : '2912', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());

            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMTAILBOARDTHICKNESS)
                .label(config.getTranslationText('468'))
                .tooltip(config.getTooltipText('1786', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        if (bodyType === config.BODY_TYPES.BEAVERTAIL) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMRAMPLENGTH)
                .label(config.getTranslationText('580'))
                .tooltip(config.getTooltipText('1789', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());

            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMRAMPPLATESPROTRUSION)
                .label(config.getTranslationText('582'))
                .tooltip(config.getTooltipText('1791', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        return items;
    }

    function getBodyHeightMenuTabItems(bodyType) {
        var items = [];

        var isOldHookliftBody = bodyType === config.BODY_TYPES.HOOKLIFT && configurationObject.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMSUBFRAMEHEIGHT)
            .label(isOldHookliftBody ? config.getTranslationText('2916') : config.getTranslationText('2135'))
            .tooltip(config.getTooltipText(isOldHookliftBody ? '2917' : '2932', globals.user.getCultureCode()), true)
            .customCssClasses(customCssClassForTextInput)
            .addFocusCallback()
            .build());

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMHEIGHTTYPE)
            .label(config.getTranslationText('1494'))
            .array(offerManager.getBodyLengthTypeOptions())
            .update()
            .width(selectMenuWidth)
            .build());

        heightTooltipTextComputed = computed(function () {
            try {
                // offerManager.refreshData()();
                return globals.getBodyHeightTooltipText(configurationObject.getHeightType(), globals.user.getCultureCode());
            } catch (e) {
                TScMessenger.writeDebugMessage('Error in heightTooltipTextComputed');
                return 0;
            }
        });

        var bodyHeightLabel = config.getTranslationText('180');
        if (isOldHookliftBody) {
            bodyHeightLabel = config.getTranslationText('1545');
        } else if (configurationObject.getType() === config.BODY_TYPES.TANKER) {
            bodyHeightLabel = config.getTranslationText('2904');
        }

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMHEIGHT)
            .label(bodyHeightLabel)
            .customCssClasses(customCssClassForTextInput)
            .addFocusCallback()
            .tooltip(heightTooltipTextComputed, true)
            .build());

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMSUBSTRUCTUREHEIGHT)
            .label(isOldHookliftBody ? config.getTranslationText('2919') : config.getTranslationText('461'))
            .tooltip(config.getTooltipText(isOldHookliftBody ? '2920' : '1794', globals.user.getCultureCode()), true)
            .customCssClasses(customCssClassForTextInput)
            .addFocusCallback()
            .build());

        if (bodyType === config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMBOTTOMOFBODYTOTOPOFCHASSIS)
                .label(config.getTranslationText('1213'))
                .tooltip(config.getTooltipText('1834', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        if (bodyType !== config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMROOFTHICKNESS)
                .label(config.getTranslationText('399'))
                .tooltip(config.getTooltipText('1797', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMBODYDROPSIDEHEIGHT)
                .label(config.getTranslationText('1543'))
                .tooltip(heightTooltipTextComputed, true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());

        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMHEADBOARDHEIGHT)
            .label(isOldHookliftBody ? config.getTranslationText('2922') : config.getTranslationText('179'))
            .tooltip(config.getTooltipText(isOldHookliftBody ? '2923' : '1798', globals.user.getCultureCode()), true)
            .customCssClasses(customCssClassForTextInput)
            .addFocusCallback()
            .build());
        
        if (bodyType === config.BODY_TYPES.FLATDECK) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMTAILBOARDHEIGHT)
                .label(config.getTranslationText('469'))
                .tooltip(config.getTooltipText('1799', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .addFocusCallback()
                .build());
        }

        return items;
    }

    function getBodyWidthMenuTabItems(bodyType) {
        var items = [];

        if (bodyType !== config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMWIDTHTYPE)
                .label(config.getTranslationText('1495'))
                .array(offerManager.getBodyLengthTypeOptions())
                .update()
                .width(selectMenuWidth)
                .build());
        }

        widthTooltipTextComputed = computed(function () {
            try {
                // offerManager.refreshData()();
                return globals.getBodyWidthTooltipText(configurationObject.getWidthType(), globals.user.getCultureCode());
            } catch (e) {
                TScMessenger.writeDebugMessage('In widthTooltipTextComputed error');
                return 0;
            }
        });

        var bodyWidthLabel = config.getTranslationText('573');
        if (configurationObject.getType() === config.BODY_TYPES.TANKER) {
            bodyWidthLabel = config.getTranslationText('2905');
        }
        items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMWIDTH)
            .label(bodyWidthLabel)
            .tooltip(widthTooltipTextComputed, true)
            .customCssClasses(customCssClassForTextInput)
            .build());

        if (bodyType !== config.BODY_TYPES.FLATDECK && bodyType !== config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMSIDEWALLLEFT)
                .label(config.getTranslationText('427'))
                .tooltip(config.getTooltipText('1802', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .build());

            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMSIDEWALLRIGHT)
                .label(config.getTranslationText('428'))
                .tooltip(config.getTooltipText('1803', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .build());
        }
        if (bodyType === config.BODY_TYPES.SERVICE) {
            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMLEFTCOMPARTMENTWIDTH)
                .label(config.getTranslationText('1214'))
                .tooltip(config.getTooltipText('1835', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .build());

            items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMRIGHTCOMPARTMENTWIDTH)
                .label(config.getTranslationText('1215'))
                .tooltip(config.getTooltipText('1836', globals.user.getCultureCode()), true)
                .customCssClasses(customCssClassForTextInput)
                .build());
        }

        return items;
    }

    function getWidthMenuItem() {
        return new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMDRAWINGWIDTH)
            .label(config.getTranslationText('573'))
            .customCssClasses(customCssClassForTextInput)
            .tooltip(config.getTranslationText('4060'), true)
            .build();
    }

    function createAdjustScaleMenuStep() {
        var menuItems = [];

        if (accessoryType !== config.ACCESSORY_TYPES.BODY) {
            menuItems.push(getLengthMenuItem());
            menuItems.push(getHeightMenuItem());
            menuItems.push(getWidthMenuItem());
        }

        switch (accessoryType) {
            case config.ACCESSORY_TYPES.BODY:
                var tempBodyType = configurationObject.getType(),
                    tabs = [];
                
                // Length MenuTab
                tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newTab(config.getTranslationText('217'))
                    .items(getBodyLengthMenuTabItems(tempBodyType))
                    .build());
                
                // Height MenuTab
                tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newTab(config.getTranslationText('180'))
                    .items(getBodyHeightMenuTabItems(tempBodyType))
                    .build());
                
                //Width MenuTab
                tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newTab(config.getTranslationText('573'))
                    .items(getBodyWidthMenuTabItems(tempBodyType))
                    .build());

                menuItems.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
                    .asAccordion(null, config.ACCORDION_HEIGHT_STYLE_OPTIONS.FILL)
                    .tabs(tabs)
                    .build());
                
                break;
            case config.ACCESSORY_TYPES.CRANE:
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMFRONTOVERHANG)
                            .label(config.getTranslationText('1407'))
                            .tooltip(config.getTranslationText('4069'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());
                break;
            case config.ACCESSORY_TYPES.OTHER:
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                break;
            case config.ACCESSORY_TYPES.HITCH:
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMOUNTINGLENGTH)
                            .label(config.getTranslationText('912'))
                            .tooltip(config.getTranslationText('4067'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMCOUPLINGOFFSET)
                            .label(config.getTranslationText('913'))
                            .tooltip(config.getTranslationText('4068'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMCOUPLINGHEIGHT)
                            .label(config.getTranslationText('915'))
                            .tooltip(config.getTranslationText('4070'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMECHANISMLENGTH)
                            .label(config.getTranslationText('1045'))
                            .tooltip(config.getTranslationText('4065'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMECHANISMHEIGHT)
                            .label(config.getTranslationText('1048'))
                            .tooltip(config.getTranslationText('4066'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMPROTRUSION)
                            .label(config.getTranslationText('2602'))
                            .tooltip(config.getTranslationText('4064'), true)
                            .customCssClasses(customCssClassForTextInput)
                            .build());
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMAFRAME)
                    .label(config.getTranslationText('4392'))
                    .tooltip(config.getTranslationText('4396'), true)
                    .customCssClasses(customCssClassForTextInput)
                    .addFocusCallback()
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMHOOKOFFSET)
                    .label(config.getTranslationText('4393'))
                    .tooltip(config.getTranslationText('4397'), true)
                    .customCssClasses(customCssClassForTextInput)
                    .addFocusCallback()
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMCENTREOFROLLER)
                    .label(config.getTranslationText('4394'))
                    .tooltip(config.getTranslationText('4398'), true)
                    .customCssClasses(customCssClassForTextInput)
                    .addFocusCallback()
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMPLATFORMHEIGHT)
                    .label(config.getTranslationText('4395'))
                    .tooltip(config.getTranslationText('4399'), true)
                    .customCssClasses(customCssClassForTextInput)
                    .addFocusCallback()
                    .build());
                break;
            default:
                break;
        }
        return new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuStep(config.getTranslationText('4906'), 0, configurationObject.getDescription())
                    .items(menuItems)
                    .build();

        function getLengthMenuItem() {
            return new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMDRAWINGLENGTH)
                        .label(config.getTranslationText('217'))
                        .customCssClasses(customCssClassForTextInput)
                        .tooltip(config.getTranslationText('4058'), true)
                        .build();
                        
        }
        function getHeightMenuItem() {
            return new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMDRAWINGHEIGHT)
                    .label(config.getTranslationText('180'))
                    .customCssClasses(customCssClassForTextInput)
                    .tooltip(config.getTranslationText('4059'), true)
                    .build();
        }
    }

    function createAdjustCogMenuStep() { 
        var menuItems = [];

        function getBodyWeightMenuTabItems(bodyType) {
            var items = [];
            var horizontalCogTooltipText;

            var isOldHookliftBody = bodyType === config.BODY_TYPES.HOOKLIFT && configurationObject.getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY;

            switch (accessoryType) {
                case config.ACCESSORY_TYPES.FRIDGE:
                    horizontalCogTooltipText = config.getTooltipText('1695', globals.user.getCultureCode());
                    break;
                default:
                    horizontalCogTooltipText = config.getTooltipText('4061', globals.user.getCultureCode());
                    break;
            }
            if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                var bodyMassTypeSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMMASSTYPE)
                    .label(config.getTranslationText('754'))
                    .array(offerManager.getMassTypeOptions())
                    .update()
                    .width(65)
                    .build();

                var bodyMassUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMASS)
                    .label(isOldHookliftBody ? config.getTranslationText('609') : config.getTranslationText('588'))
                    .customCssClasses(customCssClassForTextInput)
                    .hideIncrementAndDecrementButtons()
                    .build();

                items.push(new MenuItemBuilder(modalPathForAttrObvGroup).newInputGroupUpDownBoxAndSelect(bodyMassUpDownBox, bodyMassTypeSelect)
                    .label(isOldHookliftBody ? config.getTranslationText('609') : config.getTranslationText('588'))
                    .build());
            }

            var itemHorizontalCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMHORIZONTALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemHorizontalCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMHORIZONTALCOG)
                .label(config.getTranslationText('189'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(horizontalCogTooltipText, true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemHorizontalCOGUpDownBox, itemHorizontalCOGSelect)
                .label(config.getTranslationText('189'))
                .build());

            var itemVerticalCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMVERTICALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemVerticalCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMVERTICALCOG)
                .label(config.getTranslationText('552'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(config.getTranslationText('4062'), true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemVerticalCOGUpDownBox, itemVerticalCOGSelect)
                .label(config.getTranslationText('552'))
                .build());

            var itemLateralCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMLATERALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemLateralCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMLATERALCOG)
                .label(config.getTranslationText('1175'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(config.getTranslationText('4063'), true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemLateralCOGUpDownBox, itemLateralCOGSelect)
                .label(config.getTranslationText('1175'))
                .build());

            return items;
        }

        function getItemMassMenuPart() {
            return new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMASS)
                .label(config.getTranslationText('2884'))
                .customCssClasses(customCssClassForTextInput)
                .build();
        }

        function getBodyLoadMenuTabItems(bodyType) {
            var items = [];
            
            var itemBodyPayloadHorizontalCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemBodyPayloadHorizontalCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOG)
                .label(config.getTranslationText('1496'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(config.getTranslationText('1725'), true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemBodyPayloadHorizontalCOGUpDownBox, itemBodyPayloadHorizontalCOGSelect)
                .label(config.getTranslationText('1496'))
                .build());

            var itemBodyPayloadVerticalCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemBodyPayloadVerticalCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOG)
                .label(config.getTranslationText('1497'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(config.getTranslationText('1726'), true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemBodyPayloadVerticalCOGUpDownBox, itemBodyPayloadVerticalCOGSelect)
                .label(config.getTranslationText('1497'))
                .build());

            var itemBodyPayloadLateralCOGSelect = new MenuItemBuilder(modalPathForAttrObvGroup).newSelect(config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOGTYPE)
                .array(offerManager.getCOGTypeOptions())
                .update()
                .width(65)
                .build();
            var itemBodyPayloadLateralCOGUpDownBox = new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMBODYPAYLOADLATERALCOG)
                .label(config.getTranslationText('1498'))
                .customCssClasses(customCssClassForTextInput)
                .tooltip(config.getTranslationText('1828'), true)
                .hideIncrementAndDecrementButtons()
                .build();
            items.push(new MenuItemBuilder(path).newInputGroupUpDownBoxAndSelect(itemBodyPayloadLateralCOGUpDownBox, itemBodyPayloadLateralCOGSelect)
                .label(config.getTranslationText('1498'))
                .build());

            return items;
        }


        if (accessoryType === config.ACCESSORY_TYPES.BODY) {
            var tempBodyType = configurationObject.getType(),
                tabs = [];

            // Body Weight MenuTab
            tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                .newTab(config.getTranslationText('2672'))
                .items(getBodyWeightMenuTabItems(tempBodyType))
                .build());

            // Load MenuTab
            tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                .newTab(config.getTranslationText('813'))
                .items(getBodyLoadMenuTabItems(tempBodyType))
                .build());

            menuItems.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
                .asAccordion(null, config.ACCORDION_HEIGHT_STYLE_OPTIONS.FILL)
                .tabs(tabs)
                .build());
        } else {
            if (accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newText(config.VALUE_TYPE.ITEMUNITOFMEASURE)
                    .label(config.getTranslationText('2879'))
                    .customCssClasses('custom-css-text')
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newTel(config.VALUE_TYPE.ITEMPAYLOAD)
                    .label(config.getTranslationText('2883'))
                    .customCssClasses(customCssClassForTextInput)
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newTel(config.VALUE_TYPE.ITEMDENSITY)
                    .label(config.getTranslationText('2880'))
                    .customCssClasses(customCssClassForTextInput)
                    .build());

                menuItems.push(new MenuItemBuilder(modalPathForAttrObvGroup).newStandardUpDown(config.VALUE_TYPE.ITEMMASS)
                    .label(config.getTranslationText('2884'))
                    .customCssClasses(customCssClassForTextInput)
                    .build());

            } else {
                menuItems.push(getItemMassMenuPart());
            }
            getBodyWeightMenuTabItems().forEach(function (weightItem) {
                menuItems.push(weightItem);
            });
        }

        return new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuStep(config.getTranslationText('4907'), 0, configurationObject.getDescription())
                    .items(menuItems)
                    .build();
    }

    function createDescriptionMenuStep() {
        return new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuStep(config.getTranslationText('4908'), 0, configurationObject.getDescription())
                    .items([
                        new MenuItemBuilder(modalPathForAttrObvGroup).newText(config.VALUE_TYPE.ITEMDESCRIPTION)
                            .label(config.getTranslationText('110'))
                            .maxLength(50)
                            .build()
                    ])
                    .build();
    }

    //#region Add New functions

    //#region Add New Body functions
    /**
    * Create steps required for adding a new body
    * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
    */
    function createAddNewBodyWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new compactor body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyCompactorWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new dropside body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyDropsideWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new flatdeck body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyFlatdeckWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new tautliner body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyTautlinerWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new tipper body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyTipperWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new van body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyVanWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new rollback body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyRollbackWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new timber body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyTimberWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new livestock body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyLivestockWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new beavertail body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyBeavertailWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new hooklift body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyHookliftWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new other body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyOtherWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new service body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewBodyServiceWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    //#endregion Add New Body functions

    //#region Add New Trailer functions
    /**
        * Create steps required for adding a new trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTrailerWizardSteps(excludeMenus) {
        switch (accessorySubType) {
            case config.TRAILER_TYPES.SEMI:
                return createAddNewTrailerSemiWizardSteps(excludeMenus);
            case config.TRAILER_TYPES.INTERLINK:
                return createAddNewTrailerInterlinkWizardSteps(excludeMenus);
            case config.TRAILER_TYPES.PUP:
                return createAddNewTrailerPupWizardSteps(excludeMenus);
            case config.TRAILER_TYPES.DRAWBAR:
                return createAddNewTrailerDrawbarWizardSteps(excludeMenus);
            default:
                throw 'Unknown accessorySubType';
        }
    }
    /**
        * Create steps required for adding a new semi trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTrailerSemiWizardSteps(excludeMenus) {
        return temporaryWizardStepsForTesting(excludeMenus);
    }
    /**
        * Create steps required for adding a new interlink trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTrailerInterlinkWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new pup trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTrailerPupWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new drawbar trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTrailerDrawbarWizardSteps(excludeMenus) {
        return temporaryWizardStepsForTesting(excludeMenus);
    }
    //#endregion Add New Trailer functions

    //#region Add New Accessory functions
    /**
        * Create steps required for adding a new crane
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewCraneWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new fifth wheel
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewFifthWheelWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new hitch
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewHitchWizardSteps(excludeMenus) {
        return temporaryWizardStepsForTesting(excludeMenus);
    }
    /**
        * Create steps required for adding a new taillift
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewTailliftWizardSteps(excludeMenus) {
        return temporaryWizardStepsForTesting(excludeMenus);
    }
    /**
        * Create steps required for adding a new fridge
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewFridgeWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new other
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewOtherWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
    * Create steps required for adding a new hooklift
    * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
    */
    function createAddNewHookliftWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for adding a new payload
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createAddNewPayloadWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    //#endregion Add New Accessory functions

    //#endregion Add New functions

    //#region Edit Existing functions

    //#region Edit Existing Body functions
    /**
        * Create steps required for editing an existing body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing compactor body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyCompactorWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing dropside body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyDropsideWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing flatdeck body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyFlatdeckWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing tautliner body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyTautlinerWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing tipper body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyTipperWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing van body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyVanWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing rollback body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyRollbackWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing timber body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyTimberWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing livestock body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyLivestockWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing beavertail body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyBeavertailWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing hooklift body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyHookliftWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing other body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyOtherWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing service body
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingBodyServiceWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    //#endregion Edit Existing Body functions

    //#region Edit Existing Trailer functions
    /**
        * Create steps required for editing an existing trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTrailerWizardSteps() {
        switch (accessorySubType) {
            case config.TRAILER_TYPES.SEMI:
                return createEditExistingTrailerSemiWizardSteps();
            case config.TRAILER_TYPES.INTERLINK:
                return createEditExistingTrailerInterlinkWizardSteps();
            case config.TRAILER_TYPES.PUP:
                return createEditExistingTrailerPupWizardSteps();
            case config.TRAILER_TYPES.DRAWBAR:
                return createEditExistingTrailerDrawbarWizardSteps();
            default:
                throw 'Unknown accessorySubType';
        }
    }
    /**
        * Create steps required for editing an existing semi trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTrailerSemiWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing interlink trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTrailerInterlinkWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing pup trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTrailerPupWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing drawbar trailer
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTrailerDrawbarWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    //#endregion Edit Existing Trailer functions

    //#region Edit Existing Accessory functions
    /**
        * Create steps required for editing an existing crane
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingCraneWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing fifth wheel
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingFifthWheelWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing hitch
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingHitchWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing taillift
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingTailliftWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing fridge
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingFridgeWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing other
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingOtherWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
    * Create steps required for editing an existing hooklift
    * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
    */
    function createEditExistingHookliftWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    /**
        * Create steps required for editing an existing payload
        * @returns {object[]} - Array of objects created using WizardBuilderHelper.ConfigurationWizardStepBuilder
        */
    function createEditExistingPayloadWizardSteps() {
        return temporaryWizardStepsForTesting();
    }
    //#endregion Edit Existing Accessory functions

    //#endregion Edit Existing functions

    function temporaryWizardStepsForTesting(excludeMenus) {
        var returnArray = [];

        if (includeSelectEquipmentStep === true) {
            returnArray.push(getSelectEquipmentTypeStep(templatesArray));
        }
        returnArray.push(getSelectSideViewDrawingTypeStep());                    
        returnArray.push(getSpecifySideViewOriginStep());
        returnArray.push(getAdjustScaleForSideViewStep(excludeMenus));
        returnArray.push(getAdjustCogForSideViewStep(excludeMenus));
        // Add Review & Finalize
        returnArray.push(getReviewAndFinalizeStep(excludeMenus));

        return returnArray;
    }
}
function createConfigurationWizardCallback() {
    configurationWizard.value = createConfigurationWizard(wizardTypeToUse, includeSelectionWizardStep);
}
// #endregion Configuration Wizard

// #region Attribute Group Functions
function createAttributeObservableGroupForModal(path, objectToUse) {
    
    var length = graphicsManager.getLengthFromDrawing(objectToUse); //length = objectToUse.getLength !== undefined ? objectToUse.getLength() : graphicsManager.getLengthFromDrawing(objectToUse);
    var height = graphicsManager.getHeightFromDrawing(objectToUse);//objectToUse.getHeight !== undefined ? objectToUse.getHeight() : 0;
    var width = objectToUse.getWidth !== undefined ? objectToUse.getWidth() : 0;//#unfinished, this should also be the height of the drawing when in top view

    offerManager.createItemConfigWizardDrawingAOGs(length, height, width, path);
    offerManager.createItemObjectObservablesForItemConfigWizard(objectToUse, path);
    
}

// #endregion Attribute Group Functions

function getAccessoryFromCloudServices(accessoryStub, pathRoot, pmse, onSuccessCallback) {
    // offerManager.showShellSpinner()(true);
    globals.showSpinner();
    offerManager.getAccessory(accessoryStub, pathRoot)
        .then(function (response) {
            // offerManager.showShellSpinner()(false);
            globals.hideSpinner();
            if (response.Response.Result.ReturnCode === 1) {
                var fullAccessory = response.Accessory;
                try {

                    var accessoryPath;
                    switch (fullAccessory.getAccessoryType()) {
                        case config.ACCESSORY_TYPES.CRANE:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE;
                            break;
                        case config.ACCESSORY_TYPES.OTHER:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER;
                            break;
                        case config.ACCESSORY_TYPES.FRIDGE:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE;
                            break;
                        case config.ACCESSORY_TYPES.TAILLIFT:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT;
                            break;
                        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL;
                            break
                        case config.ACCESSORY_TYPES.HITCH:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH;
                            break;
                        case config.ACCESSORY_TYPES.HOOKLIFT:
                            accessoryPath = pathRoot + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT;
                            break;
                        default:
                            accessoryPath = 'ERROR_IN_PATH_WHEN_ADDING_ACCESSORY_CONFIGURATION_WIZARD_MODAL_JS';
                    }
                    createAttributeObservableGroupForModal(modalPathForAttrObvGroup, fullAccessory);
                    configurationObject = fullAccessory;
                    
                    onSuccessCallback();

                    if (pmse !== undefined) {
                        pmse.resolve();
                    }
                } catch (error) {
                    
                    dataManager.log("addAccessoryToCurrentChassis, offerManager.getAccessory, fail, 1571", error, 'itemConfigurationWizardModal', config.LOG_MESSAGE_TYPE.ERROR);
                    globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));

                    if (pmse !== undefined) {
                        pmse.reject();
                    }
                }
            } else {
                globals.hideSpinner();
                dataManager.log("addAccessoryToCurrentChassis, offerManager.getAccessory, ReturnCode=" + response.Response.Result.ReturnCode + ", Message=" + response.Response.Result.Message, null, 'itemConfigurationWizardModal', config.LOG_MESSAGE_TYPE.ERROR);
                if (pmse !== undefined) {
                    pmse.reject();
                }
            }

        })
        .fail(function (error) {
           
            globals.hideSpinner();
            if (error.errorMessage === 'Offline') {
                globals.showDialog(new CustomMessageBox(config.getMessageText('1573', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1573')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
            } else {
                dataManager.log("addAccessoryToCurrentChassis, offerManager.getAccessory, fail, 1571", error, 'itemConfigurationWizardModal', config.LOG_MESSAGE_TYPE.ERROR);
                globals.showDialog(new CustomMessageBox(config.getMessageText('1571', globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference('1571')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
            }
        });
}

function ok() {
    var self = this;
    // offerManager.showShellSpinner()(true);
    globals.showSpinner();
    if (accessoryType === config.ACCESSORY_TYPES.BODY) {
        $.when(dataManager.getBodyStubs()).done(function (data) {
            var duplicatePersonalLibraryDescriptions = data.bodies.Bodies.filter(function (body) {
                return body.Id !== configurationObject.getSourceDatabaseId() &&
                    body.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM &&
                    body.Description === configurationObject.getDescription() &&
                    body.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL;
            });
            var alreadyExists = data.bodies.Bodies.filter(function (body) {
                return body.Id === configurationObject.getSourceDatabaseId() && body.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM;
            });
            doDescriptionChecks(duplicatePersonalLibraryDescriptions, alreadyExists, doOK);
        });
    } else if (accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
        $.when(dataManager.getPayloadStubs()).done(function (data) {
            var duplicatePersonalLibraryDescriptions = data.payloads.Payloads.filter(function (payload) {
                return payload.Id !== configurationObject.getSourceDatabaseId() &&
                    payload.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM &&
                    payload.Description === configurationObject.getDescription() &&
                    payload.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL;
            });
            var alreadyExists = data.payloads.Payloads.filter(function (payload) {
                return payload.Id === configurationObject.getSourceDatabaseId() && payload.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM;
            });
            
            doDescriptionChecks(duplicatePersonalLibraryDescriptions, alreadyExists, doOK);
        });
    } else {
        $.when(dataManager.getAccessoryStubs()).done(function (data) {
            var duplicatePersonalLibraryDescriptions = data.accessories.Accessories.filter(function (accessory) {
                return accessory.Id !== configurationObject.getSourceDatabaseId() && 
                    accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && 
                    accessory.AccessoryType === configurationObject.getAccessoryType() && 
                    accessory.Description === configurationObject.getDescription() &&
                    accessory.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL;
            });

            var alreadyExists = data.accessories.Accessories.filter(function (accessory) {
                return accessory.Id === configurationObject.getSourceDatabaseId() && accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && accessory.AccessoryType === configurationObject.getAccessoryType();
            });
            doDescriptionChecks(duplicatePersonalLibraryDescriptions, alreadyExists, doOK);
        });
    }
    function doDescriptionChecks(duplicatePersonalLibraryDescriptions, alreadyExists, continueSaveCallback) {
        if (duplicatePersonalLibraryDescriptions.length > 0) {
            globals.hideSpinner();
            offerManager.configurationActionResponseHandlerCallback()(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.NAME_ALREADY_EXISTS_PERSONAL);
        } else if (alreadyExists.length > 0) {
            if (alreadyExists[0].Description !== configurationObject.getDescription()) {
                var messageText = config.getMessageText('2720', globals.user.getCultureCode());
                //2720
                globals.hideSpinner();
                
                globals.showDialog(new CustomMessageBox(messageText, config.applicationTitle, [config.getTranslationText('2718'), config.getTranslationText('2719')], config.getMessageReference('1651')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY)).addCallback(function (dialogResult) {
                    if (dialogResult === config.getTranslationText('2718')) {
                        configurationObject.setSourceDatabaseId(alreadyExists[0].Id);
                        configurationObject.setUpdateCounter(alreadyExists[0].UpdateCounter);
                        offerManager.showShellSpinner()(true);
                        continueSaveCallback(true);
                    } else {
                        continueSaveCallback(false);
                    }
                });
            } else {
                configurationObject.setSourceDatabaseId(alreadyExists[0].Id);
                configurationObject.setUpdateCounter(alreadyExists[0].UpdateCounter);
                continueSaveCallback(true);
            }
        } else {
            continueSaveCallback(false);
        }
    }

    function doOK(overwrite) {
        var accessoryIdToUse;
        if (overwrite === true) {
            accessoryIdToUse = configurationObject.getSourceDatabaseId();
        } else {
            accessoryIdToUse = 0;
        }

        switch (configurationObject.getAccessoryType()) {
            case config.ACCESSORY_TYPES.CRANE:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.OTHER:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                configurationObject.setWeightType(config.PAYLOAD_WEIGHT_TYPES.WEIGHT);
                configurationObject.setMass(configurationObject.getPayload() * configurationObject.getDensity());
                break;
            case config.ACCESSORY_TYPES.HITCH:
                configurationObject.setFunnelLength(offerManager.getActiveConfigurationWizardItemSize().getLength() - (configurationObject.getMountingLength() + configurationObject.getCouplingOffset()));
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var originXOffsetAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX);
                originXOffsetAOG.value.value = originXOffsetAOG.value.value - configurationObject.getMechanismLength();

                var originYOffsetAOG = offerManager.getAttributeGroup('configurationModal' + '.' + configurationObject.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY);
                originYOffsetAOG.value.value = originYOffsetAOG.value.value - configurationObject.getMechanismHeight();

                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                // do nothing
                break;
            case config.ACCESSORY_TYPES.BODY:
                if (configurationObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY &&
                    (configurationObject.getType() === config.BODY_TYPES.DROPSIDE || configurationObject.getType() === config.BODY_TYPES.BEAVERTAIL ||
                    configurationObject.getType() === config.BODY_TYPES.FLATDECK || configurationObject.getType() === config.BODY_TYPES.ROLLBACK ||
                    configurationObject.getType() === config.BODY_TYPES.TIPPER || configurationObject.getType() === config.BODY_TYPES.TIMBER)) {

                    if (configurationObject.getType() === config.BODY_TYPES.TIMBER || configurationObject.getType() === config.BODY_TYPES.FLATDECK) {
                        configurationObject.setHeadboardHeight(configurationObject.getHeight());
                    } else {
                        configurationObject.setHeadboardHeight(configurationObject.getActualInternalHeight());
                    }
                    
                    if (configurationObject.getType() === config.BODY_TYPES.DROPSIDE || configurationObject.getType() === config.BODY_TYPES.BEAVERTAIL) {
                        if (configurationObject.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                            configurationObject.setHeight(configurationObject.getDropsideHeight());
                        } else {
                            configurationObject.setHeight(configurationObject.getDropsideHeight() + configurationObject.getSubstructureHeight() + configurationObject.getRoofThickness());
                        }
                        
                    }
                }
                
                break;
            default:
                break;
        }
        
        //save changes to graphic
        if (configurationObject.getGraphicBlob() !== null) {
            graphicsManager.notify('Save_Current_Item_Graphic', wizardType === config.WIZARD_TYPES.EDIT_EXISTING).then(function (){
                finshOK();
            });
        }else {
            finshOK();
        }
        function finshOK() {
            offerManager.deleteObservables(path);

            var returnObject = {
                dialogAction: config.DIALOG_RESULTS_OPTIONS.OK,
                path: parentPath,
                wizardType: wizardType,
                accessoryType: accessoryType,
                accessorySubType: accessorySubType,
                chassisObjectType: chassisObjectType,
                configurationObject: configurationObject,
                accessoryId: accessoryIdToUse
            };
            // Close any open tooltips
            WebuiPopovers.hideAll();
            nextTick(function () { 
                offerManager.getUndoHandler().enableUndoOpCreation();
                close(returnObject);
                globals.hideSpinner();
            });
        }
        
    }
}

function close(returnObj) {

   popup.value.close(returnObj);
   resolvePromise(returnObj);
}

function cancelButtonClick() {
    if(configurationWizard.value.isCancelButtonActive.value === true) {
        configurationWizard.value.clickOnCancelButton();
    }
}

function backButtonClick() {
    if(configurationWizard.value.isBackButtonActive.value === true) {
        configurationWizard.value.clickOnBackButton();
    }
}

function nextButtonClick() {
    if(configurationWizard.value.isNextButtonActive.value === true) {
        configurationWizard.value.clickOnNextButton();
    }
}

function doneButtonClick() {
    if(configurationWizard.value.isDoneButtonActive.value === true) {
        configurationWizard.value.clickOnDoneButton();
    }
}
        return {
            // Refs
            popup,
            configurationWizard,
            // Functions
            show,
            ok,
            close,
            getPageContainerClass,
            cancelButtonClick,
            backButtonClick,
            nextButtonClick,
            doneButtonClick,
            // Text
            getTranslationText: config.getTranslationText
            
        }
    }
}
</script>

<style lang="scss" scoped>

</style>