<template>
    <popup-modal ref="popup">
        <div v-if="popup.isVisible">
            <div id="modal" class="messageBox save-accessory-modal-container">
                <div class="modal-header modal-header-text">
                    <h4 class="modal-title"><span v-text="titleText"></span></h4>
                </div>
                <div class="modal-body modal-body-text fill-display-flex center-vertical">        
                    <div class="configuration-wizard-input-container-div">
                        <!-- <div data-bind="template: {name: 'template-menu', data: activeMenu }"></div> -->
                        <MenuContainer :menuFromParent="activeMenu"></MenuContainer>
                        <!-- <component :is="component" v-bind="componentProps"/> -->
                    </div>
                </div>    
                <div class="modal-footer">
                    <div class="modal-footer-button-container">
                        <button class="btn btn-default btn-truckscience btn-primary" @click="ok" @touchstart="ok"><span v-text="getTranslationText('271')"></span></button>
                        <button class="btn btn-default btn-truckscience" @click="close" @touchstart="close"><span v-text="getTranslationText('65')"></span></button>
                    </div>
                </div>
            </div>
        </div>
    </popup-modal>
</template>

<script>

import config from '@/services/config';
// import globals from '@/services/config';
import PopupModal from '@/components/modals/PopupModal.vue';
import dataManager from '@/services/dataManager';
import CustomMessageBox from '@/services/CustomMessageBox';
import Menu from '@/services/menu/menu';
import MenuItemBuilder from '@/services/menu/menuItemBuilder';
import MenuPartBuilder from '@/services/menu/menuPartBuilder';
import MenuContainer from '@/components/menu/MenuContainer.vue';
import bus from '@/main';
import { shallowRef, ref, onMounted, onBeforeMount, toRef, nextTick } from 'vue';


var TScMessenger = window.TScMessenger;
const $ = window.$;
const WebuiPopovers = window.WebuiPopovers;


export default {
    name: 'SaveAccessoryModal',
    components: { PopupModal, MenuContainer },
    props: {
        propsForModal: Object
    },
    setup(props) {
        const opts = toRef(props, 'propsForModal');
        const popup = ref(null);
        // const component = ref('');
        // const componentProps = ref(null);
        let resolvePromise = undefined,
        rejectPromise = undefined;
        let saveAccessoryObject,
            activeMenu = shallowRef(null),
            dataManagerCallback,
            onSuccessCallback,
            onErrorCallback,
            allowSubmitToPublicLibrary,
            modalPathForAttrObvGroup = 'saveAccessoryModal',
            offerManager = null,
            titleText = shallowRef(''),
            getTranslationText = config.getTranslationText,
            accessoryDetails,
            accessoryDescription = shallowRef(''),
            id,
            menuPath,
            path = modalPathForAttrObvGroup,
            saveTypeOptionValue = shallowRef(''),
            // isCompanyOptionDisabled = globals.user.isTrialUser() && !globals.user.isVipTrialUser() || globals.user.getCompanyName() === config.trialRegistrationCompanyNameDefault,
            // companyOptionTooltip = isCompanyOptionDisabled === true ? config.getMessageText('1591', globals.user.getCultureCode()) : '',
            // tooltipMessage = config.getTranslationText('1321'),
            configurationActionResponseHandlerCallback,
            displayFooterCheckbox = false,
            isFooterCheckboxChecked = shallowRef(false),
            footerCheckboxText,
            footerCheckboxTextTooltip,
            globals;

            
            //#endregion

            
            onBeforeMount(()=> {
                offerManager = opts.value.offerManager;
                titleText.value = opts.value.title;
                accessoryDetails = opts.value.accessoryDetails;
                dataManagerCallback = opts.value.dataManagerCallback;
                onSuccessCallback = opts.value.onSuccessCallback;
                onErrorCallback = opts.value.onErrorCallback;
                configurationActionResponseHandlerCallback = opts.value.configurationActionResponseHandlerCallback;
                menuPath = opts.value.menuPath;
                allowSubmitToPublicLibrary = opts.value.allowSubmitToPublicLibrary;
                footerCheckboxText = opts.value.footerCheckboxText;
                footerCheckboxTextTooltip = opts.value.footerCheckboxTextTooltip;
                globals = opts.value.globals;
                displayFooterCheckbox = typeof allowSubmitToPublicLibrary === 'boolean' ? allowSubmitToPublicLibrary : false;
                accessoryDescription.value = accessoryDetails.Description;
                id = accessoryDetails.SourceDatabaseId;
                saveTypeOptionValue.value = accessoryDetails.AccessLevel;

                setupModal();
                // try {
                //     setupModal();
                    
                // } catch (e) {
                //     TScMessenger.writeDebugMessage('');
                    
                // }
            })

            // onMounted(() => {
            //     //setTimeout(function () {
            //     //    $('#accessory-input').focus(function () {
            //     //        try {
            //     //            document.getElementById(this.id).selectionStart = 0;
            //     //            document.getElementById(this.id).selectionEnd = 999;
            //     //        } catch (e) {
            //     //            this.select();        // Works in browsers, not iPad
            //     //        }
            //     //    });
            //     //}, 100);
                
            //     try {
            //         activeMenu.value.updateJsElements();
            //         //$('#saveAccessoryModal-accordion').
            //         $('#saveAccessoryModal-AdvancedOptions-hrefValue').tab('show');
                    
            //     } catch (e) {
            //         TScMessenger.writeDebugMessage('');
                    
            //     }
            // })
            
            function getResultObject() {
                return saveAccessoryObject;
            }
            //#region Functions
            
            function show() {
                //offerManager, title, accessoryDetails, dataManagerCallback, onSuccessCallback, onErrorCallback, configurationActionResponseHandlerCallback, menuPath, allowSubmitToPublicLibrary, footerCheckboxText, footerCheckboxTextTooltip
                
                
                
                
                
                

                popup.value.open();
                setTimeout(function () {
                    activeMenu.value.updateJsElements();
                }, 0);
                // Once we set our config, we tell the popup modal to open
                
                // Return promise so the caller can get results
                return new Promise((resolve, reject) => {
                    resolvePromise = resolve
                    rejectPromise = reject
                })
            }
            
            function showPopover() {
                globals.displayTooltip('#save-accessory-save-to-info');
            }

            function showFooterPopover() {
                globals.displayTooltip('#save-accessory-save-to-info-footer');
            }

            function toggleFooterCheckbox() {
                if (isFooterCheckboxChecked.value === true) {
                    isFooterCheckboxChecked.value = false;
                } else {
                    isFooterCheckboxChecked.value = true;
                }
            }

            function saveAccessory(overwrite) {
                let objectToSave = getResultObject();
                //make sure the token is still valid and extend if necessary
                if (!dataManager.securityTokenIsValid()) {
                    bus.emit('logout');
                    return;
                }
                //shell.showSpinner();
                var accessoryIdToUse;
                if (overwrite === true) {
                    accessoryIdToUse = accessoryDetails.Id;
                } else {
                    accessoryIdToUse = 0;
                }
                //$.when(dataManagerCallback(accessoryIdToUse, accessoryDetails.AccessoryType, accessoryDetails, self.saveTypeOptionValue(), self.isFooterCheckboxChecked())).then(
                $.when(dataManagerCallback(accessoryIdToUse, accessoryDetails.AccessoryType, accessoryDetails, objectToSave.getAccessLevel(), objectToSave.getPublishToPublicLibrary(), objectToSave.getPublicPublishEditLevel(), objectToSave.getPublishToDistributorLibrary(), objectToSave.getDistributorPublishEditLevel())).then(
                    function (success) {
                        // onSuccessCallback(success, menuPath, popup, { getResultObject } );
                        onSuccessCallback(success, menuPath, close, { getResultObject } );
                    },
                    function (error) {
                        //shell.hideSpinner();
                        onErrorCallback(self, error);
                    }
                );
            }

            function setupModal() {
                var saveAccessoryInitObject = {
                    description: accessoryDetails.Description,
                    accessLevel: accessoryDetails.AccessLevel,
                    publishToPublicLibrary: false,
                    publicPublishEditLevel: config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
                    publishToDistributorLibrary: false,
                    distributorPublishEditLevel: config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
                    accessoryType: accessoryDetails.AccessoryType
                };
                saveAccessoryObject = new SaveAccessoryObject(saveAccessoryInitObject);
                //createAttributeObservableGroupForModal(modalPathForAttrObvGroup, saveAccessoryObject);
                offerManager.createObservablesForSaveAccessoryModal(modalPathForAttrObvGroup, saveAccessoryObject);

                activeMenu.value = createModalBody();
                
                
                // componentProps.value = { 'menuFromParent': activeMenu.value };
                // component.value = 'MenuContainer';
            }

            function SaveAccessoryObject(init) {
                var sa = this;
                sa._description = init.description;
                sa._accessLevel = init.accessLevel;
                sa._publishToPublicLibrary = init.publishToPublicLibrary;
                sa._publicPublishEditLevel = init.publicPublishEditLevel;
                sa._publishToDistributorLibrary = init.publishToDistributorLibrary;
                sa._distributorPublishEditLevel = init.distributorPublishEditLevel;
                sa._accessoryType = init.accessoryType;

                sa.getDescription = function () { return sa._description; };
                sa.setDescription = function (newValue) { sa._description = newValue; };
                sa.getAccessLevel = function () { return sa._accessLevel; };
                sa.setAccessLevel = function (newValue) { sa._accessLevel = newValue; };
                sa.getPublishToPublicLibrary = function () { return sa._publishToPublicLibrary; };
                sa.setPublishToPublicLibrary = function (newValue) { sa._publishToPublicLibrary = newValue; };
                sa.getPublicPublishEditLevel = function () { return sa._publicPublishEditLevel; };
                sa.setPublicPublishEditLevel = function (newValue) { sa._publicPublishEditLevel = newValue; };
                sa.getPublicEditLevelForSelect = function () {
                    var noEditOption = getPublishToPublicLibraryOptions().find(function (option) {
                        return option.id = config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT;
                    });
                    return noEditOption;
                };
                sa.getPublishToDistributorLibrary = function () { return sa._publishToDistributorLibrary; };
                sa.setPublishToDistributorLibrary = function (newValue) { sa._publishToDistributorLibrary = newValue; };
                sa.getDistributorPublishEditLevel = function () { return sa._distributorPublishEditLevel; };
                sa.setDistributorPublishEditLevel = function (newValue) { sa._distributorPublishEditLevel = newValue; };
                sa.getDistributorEditLevelForSelect = function () {
                    var noEditOption = getPublishToPublicLibraryOptions().find(function (option) {
                        return option.id = config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT;
                    });
                    return noEditOption;
                };
                sa.getAccessoryType = function () { return sa._accessoryType; };
            }

            function createModalBody() {
                var saveAccessoryMenu = new Menu('save-accessory-menu');
                saveAccessoryMenu.createMenu(createMenuItems());
                return saveAccessoryMenu;
            }

            /**
             * 
             * @returns {object}
             * */
            function createMenuItems() {
                var items = [];

                items.push(addDescriptionMenuItem());
                items.push(addSaveToRadioButton());
                items.push(addAdvancedOptionsTabGroup());

                return new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuStep(config.getTranslationText('4900'), 0)
                    .items(items)
                    .build();
            }

            /**
             * Creates description item to be shown on modal
             * @returns {object} - Returns created object to be used
             * */
            function addDescriptionMenuItem() {
                return new MenuItemBuilder(modalPathForAttrObvGroup)
                    .newText(config.VALUE_TYPE.SAVEACCESSORYDESCRIPTION)
                    .label(config.getTranslationText('4257'))
                    .maxLength(50)
                    .width(100)
                    .customCssClasses('menu-description')
                    .addTooltipTextId('4269')
                    .tooltip()
                    .build();
            }

            /**
             * 
             * @returns {object} - Object to create radiop button input
             * */
            function addSaveToRadioButton() {
                return new MenuItemBuilder(modalPathForAttrObvGroup)
                    .newRadioInput(config.VALUE_TYPE.SAVEACCESSORYACCESSLEVEL, addRadioButtonOptions())
                    .label(config.getTranslationText('1320'))
                    .addTooltipTextId('1321')
                    .tooltip()
                    .build();
            }

            /**
             * Creates access level options that can be selected by the user (Personal/Team)
             * @returns {object[]} - Array of options
             * */
            function addRadioButtonOptions() {
                var options = [],
                    isTeamOptionDisabled = false,
                    teamOptionTooltip = '';

                options.push(
                    new MenuItemBuilder(modalPathForAttrObvGroup)
                        .newRadioInputOption(config.getTranslationText('2829'), config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL)
                        .build()
                );

                var displayUpgradeIcon = false;
                if (globals.user.isTrialUser() && !globals.user.isVipTrialUser() || globals.user.getCompanyName() === config.trialRegistrationCompanyNameDefault) {
                    isTeamOptionDisabled = true;
                    teamOptionTooltip = config.getMessageText('1591', globals.user.getCultureCode());
                } else {
                    if (globals.user.isUserAllowedFullAccessToTeamLibrary() === false) {
                        displayUpgradeIcon = true;
                    }
                }
                
                options.push(
                    new MenuItemBuilder(modalPathForAttrObvGroup)
                        .newRadioInputOption(config.getTranslationText('2834'), config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY, null, isTeamOptionDisabled, teamOptionTooltip, displayUpgradeIcon)
                        .build()
                );

                return options;
            }

            /**
             * 
             * @returns {object}
             * */
            function addAdvancedOptionsTabGroup() {
                return new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newMenuItem(config.ITEM_TYPE.MENUTABGROUP)
                    .asAccordion()
                    .customCssClasses('accordion-on-modal')
                    .keepAllAccordionsClosedWhenInitialized()
                    .tabs(addAdvancedGroupTabs())
                    .build();
            }

            /**
             *
             * @returns {object[]}
             * */
            function addAdvancedGroupTabs() {
                var tabs = [];

                tabs.push(new MenuPartBuilder(modalPathForAttrObvGroup)
                    .newTab(config.getTranslationText('4258'))
                    .items(addAdvancedGroupTabItems())
                    .build());

                return tabs;
            }

            /**
             * 
             * @returns {object[]}
             * */
            function addAdvancedGroupTabItems() {
                var items = [];

                items.push(addShareToDistributorLibraryValue());
                items.push(addShareToPublicLibraryValue());

                return items;
            }

            /**
             * 
             * @returns {object}
             * */
            function addShareToPublicLibraryValue() {
                function willOverrideBeDisabled() {
                    if ((globals.user.isTrialUser() === true && globals.user.isVipTrialUser() === false) || saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        return true;
                    } else {
                        return false;
                    }
                }

                function displayTrailerErrorMessage() {
                    var messageCode = '4787';
                    nextTick(function(){
                        globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    });
                }

                return new MenuItemBuilder(modalPathForAttrObvGroup)
                    .newSelect(config.VALUE_TYPE.SAVEACCESSORYPUBLISHTOPUBLICLIBRARY)
                    .label(config.getTranslationText('4259'))
                    .array(getPublishToPublicLibraryOptions())
                    .update()
                    .width(150)
                    .addTooltipTextId('4260')
                    .tooltip()
                    .isOverrideDisabled(willOverrideBeDisabled(), config.getTranslationText('4534'), displayTrailerErrorMessage)
                    .build();
            }

            /**
             * 
             * @returns {object}
             * */
            function addShareToDistributorLibraryValue() {
                function willOverrideBeDisabled() {
                    if ((globals.user.isTrialUser() === true && globals.user.isVipTrialUser() === false) || saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        return true;
                    } else {
                        return false;
                    }
                }

                function displayTrailerErrorMessage() {
                    var messageCode = '4786';
                    nextTick(function(){
                        globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271')], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY));
                    })
                }

                function displayUpgradeMessage() {
                    var messageCode = '4563';
                    if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        messageCode = '4561';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        messageCode = '4567';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        messageCode = '4565';
                    }
                    nextTick(function(){
                        globals.showDialog(new CustomMessageBox(config.getMessageText(messageCode, globals.user.getCultureCode()), config.applicationTitle, [config.getTranslationText('271'), config.getTranslationText('65')], config.getMessageReference(messageCode)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(function(dialogResult){
                            nextTick(function(){
                                bus.emit('handleEssentialsMessageResponse', { clickedButtonText: dialogResult, source: config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_TEAM_LIBRARY, modalDisplayMode: config.MODAL_DISPLAY_MODE.OVERLAY });
                            });
                        });
                    })
                }

                function upgradeIconTooltipMessage() {
                    var upgradeTooltipId = '4562';
                    if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        upgradeTooltipId = '4560';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        upgradeTooltipId = '4566';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        upgradeTooltipId = '4564';
                    }

                    return config.getTranslationText(upgradeTooltipId);
                }

                function upgradeIconTooltipLinkId() {
                    var upgradeTooltipId = 'upgrade-link-distributor-accessory';
                    if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        upgradeTooltipId = 'upgrade-link-distributor-body';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        upgradeTooltipId = 'upgrade-link-distributor-trailer';
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        upgradeTooltipId = 'upgrade-link-distributor-payload';
                    }

                    return upgradeTooltipId;
                }

                function upgradeIconTooltipCheckLocation() {
                    var upgradeCheckLocation = config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_MODAL_ACCESSORY;
                    if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                        upgradeCheckLocation = config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_MODAL_BODY;
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
                        upgradeCheckLocation = config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_MODAL_TRAILER;
                    } else if (saveAccessoryObject.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        upgradeCheckLocation = config.ESSENTIALS_CHECK_LOCATION.SAVE_ACCESSORY_MODAL_PAYLOAD;
                    }

                    return upgradeCheckLocation;
                }

                function hideUpgradeTooltipForEnterpriseUsers() {
                    // TCTODO: Change to Essentials/Professional users APP_PRODUCT
                    return willOverrideBeDisabled() === false && (globals.user.getEdition() === config.APP_EDITION.ESSENTIALS || globals.user.getEdition() === config.APP_EDITION.PROFESSIONAL);
                }

            return new MenuItemBuilder(modalPathForAttrObvGroup)
                    .newSelect(config.VALUE_TYPE.SAVEACCESSORYPUBLISHTODISTRIBUTORLIBRARY)
                    .label(config.getTranslationText('4551'))
                    .array(getPublishToPublicLibraryOptions())
                    .update()
                    .width(150)
                    .addTooltipTextId('4552')
                    .tooltip()
                    .isOverrideDisabled(willOverrideBeDisabled(), config.getTranslationText('4534'), displayTrailerErrorMessage)
                    .displayUpgradeTooltip(hideUpgradeTooltipForEnterpriseUsers(), displayUpgradeMessage, upgradeIconTooltipMessage(), upgradeIconTooltipLinkId(), upgradeIconTooltipCheckLocation())
                    .build();
            }

            /**
             * 
             * @returns {object}
             * */
            function getPublishToPublicLibraryOptions() {
                return [
                    {
                        id: config.ITEM_EDIT_LEVEL_OPTIONS.NO_EDIT,
                        description: config.getTranslationText('4261')
                    },
                    {
                        id: config.ITEM_EDIT_LEVEL_OPTIONS.FULL_EDIT,
                        description: config.getTranslationText('4262')
                    }
                ];
            }
            

            function ok() {
                
                let objectToSave = getResultObject();
                //shell.showSpinner();
                //if (this.accessoryDescription() !== '') {
                if (objectToSave.getDescription() !== '') {
                    // check if id already exists
                    //if (this.accessoryDescription() !== this.accessoryDetails.Description) {
                    if (objectToSave.getDescription() !== accessoryDetails.Description) {
                        accessoryDetails.Id = 0;
                        accessoryDetails.SourceDatabaseId = 0;
                        accessoryDetails.Description = objectToSave.getDescription();
                    } else {
                        accessoryDetails.Id = accessoryDetails.SourceDatabaseId;
                    }
                    if (accessoryDetails.AccessoryType === config.ACCESSORY_TYPES.BODY) {
                        
                        $.when(dataManager.getBodyStubs()).done(function (data) {
                            
                            //var duplicateTargetLibraryDescriptions = data.bodies.Bodies.filter(itemDuplicateDescriptionFilter(self.saveTypeOptionValue()));
                            var duplicateTargetLibraryDescriptions = data.bodies.Bodies.filter(itemDuplicateDescriptionFilter(objectToSave.getAccessLevel()));

                            var alreadyExists = data.bodies.Bodies.filter(itemAlreadyExistsFilter);

                            //if (self.accessoryDetails.AccessLevel !== self.saveTypeOptionValue()) {
                            //    handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //} else {
                            //    doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //}
                            if (accessoryDetails.AccessLevel !== objectToSave.getAccessLevel()) {
                                handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            } else {
                                doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            }
                        });
                        
                    } else if (accessoryDetails.AccessoryType === config.ACCESSORY_TYPES.TRAILER) {

                        $.when(dataManager.getTrailerStubs()).done(function (data) {
                            
                            //var duplicateTargetLibraryDescriptions = data.trailers.Trailers.filter(itemDuplicateDescriptionFilter(self.saveTypeOptionValue()));
                            var duplicateTargetLibraryDescriptions = data.trailers.Trailers.filter(itemDuplicateDescriptionFilter(objectToSave.getAccessLevel()));

                            var alreadyExists = data.trailers.Trailers.filter(itemAlreadyExistsFilter);

                            //if (self.accessoryDetails.AccessLevel !== self.saveTypeOptionValue()) {
                            //    handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //} else {
                            //    doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //}
                            if (accessoryDetails.AccessLevel !== objectToSave.getAccessLevel()) {
                                handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            } else {
                                doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            }
                            
                        });
                    } else if (accessoryDetails.AccessoryType === config.ACCESSORY_TYPES.PAYLOAD) {

                        $.when(dataManager.getPayloadStubs()).done(function (data) {
                            
                            //var duplicateTargetLibraryDescriptions = data.payloads.Payloads.filter(itemDuplicateDescriptionFilter(self.saveTypeOptionValue()));
                            var duplicateTargetLibraryDescriptions = data.payloads.Payloads.filter(itemDuplicateDescriptionFilter(objectToSave.getAccessLevel()));

                            var alreadyExists = data.payloads.Payloads.filter(itemAlreadyExistsFilter);

                            //if (self.accessoryDetails.AccessLevel !== self.saveTypeOptionValue()) {
                            //    handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //} else {
                            //    doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //}
                            if (accessoryDetails.AccessLevel !== objectToSave.getAccessLevel()) {
                                handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            } else {
                                doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            }
                            
                        });
                    } else {
                        
                        $.when(dataManager.getAccessoryStubs()).done(function (data) {
                            var alreadyExists = [];
                            
                            var duplicateTargetLibraryDescriptions;
                            //if (self.saveTypeOptionValue() === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL) {
                            if (objectToSave.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL) {
                                duplicateTargetLibraryDescriptions = data.accessories.Accessories.filter(function (accessory) {
                                    return accessory.Id !== id &&
                                        accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM &&
                                        accessory.AccessoryType === accessoryDetails.AccessoryType &&
                                        accessory.Description === accessoryDetails.Description &&
                                        accessory.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL;
                                });
                            } else {
                                duplicateTargetLibraryDescriptions = data.accessories.Accessories.filter(function (accessory) {
                                    return accessory.Id !== id &&
                                        accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM &&
                                        accessory.AccessoryType === accessoryDetails.AccessoryType &&
                                        accessory.Description === accessoryDetails.Description &&
                                        accessory.AccessLevel === config.CUSTOM_ITEM_ACCESS_LEVEL.COMPANY;
                                });
                            }

                            alreadyExists = data.accessories.Accessories.filter(function (accessory) {
                                return accessory.Id === id && accessory.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM && accessory.AccessoryType === accessoryDetails.AccessoryType;
                            });

                            //if (self.accessoryDetails.AccessLevel !== self.saveTypeOptionValue()) {
                            //    handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //} else {
                            //    doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, self.saveAccessory);
                            //}
                            if (accessoryDetails.AccessLevel !== objectToSave.getAccessLevel()) {
                                handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            } else {
                                doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, saveAccessory);
                            }
                            
                            
                        });
                        
                    }
                    
                }

                function itemAlreadyExistsFilter(item) {
                    return item.Id === id && item.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM;
                }

                function itemDuplicateDescriptionFilter(customItemAccessLevel) {
                    return function (item) {
                        return item.Id !== id &&
                            item.Source === config.VEHICLE_SOURCE_TYPES.CUSTOM &&
                            item.Description === accessoryDetails.Description &&
                            item.AccessLevel === customItemAccessLevel;
                    };
                    
                }

                function handleAccessLevelChangeFlow(duplicateTargetLibraryDescriptions, alreadyExists, continueSaveCallback) {
                    var msgRef,
                        messageText,
                        objectToSave = getResultObject();
                    
                    if (objectToSave.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL) {
                        msgRef = '1647';
                    } else {
                        msgRef = '1648';
                    }

                    messageText = config.getMessageText(msgRef, globals.user.getCultureCode());

                    globals.showDialog(new CustomMessageBox(messageText, config.applicationTitle, [config.getTranslationText('576'), config.getTranslationText('261')], config.getMessageReference(msgRef)).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY))
                        .addCallback(function (dialogResult) {
                            if (dialogResult === config.getTranslationText('576')) {
                                 nextTick(function () {
                                    doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, continueSaveCallback);
                                });
                            }
                        });
                }

                function doDescriptionChecks(duplicateTargetLibraryDescriptions, alreadyExists, continueSaveCallback) {
                    if (duplicateTargetLibraryDescriptions.length > 0) {
                         if(objectToSave.getAccessLevel() === config.CUSTOM_ITEM_ACCESS_LEVEL.PERSONAL) {
                            configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.NAME_ALREADY_EXISTS_PERSONAL);
                        }   else {
                            configurationActionResponseHandlerCallback(config.ACTION_TYPE.CUSTOM_SAVE, config.ACTION_ID.NAME_ALREADY_EXISTS_TEAM);
                        }
                    } else if (alreadyExists.length > 0) {
                        if (alreadyExists[0].Description !== accessoryDescription.value) {
                            var messageText = config.getMessageText('1651', globals.user.getCultureCode());
                            //1651
                            globals.showDialog(new CustomMessageBox(messageText, config.applicationTitle, [config.getTranslationText('1332'), config.getTranslationText('1333')], config.getMessageReference('1651')).setModalDisplayMode(config.MODAL_DISPLAY_MODE.OVERLAY)).addCallback(function (dialogResult) {
                                if (dialogResult === config.getTranslationText('1332')) {
                                    accessoryDetails.Id = alreadyExists[0].Id
                                    accessoryDetails.UpdateCounter = alreadyExists[0].UpdateCounter
                                    continueSaveCallback(true);
                                } else {
                                    continueSaveCallback(false);
                                }
                            });
                        } else {
                            accessoryDetails.Id = alreadyExists[0].Id
                            accessoryDetails.UpdateCounter = alreadyExists[0].UpdateCounter
                            continueSaveCallback(true);
                        }
                    } else {
                        continueSaveCallback(false);
                    }
                }
            }

            function close() {
                WebuiPopovers.hideAll();
                //self.activeMenu.cleanUp();
                offerManager.deleteObservables(path);
                popup.value.close();
                resolvePromise();
            }
        

        return {
            ok,
            close,
            show,
            getTranslationText,
            titleText,
            popup,
            getResultObject,
            activeMenu,
            // componentProps,
            // component
        }
        
    }
}
</script>