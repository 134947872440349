
import language from './language';
import { shallowRef, ref } from 'vue'




var CustomTruckScienceRequirements = window.CustomTruckScienceRequirements;


  var applicationTitle = 'TruckScience',
    // dimension constants
    dimensionLayerHeight = 20,
    dimensionLayerWidth = 20,
    dimensionDrawingBuffer = 20,
    dimensionFontSize = 10,
    dimensionFontSizePDF = 6,
    dimensionFont = 'Helvetica',
    dimensionTextDefaultColour = 'black',
    dimensionTextHoverColour = 'red',
    dimensionTextClickColour = 'blue',
    dimensionDefaultColour = 'black',
    dimensionHoverColour = 'red',
    dimensionClickColour = 'blue',
    dimensionLineDefaultWidth = 1,
    dimensionLineHighlightedWidth = 2,
    dimensionObjectDefaultOpacity = 1,
    dimensionObjectHighlightedOpacity = 0.7,
    dimensionObjectDefaultShadowBlur = 0,
    dimensionObjectHighlightedShadowBlur = 10,
    dimensionObjectDefaultShadowOffset = 0,
    dimensionObjectHighlightedShadowOffset = 3,
    dimensionObjectUnAlphadShadowColour = 'rgba(80, 80, 80, 0)',
    //drawing constants
    generalDrawingBufferSmall = 2,
    generalDrawingMargin = 10,
    //local storage constants
    localStorageData = 'DATA',
    localStorageExpiry = 'EXPIRY',
    localStorageUser = 'USER',
    //transaction cache expiries
    vehicleSelectionValidity = 604800,
    companyOverviewValidity = 604800,
    newOfferValidity = 604800,
    millisInDay = 86400000,
    //offline transaction names
    newOfferTransaction = 'NEWOFFER',
    //box contants
    boxRectOffsetX = 35,
    boxRectOffsetY = 60,
    boxRectHeight = 20,
    boxRectWidth = 70,
    boxFontSize = 14,
    boxFont = 'Calibri',
    boxTextDefaultColour = 'white',
    boxArrayLength = 4,
    boxLabelBuffer = 5,
    //drawing constants
    kineticObjectActionTypeMove = 'move',
    kineticObjectActionTypeUpdateStartX = 'updateStartX',
    kineticObjectActionTypeUpdateStartY = 'updateStartY',
    kineticObjectActionTypeUpdateEndX = 'updateEndX',
    kineticObjectActionTypeUpdateEndY = 'updateEndY',
    configurationTableFontSize = 13,
    configurationDashboardAndTableTitleFontSize = 15,
    configurationNotesAndWarningsFontSize = 12,
    configurationDimensionFontSize = 13,
    configurationLabelFontSize = 13,
    configurationAxleLetterFontSize = configurationDimensionFontSize,
    //summaryTableFontSize = 16,
    //summaryNotesAndWarningsFontSize = 16,
    //summaryDimensionFontSize = 14,
    //summaryLabelFontSize = 15,
    summaryTableFontSize = 19,
    summaryDashboardAndTableTitleFontSize = 20,
    summaryNotesAndWarningsFontSize = 19,
    summaryDimensionFontSize = 17,
    summaryLabelFontSize = 18,
    summaryAxleLetterFontSize = summaryDimensionFontSize,
    configurationDimensionLayerIncrement = 16,
    summaryDimensionLayerIncrement = 20,
    defaultTableLinkChar = '-',
    defaultTableBlankRowChar = '-',
    gapBetweenTyresInMm = 20,
    gapBetweenTyresAndChassis = 325,
    configurationTableAreaVerticalSeparatorThickness = 1,
    minimumComplianceDashboardAndTableBuffer = 60,
    configurationTableInterColGap = 2,
    fifthWheelSubframeBaseLength = 1740,
    topViewHitchRadius = 150,
    topViewFifthWheelRadius = 400,
    //app constants
    showDebugToast = false,
    cloudServicesAddress = '',
    azureFunctionsUrl = '',
    cdnAddress = '',
    appVersionNumber = '',
    ipapiKey = '',
    appEnvironment = '',
    defaultColour1 = '#3684AB',
defaultColour2 = '#AFC8CF',
defaultColour3 = '#78B9D9',
defaultColour4 = '#C5E1EF',
defaultCultureCode = 'en',
defaultOverviewPermission = 'Y',
defaultCRMPermission = 'Y',
defaultBrochurePermission = 'Y',
defaultConfigurationPermission = 'Y',
defaultPerformancePermission = 'Y',
defaultCostingPermission = 'Y',
defaultSpecificationPermission = 'Y',
defaultTrainingPermission = 'N',
defaultTrainingUrl = '',
    // selection constants
selectionApplicationDistribution = 'DISTRIBUTION',
    selectionApplicationConstruction = 'CONSTRUCTION',
    selectionApplicationLongDistance = 'LONGDISTANCE',
    selectionApplicationOther = 'OTHER',
    selectionVehicleTypeTipper = "TIPPER",
    selectionVehicleTypeMixer = "MIXER",
    defaultSearchResultsLimit = 50,
    //offer status constants
    offerStatusInProgress = 'INPROGRESS',
    offerStatusCompleted = 'COMPLETED',
    offerStatusOrderLost = 'LOST',
    //offer status lost constants
    offerLostReasonEngine = 'ENGINE',
    offerLostReasonBody = 'BODY',
    offerLostReasonChassis = 'CHASSIS',
    offerLostReasonTransmission = 'TRANSMISSION',
    offerLostReasonOther = 'OTHER',
    //calculation constants
    vehicleTypeStandard = 'STANDARD',
    vehicleTypeTemplate = 'TEMPLATE',
    pixelsPerMillimetre = 3.779527559,
    millimetresPerFontPoint = 0.3514, //0.37594,
    //map constants
    bingMapsKey = "AjmHwCNrVDEqB_9n3piag0RzqGHFQsjzkXOHXFjgWGzaVg3F6-omXD9ypR1jQjNF",
    bingMapsMaxElevationPoints = 256,
    maximumSectionLength = 200,
    bingMapsMaxWaypoints = 25,
    bingMapsBaseUrl = 'https://dev.virtualearth.net/',
    //bingMapsMaxRoutableDistance = 2500,
    //bingMapsMaxRoutableDistanceMetric = 5000,
    //bingMapsMaxRoutableDistanceImperial = 3000,
    bingMapsMaxRoutableDistanceMetric = 10000,
    bingMapsMaxRoutableDistanceImperial = 6000,
    //decimal places constants
    defaultDecimalsHighPricedItems = 0,
    defaultDecimalsMediumPricedItems = 0,
    defaultDecimalsLowPricedItems = 0,
    defaultDecimalsMinMaxLabel = 0,
    //onroad costs constants
    onRoadCostTypeCost = 'COST',
    onRoadCostTypeDiscount = 'DISCOUNT',
    onRoadCostValueTypeConstant = 'CONSTANT',
    onRoadCostValueTypePercentage = 'PERCENTAGE',
    //database attribute contstants
    databaseAttributeActionTextCode = 364,
    databaseAttributeOnRoadCostCode = 373,
    databaseAttributesForTranslation = [databaseAttributeActionTextCode, databaseAttributeOnRoadCostCode],
    //minimum requirements        
    osNameAndroid = "Android",
    osNameiOS = "iOS",
    osNameiOSMinimumVersion = 8,
    osNameMac = "Mac",
    osNameWindows10 = "Windows 10",
    osNameWindows81 = "Windows 8.1",
    osNameWindows8 = "Windows 8",
    osNameWindows7 = "Windows 7",
    minAndroidVersion = 4.4,
    minScreenHeight = 768,
    minScreenWidth = 1024,
    minBrowserHeight = 710,
    minBrowserWidth = 1024,
    validWindowsOperatingSystems = [osNameWindows10, osNameWindows81, osNameWindows8, osNameWindows7],
    touchWindowsOperatingSystems = [osNameWindows10, osNameWindows81, osNameWindows8],
    selectionVehicleType = "vehicle-type",
    selectionAxleLayout = "axle-layout",
    selectionVehicleMake = "vehicle-make",
    selectionVehicleRange = "vehicle-range",
    selectionVehicleSelection = "vehicle-selection",
    selectionTypeAxleLayoutMakeRange = [selectionVehicleType, selectionAxleLayout, selectionVehicleMake, selectionVehicleRange, selectionVehicleSelection],
    selectionMakeRangeTypeAxleLayout = [selectionVehicleMake, selectionVehicleRange, selectionVehicleType, selectionAxleLayout, selectionVehicleSelection],
    selectionVehicleMakeGeneric = 'GENERIC',
    selectionVehicleTypeRigidImage = CustomTruckScienceRequirements.getMetaTagContent('cdn') + 'img/app/vehicleTypes/VehicleTypeRigid.png',
    selectionVehicleTypeTruckTractorImage = CustomTruckScienceRequirements.getMetaTagContent('cdn') + 'img/app//vehicleTypes/VehicleTypeTractor.png',
    selectionVehicleTypeCompleteVehicleImage = CustomTruckScienceRequirements.getMetaTagContent('cdn') + 'img/app//vehicleTypes/VehicleTypePanelvan.png',
    selectionAxleLayout4X2 = '4X2',
    selectionAxleLayout4X4 = '4X4',
    selectionAxleLayout6X2 = '6X2',
    selectionAxleLayout6X4 = '6X4',
    selectionAxleLayout6X6 = '6X6',
    selectionAxleLayout8X2 = '8X2',
    selectionAxleLayout8X4SS = '8X4SS',
    selectionAxleLayout8X4TS = '8X4TS',
    selectionAxleLayout8X8 = '8X8',
    selectionAxleLayout10X4 = '10X4',
    //url proerties
    // defReportFooterURL = '',
    // defReportHeaderURL = '',
    appHeaderURL = shallowRef(''),
    displayAppHeaderTextImage = shallowRef(false),
    appFooterURL = shallowRef(''),
    //data driven config values
    loggingLevel = '',
    debugLogRetentionPeriod = 0, //in seconds
    debugLogCommunicationPeriod = 0, //in seconds
    noOfDaysLicenceExpiryMessageShows = 0,
    isOnlineCheckPeriod = 0,
    isOnlineTimeout = 20,
    isOnlineLowPriorityValidityPeriod = 0,
    isOnlineMediumPriorityValidityPeriod = 0,
    isOnlineHighPriorityValidityPeriod = 0,
    postRequestTimeout = 40, //initialising to 40 seconds as the first time into the app the login/security timeout needs to be set but values aren't stored, quick fix... 
    getRequestTimeout = 40,
    usageCommunicationPeriod = 0, //in seconds
    maxNewOffersCached = 0,
    maxSavedOffersCached = 0,
    menuSlideDuration = 200,
    loadingSummaryDisclaimer = '',
    specificationSummaryDisclaimer = '',
    costingSummaryDisclaimer = '',
    routingSummaryDisclaimer = '',
    maxTrialChangesBeforeForcedEmailVerification = 0,
    externalAPIKey = '',
    applicationIdentityType = 'HTML5',
    showTrialCalculationsRemainingMessageThreshold = 2,
    trailUserObfuscationText = 'XXXXXX',
    trailUserObfuscationShortText = 'XX',
    intercomAppId = '',
    azureFunctionsServer = '',
    chargebeeSiteName = '',
    chargebeeProductVersion = '',
    pdfDocumentColour = '#444444',
    swingClearanceLimit = 300,
    protrusionSwingClearanceLimit = 100,
    //axle defaults
    vehicleAxleDefaultAxleWeight = 612.35,
    vehicleAxleDefaultMaxLiftingTravel = 200,
    vehicleAxleDefaultTyreRadius = 513,
    vehicleAxleDefaultTyreWidth = 315,
    vehicleAxleDefaultManufacturerRating = 5896.7,
    vehicleAxleDefaultSuspensionMassRating = 5896.7,
    vehicleAxleDefaultTyreMassRating = 5896.7,
    vehicleAxleDefaultDesiredWeight = 5896.7,
    vehicleAxleDefaultSecondSteerAxleSpread = 1800,
    vehicleAxleDefaultAxleSpread = 1321,
    vehicleAxleDefaultAxleBuffer = 37,
    vehicleAxleDefaultLiftingMaxTravel = 200,
    maxTotalAxles = 6,
    maxFrontAxles = 2,
    maxRearAxles = 4,

    radiusOfTheEarth = 6371,
    tollBuffer = 10,
    tollZoomLevel = 6,
    accessCode = '',
    shareeTempName = 'ShareeFirstName',
    nteaLogoLocation = 'img/app/NTEA_Logo.png',
    maxDxfFileSizeInMB = 3,
    maxReportLogoFileSizeInKb = 150,
    trialRegistrationCompanyNameDefault = 'CompanyNameNotRequired',
    allowOpenOfferByDistributorFlagString = "AllowOpenOfferByDistributor",

    roundingPrecisionForTableAndWarnings = 0,
    tableAndDashboardTitleHeight = 30,
    tableAndDashboardTitleToDataGap = 10,
    allowedNumberOfFuelTanksOrBatteries = 8,

    identity = {
        username: '',
        password: '',
        redirectFlag: ''
    },

    VALIDATION_MESSAGE_TYPE = {
        BETWEEN: "2407",
        GREATERTHAN: "2408",
        LESSTHAN: "2409"
    },

    RANGE_TYPES = {
        BETWEEN: "BETWEEN",
        GREATER: "GREATER",
        LESSER: "LESSER"
    },

    LOGIN_REAUTH_STEP_TEXT_CODE = {
        DOWNLOADING_APPLICATION_DATA: "DOWNLOADING_APPLICATION_DATA",
        DOWNLOADING_SETTINGS: "DOWNLOADING_SETTINGS",
        DOWNLOADING_IMAGES: "DOWNLOADING_IMAGES",
        REFRESHING_APPLICATION_DATA: "REFRESHING_APPLICATION_DATA",
        REFRESHING_SETTINGS: "REFRESHING_SETTINGS",
        REFRESHING_IMAGES: "REFRESHING_IMAGES",
        CONFIGURING_APPLICATION: "CONFIGURING_APPLICATION"
    },

    loginReAuthStepsText = {
        "en": {
            "DOWNLOADING_APPLICATION_DATA": "Loading Vehicles...", // User Story 17565 Translations
            "DOWNLOADING_SETTINGS": "Loading Trailers, Bodies & Equipment...", // User Story 17565 Translations
            "DOWNLOADING_IMAGES": "Downloading Images...", // User Story 17565 Translations
            "REFRESHING_APPLICATION_DATA": "Refreshing App Data...", // User Story 17565 Translations
            "REFRESHING_SETTINGS": "Refreshing Settings...", // User Story 17565 Translations
            "REFRESHING_IMAGES": "Refreshing Images...", // User Story 17565 Translations
            "CONFIGURING_APPLICATION": "Loading Regulations..." // User Story 17565 Translations
        },
        "vi": {
            "DOWNLOADING_APPLICATION_DATA": "Đang tải dữ liệu ứng dụng...",
            "DOWNLOADING_SETTINGS": "Đang tải cài đặt...",
            "DOWNLOADING_IMAGES": "Đang tải hình ảnh...",
            "REFRESHING_APPLICATION_DATA": "Đang làm mới dữ liệu ứng dụng...",
            "REFRESHING_SETTINGS": "Đang làm mới cài đặt...",
            "REFRESHING_IMAGES": "Đang làm mới hình ảnh...",
            "CONFIGURING_APPLICATION": "Đang cấu hình ứng dụng..."
        }
    },


    

    
    getMessageText = function (code, cultureCode, variable) {
        var variableToUse;
        if (Array.isArray(variable)) {
            variableToUse = variable;
        } else if (variable !== undefined) {
            variableToUse = [variable];
        }
        //var selectedMessage = getTranslationTextWithCultureCode(code, cultureCode);
        //if (selectedMessage !== undefined) {
        //    if (variable !== undefined) {
        //        selectedMessage = selectedMessage.replace('$', variable);
        //    }
        //    return selectedMessage;
        //} else {
        //    return '';
        //}
        return getTranslationTextWithCultureCode(code, cultureCode, variableToUse);
    },

    getMessageTextNoCode = function (code, cultureCode, variable) {
        return getMessageText(code, cultureCode, variable, true);
    },

    getMessageReference = function (code) {
        return language.getTranslationText('651') + code;
    },

    getValidationMessageText = function (code, cultureCode, variableName, greaterThanValue, lessThanValue) {
        var selectedMessage = getTranslationTextWithCultureCode(code, cultureCode);

        if (selectedMessage !== undefined) {
            if (variableName !== undefined) {
                var attributeDescriptionText = variableName.split('#')[0];
                var attributeAdditionalText;
                if (variableName.split('#').length > 1) {
                    attributeAdditionalText = variableName.split('#')[1];
                }

                selectedMessage = selectedMessage.replace('$variableName', attributeDescriptionText);
                if (greaterThanValue !== undefined) {
                    selectedMessage = selectedMessage.replace('$greaterThan', greaterThanValue);
                }
                if (lessThanValue !== undefined) {
                    selectedMessage = selectedMessage.replace('$lessThan', lessThanValue);
                }
                if (attributeAdditionalText !== undefined) {
                    selectedMessage = selectedMessage.replace('$additionalMessage', attributeAdditionalText + '.');
                } else {
                    selectedMessage = selectedMessage.replace(' $additionalMessage', '');
                }
            }
            return selectedMessage;
        } else {
            return '';
        }
    },

    getLoginReAuthStepText = function (code, cultureCode) {

        if (cultureCode === undefined || cultureCode === '') {
            cultureCode = 'en';
        }
        cultureCode = cultureCode.toLowerCase();
        if (loginReAuthStepsText[cultureCode] === undefined) {
            cultureCode = 'en';
        } else if (loginReAuthStepsText[cultureCode][code] === undefined) {
            cultureCode = 'en';
        }
        return loginReAuthStepsText[cultureCode][code]; // !== undefined ? loginReAuthStepsText[cultureCode][code] : loginReAuthStepsText['en'][code];
    },

    //getTableRowLabelText = function (code, cultureCode) {

    //    if (cultureCode === undefined || cultureCode === '') {
    //        cultureCode = 'en';
    //    }

    //    return loginReAuthStepsText[cultureCode][code];
    //},

    // showDetails = function () {

    //     var
    //         content = $('<div />').html("<input type='text' id='test'/>"),
    //         value;

    // },

    getTooltipText = function (code, cultureCode) {
        return getTranslationTextWithCultureCode(code, cultureCode);
        //var selectedMessage = '';
        //if (cultureCode === undefined || cultureCode === '') {
        //    cultureCode = 'en';
        //}
        //cultureCode = cultureCode.toLowerCase();
        //if (tooltips[cultureCode.toLowerCase()] === undefined) {
        //    cultureCode = 'en';
        //} else if (tooltips[cultureCode][code] === undefined) {
        //    cultureCode = 'en';
        //}
        ////selectedMessage = tooltips[cultureCode][code] || '';
        //selectedMessage = tooltips[cultureCode][code]; // !== undefined ? tooltips[cultureCode][code] : tooltips['en'][code];
        //return selectedMessage;
    },

    //getValidationBaseText = function (code, cultureCode) {
    //    var selectedMessage = '';
    //    if (cultureCode === undefined || cultureCode === '') {
    //        cultureCode = 'en';
    //    }
    //    cultureCode = cultureCode.toLowerCase();
    //    //selectedMessage = validationBaseTexts[cultureCode][code] || '';
    //    if (validationBaseTexts[cultureCode] === undefined) {
    //        cultureCode = 'en';
    //    } else if (validationBaseTexts[cultureCode][code] === undefined) {
    //        cultureCode = 'en';
    //    }
    //    selectedMessage = validationBaseTexts[cultureCode][code]; // !== undefined ? validationBaseTexts[cultureCode][code] : validationBaseTexts['en'][code];
    //    return selectedMessage;
    //},

    getValidationBaseText = function (code, cultureCode) {
        return getTranslationTextWithCultureCode(code, cultureCode);
    },

    getProductTourText = function (code, cultureCode, variable) {
        //var selectedMessage = '';
        //if (cultureCode === undefined || cultureCode === '') {
        //    cultureCode = 'en';
        //}
        //cultureCode = cultureCode.toLowerCase();
        ////selectedMessage = validationBaseTexts[cultureCode][code] || '';
        //if (productTours[cultureCode] === undefined) {
        //    cultureCode = 'en';
        //} else if (productTours[cultureCode][tourCode][tourType][code] === undefined) {
        //    cultureCode = 'en';
        //}
        //selectedMessage = productTours[cultureCode][tourCode][tourType][code];

        //var selectedMessage = getTranslationTextWithCultureCode(code, cultureCode);
        //if (selectedMessage !== undefined) {
        //    if (variable !== undefined) {
        //        selectedMessage = selectedMessage.replace('$', variable);
        //    }
        //}
        //return selectedMessage;
        return getTranslationTextWithCultureCode(code, cultureCode, variable);
    },

    getAppName = function () {
        //return this.getAppObjectBasedOnAlias(getAlias()).appNameAndVersion;
        return this.getAppObjectBasedOnAlias(getAlias()).appName;
    },

    getAppVersion = function () {
        return 'v' + this.appVersionNumber;
    },

    getAppShortcutName = function () {
        return this.getAppObjectBasedOnAlias(getAlias()).appName;
    },

    getAppHeaderURL = function () {
        return this.getAppObjectBasedOnAlias(getAlias()).appHeaderUrl;
    },

    getHeaderURL = function () {
        return this.appHeaderURL.value;
    },

    usingOemUrl = function () {
        return getAppObjectBasedOnAlias(getAlias()).isOemUrl;
    },

    setHeaderURL = function (url) {
        this.appHeaderURL.value = url;
        this.displayAppHeaderTextImage.value = this.displayHeaderText();
    },

    displayHeaderText = function () {
        var headerUrl = this.cdnAddress + "img/app/logos/truckscience/appheader.png";
        return getHeaderURL() === headerUrl;
    },

    getAppFooterURL = function () {
        return this.getAppObjectBasedOnAlias(getAlias()).appHeaderUrl;
    },

    getFooterURL = function () {
        return this.appFooterURL.value;
    },

    setFooterURL = function (url) {
        this.appFooterURL.value = url;
    },

    defaultReportHeaderURL = function () {
        if ((this.defReportHeaderURL === '' || this.defReportHeaderURL === undefined) && (this.cdnAddress !== undefined && this.cdnAddress !== '')) {
            return this.cdnAddress + "img/app/logos/truckscience/reportheader.jpg";
        }
        return this.defReportHeaderURL;
    },

    defaultReportFooterURL = function () {
        if ((this.defReportFooterURL === '' || this.defReportFooterURL === undefined) && (this.cdnAddress !== undefined && this.cdnAddress !== '')) {
            return this.cdnAddress + "img/app/logos/truckscience/reportfooter.jpg";
        }
        return this.defReportFooterURL;
    },

    setDefaultReportHeaderURL = function (url) {
        this.defReportHeaderURL = url;
    },

    setDefaultReportFooterURL = function (url) {
        this.defReportFooterURL = url;
    },

    getTrialUserReportLogoURL = function () {
        return this.cdnAddress + "img/logos/trial/your_logo1.jpg";
    },

    getAlias = function () {

        //determine the domain
        var domain = window.location.hostname.split('.');

        if (domain.length === 1 || domain.length === 3) {
            return domain[0];
        } else {

            var alias = '';

            for (var k = 0; k < domain.length - 2; k++) {
                if (k !== 0) {
                    alias = alias + '.';
                }
                alias = alias + domain[k];
            }

            return alias;
        }
    },

    getAppObjectBasedOnAlias = function () {
        var returnObject = {};
        //returnObject.appName = "Axle Weight Calculator";
        //returnObject.appName = "TruckScience | Axle Weight Calculator & Sales Tool";
        returnObject.appName = "TruckScience";
        returnObject.appNameAndVersion = "TruckScience | Axle Weight Calculator & Sales Tool";
        returnObject.appHeaderUrl = this.cdnAddress + "img/app/logos/truckscience/appheader.png";
        returnObject.appFooterUrl = this.cdnAddress + "img/app/logos/truckscience/appfooter.png";
        returnObject.isOemUrl = false;

        // Any other processing
        returnObject.appNameAndVersion += ' v' + this.appVersionNumber;

        return returnObject;
    },

    getCloudServicesUrl = function () {

        //remove the v1 from the end of the cloud services url if it exists, as we are no longer including the version into the config
        var suffix = 'v1/'
        var str = config.cloudServicesAddress
        var index = str.indexOf('v1/', str.length - suffix.length)

        if (index !== -1) {
            return str.substring(0, str.length - suffix.length)
        } else {
            return config.cloudServicesAddress
        }

    },

    getAzureFunctionsUrl = function () {
        return config.azureFunctionsServer;
    },

    setAzureFunctionsUrl = function (url) {
        config.azureFunctionsServer = url;
    },
    

    fillColour = function (name) {
        switch (name) {
            case 'CHASSIS':
                //rgb(0, 0, 0)
                return '#000000';
            case 'CHASSIS_DETAIL_OFFBLACK':
                //rgb(36, 36, 36)
                return '#242424';
            case 'CHASSIS_DETAIL_DARKGREY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'CHASSIS_DETAIL_LIGHTGREY':
                //rgb(182, 182, 182)
                return '#B6B6B6';
            case 'CAB':
                //rgb(34,139,34)
                return '#228B22';
            case 'CAB_DETAIL_BLACK':
                //rgb(0, 0, 0)
                return '#000000';
            case 'CAB_DETAIL_OFFBLACK':
                //rgb(36, 36, 36)
                return '#242424';
            case 'CAB_DETAIL_DARKGREY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'CAB_DETAIL_LIGHTGREY':
                //rgb(182, 182, 182)
                return '#B6B6B6';
            case 'LINE':
                //rgb(0, 0, 0)
                return '#000000';
            case 'LINE_DASHED':
                //rgb(0, 0, 0)
                return '#000000';
            case 'LINE_DASHED_DARKGREY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'WINDOW_BLUE':
                //rgb(109, 218, 218)
                return '#6DDADA';
            case 'WINDOW_LIGHT_BLUE':
                //rgb(172, 233, 233)
                return '#ACE9E9';
            case 'LIGHTS_ORANGE':
                //rgb(255, 109, 0)
                return '#FF6D00';
            case 'LIGHTS_YELLOW':
                //rgb(255, 225, 127)
                return '#FFFF7F';
            case 'LIGHTS':
                //rgb(218, 218, 182)
                return '#DADAB6';
            case 'BODY':
                //rgb(182, 182, 182)
                return '#B6B6B6';
            case 'BODY_DETAIL_BLACK':
                //rgb(0, 0, 0)
                return '#000000';
            case 'BODY_DETAIL_OFFBLACK':
                //rgb(36, 36, 36)
                return '#242424';
            case 'BODY_DETAIL_DARKGREY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'BODY_DETAIL_LIGHTGREY':
                //rgb(182, 182, 182)
                return '#B6B6B6';
            case 'ACCESSORY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'ACCESSORY_DETAIL_BLACK':
                //rgb(0, 0, 0)
                return '#000000';
            case 'ACCESSORY_DETAIL_OFFBLACK':
                //rgb(36, 36, 36)
                return '#242424';
            case 'ACCESSORY_DETAIL_DARKGREY':
                //rgb(72, 72, 72)
                return '#484848';
            case 'ACCESSORY_DETAIL_LIGHTGREY':
                //rgb(182, 182, 182)
                return '#B6B6B6';
            default:
                //rgb(255, 255, 255)
                return '#FFFFFF';
        }
    },

    setupPolyfills = function () {
        if (!String.prototype.includes) {
            String.prototype.includes = function (search, start) {
                'use strict';
                if (typeof start !== 'number') {
                    start = 0;
                }

                if (start + search.length > this.length) {
                    return false;
                } else {
                    return this.indexOf(search, start) !== -1;
                }
            };
        }
        if (!Array.prototype.findIndex) {
            Object.defineProperty(Array.prototype, 'findIndex', {
                value: function (predicate) {
                    'use strict';
                    if (this == null) {
                        throw new TypeError('Array.prototype.findIndex called on null or undefined');
                    }
                    if (typeof predicate !== 'function') {
                        throw new TypeError('predicate must be a function');
                    }
                    var list = Object(this);
                    var length = list.length >>> 0;
                    var thisArg = arguments[1];
                    var value;

                    for (var i = 0; i < length; i++) {
                        value = list[i];
                        if (predicate.call(thisArg, value, i, list)) {
                            return i;
                        }
                    }
                    return -1;
                },
                enumerable: false,
                configurable: false,
                writable: false
            });
        }
        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Number/isNaN
        // The following works because NaN is the only value in javascript which is not equal to itself.
        Number.isNaN = Number.isNaN || function (value) {
            return value !== value;
        }
        if (!Array.isArray) {
            Array.isArray = function (arg) {
                return Object.prototype.toString.call(arg) === '[object Array]';
            };
        }

        // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/from
        // Production steps of ECMA-262, Edition 6, 22.1.2.1
        if (!Array.from) {
            Array.from = (function () {
                var toStr = Object.prototype.toString;
                var isCallable = function (fn) {
                    return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
                };
                var toInteger = function (value) {
                    var number = Number(value);
                    if (isNaN(number)) { return 0; }
                    if (number === 0 || !isFinite(number)) { return number; }
                    return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
                };
                var maxSafeInteger = Math.pow(2, 53) - 1;
                var toLength = function (value) {
                    var len = toInteger(value);
                    return Math.min(Math.max(len, 0), maxSafeInteger);
                };

                // The length property of the from method is 1.
                return function from(arrayLike/*, mapFn, thisArg */) {
                    // 1. Let C be the this value.
                    var C = this;

                    // 2. Let items be ToObject(arrayLike).
                    var items = Object(arrayLike);

                    // 3. ReturnIfAbrupt(items).
                    if (arrayLike == null) {
                        throw new TypeError('Array.from requires an array-like object - not null or undefined');
                    }

                    // 4. If mapfn is undefined, then let mapping be false.
                    var mapFn = arguments.length > 1 ? arguments[1] : void undefined;
                    var T;
                    if (typeof mapFn !== 'undefined') {
                        // 5. else
                        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
                        if (!isCallable(mapFn)) {
                            throw new TypeError('Array.from: when provided, the second argument must be a function');
                        }

                        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
                        if (arguments.length > 2) {
                            T = arguments[2];
                        }
                    }

                    // 10. Let lenValue be Get(items, "length").
                    // 11. Let len be ToLength(lenValue).
                    var len = toLength(items.length);

                    // 13. If IsConstructor(C) is true, then
                    // 13. a. Let A be the result of calling the [[Construct]] internal method 
                    // of C with an argument list containing the single item len.
                    // 14. a. Else, Let A be ArrayCreate(len).
                    var A = isCallable(C) ? Object(new C(len)) : new Array(len);

                    // 16. Let k be 0.
                    var k = 0;
                    // 17. Repeat, while k < len… (also steps a - h)
                    var kValue;
                    while (k < len) {
                        kValue = items[k];
                        if (mapFn) {
                            A[k] = typeof T === 'undefined' ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
                        } else {
                            A[k] = kValue;
                        }
                        k += 1;
                    }
                    // 18. Let putStatus be Put(A, "length", len, true).
                    A.length = len;
                    // 20. Return A.
                    return A;
                };
            }());
        }
    },

    // API_NAMES = {
    //     INTERCOM: 'INTERCOM',
    //     TRUCKSCIENCE: 'TRUCKSCIENCE'
    // },

    OPERATION = {
        GetSelectionList: 'getSelectionList',
        GetNewOfferDetails: 'getNewOfferDetails',
        ClearPartialNewOfferDetails: 'clearPartialNewOfferDetails',
        GetExistingOfferDetails: 'getExistingOfferDetails',
        GetOverviewContent: 'getOverviewContent',
        GetNewOfferSpecificationDetails: 'getNewOfferSpecificationDetails',
        GetExistingOfferSpecificationDetails: 'getExistingOfferSpecificationDetails',
        GetSalesPeople: 'getSalesPeople',
        SaveOffer: {
            Code: 'saveoffer',
            BaseUrl: 'offers/saveoffer'
        },
        UpdateOfferStatus: {
            Code: 'updateOfferStatus',
            BaseUrl: 'offers/offerstatus'
        },
        DeleteOffer: {
            Code: 'deleteOffer',
            BaseUrl: 'offers/delete'
        },
        GetCustomers: 'getCustomers',
        SaveCustomer: {
            Code: 'saveCustomer',
            BaseUrl: 'customers/post'
        },
        DeleteCustomer: {
            Code: 'deleteCustomer',
            BaseUrl: 'customers/delete'
        },
        GetCustomer: 'getCustomer',
        Customer: 'customer',
        SalesPerson: 'salesperson',
        LatestVersions: 'latestVersions',
        LicenceLease: 'licenceLease',
        SaveVehicle: 'saveVehicle',
        GetVehicle: 'getVehicle',
        ConfigDrawing: 'configDrawing',
        SyncQueue: 'syncQueue',
        SaveCustomerNote: {
            Code: 'saveCustomerNote',
            BaseUrl: 'notes/post'
        },
        DeleteCustomerNote: {
            Code: 'deleteCustomerNote',
            BaseUrl: 'notes/delete'
        },
        SaveCustomerAction: {
            Code: 'saveCustomerAction',
            BaseUrl: 'actions/post'
        },
        DeleteCustomerAction: {
            Code: 'deleteCustomerAction',
            BaseUrl: 'actions/delete'
        },
        Sync: 'sync',
        GetTranslations: 'getTranslations',
        GetCommonPackDetails: 'getCommonPackDetails',
        GetLegislationList: 'getLegislationList',
        GetLegislationDetails: 'getLegislationDetails',
        GetOnRoadCosts: 'getOnRoadCosts',
        GetSalesPersonInfo: 'getSalesPersonInfo',
        LogDebugMessage: 'logDebugMessage',
        GetDebugMessage: 'GetDebugMessage',
        PostDebugInfo: {
            Code: 'postDebugInfo',
            BaseUrl: 'internals/debug'
        },
        CheckIsOnline: {
            Code: 'checkIsOnline',
            BaseUrl: 'internals/status'
        },
        PostUsageInfo: {
            Code: 'PostUsageInfo',
            BaseUrl: 'internals/usage'
        },
        LastDebugLogUpload: 'lastDebugLogUpload',
        LastUsageLogUpload: 'lastUsageLogUpload',
        DeleteDebugLogs: 'deleteDebugLogs',
        GetAppConfig: {
            Code: 'getAppConfig',
            BaseUrl: 'appconfig/get'
        },
        DeleteUsageLogs: 'deleteUsageLogs',
        GetUserSettings: 'GetUserSettings',
        SaveUserSettings: {
            Code: 'saveUserSettings',
            BaseUrl: 'usersettings/post'
        },
        LogAppUsage: 'logAppUsage',
        GetVehiclePackData: 'getVehiclePackData',
        GetBodies: 'getBodies',
        GetBody: 'getBody',
        GetDataRequest: 'GetDataRequest',
        PostDataRequest: 'PostDataRequest',
        GetCranes: 'getCranes',
        GetCrane: 'getCrane',
        GetAccessories: 'getAccessories',
        SaveCustomAccessory: {
            Code: 'saveCustomAccessory',
            BaseUrl: 'accessories/post'
        },
        SaveBody: 'saveBody',
        RemoveLocalBody: 'removeLocalBody',
        SaveAccessory: 'saveAccessory',
        RemoveLocalAccessory: 'removeLocalAccessory',
        DeleteCustomAccessory: {
            Code: 'deleteCustomAccessory',
            BaseUrl: 'accessories/delete'
        },
        GetBodyStubs: 'getBodyStubs',
        GetAccessoryStubs: 'getAccessoryStubs',
        GetAccessory: 'getAccessory',
        NumVehicleOpens: 'numVehicleOpens',
        CheckEmailVerificationStatus: 'checkEmailVerificationStatus',
        VerifyCode: 'verifyCode',
        SignUp: 'signUp',
        ResendVerificationEmail: 'resendVerificationEmail',
        SendFeedback: 'sendFeedback',
        SendReferral: 'sendReferral',
        SendReferralPromptNotification: 'SendReferralPromptNotification',
        RequestTrialExtension: 'requestTrialExtension',
        RequestSalesTool: 'requestSalesTool',
        RequestUpgrade: 'requestUpgrade',
        RegisterBuyNowClick: 'registerBuyNowClick',
        BuyNow: 'buyNow',
        GetPortalURL: 'getPortalURL',
        GetUpdatePaymentMethodURL: 'getUpdatePaymentMethodURL',
        PostEmailOffer: 'postEmailOffer',
        PostLoginDebug: 'postLoginDebug',
        PostAppOptionItem: {
            Code: 'postAppOptionItem',
            BaseUrl: 'users/appoptionitem'
        },
        SendIntercomEventData: {
            Code: 'sendIntercomEventData',
            BaseUrl: ''
        },
        SendIntercomUserData: {
            Code: 'sendIntercomUserData',
            BaseUrl: ''
        },
        ChangePassword: {
            Code: 'changePassword',
            BaseUrl: 'users/changepassword',
            AllowOffline: false
        },
        GetTrailerStubs: 'getTrailerStubs',
        GetTrailer: 'getTrailer',
        GetTrailers: 'getTrailers',
        SaveTrailer: 'saveTrailer',
        RemoveLocalTrailer: 'removeLocalTrailer',
        HeadCheckIsOnline: 'headCheckIsOnline',
        GetPayloadStubs: 'getPayloadStubs',
        GetPayload: 'getPayload',
        GetPayloads: 'getPayloads',
        SavePayload: 'savePayload',
        RemoveLocalPayload: 'removeLocalPayload',
        PostScreenSizeDebug: 'postScreenSizeDebug',
        LastUpdateCheck: 'lastUpdateCheck',
        PostCSVData: {
            Code: 'postCSVData',
            BaseUrl: 'uploads/post',
            AllowOffline: false
        },
        PostDXFData: {
            Code: 'postDXFData',
            BaseUrl: 'ConvertGraphicFile',
            AllowOffline: false
        },
        UpdatePendingVehicle: {
            Code: 'updatePendingVehicle',
            BaseUrl: 'appvehicles/updatevehicle'
        },
        UpdateAndSubmitPendingVehicle: {
            Code: 'updateAndSubmitPendingVehicle',
            BaseUrl: 'appvehicles/updatevehicle'
        },
        ShareOffer: {
            Code: 'shareOffer',
            BaseUrl: 'offers/shareOffer'
        },
        GetUserAssociations: 'getUserAssociations',
        GetShareeUserOfferDetails: {
            Code: 'getShareeUserOfferDetails',
            BaseUrl: 'offers/shareeUserOfferDetails',
        },
        DeleteUserOfferShare: {
            Code: 'deleteUserOfferShare',
            BaseUrl: 'offers/removeUserOfferShare'
        },
        GetSharedOfferStubs: {
            Code: 'getSharedOfferStubs',
            BaseUrl: 'offers/sharedOffers'
        },
        GetSavedOfferStubs: {
            Code: 'getSavedOfferStubs',
            BaseUrl: 'offers/savedOffers'
        },
        GetSharedOfferDetails: {
            Code: 'getSharedOfferDetails',
            BaseUrl: 'offers/existingoffer'
        },
        SaveSharedOfferStub: 'saveSharedOfferStub',
        GetOfferShareStatus: {
            Code: 'getOfferShareStatus',
            BaseUrl: 'offers/offerShareStatus'
        },
        GetSharedOfferStub: 'getSharedOfferStub',
        DownloadVehicleCsv: 'downloadVehicleCsv',
        UpdateShareeUserDetails: {
            Code: 'updateShareeUserDetails',
            BaseUrl: 'users/updateUser'
        },
        UpdateOfferShareStatus: {
            Code: 'updateOfferShareStatus',
            BaseUrl: 'offers/updateOfferShareStatus'
        },
        GetLastSharedOfferSyncTimestamp: 'getLastSharedOfferSyncTimestamp',
        ValidateNTEAUser: 'validateNteaUser',
        GetTeamSyncItems: {
            Code: 'getTeamSyncItems',
            BaseUrl: 'accessories/teamsyncitems'
        },
        GetLastTeamItemsSyncTimestamp: 'getLastTeamItemsSyncTimestamp',
        GetBodyMasses: {
            Code: 'getBodyMasses',
            BaseUrl: 'accessories/bodymasses'
        },
        GetLicenceCategories: {
            Code: 'getLicenceCategories',
            BaseUrl: 'commonpackdetails/licencecategoryinfo'
        },
        GetFolderContents: {
            Code: 'getFolderContents',
            BaseUrl: 'offers/foldercontents'
        },
        GetSearchResults: {
            Code: 'getSearchResults',
            BaseUrl: 'offers/search'
        },
        GetFolderTreeStructure: {
            Code: 'getFolderTreeStructure',
            BaseUrl: 'folders/foldertreestructure'
        },
        CreateFolder: {
            Code: 'createFolder',
            BaseUrl: 'folders/createfolder'
        },
        MoveTo: {
            Code: 'moveTo',
            BaseUrl: 'folders/moveto'
        },
        GetSharers: {
            Code: 'getSharers',
            BaseUrl: 'offers/sharers'
        },
        Rename: {
            Code: 'rename',
            BaseUrl: 'offers/rename'
        },
        Remove: {
            Code: 'remove',
            BaseUrl: 'offers/remove'
        },
        GetResources: {
            Code: 'getResources',
            BaseUrl: 'resources/resources',
        },
        PostTransSolveData: {
            Code: 'postTransSolveData',
            BaseUrl: 'offers/transsolve',
            AllowOffline: false
        },
        VerifyTransferLicenceCode: {
            Code: 'verifyTransferLicenceCode',
            BaseUrl: 'verificationcode/transferlicenceverificationstatus'
        },
        ResendTransferLicenceVerificationEmail: {
            Code: 'resendTransferLicenceVerificationEmail',
            BaseUrl: 'verificationcode/resendtransferlicenceverificationemail'
        },
        UpdateUserAfterCheckout: {
            Code: 'updateUserAfterCheckout',
            BaseUrl: 'users/updateUserAfterCheckout'
        },
        ValidateAccessToken: {
            Code: 'validateaccesstoken',
            BaseUrl: 'externalauthentication/validateaccesstoken'
        }
    },

    
    PERMISSIONS = {
        CONFIGURATION: {
            Code: 10003,
            Default: 'Y',
            Route: 'CONFIGURATION'
        },
        PERFORMANCE: {
            Code: 10004,
            Default: 'Y',
            Route: 'PERFORMANCE'
        },
        COSTING: {
            Code: 10005,
            Default: 'Y',
            Route: 'COSTING'
        },
        SPECIFICATION: {
            Code: 10006,
            Default: 'Y',
            Route: 'SPECIFICATION'
        },
        GENERAL: {
            Code: 10008,
            Default: 'Y'
        },
        BATCH: {
            Code: 10009,
            Default: 'Y'
        },
        CONFIGURATION_MENU: {
            Code: 10013,
            Default: 'Y'
        },
        CONFIGURATION_MENU_DISPLAY: {
            Code: 10014,
            Default: 'N'
        },
        CONFIGURATION_MENU_ELEVATION: {
            Code: 10015,
            Default: 'N'
        },
        CONFIGURATION_MENU_VIEWTYPE: {
            Code: 10016,
            Default: 'N'
        },
        CONFIGURATION_MENU_VIEWDETAIL: {
            Code: 10017,
            Default: 'N'
        },
        CONFIGURATION_ADMIN_MASSES_DIMENSIONS: {
            Code: 10020,
            Default: 'N'
        },
        CONFIGURATION_MENU_DISPLAY_DETAILED: {
            Code: 10022,
            Default: 'N'
        },
        GENERAL_REQUEST_DATA: {
            Code: 10025,
            Default: 'N'
        },
        PERFORMANCE_ADMIN_DRIVE_AXLE: {
            Code: 10026,
            Default: 'N'
        },
        CONFIGURATION_ADMIN_ADD_AXLES: {
            Code: 10027,
            Default: 'N'
        },
        GENERAL_UPLOAD_CSV_FILE: {
            Code: 10028,
            Default: 'N'
        },
        SELECTION_PROTOTYPE_VEHICLES: {
            Code: 10029,
            Default: 'N'
        },
        GENERAL_HIDE_BUY_NOW: {
            Code: 10030,
            Default: 'N'
        },
        GENERAL_OPEN_TSV_FILES: {
            Code: 10031,
            Default: 'N'
        },
        SETTINGS_EDIT_COMPANY_DETAILS: {
            Code: 10032,
            Default: 'N'
        },
        SETTINGS_FAVOURITES: {
            Code: 10033,
            Default: 'N'
        },
        SETTINGS_INTERNAL_STANDARDS: {
            Code: 10034,
            Default: 'N'
        },
        SETTINGS_REGULATIONS: {
            Code: 10035,
            Default: 'N'
        },                
        USER_MENU_MANAGE_ACCOUNT: {
            Code: 10036,
            Default: 'N'
        },
        CONFIGURATION_TURNING_CIRCLE: {
            Code: 10037,
            Default: 'N'
        },
        GENERAL_IMPORT_DXF: {
            Code: 10038,
            Default: 'N'
        },
        GENERAL_SHARE_CALCULATIONS_EDIT: {
            Code: 10039,
            Default: 'N'
        },
        GENERAL_TEAM_LIBRARY: {
            Code: 10040,
            Default: 'N'
        },
        GENERAL_PUBLISH_TO_DISTRIBUTOR: {
            Code: 10041,
            Default: 'N'
        },
        SETTINGS_REPORT_VIEW_LAYOUT: {
            Code: 10042,
            Default: 'N'
        },
        SUMMARY_EMAIL_REPORT: {
            Code: 10043,
            Default: 'N'
        },
        GENERAL_CHANGE_PASSWORD: {
            Code: 10044,
            Default: 'N'
        },
        GENERAL_LOGOUT: {
            Code: 10045,
            Default: 'N'
        },
        GENERAL_SHARE_CALCULATIONS: {
            Code: 10046,
            Default: 'N'
        },
        SUMMARY_ADD_PREPARED_FOR_DETAILS: {
            Code: 10047,
            Default: 'N'
        }
    },

    LOGGING_LEVEL = {
        NONE: 'NONE',
        ERRORS_ONLY: 'ERRORSONLY',
        DETAILED: 'DETAILED'
    },
    LOG_MESSAGE_TYPE = {
        ERROR: 'ERROR',
        INFO: 'INFO',
        WARNING: 'WARNING'
    },

    SIGNALR_MESSAGE_TYPE = {
        SHARED_CALCULATION: 'SHAREDCALCULATION',
        SHARED_EQUIPMENT: 'SHAREDEQUIPMENT',
        UNSHARED_CALCULATION: 'UNSHAREDCALCULATION',
        UNSHARED_EQUIPMENT: 'UNSHAREDEQUIPMENT',
        CHANGED_SHARED_CALCULATION: 'CHANGEDSHAREDCALCULATION',
        SHARE_REMOVED: 'SHAREREMOVED',
        LICENCE_TRANSFERRED: "LICENCETRANSFERRED",
    },

    CONTEXT_ERROR_CODES = {
        GEN0001A: 'GEN0001A',
        GEN0001B: 'GEN0001B',
        GEN0001C: 'GEN0001C',
        GEN0001D: 'GEN0001D',
        GEN0001E: 'GEN0001E',
        GEN0001F: 'GEN0001F'
    },

    USAGE_TYPE = {
        ONLINE: 'online',
        OFFLINE: 'offline',
        PERFORMANCE_OPENED: 'performanceOpened',
        PERFORMANCE_USED: 'performanceUsed',
        COSTING_OPENED: 'costingOpened',
        COSTING_USED: 'costingUsed',
        SPECIFICATION_OPENED: 'specificationOpened',
        SPECIFICATION_USED: 'specificationUsed',
        SUMMARY_OPENED: 'summaryOpened',
        SUMMARY_USED: 'summaryUsed',
        COMPANY_OVERVIEW_OPENED: 'companyOverviewOpened',
        COMPANY_OVERVIEW_USED: 'companyOverviewUsed',
        OFFERS_OPENED: 'offersOpened',
        OFFERS_USED: 'offersUsed',
        BROCHURE_OPENED: 'brochureOpened',
        BROCHURE_USED: 'brochureUsed',
        TRAINING_OPENED: 'trainingOpened',
        TRAINING_USED: 'trainingUsed',
        CONFIGURATION_OPENED: 'configurationOpened',
        CONFIGURATION_USED: 'configurationUsed'
    },

    //metric system ids
    MEASUREMENT_SYSTEM = {
        METRIC: 1,
        IMPERIALUK: 2,
        IMPERIALUS: 3
    },

    VEHICLE_APPLICATION_TYPES = {
        ONROAD: 0,
        ONANDOFFROAD: 1,
        OFFROADONLY: 2,
        ABNORMALPERMIT: 3,
        HIGHERMASSLIMIT: 4,
        CONCESSIONALMASSLIMIT: 5
    },

    SPECIFY_CAB_DIMENSIONS_AS_OPTIONS = {
        BBC: 'BBC',
        AC: 'AC'
    },

    SPECIFY_CHASSIS_LENGTH_AS_OPTIONS = {
        WHEELBASE: 'WHEELBASE',
        CA: 'CA'
    },

    SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS = {
        VOLUME_PER_DISTANCE: 'VOLUME_PER_DISTANCE',
        DISTANCE_PER_VOLUME: 'DISTANCE_PER_VOLUME'
    },

    SPECIFY_AXLE_RATING_AS_OPTIONS = {
        DETAILED: 'DETAILED',
        SIMPLIFIED: 'SIMPLIFIED'
    },

    VEHICLE_TYPES = {
        COMPLETE_VEHICLE: 'COMPLETEVEHICLE',
        RIGID: 'RIGID',
        TRUCK_TRACTOR: 'TRUCKTRACTOR'
    },

    VEHICLE_SOURCE_TYPES = {
        STANDARD: 'STANDARD',
        TEMPLATE: 'TEMPLATE',
        CUSTOM: 'CUSTOM',
        ALL: 'ALL'
    },

    VEHICLE_INCLUSION_TYPES = {
        YES: 'YES',
        NO: 'NO',
        NOT_SPECIFIED: 'NOTSPECIFIED'
    },

    VEHICLE_STATUS_TYPES = {
        CURRENT: 'CURRENT',
        DISCONTINUED: 'DISCONTINUED',
        PENDING: 'PENDING',
        VALIDATED: 'VALIDATED',
        PROTOTYPE: 'PROTOTYPE',
        SPECIAL: 'SPECIAL'
    },

    ACCESSORY_STATUS_TYPES = {
        CURRENT: 'CURRENT',
        DISCONTINUED: 'DISCONTINUED',
        PENDING: 'PENDING',
        VALIDATED: 'VALIDATED',
        PROTOTYPE: 'PROTOTYPE',
        SPECIAL: 'SPECIAL'
    },

    ACCESSORY_SOURCE_TYPES = {
        STANDARD: 'STANDARD',
        TEMPLATE: 'TEMPLATE',
        CUSTOM: 'CUSTOM',
        ALL: 'ALL'
    },

    CONTROL_TYPES = {
        FORWARD: 'FORWARD',
        BUS: 'BUS',
        SEMI_FORWARD: 'SEMI-FORWARD',
        NORMAL: 'NORMAL',
        COACH: 'COACH'
    },

    // Cloud Services - Models.V1.AccessoryType
    ACCESSORY_TYPES = {
        BODY: 'Body',
        CRANE: 'Crane',
        CREW_CAB: 'CrewCab',
        FACTORY_FITTED_FUEL_TANK: 'FactoryFittedFuelTank',
        FUEL_TANK: 'FuelTank',
        FIFTH_WHEEL: 'FifthWheel',
        HITCH: 'Hitch',
        BULLBAR: 'Bullbar',
        ROLLOVER: 'Rollover',
        FAIRING: 'Fairing',
        TAILLIFT: 'Taillift',
        FRIDGE: 'Fridge',
        HEADBOARD: 'Headboard',
        OTHER: 'Other',
        TRAILER: 'Trailer',
        PAYLOAD: 'Payload',
        REAR_BUMPER: 'Rearbumper',
        TOOLBOX: 'Toolbox',
        AEROKIT: 'Aerokit',
        SNOWPLOW: 'Snowplow',
        AERIAL_PLATFORM: 'Aerialplatform',
        OUTRIGGER: 'Outrigger',
        HOOKLIFT: 'Hooklift'
    },

    ACCESSORY_SUB_TYPES = {
        CONVENTIONAL: 'CONVENTIONAL',
        UNDERSLUNG: 'UNDERSLUNG'
    },

    CHASSIS_OBJECT_TYPES = {
        VEHICLE: 'VEHICLE',
        TRAILER1: 'TRAILER1',
        TRAILER2: 'TRAILER2'
    },

    TAILLIFT_TYPES = {
        CANTILEVER: 'CANTILEVER',
        COLUMN_UPPER_BOX: 'COLUMNUPPERBOX',
        COLUMN_LOWER_BOX: 'COLUMNLOWERBOX',
        SLIDER: 'SLIDER',
        TUCKAWAY: 'TUCKAWAY'
    },

    HITCH_TYPES = {
        CONVENTIONAL: 'CONVENTIONAL',
        UNDERSLUNG: 'UNDERSLUNG'
    },

    OBJECT_TYPES = {
        PAYLOAD: 'PAYLOAD',
        AXLES: 'AXLES',
        FUEL_TANKS: 'FUEL_TANKS',
        CREW_FIRST: 'CREW_FIRST',
        CREW_SECOND: 'CREW_SECOND',
        ACCESSORY: 'ACCESSORY',
        UPRIGHTS: 'UPRIGHTS',
        BODY: 'BODY',
        SETTINGS: 'SETTINGS',
        WAYPOINT: 'WAYPOINT',
        PERFORMANCE: 'PERFORMANCE',
        TURNING_CIRCLE: 'TURNINGCIRCLE',
        WEIGHTS_TABLE: 'WEIGHTSTABLE',
        COMPLIANCE_DASHBOARD: 'COMPLIANCEDASHBOARD',
        REPORT_OPTIONS: 'REPORTOPTIONS',
        COSTING: 'COSTING',
        RIG: 'RIG',
        AXLE_GROUPS: 'AXLE_GROUPS',
        AXLES_TOTAL: 'AXLES_TOTAL',
        AXLES_REAR_TOTAL: 'AXLES_REAR_TOTAL',
        AXLES_TRAILER_FRONT: 'AXLES_TRAILER_FRONT',
        INTERNAL_STANDARD: 'INTERNAL_STANDARD',
        SAFETY: 'SAFETY'
    },

    SUMMARY_REPORT_OPTIONS = {
        CONFIGURATION: 'CONFIGURATION',
        SPECIFICATION: 'SPECIFICATION',
        COSTING: 'COSTING',
        PERFORMANCE: 'PERFORMANCE'
    },

    SUMMARY_REPORT_OFFER_OPTIONS = {
        DRAWING_VIEW: 'DRAWINGVIEW',
        LOAD_PLAN: 'LOADPLAN',
        WEIGHT_AND_COG: 'WEIGHTANDCOG',
    },

    BODY_TYPES = {
        COMPACTOR: 'COMPACTOR',
        DROPSIDE: 'DROPSIDE',
        FLATDECK: 'FLATDECK',
        TAUTLINER: 'TAUTLINER',
        TANKER: 'TANKER',
        TIPPER: 'TIPPER',
        VAN: 'VAN',
        ROLLBACK: 'ROLLBACK',
        TIMBER: 'TIMBER',
        LIVESTOCKCARRIER: "LIVESTOCKCARRIER",
        SHEEPCARRIER: "SHEEPCARRIER",
        PIGCARRIER: "PIGCARRIER",
        CATTLECARRIER: "CATTLECARRIER",
        LIVESTOCK: "LIVESTOCK",
        BEAVERTAIL: "BEAVERTAIL",
        HOOKLIFT: "HOOKLIFT",
        OTHER: "OTHER",
        SERVICE: "SERVICE",
        NONE: "NONE"
    },

    BODY_SUB_TYPES = {
        WITH_CAB_PROTECTOR: 'WITHCABPROTECTOR',
    WITHOUT_CAB_PROTECTOR: 'WITHOUTCABPROTECTOR',
    BODY_ONLY: 'BODYONLY'
    },

    TRAILER_TYPES = {
        SEMI: 'SEMI',
        INTERLINK: 'INTERLINK',
        PUP: 'PUP',
        DRAWBAR: 'DRAWBAR'
    },

    TRAILER_BODY_TYPES = {
        BULKER: "BULKER",
        CANE: "CANE",
        CATTLECARRIER: "CATTLECARRIER",
        DOLLY: "DOLLY",
        DROPSIDE: 'DROPSIDE',
        FLATDECK: 'FLATDECK',
        LOWBED: 'LOWBED',
        NONE: "NONE",
        REEFER: 'REEFER',
        SKELETAL: "SKELETAL",
        TANKER: 'TANKER',
        TAUTLINER: 'TAUTLINER',
        TIMBER: 'TIMBER',
        TIPPER: 'TIPPER',
        VAN: 'VAN',
        WALKINGFLOOR: 'WALKINGFLOOR'
    },

    TRAILER_TARE_TYPES = {
        AUTO_CALCULATED: "AUTOCALC",
        SPECIFY_CHASSIS_SPLITS: "SPECIFYSPLITS",
        SPECIFY_COMPLETE_TRAILER_SPLITS: "SPECIFYCOMPSPLITS"
    },

    DRAWBAR_TYPES = {
        CONVENTIONAL: 'CONVENTIONAL',
        UNDERSLUNG: 'UNDERSLUNG'
    },

    COMBINATION_TYPES = {
        VEHICLE: 'vehicle',
        VEHICLE_AND_DRAWBAR: 'vehicleAndDrawbar',
        VEHICLE_AND_SEMI: 'vehicleAndSemi',
        VEHICLE_AND_PUP: 'vehicleAndPup',
        VEHICLE_AND_INTERLINK_AND_SEMI: 'vehicleAndInterlinkAndSemi',
        VEHICLE_AND_SEMI_AND_DRAWBAR: 'vehicleAndSemiAndDrawbar',
        VEHICLE_AND_SEMI_AND_PUP: 'vehicleAndSemiAndPup',
        UNKNOWN: 'unknown'
    },

    SIDE_DOOR_TYPES = {
        //SINGLE: 0,
        //DOUBLE: 1
        SINGLE: 'SINGLE',
        DOUBLE: 'DOUBLE'
    },

    BODY_MEASUREMENT_TYPES = {
        EXTERNAL: 'EXTERNAL',
        INTERNAL: 'INTERNAL'
    },

    COG_TYPES = {
        LENGTH: 'MILLIMETRE',
        PERCENTAGE: 'PERCENTAGE'
    },

    MASS_TYPES = {
        MASS: 'KILOGRAM',
        MASSPERDISTANCE: 'KILOGRAMPERMETRE'
    },

    PAYLOAD_TYPES = {
        SIMPLE: 'SIMPLE',
        DETAILED: 'DETAILED'
    },

    PAYLOAD_WEIGHT_TYPES = {
        WEIGHT: 'WEIGHT',
        VOLUME: 'VOLUME'
    },

    USER_TYPES = {
        UNKNOWN: 'Unknown',
        FULL_USER_FORMER: 'FullUserFormer',
        FULL_USER_OK: 'FullUserOK',
        FULL_USER_ARREARS: 'FullUserArrears',
        FULL_USER_CANCELLED: 'FullUserCancelled',
        FULL: 'Full',
        TRIAL: 'Trial',
        TRIAL_USER_OK: 'TrialUserOK',
        TRIAL_USER_FORMER: 'TrialUserFormer',
        TRIAL_USER_VIP_OK: 'TrialUserVipOK'
    },

    USER_STATUS = {
        ACCOUNT_IN_ARREARS: 'ACOCUNT IN ARREARS',
        ACCOUNT_CANCELLED: 'ACCOUNT CANCELLED',
        ACTIVE: 'ACTIVE'
    },

    WAYPOINT_PUSHPIN_COLOURS = {
        START: '#149D49',
        END: '#EE4036',
        VIA: '#2167FF'
    },

    DRAWING_COLOURS = {
        BLUE: 0x4990E2,
        //RED: 0xDB2234,
        RED: 0xC43333,
        //GREEN: 0xB8CB3B,
        GREEN: 0x85B200,
        //ORANGE: 0xE78518,
        ORANGE: 0xEF8033,
        ORANGE_STRING: '#EF8033',
        WHITE: 0xFFFFFF,
        BLACK: 0x000000,
        TABLE_CELL: 0xF6F7FB,
        COLUMN_TITLE: 0x444444,
        MOUSE_OVER: 0x1f69c0,//0x1169c0
        CLICKED: 0x251fc0,//0x14457e////0xe29b49
        CLICKABLE: 0x4990e2,
        MOUSE_OVER_ALT: 0xffffff,
        CLICKED_ALT: 0xd8d8d8,
        CLICKABLE_ALT: 0xe5e5e5,
        PAYLOAD_LINE_COLOUR: 0x939393,//0xbdbdbd//0xD3D3D3
        DARK_GREY: 0x9797a6,
        ALT_TABLE_CELL_GREY: 0xebeef6,
        ALT_TABLE_CELL_TEXT: 0x666666,
        LIGHT_GREEN: 0xa2d900,
        LIGHT_RED: 0xd14d4d,
        LIGHT_ORANGE: 0xf29757,
        DASHBOARD_TITLE_BACKGROUND: 0xBDBDC6,
        DASHBOARD_CONTENT_BACKGROUND: 0xEDEDF0,
        TABLE_TITLE_BACKGROUND: 0xDDDDE1,
        TABLE_CELL_BACKGROUND: 0xF6F6F7
    },

    TABLE_HIGHLIGHT_COLOURS = {
        OK: DRAWING_COLOURS.GREEN,
        WARNING: DRAWING_COLOURS.ORANGE,//0xFFA500,
        FAIL: DRAWING_COLOURS.RED,
        UNDER_LOADING: DRAWING_COLOURS.RED,
        CANT_CALCULATE: DRAWING_COLOURS.TABLE_CELL,
        PAYLOAD: DRAWING_COLOURS.BLUE
    },

    TABLE_COLUMNS = {
        FRONT: 'Front',
        TOTAL: 'Total',
        REAR: 'Rear',
        PUSHER: 'Pusher',
        TAG: 'Tag',
        REAR_CONNECTED: 'RearConnected',
        TRAILER_FRONT: 'TrailerFront',
        TRAILER_REAR: 'TrailerRear',
        BRIDGE_GROUP: 'BridgeGroup',
        BRIDGE_DISTANCE: 'BridgeDistance',
        BRIDGE_DISTANCE_ROUNDED: 'BridgeDistanceRounded',
        BRIDGE_PERMISSIBLE: 'BridgePermissible',
        BRIDGE_ACTUAL: 'BridgeActual',
        BRIDGE_OVERLOAD: 'BridgeOverload',
        TRAILER_1: 'Trailer1',
        TRAILER_2: 'Trailer2',
        LABELS: 'Labels',
        BRIDGE_UNUSED_CAPACITY: 'BridgeUnusedCapacity'
    },

    TABLE_ROWS = {
        CHASSIS_WEIGHT_ORIGINAL: 'CHASSIS_WEIGHT_ORIGINAL',
        CHASSIS_WEIGHT_ORIGINAL_RECALCULATED: 'CHASSIS_WEIGHT_ORIGINAL_RECALCULATED',
        PUSHER: 'PUSHER',
        TAG: 'TAG',
        CHASSIS_WEIGHT_MODIFIED: 'CHASSIS_WEIGHT_MODIFIED',
        TARE: 'TARE',
        FUEL_AND_CREW: 'FUEL_AND_CREW',
        EQUIPMENT: 'EQUIPMENT',
        VEHICLE_UNLADEN_TOTAL: 'VEHICLE_UNLADEN_TOTAL'
    },

    TABLE_ROW_TYPE = {
        DATA: 'DATA',
        UNDERLINE: 'UNDERLINE'
    },

    DIMENSION_TYPE = {
        VERTICAL_LEFT: 'verticalLeft',
        VERTICAL_RIGHT: 'verticalRight',
        HORIZONTAL_TOP: 'horizontalTop',
        HORIZONTAL_BOTTOM: 'horizontalBottom',
        VERTICAL_INNER: 'verticalInner',
        HORIZONTAL_INNER: 'horizontalInner',
        COG_BOTTOM_LEFT: 'cogBottomLeft',
        COG_BOTTOM_RIGHT: 'cogBottomRight',
        COG_TOP_LEFT: 'cogTopLeft',
        COG_TOP_RIGHT: 'cogTopRight',
        FREE_ANGLE: 'freeAngle',
        HORIZONTAL_COG_GROUP: 'horizontalCOGGroup'
    },

    DIMENSION_STYLE = {
        JUMP_LEFT: 'jumpLeft',
        JUMP_RIGHT: 'jumpRight',
        JUMP_UP: 'jumpUp',
        JUMP_DOWN: 'jumpDown',
        LINE: 'line',
        STANDARD: 'standard',
        TEXT: 'text',
        COG: 'cog',
        POP_LAYER: 'popLayer',
    },

    ARROW_STYLE = {
        LEFT: 0,
        RIGHT: 1,
        UP: 2,
        DOWN: 3
    },

    LINE_END_STYLE = {
        ARROW_START_AND_END_WITH_DELIMITER: 'arrowStartAndEndWithDelimiter',
        ARROW_START_AND_END_WITHOUT_DELIMITER: 'arrowStartAndEndWithoutDelimiter',
        ARROW_START_ONLY_WITH_DELIMITER: 'arrowStartOnlyWithDelimiter',
        ARROW_START_ONLY_WITHOUT_DELIMITER: 'arrowStartOnlyWithoutDelimiter',
        ARROW_END_ONLY_WITH_DELIMITER: 'arrowEndOnlyWithDelimiter',
        ARROW_END_ONLY_WITHOUT_DELIMITER: 'arrowEndOnlyWithoutDelimiter',
    ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT: 'arrowStartAndEndExternalToDimensionWithDelimiterJumpLeft',
    ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT: 'arrowStartAndEndExternalToDimensionWithDelimiterJumpRight',
    ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP: 'arrowStartAndEndExternalToDimensionWithDelimiterJumpUp',
    ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN: 'arrowStartAndEndExternalToDimensionWithDelimiterJumpDown'
    },

    DRAWING_DIRECTION_OF_MOVEMENT = {
        HORIZONTAL: 'horizontal',
        VERTICAL: 'vertical',
        HORIZONTAL_AND_VERTICAL: 'horizontalAndVertical'
    },

    SPACER_TYPE = {
        SMALL_VERTICAL: 'SMALL_VERTICAL',
        LARGE_VERTICAL: 'LARGE_VERTICAL',
        CUSTOM_VERTICAL: 'CUSTOM_VERTICAL',
        LINE: 'LINE'
    },

    COMPLIANCE_DASHBOARD_ITEM_TYPES = {
        SPACER: 'SPACER',
        TEXT: 'TEXT',
        ICON: 'ICON',
        ROW: 'ROW'
    },

    COMPLIANCE_DASHBOARD_VIEWS = {
        MAIN: 'MAIN',
        INTERNAL_STANDARDS: 'INTERNAL_STANDARDS',
        PAYLOAD_ACHIEVED: 'PAYLOAD_ACHIEVED',
        REGULATORY_COMPLIANCE: 'REGULATORY_COMPLIANCE',
        REGULATORY_COMPLIANCE_INSTANCE: 'REGULATORY_COMPLIANCE_INSTANCE',
        MANUFACTURER_LIMITS: 'MANUFACTURER_LIMITS'
    },

    COG_ELEMENT_STYLE = {
        DRAW_RIGHT: 'drawRight',
        DRAW_LEFT: 'drawLeft',
        INLINE_RIGHT: 'inlineRight',
        INLINE_LEFT: 'inlineLeft',
        DRAW_TOP: 'drawTop',
        DRAW_BOTTOM: 'drawBottom',
    },

    COG_ELEMENTS = {
        VEHICLE_COG: 'vehicleCOG',
        FUELTANK1_COG: 'fueltank1COG',
        FUELTANK2_COG: 'fueltank2COG',
        FUELTANK3_COG: 'fueltank3COG',
        FUELTANK4_COG: 'fueltank4COG',
        FUELTANK5_COG: 'fueltank5COG',
        FUELTANK6_COG: 'fueltank6COG',
        FUELTANK7_COG: 'fueltank7COG',
        FUELTANK8_COG: 'fueltank8COG',
        CREWFIRSTROW_COG: 'crewFirstRowCOG',
        CREWSECONDROW_COG: 'crewSecondRowCOG',
        BODY_COG: 'bodyCOG',
        CRANE_COG: 'craneCOG',
        TAILLIFT_COG: 'tailliftCOG',
        OTHER_COG: 'otherCOG',
        OVERALL_COG: 'overallCOG',
        PAYLOAD_COG: 'payloadCOG',
        FRIDGE_COG: 'fridgeCOG',
        HOOKLIFT_COG: 'hookliftCOG',
        FIFTH_WHEEL_COG: 'fifthWheelCOG',
        HITCH_COG: 'hitchCOG',
        TRAILER_COG: 'trailerCOG',
        TRAILER_OVERALL_COG: 'trailerOverallCOG',
        VEHICLE_KINGPIN_LOAD: 'vehicleKingpinLoad',
        TRAILER1_KINGPIN_LOAD: 'trailer1KingpinLoad',
        VEHICLE_HITCH_LOAD: 'vehicleHitchLoad',
    },

    COG_AXIS_TYPES = {
        HORIZONTAL: 'horizontal',
        VERTICAL: 'vertical',
        LATERAL: 'lateral'
    },

    CORNER_TYPES = {
        TOP_LEFT: 'topLeft',
        TOP_RIGHT: 'topRight',
        BOTTOM_LEFT: 'bottomLeft',
        BOTTOM_RIGHT: 'bottomRight'
    },

    SCALING_PLANE = {
        HORIZONTAL: 'HORIZONTAL',
        VERTICAL: 'VERTICAL'
    },

    SCALING_DIMENSION_TYPES = {
        LENGTH: 'LENGTH',
        WIDTH: 'WIDTH',
        HEIGHT: 'HEIGHT',
        NONE: 'NONE'
    },

    INPUT_TYPE = {
        TEXTBOX: 'TextBox',
        TEXT: 'text',
        NUMBER: 'number',
        TEL: 'tel',
        SELECT: 'select',
        UPDOWNBOX: 'UpDownBox',
        RADIOBUTTON: 'RadioButton',
        THEORETICAL: 'theoretical',
        MENUTABGROUP: 'menuTabGroup',
        CHECKBOX: 'checkbox',
        DATAGRID: 'datagrid',
        DATAGRIDVALUE: 'datagridvalue',
        TEXTAREA: 'textArea',
        COMBOBOX: 'combobox',
        INPUT_GROUP: 'inputGroup',
        IMAGE_UPLOAD: 'imageUpload',
        RADIO: 'radio',
        MULTISELECT: 'multiSelect'
    },

    ITEM_TYPE = {
        ITEM: 'item',
        VALUE: 'value',
        MENUTABGROUP: 'menuTabGroup',
        SELECTIONGROUP: 'selectionGroup',
        SELECTIONGROUPITEM: 'selectionGroupItem',
        SELECT: 'select',
        TEXT: 'text',
        LISTITEMS: 'listItems',
        DATAGRID: 'datagrid',
        DIVIDER: 'divider',
        ACCESSORYGROUP: 'accessoryGroup',
        MENUCARD: 'menuCard',
        SELECTIONWIZARD: 'selectionWizard',
        MENUTAB: 'menuTab',
        MENU_CARD: 'menuCard',
        COMBOBOX: 'combobox',
        TAB_GROUP: 'tabGroup',
        INPUT_GROUP: 'inputGroup',
        MENU_TABLE: 'menuTable',
        MENU_TABLE_ROW: 'menuTableRow',
        MENU_TABLE_CELL: 'menuTableCell',
        RADIO: 'radio',
        RADIO_OPTION: 'radioOption'
    },

    TAB_GROUP_DISPLAY_TYPE = {
        ACCORDION: 'accordion',
        TABS: 'tabs'
    },

    TOOLTIP_TYPES = {
        INFO: 'INFO',
        INPUT: 'INPUT'
    },

    INPUT_GROUP_TYPES = {
        UPDOWNBOX_AND_SELECTMENU: 'UPDOWNBOX_AND_SELECTMENU'
    },

    INCREMENT_TYPE = {
        LENGTH: 'length',
        MASS: 'mass',
        PERCENTAGE: 'percentage',
        PERCENTAGEINCREMENT: 'percentageIncrement',
        VOLUME: 'volume',
        SPECIFICGRAVITY: 'specificGravity',
        LINEARDENSITY: 'linearDensity',
        DEGREES: 'degrees',
        MONTHLYDISTANCE: 'monthlyDistance',
        MONTHS: 'months',
        CURRENCYPERVOLUME: 'currencyPerVolume',
        HIGHCURRENCY: 'highCurrency',
        MEDIUMCURRENCY: 'mediumCurrency',
        CURRENCYTOTALPRICE: 'currencyTotalPrice',
        CURRENCYPERMONTH: 'currencyPerMonth',
        PERCENTAGEOFTOTALPRICE: 'percentageOfTotalPrice',
        PERCENTAGESINGLEPLACE: 'percentageSinglePlace',
        PERCENTAGEDOUBLEPLACE: 'percentageDoublePlace',
        CURRENCYPERYEAR: 'currencyPerYear',
        PERCENTAGEOFFIXEDCOST: 'percentageOfFixedCost',
        VOLUMEPER100KMS: 'volumePer100KMS',
        CURRENCYPERDISTANCE: 'currencyPerDistance',
        CURRENCYMINORPERDISTANCE: 'currencyMinorPerDistance',
        CURRENCYSINGLEPLACE: 'currencySinglePlace',
        AREA: 'area',
        SPEED: 'speed',
        NONE: 'none',
        COEFFICIENTOFDRAG: 'coefficientOfDrag',
        DRIVEAXLERATIO: 'driveAxleRatio',
        FUELCONSUMPTION: 'fuelConsumption',
        FUELCONSUMPTIONNOCONVERSION: 'fuelConsumptionNoConversion',
        UNITOFMEASURE: 'unitOfMeasure',
        DENSITY: 'density',
        NUMBER: 'number',
        CURRENCYPERTRIP: 'currencyPerTrip',
        VALUEPERMONTH: 'valuePerMonth',
        TRIPSPERMONTH: 'tripsPerMonth',
        PERCENTAGEOFFUEL: 'percentageOfFuel',
        HOURSPERMONTH: 'hoursPerMonth',
        VOLUMEPERHOUR: 'volumePerHour',
    },

    VALUE_TYPE = {
        VEHICLEDESCRIPTION: 'vehicleDescription',
        FRONTOVERHANG: 'frontOverhang',
        AXLE1FRONTTOAXLE2FRONT: 'axle1FrontToAxle2Front',
        AXLE2FRONTTOAXLE1REAR: 'axle2FrontToAxle1Rear',
        AXLE1REARTOAXLE2REAR: 'axle1RearToAxle2Rear',
        AXLE2REARTOAXLE3REAR: 'axle2RearToAxle3Rear',
        AXLE3REARTOAXLE4REAR: 'axle3RearToAxle4Rear',
        REAROVERHANG: 'rearOverhang',
        AFTERFRAME: 'afterFrame',
        REAROVERHANGTOTAL: 'rearOverhangTotal',
        REAROVERHANGLEGISLATION: 'rearOverhangLegislation',
        COMPLETEREAROVERHANG: 'completeRearOverhang',
        REARPROTRUSION: 'rearProtrusion',
        REARPROTRUSIONVEHICLEONLY: 'rearProtrusionVehicleOnly',
        GROUNDCLEARANCEFRONT: 'groundClearanceFront',
        GROUNDCLEARANCEREAR: 'groundClearanceRear',
        WHEELBASETHEORETICAL: 'wheelbaseTheoretical',
        WHEELBASELEGISLATION: 'wheelbaseLegislation',
        WHEELBASETHEORETICALENDTOAXLE4REAR: 'wheelbaseTheoreticalEndtoAxle4Rear',
        AXLE1FRONTTOWHEELBASETHEORETICALSTART: 'axle1FrontToWheelbaseTheoreticalStart',
        AXLE1FRONTTOWHEELBASELEGISLATIONSTART: 'axle1FrontToWheelbaseLegislationStart',
        BUMPERTOBACKOFCAB: 'bumperToBackOfCab',
        AXLE1FRONTTOBACKOFCAB: 'axle1FrontToBackOfCab',
        REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES: 'rearMostProtrudingItemBehindCabToRearAxles',
        CABTOAXLES: 'cabToAxles',
        CABTOCHASSISEND: 'cabToChassisEnd',
        CABGAPRECOMMENDEDMIN: 'cabGapREcommendedMin',
        CABTILT: 'cabTilt',
        CABHINGEPOINTFROMBUMPER: 'cabHingePointFromBumper',
        CABHINGEPOINTFROMGROUND: 'cabHingePointFromGround',
        CABTILTRADIUS: 'cabTiltRadius',
        COGFROMHEADBOARDINSIDE: 'COGFromHeadboardInside',
        COGFROMWHEELBASETHEORETICALATREAR: 'COGFromWheelbaseTheoreticalAtRear',
        COGFROMBUMPERTOSTARTOFBODYINTERNAL: 'COGFromBumperToStartOfBodyInternal',
        BUMPERTOSTARTOFBODYINTERNAL: 'bumperToStartOfBodyInternal',
        BODYLENGTHINTERNAL: 'bodyLengthInternal',
        BODYWIDTHINTERNAL: 'bodyWidthInternal',
        BODYFLOORHEIGHT: 'bodyFloorHeight',
        BODYHEIGHTINTERNAL: 'bodyHeightInternal',
        CABHEIGHT: 'cabHeight',
        CHASSISHEIGHTFRONT: 'chassisHeightFront',
        CHASSISHEIGHTREAR: 'chassisHeightRear',
        CABWIDTH: 'cabWidth',
        REARWIDTH: 'rearWidth',
        OVERALLWIDTH: 'overallWidth',
        TRACKWIDTHFRONT: 'trackWidthFront',
        TRACKWIDTHREAR: 'trackWidthRear',
        CHASSISWIDTH: 'chassisWidth',
        TAREFRONT: 'tareFront',
        TAREREAR: 'tareRear',
        TAREREARREAR: 'tareRearRear',
        TARETOTAL: 'tareTotal',
        HORIZONTALCOG: 'horizontalCOG',
        VERTICALCOG: 'verticalCOG',
        LATERALCOG: 'lateralCOG',
        VERTICALEXHAUSTHEIGHT: 'verticalExhaustHeight',
        OVERALLHEIGHTATREAR: 'overallHeightAtRear',
        TOOLSINCLUDED: 'toolsIncluded',
        SPAREWHEELINCLUDED: 'spareWheelIncluded',
        MANUFACTURERSFRONTAXLEMASS: 'manufacturersFrontAxleMass',
        MANUFACTURERSREARAXLEMASS: 'manufacturersRearAxleMass',
        MANUFACTURERSGROSSVEHICLEMASS: 'manufacturersGrossVehicleMass',
        MANUFACTURERSGROSSCOMBINATIONMASS: 'manufacturersGrossCombinationMass',
        FRONTROADFRIENDLYSUSPENSION: 'frontRoadFriendlySuspension',
        REARROADFRIENDLYSUSPENSION: 'rearRoadFriendlySuspension',
        ROLLOVERPROTECTION: 'rolloverProtection',
        FRONTUNDERRUNPROTECTION: 'frontUnderrunProtection',
        DEVICESFORINDIRECTVISION: 'devicesForIndirectVision',
        COMMERCIALVEHICLEBRAKESYSTEMS: 'commercialVehicleBrakeSystems',
        ADVANCEDEMERGENCYBRAKING: 'advancedEmergencyBraking',
        LANEDEPARTUREWARNING: 'laneDepartureWarning',
        SIDEUNDERRUNPROTECTION: 'sideUnderrunProtection',
        CONSPICUITYMARKINGSTOTHEREAR: 'conspicuityMarkingsToTheRear',
        CONSPICUITYMARKINGSTOBOTHSIDES: 'conspicuityMarkingsToBothSides',
        BLINDSPOTINFORMATIONSYSTEMS: 'blindSpotInformationSystems',
        PERMISSIBLEFRONTAXLEMASS: 'permissibleFrontAxleMass',
        PERMISSIBLEREARAXLEMASS: 'permissibleRearAxleMass',
        PERMISSIBLETOTALAXLEMASS: 'permissibleTotalAxleMass',
        CREWMASSPERPERSON: 'crewMassPerPerson',
        CREWFIRSTROW: 'crewFirstRow',
        NOOFCREWINFIRSTROW: 'noOfCrewInFirstRow',
        CREWFIRSTROWHORIZONTALCOG: 'crewFirstRowHorizontalCOG',
        CREWFIRSTROWVERTICALCOG: 'crewFirstRowVerticalCOG',
        CREWFIRSTROWLATERALCOG: 'crewFirstRowLateralCOG',
        CREWFIRSTROWMASS: 'crewFirstRowMass',
        CREWFIRSTROWFRONTAXLELOAD: 'crewFirstRowFrontAxleLoad',
        CREWFIRSTROWREARAXLELOAD: 'crewFirstRowRearAxleLoad',
        CREWSECONDROW: 'crewSecondRow',
        NOOFCREWINSECONDROW: 'noOfCrewInSecondRow',
        CREWSECONDROWHORIZONTALCOG: 'crewSecondRowHorizontalCOG',
        CREWSECONDROWVERTICALCOG: 'crewSecondRowVerticalCOG',
        CREWSECONDROWLATERALCOG: 'crewSecondRowLateralCOG',
        CREWSECONDROWMASS: 'crewFirstRowMass',
        CREWSECONDROWFRONTAXLELOAD: 'crewFirstRowFrontAxleLoad',
        CREWSECONDROWREARAXLELOAD: 'crewFirstRowRearAxleLoad',
        BODYDESCRIPTION: 'bodyDescription',
        BODYCABGAP: 'bodyCabGap',
        BODYLENGTH: 'bodyLength',
        BODYLENGTHTYPE: 'bodyLengthType',
        BODYSTARTTOWHEELBASESPECIFIEDEND: 'bodyStartToWheelbaseSpecifiedEnd',
        BODYLENGTHEXTERNALINCLUDINGREARDOORS: 'bodyLengthExternalInclRearDoors',
        BODYHEADBOARDTHICKNESS: 'bodyHeadboardThickness',
        BODYTAILBOARDTHICKNESS: 'bodyTailboardThickness',
        BODYSUBFRAMEHEIGHT: 'bodySubframeHeight',
        BODYSUBSTRUCTUREHEIGHT: 'bosySubstructureHeight',
        BODYBOTTOMOFBODYTOTOPOFCHASSIS: 'bodyBottomOfBodyToTopOfChassis',
        BODYLEFTCOMPARTMENTWIDTH: 'bodyLeftCompartmentWidth',
        BODYRIGHTCOMPARTMENTWIDTH: 'bodyRightCompartmentWidth',
        BODYHEIGHT: 'bodyHeight',
        BODYHEIGHTTYPE: 'bodyHeightType',
        BODYHEIGHTEXTERNAL: 'bodyHeightExternal',
        BODYHEIGHTEXTERNALFROMFLOOR: 'bodyHeightExternalFromFloor',
        BODYROOFTHICKNESS: 'bodyRoofThickness',
        BODYMASS: 'bodyMass',
        BODYFRONTAXLELOAD: 'bodyFrontAxleLoad',
        BODYREARAXLELOAD: 'bodyRearAxleLoad',
        BODYMASSTYPE: 'bodyMassType',
        BODYHORIZONTALCOG: 'bodyHorizontalCOG',
        BODYHORIZONTALCOGTYPE: 'bodyHorizontalCOGType',
        BODYVERTICALCOG: 'bodyVerticalCOG',
        BODYVERTICALCOGTYPE: 'bodyVerticalCOGType',
        BODYLATERALCOG: 'bodyLateralCOG',
        BODYLATERALCOGTYPE: 'bodyLateralCOGType',
        BODYHEADBOARDHEIGHT: 'bodyHeadboardHeight',
        BODYTAILBOARDHEIGHT: 'bodyTailboardHeight',
        BODYWIDTH: 'bodyWidth',
        BODYWIDTHTYPE: 'bodyWidthType',
        BODYWIDTHEXTERNAL: 'bodywidthExternal',
        BODYSIDEWALLLEFT: 'bodySideWallLeft',
        BODYSIDEWALLRIGHT: 'bodySideWallRight',
        BODYFLOORWIDTH: 'bodyFloorWidth',
        BODYHINGEPOINT: 'bodyHingePoint',
        BODYCOGTOREARAXLES: 'bodyCOGToReaRAxles',
        REAROFBODYTOREAROFVEHICLE: 'rearOfBodyToRearOfVehicle',
        BODYSIDEDOORS: 'bodySideDoors',
        BODYSIDEDOORTYPE: 'bodySideDoorType',
        BODYSIDEDOORFRONTTODOOR: 'bodySideDoorFrontToDoor',
        BODYSIDEDOORWIDTHPERDOOR: 'bodySideDoorWidthPerDoor',
        BODYSIDEDOORHEIGHT: 'bodySideDoorHeight',
        BODYUPRIGHTINSIDEFRONTTOUPRIGHT: 'bodyUprightInsideFrontToUpright',
        BODYUPRIGHTWIDTH: 'bodyUprightWidth',
        BODYUPRIGHTHEIGHT: 'bodyUprightHeight',
        BODYMASSFRONT: 'bodyMassFront',
        BODYMASSREAR: 'bodyMassRear',
        BODYMASSTOTAL: 'bodyMassTotal',
        BODYABOVEBELOWCHASSIS: 'bodyAboveBelowChassis',
        BODYOVERHANG: 'bodyOverhang',
        BODYFROMAXLE1FRONT: 'bodyFromAxle1Front',
        OVERALLHORIZONTALCOG: 'overallHorizontalCOG',
        OVERALLVERTICALCOG: 'overallVerticalCOG',
        OVERALLLATERALCOG: 'overallLateralCOG',
        AXLE1FRONTTOSTARTOFBODY: 'axle1FrontToStartOfBody',
        VEHICLEFRONTAXLEOVERLOAD: 'vehicleFrontAxleOverload',
        VEHICLEREARAXLEOVERLOAD: 'vehicleRearAxleOverload',
        VEHICLETOTALOVERLOAD: 'vehicleTotalOverload',
        FUELTYPE: 'fuelType',
        FUELSPECIFICGRAVITY: 'fuelSpecificGravity',
        FUELADBLUESPECIFICGRAVITY: 'fuelAdBlueSpecificGravity',
        FUELTANKHORIZONTALCOG: 'fuelTankHorizontalCOG',
        FUELTANKVERTICALCOG: 'fuelTankVerticalCOG',
        FUELTANKLATERALCOG: 'fuelTankLateralCOG',
        FUELTANKCAPACITY: 'fuelTankCapacity',
        FUELTANKVOLUMEINCLUDEDINTARE: 'fuelTankVolumeIncludedInTare',
        FUELTANKVOLUMEINCLUDEDINEXTRAS: 'fuelTankVolumeIncludedInExtras',
        FUELTANKINCLUDED: 'fuelTankIncluded',
        FUELTANKOFFEREDAS: 'fuelTankOfferedAs',
        FUELTANKMASS: 'fuelTankMass',
        FUELTANKFRONTAXLELOAD: 'fuelTankFrontAxleLoad',
        FUELTANKREARAXLELOAD: 'fuelTankRearAxleLoad',
        FUELEXPRESSEDAS: 'fuelExpressedAs',
        FUELTANKWEIGHTINCLUDEDINFUELANDCREW: 'fuelTankWeightIncludedInFuelAndCrew',
        AXLEMANUFACTURERRATING: 'axleManufacturerRating',
        AXLEWEIGHT: 'axleWeight',
        AXLEWEIGHTOVERRIDE: 'axleWeightOverride',
        SUSPENSIONMASSRATING: 'suspensionMassRating',
        SUSPENSIONMASSRATINGOVERRIDE: 'suspensionMassRatingOverride',
        TYREMASSRATING: 'tyreMassRating',
        TYREMASSRATINGOVERRIDE: 'tyreMassRatingOverride',
        LIFTINGAXLE: 'liftingAxle',
        STEERINGAXLE: 'steeringAxle',
        NOOFTYRESONAXLE: 'noOfTyresOnAxle',
        AXLELIFTINGMAXTRAVEL: 'axleLiftingMaxTravel',
        AXLERAISED: 'axleRaised',
        AXLEADDED: 'axleAdded',
        TYRERADIUS: 'tyreRadius',
        TYREWIDTH: 'tyreWidth',
        OVERALLVEHICLELENGTH: 'overallVehicleLength',
        COMPLETEOVERALLLENGTH: 'completeOverallLength',
        OVERALLVEHICLEWIDTH: 'overallVehicleWidth',
        CABPROTRUSION: 'cabProtrusion',
        OVERALLVEHICLEHEIGHT: 'overallVehicleHeight',
        CRANEDESCRIPTION: 'craneDescription',
        CRANECABGAP: 'craneCabGap',
        CRANEFROMLEFTOFVEHICLEREARWIDTH: 'craneFromLeftOfVehicleRearWidth',
        CRANEFROMCENTREOFVEHICLE: 'craneFromCentreOfVehicle',
        CRANESUBFRAMEHEIGHT: 'craneSubframe',
        CRANELENGTH: 'craneLength',
        CRANEHEIGHT: 'craneHeight',
        CRANEWIDTH: 'craneWidth',
        CRANEMASS: 'craneMass',
        CRANEFRONTAXLELOAD: 'craneFrontAxleLoad',
        CRANEREARAXLELOAD: 'craneRearAxleLoad',
        CRANEHORIZONTALCOG: 'craneHorizontalCOG',
        CRANEHORIZONTALCOGTYPE: 'craneHorizontalCOGType',
        CRANEVERTICALCOG: 'craneVerticalCOG',
        CRANEVERTICALCOGTYPE: 'craneVerticalCOGType',
        CRANELATERALCOG: 'craneLateralCOG',
        CRANELATERALCOGTYPE: 'craneLateralCOGType',
        CRANEABOVEBELOWCHASSIS: 'craneAboveBelowChassis',
        CRANEAXLE1FRONTTOEQUIPMENT: 'craneAxle1FrontToEquipment',
        CRANEENDOFEQUIPMENTTOENDOFCHASSIS: 'craneEndOfEquipmentToEndOfChassis',
        CRANEFROMAXLE1FRONT: 'craneFromAxle1Front',
        CHASSISPAYLOADTYPE: 'chassisPayloadType',
        SIMPLEPAYLOADMASS: 'simplePayloadMass',
        SIMPLEPAYLOADFRONTAXLELOAD: 'simplePayloadFrontAxleLoad',
        SIMPLEPAYLOADREARAXLELOAD: 'simplePayloadRearAxleLoad',
        SIMPLEPAYLOADHORIZONTALCOG: 'simplePayloadHorizontalCOG',
        SIMPLEPAYLOADVERTICALCOG: 'simplePayloadVerticalCOG',
        SIMPLEPAYLOADLATERALCOG: 'simplePayloadLateralCOG',
        SIMPLEPAYLOADCOGTYPE: 'simplePayloadCOGType',
        SIMPLEPAYLOADVERTICALCOGTYPE: 'simplePayloadVerticalCOGType',
        SIMPLEPAYLOADLATERALCOGTYPE: 'simplePayloadLateralCOGType',
        DETAILEDPAYLOADPAYLOAD: 'detailedPayloadPayload',
        DETAILEDPAYLOADMASS: 'detailedPayloadMass',
        DETAILEDPAYLOADFRONTAXLELOAD: 'detailedPayloadFrontAxleLoad',
        DETAILEDPAYLOADREARAXLELOAD: 'detailedPayloadRearAxleLoad',
        DETAILEDPAYLOADHORIZONTALCOG: 'detailedPayloadHorizontalCOG',
        DETAILEDPAYLOADVERTICALCOG: 'detailedPayloadVerticalCOG',
        DETAILEDPAYLOADLATERALCOG: 'detailedPayloadLateralCOG',
        DETAILEDPAYLOADDENSITY: 'detailedPayloadDensity',
        DETAILEDPAYLOADUNITOFMEASURE: 'detailedPayloadUnitOfMeasure',
        DETAILEDPAYLOADLENGTH: 'detailedPayloadLength',
        DETAILEDPAYLOADHEIGHT: 'detailedPayloadHeight',
        DETAILEDPAYLOADWIDTH: 'detailedPayloadWidth',
        DETAILEDPAYLOADFROMLEFTSIDEWALL: 'detailedPayloadFromLeftSidewall',
        DETAILEDPAYLOADFROMHEADBOARD: 'detailedPayloadFromHeadboard',
        DETAILEDPAYLOADABOVEDECK: 'detailedPayloadAboveDeck',
        DETAILEDPAYLOADDESCRIPTION: 'detailedPayloadDescription',
        DETAILEDPAYLOADTYPE: 'detailedPayloadType',
        DETAILEDPAYLOADCOGTYPE: 'detailedPayloadCOGType',
        DETAILEDPAYLOADVERTICALCOGTYPE: 'detailedPayloadVerticalCOGType',
        DETAILEDPAYLOADLATERALCOGTYPE: 'detailedPayloadLateralCOGType',
        DETAILEDPAYLOADWEIGHTTYPE: 'detailedPayloadWeightType',
        SETTINGSLENGTHINCREMENT: 'settingsLengthIncrement',
        SETTINGSMASSINCREMENT: 'settingsMassIncrement',
        SETTINGSPERCENTAGEINCREMENT: 'settingsPercentageIncrement',
        SETTINGSMEASUREMENTSYSTEM: 'settingsMeasurementSystem',
        SETTINGSLEGISLATION: 'settingsLegislation',
        SETTINGSAPPLYHIGHERMASSLIMITS: 'settingsApplyHigherMassLimits',
        SETTINGSVEHICLEAPPLICATION: 'settingsVehicleApplication',
        SETTINGSDESCRIPTION: 'settingsDescription',
        SETTINGSOVERALLDESCRIPTION: 'settingsOverallDescription',
        SETTINGSADDITIONALDESCRIPTION: 'settingsAdditionalalDescription',
        SETTINGSPREPAREDFORNAME: 'settingsPreparedForName',
        SETTINGSPREPAREDFORTELEPHONE: 'settingsPreparedForTelelphone',
        SETTINGSADDITIONALNOTES: 'settingsAdditionalNotes',
        SETTINGSCOLOURDRAWINGS: 'settingsColourDrawings',
        SETTINGSSHOWCOMPLIANCESCORECARD: 'settingsShowComplianceScorecard',
        SETTINGSSPECIFYCABDIMENSIONSAS: 'settingsSpecifyCabDimensionsAs',
        SETTINGSSPECIFYCHASSISLENGTHAS: 'settingsSpecifyChassisLengthAs',
        SETTINGSSPECIFYWHEELBASEAS: 'settingsSpecifyWheelbaseAs',
        SETTINGSSPECIFYFUELCONSUMPTIONAS: 'settingsSpecifyFuelConsumptionAs',
        SETTINGSDEFAULTLICENCINGMETHODREGION: 'settingsDefaultLicencingMethodRegion',
        SETTINGSSPECIFYLICENCINGREGIONAS: 'settingsSpecifyLicensingRegionAs',
        SETTINGSSPECIFYAXLERATINGAS: 'settingsSpecifyAxleRatingAs',
        SETTINGSDEFAULTREPORTPDFPAGESIZE: 'settingsDefaultReportPdfPageSize',
        SETTINGSCOMPANYAPPLICATIONNOTE: 'settingsCompanyApplicationNote',
        SETTINGSSPECIFYBODYPOSITIONAS: 'settingsSpecifyBodyPositionAs',
        SETTINGSSPECIFYEQUIPMENTPOSITIONAS: 'settingsSpecifyEquipmentPositionAs',
        SETTINGSSHOWTIPS: 'settingsShowTips',
        SETTINGSREPORTVIEWLAYOUT: 'settingsReportViewLayout',
        SUMMARYNOTE: 'summaryNote',
        OTHERDESCRIPTION: 'otherDescription',
        OTHERCABGAP: 'otherCabGap',
        OTHERABOVEBELOWCHASSIS: 'otherAboveBelowChassis',
        OTHERFROMLEFTOFVEHICLEREARWIDTH: 'otherFromLeftOfVehicleRearWidth',
        OTHERFROMCENTREOFVEHICLE: 'otherFromCentreOfVehicle',
        OTHERLENGTH: 'otherLength',
        OTHERHEIGHT: 'otherHeight',
        OTHERWIDTH: 'otherWidth',
        OTHERENDOFEQUIPMENTTOENDOFCHASSIS: 'otherEndOfEquipmentToEndOfChassis',
        OTHERMASS: 'otherMass',
        OTHERFRONTAXLELOAD: 'otherFrontAxleLoad',
        OTHERREARAXLELOAD: 'otherRearAxleLoad',
        OTHERHORIZONTALCOG: 'otherHorizontalCOG',
        OTHERVERTICALCOG: 'otherVerticalCOG',
        OTHERLATERALCOG: 'otherLateralCOG',
        OTHERCOGTYPE: 'otherCOGType',
        OTHERVERTICALCOGTYPE: 'otherVerticalCOGType',
        OTHERLATERALCOGTYPE: 'otherLateralCOGType',
        OTHERFROMAXLE1FRONT: 'otherFromAxle1Front',
        FRIDGEDESCRIPTION: 'fridgeDescription',
        FRIDGEMOUNTINGPOSITION: 'fridgeMountingPosition',
        FRIDGEFROMFRONTOFBODY: 'fridgeFromFrontOfBody',
        FRIDGEPROTRUSION: 'fridgeProtrusion',
        FRIDGEFROMLEFTOFBODYWIDTHEXTERNAL: 'fridgeFromLeftOfBodyWidthExternal',
        FRIDGEFROMCENTREOFVEHICLE: 'fridgeFromCentreOfVehicle',
        FRIDGEHEIGHT: 'fridgeHeight',
        FRIDGEWIDTH: 'fridgeWidth',
        FRIDGEMASS: 'fridgeMass',
        FRIDGEFRONTAXLELOAD: 'fridgeFrontAxleLoad',
        FRIDGEREARAXLELOAD: 'fridgeRearAxleLoad',
        FRIDGEHORIZONTALCOG: 'fridgeHorizontalCOG',
        FRIDGEVERTICALCOG: 'fridgeVerticalCOG',
        FRIDGELATERALCOG: 'fridgeLateralCOG',
        FRIDGECOGTYPE: 'fridgeCOGType',
        FRIDGEVERTICALCOGTYPE: 'fridgeVerticalCOGType',
        FRIDGELATERALCOGTYPE: 'fridgeLateralCOGType',
        TAILLIFTDESCRIPTION: 'tailliftDescription',
        TAILLIFTFROMBACKOFBODY: 'tailliftFromBackOfBody',
        TAILLIFTMECHANISMLENGTH: 'tailliftMechanismLength',
        TAILLIFTPLATFORMPROTRUSION: 'tailliftPlatformProtrusion',
        TAILLIFTABOVEORBELOWFLOOR: 'tailliftAboveOrBelowFloor',
        TAILLIFTFROMLEFTOFBODYWIDTHEXTERNAL: 'tailliftFromLeftOfBodyWidthExternal',
        TAILLIFTFROMCENTREOFVEHICLE: 'tailliftFromCentreOfVehicle',
        TAILLIFTPLATFORMHEIGHT: 'tailliftPlatformHeight',
        TAILLIFTWIDTH: 'tailliftWidth',
        TAILLIFTMECHANISMHEIGHT: 'tailliftMechanismHeight',
        TAILLIFTMASS: 'tailliftMass',
        TAILLIFTFRONTAXLELOAD: 'tailliftFrontAxleLoad',
        TAILLIFTREARAXLELOAD: 'tailliftRearAxleLoad',
        TAILLIFTHORIZONTALCOG: 'tailliftHorizontalCOG',
        TAILLIFTVERTICALCOG: 'tailliftVerticalCOG',
        TAILLIFTLATERALCOG: 'tailliftLateralCOG',
        TAILLIFTCOGTYPE: 'tailliftCOGType',
        TAILLIFTVERTICALCOGTYPE: 'tailliftVerticalCOGType',
        TAILLIFTLATERALCOGTYPE: 'tailliftLateralCOGType',
        UNLADENTOTAL: 'unladenTotal',
        UNLADENFRONT: 'unladenFront',
        UNLADENREAR: 'unladenRear',
        PAYLOADTOTAL: 'payloadTotal',
        PAYLOADFRONT: 'payloadFront',
        PAYLOADREAR: 'payloadRear',
        GROSSTOTAL: 'grossTotal',
        GROSSFRONT: 'grossFront',
        GROSSREAR: 'grossRear',
        OVERLOADTOTAL: 'overloadTotal',
        OVERLOADFRONT: 'overladFront',
        OVERLOADREAR: 'overloadRear',
        COMBINATIONOVERALLWIDTH: 'combinationOverallWidth',
        RAMPLENGTH: 'rampLength',
        RAMPANGLE: 'rampAngle',
        RAMPPLATESPROTRUSION: 'rampPlatesProtrusion',
        RAMPPLATESHEIGHT: 'rampPlatesHeight',
        RAMPPLATESMASS: 'rampPlatesMass',
        RAMPPLATESFRONTAXLELOAD: 'rampPlatesFrontAxleLoad',
        RAMPPLATESREARAXLELOAD: 'rampPlatesRearAxleLoad',
        RAMPPLATESCOGTYPE: 'rampPlatesCOGType',
        RAMPPLATESHORIZONTALCOGOVERRIDE: 'rampPlatesHorizontalCOGOverride',
        RAMPPLATESHORIZONTALCOG: 'rampPlatesHorizontalCOG',
        RAMPPLATESVERTICALCOGTYPE: 'rampPlatesVerticalCOGType',
        RAMPPLATESVERTICALCOGOVERRIDE: 'rampPlatesVerticalCOGOverride',
        RAMPPLATESVERTICALCOG: 'rampPlatesVerticalCOG',
        RAMPPLATESLATERALCOGTYPE: 'rampPlatesLateralCOGType',
        RAMPPLATESLATERALCOG: 'rampPlatesLateralCOG',
        RAMPPLATESLATERALCOGOVERRIDE: 'rampPlatesLateralCOGOverride',
        TOTALBODYMASS: 'totalBodyMass',
        TOTALBODYHORIZONTALCOG: 'totalBodyHorizontalCOG',
        TOTALBODYVERTICALCOG: 'totalBodyVerticalCOG',
        BODYSUBFRAMELENGTH: 'bodySubframeLength',
        FRONTEXTRAS: 'frontExtras',
        REAREXTRAS: 'rearExtras',
        TOTALEXTRAS: 'totalExtras',
        TAREDRIVERINCLUDED: 'tareDriverIncluded',
        AXLEOVERLOADPUSHER: 'axleOverloadPusher',
        AXLEOVERLOADTAG: 'axleOverloadTag',
        AXLESOVERLOADREARREAR: 'axlesOverloadRearRear',
        AXLEGROSSPUSHER: 'axleGrossPusher',
        AXLEGROSSTAG: 'axleGrossTag',
        AXLEGROSSREARREAR: 'axleGrossRearRear',
        AXLEPERMISSIBLEPUSHER: 'axlePermissiblePusher',
        AXLEPERMISSIBLETAG: 'axlePermissibleTag',
        AXLEPERMISSIBLEREARREAR: 'axlePermissibleRearRear',
        AXLETAREPUSHER: 'axleTarePusher',
        AXLETARETAG: 'axleTareTag',
        AXLETAREREARREAR: 'axleTareRearRear',
        AXLEUNLADENPUSHER: 'axleUnladenPusher',
        AXLEUNLADENTAG: 'axleUnladenTag',
        AXLEUNLADENREARREAR: 'axleUnladenRearRear',
        AXLEPUSHER: 'axlePusher',
        AXLETAG: 'axleTag',
        AXLEREARREAR: 'axleRearRear',
        FIFTHWHEELDESCRIPTION: 'fifthWheelDescription',
        FIFTHWHEELOFFSET: 'fifthWheelOffset',
        FIFTHWHEELFROMLEFTOFVEHICLEREARWIDTH: 'fifthWheelFromLeftOfVehicleRearWidth',
        FIFTHWHEELFRONTOVERHANG: 'fifthWheelFrontOverhang',
        FIFTHWHEELLENGTH: 'fifthWheelLength',
        FIFTHWHEELSUBFRAMELENGTH: 'fifthWheelSubframeLength',
        FIFTHWHEELSUBFRAMEHEIGHT: 'fifthWheelSubframeHeight',
        FIFTHWHEELHEIGHT: 'fifthWheelHeight',
        FIFTHWHEELWIDTH: 'fifthWheelWidth',
        FIFTHWHEELMASS: 'fifthWheelMass',
        FIFTHWHEELFRONTAXLELOAD: 'fifthWheelFrontAxleLoad',
        FIFTHWHEELREARAXLELOAD: 'fifthWheelRearAxleLoad',
        FIFTHWHEELCOGTYPE: 'fifthWheelCOGType',
        FIFTHWHEELHORIZONTALCOG: 'fifthWheelHorizontalCOG',
        FIFTHWHEELHORIZONTALCOGOVERRIDE: 'fifthWheelHorizontalCOGOverride',
        FIFTHWHEELVERTICALCOG: 'fifthWheelVerticalCOG',
        FIFTHWHEELVERTICALCOGTYPE: 'fifthWheelVerticalCOGType',
        FIFTHWHEELVERTICALCOGOVERRIDE: 'fifthWheelVerticalCOGOverride',
        FIFTHWHEELLATERALCOG: 'fifthWheelLateralCOG',
        FIFTHWHEELLATERALCOGTYPE: 'fifthWheelLateralCOGType',
        FIFTHWHEELHEIGHTFROMGROUND: 'fifthWheelHeightFromGround',
        TRAILERDESCRIPTION: 'trailerDescription',
        TRAILERDRAWBARTYPE: 'trailerDrawbarType',
        TRAILERDRAWBARPROTRUSION: 'trailerDrawbarProtrusion',
        TRAILERDRAWBARLENGTH: 'trailerDrawbarLength',
        TRAILERLENGTH: 'trailerLength',
        TRAILEROVERALLLENGTH: 'trailerOverallLength',
        TRAILERLENGTHEXTERNAL: 'trailerLengthExternal',
        TRAILERFRONTOVERHANG: 'trailerFrontOverhang',
        TRAILERFRONTTOSTARTOFTAPER: 'trailerFrontToStartOfTaper',
        TRAILERAXLE1FRONTTOAXLE2FRONT: 'trailerAxle1FrontToAxle2Front',
        TRAILERAXLE2FRONTTOAXLE3FRONT: 'trailerAxle2FrontToAxle3Front',
        TRAILERAXLE1FRONTTOWHEELBASELEGISLATIONSTART: 'trailer1Axle1FrontToWheelbaseLegislationStart',
        TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART: 'trailerAxle1FrontToWheelbaseTheoreticalStart',
        TRAILERWHEELBASETHEORETICAL: 'trailerWheelbaseTheoretical',
        TRAILERWHEELBASETURNING: 'trailerWheelbaseTurning',
        TRAILERWHEELBASELEGISLATION: 'trailerWheelbaseLegislation',
        TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE: 'trailerWheelbasetheoreticalEndToRearMostAxle',
        TRAILERKINGPINTOAXLE1REAR: 'trailerKingpinToAxle1Rear',
        TRAILERHITCHPOINTTOAXLE1REAR: 'trailerHitchPointToAxle1Rear',
        TRAILERAXLE1REARTOAXLE2REAR: 'trailerAxle1RearToAxle2Rear',
        TRAILERAXLE2REARTOAXLE3REAR: 'trailerAxle2RearToAxle3Rear',
        TRAILERAXLE3REARTOAXLE4REAR: 'trailerAxle3RearToAxle4Rear',
        TRAILERAXLELASTTOCHASSISEND: 'trailerAxleLastToChassisEnd',
        TRAILERKINGPINTOLANDINGLEGS: 'trailerKingpinToLandingLegs',
        TRAILERFIFTHWHEELOFFSET: 'trailerFifthWheelOffset',
        TRAILERREARMOSTAXLEFRONTTOAXLE1REAR: 'trailerRearMostAxleFrontToAxle1Rear',
        TRAILEROVERALLHEIGHT: 'trailerOverallHeight',
        TRAILERKINGPINHEIGHT: 'trailerKingpinHeight',
        TRAILERCHASSISHEIGHT: 'trailerChassisHeight',
        TRAILERFIFTHWHEELHEIGHT: 'trailerFifthWheelHeight',
        TRAILERDRAWBARHEIGHT: 'trailerDrawbarHeight',
        TRAILERTURNTABLEHEIGHT: 'trailerTurntableHeight',
        TRAILERTYRERADIUS: 'trailerTyreRadius',
        TRAILERTYREWIDTH: 'trailerTyreWidth',
        TRAILERNUMBEROFTYRESONAXLE1REAR: 'trailerNumberOfTyresOnAxle1Rear',
        TRAILERNUMBEROFTYRESONAXLE2REAR: 'trailerNumberOfTyresOnAxle2Rear',
        TRAILERNUMBEROFTYRESONAXLE3REAR: 'trailerNumberOfTyresOnAxle3Rear',
        TRAILERNUMBEROFTYRESONAXLE4REAR: 'trailerNumberOfTyresOnAxle4Rear',
        TRAILERTAREFRONT: 'trailerTareFront',
        TRAILERTAREREAR: 'trailerTareRear',
        TRAILERTARETOTAL: 'trailerTareTotal',
        TRAILERTAREFRONTOVERRIDE: 'trailerTareFrontOverride',
        TRAILERTAREREAROVERRIDE: 'trailerTareRearOverride',
        TRAILERMANUFACTURERSREARAXLESMASS: 'trailerManufacturersRearAxlesMass',
        TRAILERMANUFACTURERSFRONTAXLESMASS: 'trailerManufacturersFrontAxlesMass',
        TRAILERMANUFACTURERSFRONTAXLESMASSOVERLOAD: 'trailerManufacturersFrontAxlesMassOverload',
        TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD: 'trailerManufacturersRearAxlesMassOverload',
        TRAILERGROSSFRONT: 'trailerGrossFront',
        TRAILERGROSSREAR: 'trailerGrossRear',
        TRAILERFRONTROADFRIENDLYSUSPENSION: 'trailerFrontroadFriendlySuspension',
        TRAILERREARROADFRIENDLYSUSPENSION: 'trailerRearRoadFriendlySuspension',
        TRAILERPERMISSIBLEFRONT: 'trailerPermissibleFront',
        TRAILERPERMISSIBLEFRONTOVERRIDE: 'trailerPermissibleFrontOverride',
        TRAILERPERMISSIBLEREAR: 'trailerPermissibleRear',
        TRAILERPERMISSIBLEREAROVERRIDE: 'trailerPermissibleRearOverride',
        TRAILERUNLADENFRONT: 'trailerUnladenFront',
        TRAILERUNLADENREAR: 'trailerUnladenRear',
        TRAILERUNLADENTOTAL: 'trailerUnladenTotal',
        TRAILERPAYLOADREAR: 'trailerPayloadRear',
        TRAILERPAYLOADFRONT: 'trailerPayloadFront',
        TRAILERPAYLOADTOTAL: 'trailerPayloadTotal',
        PERMISSIBLECOMBINATIONTOTAL: 'permissibleCombinationTotal',
        TRAILEROVERLOADREAR: 'trailerOverloadRear',
        TRAILEROVERLOADFRONT: 'trailerOverloadFront',
        TRAILEROVERLOADTOTAL: 'trailerOverloadTotal',
        TRAILERREAROVERHANG: 'trailerRearOverhang',
        TRAILERREAROVERHANGLEGISLATION: 'trailerRearOverhangLegislation',
        TRAILERREAROVERHANGTOTAL: 'trailerRearOverhangTotal',
        TRAILERPAYLOADHORIZONTALCOGFROMHEADBOARDINSIDE: 'trailerPayloadHorizontalCOGFromHeadbaordInside',
        TRAILERPAYLOADHORIZONTALCOGFROMWHEELBASETHEORETICALATREAR: 'trailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear',
        TRAILERVERTICALCOG: 'trailerVerticalCOG',
        TRAILERLATERALCOG: 'trailerLateralCOG',
        TRAILERHORIZONTALCOG: 'trailerHorizontalCOG',
        TRAILERGROSSVEHICLEMASS: 'trailerGrossVehicleMass',
        BUMPERTOBACKOFREARMOSTEQUIPMENT: 'bumperToBackOfRearMostEquipment',
        CABGAPTOTRAILER: 'cabGapToTrailer',
        CABGAPTOSTARTOFBODY: 'cabGapToStartOfBody',
        GAPBETWEENTRAILERANDTRAILER: 'gapBetweenTrailerAndTrailer',
        KINGPINTOREAROFTRAILER: 'kingpinToRearOfTrailer',
        TURNTABLETOREAROFTRAILER: 'turntableToRearOfTrailer',
        COMBINATIONOVERALLLENGTH: 'combinationOverallLength',
        BACKOFTRAILERBODYTOREAROFTRAILER: 'backOfTrailerBodyToRearOfTrailer',
        OVERALLCHASSISOBJECTHEIGHT: 'overallChassisObjectHeight',
        OVERALLCHASSISOBJECTLENGTH: 'overallChassisObjectLength',
        CHASSISOBJECTREAROVERHANGTOTAL: 'chassisObjectRearOverhangTotal',
        COSTINGUTILISATION: 'costingUtilisation',
        COSTINGTRIPS: 'costingTrips',
        COSTINGFINANCEPERIOD: 'costingFinancePeriod',
        COSTINGINTERESTRATE: 'costingInterestRate',
        COSTINGRESIDUALRATE: 'costingResidualRate',
        COSTINGFUELPRICE: 'costingFuelPrice',
        COSTINGFINANCETOTALPRICE: 'costingFinanceTotalPrice',
        COSTINGCREWCOST: 'costingCrewCost',
        COSTINGINSURANCERATE: 'costingInsuranceRate',
        COSTINGLICENCEFEES: 'costingLicenceFees',
        COSTINGLICENCEFEESPROVINCE: 'costingLicenceFeesProvince',
        COSTINGLICENCEFEESREGION: 'costingLicenceFeesRegion',
        COSTINGLICENCEFEESREGIONPRICE: 'costingLicenceFeesRegionPrice',
        COSTINGLICENCEFEESREGIONEFFECTIVEDATE: 'costingLicenceFeesRegionEffectiveDate',
        COSTINGLICENCEFEESREGIONMASS: 'costingLicenceFeesRegionMass',
        COSTINGOVERHEADSRATE: 'costingOverheadsRate',
        COSTINGFUELCONSUMPTION: 'costingFuelConsumption',
        COSTINGFUELCONSUMPTIONIMPERIAL: 'costingFuelConsumptionImperial',
        COSTINGTYRES: 'costingTyres',
        COSTINGMAINTENANCE: 'costingMaintenance',
        COSTINGTOLLFEES: 'costingTollFees',
        COSTINGTOLLFEESPERTRIP: 'costingTollFeesPerTrip',
        COSTINGPERMITCOSTPERTRIP: 'costingPermitCostPerTrip',
        COSTINGOVERALLCOSTPERTRIP: 'costingOverallCostPerTrip',
        COSTINGVEHICLECLASS: 'costingVehicleClass',
        COSTINGTOTALREVENUE: 'costingTotalRevenue',
        PERFORMANCEVEHICLETOTALUNLADEN: 'performanceVehicleTotalUnladen',
        PERFORMANCEFRONTALAREA: 'performanceFrontalArea',
        PERFORMANCECOEFFICIENTOFDRAG: 'performanceCoefficientOfDrag',
        PERFORMANCEDRIVEAXLERATIO: 'performanceDriveAxleRatio',
        PERFORMANCEMAXIMUMSPEED: 'performanceMaximumSpeed',
        PERFORMANCETRAILERTOTALUNLADEN: 'performanceTrailerTotalUnladen',
        PERFORMANCETRAILER2TOTALUNLADEN: 'performanceTrailer2TotalUnladen',
        WAYPOINTDESCRIPTION: 'waypointDescription',
        WAYPOINTPAYLOADLOADED: 'waypointPayloadLoaded',
        WAYPOINTPAYLOADOFFLOADED: 'waypointPayloadOffloaded',
        WAYPOINTNETPAYLOAD: 'waypointNetPayload',
        TURNINGABILITYSPECIFIEDAS: 'turningAbilitySpecifiedAs',
        STEERINGANGLEINNER: 'steeringAngleInner',
        TURNINGRADIUSCURBTOCURB: 'turningRadiusCurbToCurb',
        TURNINGRADIUSWALLTOWALL: 'turningRadiusWallToWall',
        TURNINGRADIUSINNERMIN: 'turningRadiusInnerMin',
        VEHICLETURNINGRADIUSINNER: 'vehicleTurningRadiusInner',
        TURNINGRADIUSCURBTOCURBMAX: 'turningRadiusCurbToCurbMax',
        TURNINGRADIUSOUTERMAX: 'turningRadiusOuterMax',
        WHEELBASETURNING: 'wheelbaseTurning',
        TRAILEROVERALLWIDTHINCLUDINGACCESSORIES: 'trailerOverallWidthIncludingAccessories',
        TRAILEROVERALLWIDTH: 'trailerOverallWidth',
        TRAILERTURNINGRADIUSINNER: 'trailerTurningRadiusInner',
        VEHICLEUNLADENFRONT: 'vehicleUnladenFront',
        VEHICLEANDTRAILERUNLADENTOTAL: 'vehicleAndTrailerUnladenTotal',
        VEHICLEANDTRAILERUNLADENFRONT: 'vehicleAndTrailerUnladenFront',
        VEHICLEANDTRAILERUNLADENREAR: 'vehicleAndTrailerUnladenRear',
        VEHICLEANDTRAILERTRAILERUNLADENREAR: 'vehicleAndTrailerTrailerUnladenRear',
        VEHICLEANDTRAILERTRAILERUNLADENFRONT: 'vehicleAndTrailerTrailerUnlandenFront',
        HITCHDESCRIPTION: 'hitchDescription',
        HITCHOFFSET: 'hitchOffset',
        HITCHMOUNTINGLENGTH: 'hitchMountingLength',
        HITCHCOUPLINGOFFSET: 'hitchCouplingOffset',
        HITCHFROMLEFTOFVEHICLEREARWIDTH: 'hitchFromLeftOfVehicleRearWidth',
        HITCHFUNNELLENGTH: 'hitchFunnelLength',
        HITCHCOUPLINGHEIGHT: 'hitchCouplingHeight',
        HITCHOVERALLHEIGHT: 'hitchOverallHeight',
        HITCHWIDTH: 'hitchWidth',
        HITCHHEIGHTFROMGROUND: 'hitchHeightFromGround',
        HITCHMASSOVERRIDE: 'hitchMassOverride',
        HITCHMASS: 'hitchMass',
        HITCHFRONTAXLELOAD: 'hitchFrontAxleLoad',
        HITCHREARAXLELOAD: 'hitchRearAxleLoad',
        HITCHCOGTYPE: 'hitchCOGType',
        HITCHHORIZONTALCOGOVERRIDE: 'hitchHorizontalCOGOverride',
        HITCHHORIZONTALCOG: 'hitchHorizontalCOG',
        HITCHVERTICALCOGOVERRIDE: 'hitchVerticalCOGOverride',
        HITCHVERTICALCOG: 'hitchVerticalCOG',
        HITCHVERTICALCOGTYPE: 'hitchVerticalCOGType',
        HITCHLATERALCOG: 'hitchLateralCOG',
        HITCHLATERALCOGTYPE: 'hitchLateralCOGType',
        GAPBETWEENVEHICLEANDTRAILER: 'gapBetweenvVehicleAndTrailer',
        GROUP1BRIDGEGROUP: 'group1BridgeGroup',
        GROUP1BRIDGEDISTANCE: 'group1BridgeDistance',
        GROUP1BRIDGEDISTANCEROUNDED: 'group1BridgeDistanceRounded',
        GROUP1BRIDGEPERMISSIBLE: 'group1BridgePermissible',
        GROUP1BRIDGEACTUAL: 'group1BridgeActual',
        GROUP1BRIDGEOVERLOAD: 'group1BridgeOverload',
        GROUP2BRIDGEGROUP: 'group2BridgeGroup',
        GROUP2BRIDGEDISTANCE: 'group2BridgeDistance',
        GROUP2BRIDGEDISTANCEROUNDED: 'group2BridgeDistanceRounded',
        GROUP2BRIDGEPERMISSIBLE: 'group2BridgePermissible',
        GROUP2BRIDGEACTUAL: 'group2BridgeActual',
        GROUP2BRIDGEOVERLOAD: 'group2BridgeOverload',
        GROUP3BRIDGEGROUP: 'group3BridgeGroup',
        GROUP3BRIDGEDISTANCE: 'group3BridgeDistance',
        GROUP3BRIDGEDISTANCEROUNDED: 'group3BridgeDistanceRounded',
        GROUP3BRIDGEPERMISSIBLE: 'group3BridgePermissible',
        GROUP3BRIDGEACTUAL: 'group3BridgeActual',
        GROUP3BRIDGEOVERLOAD: 'group3BridgeOverload',
        GROUP4BRIDGEGROUP: 'group4BridgeGroup',
        GROUP4BRIDGEDISTANCE: 'group4BridgeDistance',
        GROUP4BRIDGEDISTANCEROUNDED: 'group4BridgeDistanceRounded',
        GROUP4BRIDGEPERMISSIBLE: 'group4BridgePermissible',
        GROUP4BRIDGEACTUAL: 'group4BridgeActual',
        GROUP4BRIDGEOVERLOAD: 'group4BridgeOverload',
        GROUP5BRIDGEGROUP: 'group5BridgeGroup',
        GROUP5BRIDGEDISTANCE: 'group5BridgeDistance',
        GROUP5BRIDGEDISTANCEROUNDED: 'group5BridgeDistanceRounded',
        GROUP5BRIDGEPERMISSIBLE: 'group5BridgePermissible',
        GROUP5BRIDGEACTUAL: 'group5BridgeActual',
        GROUP5BRIDGEOVERLOAD: 'group5BridgeOverload',
        GROUP6BRIDGEGROUP: 'group6BridgeGroup',
        GROUP6BRIDGEDISTANCE: 'group6BridgeDistance',
        GROUP6BRIDGEDISTANCEROUNDED: 'group6BridgeDistanceRounded',
        GROUP6BRIDGEPERMISSIBLE: 'group6BridgePermissible',
        GROUP6BRIDGEACTUAL: 'group6BridgeActual',
        GROUP6BRIDGEOVERLOAD: 'group6BridgeOverload',
        GROUP7BRIDGEGROUP: 'group7BridgeGroup',
        GROUP7BRIDGEDISTANCE: 'group7BridgeDistance',
        GROUP7BRIDGEDISTANCEROUNDED: 'group7BridgeDistanceRounded',
        GROUP7BRIDGEPERMISSIBLE: 'group7BridgePermissible',
        GROUP7BRIDGEACTUAL: 'group7BridgeActual',
        GROUP7BRIDGEOVERLOAD: 'group7BridgeOverload',
        GROUP8BRIDGEGROUP: 'group8BridgeGroup',
        GROUP8BRIDGEDISTANCE: 'group8BridgeDistance',
        GROUP8BRIDGEDISTANCEROUNDED: 'group8BridgeDistanceRounded',
        GROUP8BRIDGEPERMISSIBLE: 'group8BridgePermissible',
        GROUP8BRIDGEACTUAL: 'group8BridgeActual',
        GROUP8BRIDGEOVERLOAD: 'group8BridgeOverload',
        GROUP9BRIDGEGROUP: 'group9BridgeGroup',
        GROUP9BRIDGEDISTANCE: 'group9BridgeDistance',
        GROUP9BRIDGEDISTANCEROUNDED: 'group9BridgeDistanceRounded',
        GROUP9BRIDGEPERMISSIBLE: 'group9BridgePermissible',
        GROUP9BRIDGEACTUAL: 'group9BridgeActual',
        GROUP9BRIDGEOVERLOAD: 'group9BridgeOverload',
        GROUP10BRIDGEGROUP: 'group10BridgeGroup',
        GROUP10BRIDGEDISTANCE: 'group10BridgeDistance',
        GROUP10BRIDGEDISTANCEROUNDED: 'group10BridgeDistanceRounded',
        GROUP10BRIDGEPERMISSIBLE: 'group10BridgePermissible',
        GROUP10BRIDGEACTUAL: 'group10BridgeActual',
        GROUP10BRIDGEOVERLOAD: 'group10BridgeOverload',
        GROUP11BRIDGEGROUP: 'group11BridgeGroup',
        GROUP11BRIDGEDISTANCE: 'group11BridgeDistance',
        GROUP11BRIDGEDISTANCEROUNDED: 'group11BridgeDistanceRounded',
        GROUP11BRIDGEPERMISSIBLE: 'group11BridgePermissible',
        GROUP11BRIDGEACTUAL: 'group11BridgeActual',
        GROUP11BRIDGEOVERLOAD: 'group11BridgeOverload',
        GROUP12BRIDGEGROUP: 'group12BridgeGroup',
        GROUP12BRIDGEDISTANCE: 'group12BridgeDistance',
        GROUP12BRIDGEDISTANCEROUNDED: 'group12BridgeDistanceRounded',
        GROUP12BRIDGEPERMISSIBLE: 'group12BridgePermissible',
        GROUP12BRIDGEACTUAL: 'group12BridgeActual',
        GROUP12BRIDGEOVERLOAD: 'group12BridgeOverload',
        GROUP13BRIDGEGROUP: 'group13BridgeGroup',
        GROUP13BRIDGEDISTANCE: 'group13BridgeDistance',
        GROUP13BRIDGEDISTANCEROUNDED: 'group13BridgeDistanceRounded',
        GROUP13BRIDGEPERMISSIBLE: 'group13BridgePermissible',
        GROUP13BRIDGEACTUAL: 'group13BridgeActual',
        GROUP14BRIDGEGROUP: 'group14BridgeGroup',
        GROUP14BRIDGEDISTANCE: 'group14BridgeDistance',
        GROUP14BRIDGEDISTANCEROUNDED: 'group14BridgeDistanceRounded',
        GROUP14BRIDGEPERMISSIBLE: 'group14BridgePermissible',
        GROUP14BRIDGEACTUAL: 'group14BridgeActual',
        GROUP14BRIDGEOVERLOAD: 'group14BridgeOverload',
        GROUP15BRIDGEGROUP: 'group15BridgeGroup',
        GROUP15BRIDGEDISTANCE: 'group15BridgeDistance',
        GROUP15BRIDGEDISTANCEROUNDED: 'group15BridgeDistanceRounded',
        GROUP15BRIDGEPERMISSIBLE: 'group15BridgePermissible',
        GROUP15BRIDGEACTUAL: 'group15BridgeActual',
        GROUP15BRIDGEOVERLOAD: 'group15BridgeOverload',
        GROUP16BRIDGEGROUP: 'group16BridgeGroup',
        GROUP16BRIDGEDISTANCE: 'group16BridgeDistance',
        GROUP16BRIDGEDISTANCEROUNDED: 'group16BridgeDistanceRounded',
        GROUP16BRIDGEPERMISSIBLE: 'group16BridgePermissible',
        GROUP16BRIDGEACTUAL: 'group16BridgeActual',
        GROUP16BRIDGEOVERLOAD: 'group16BridgeOverload',
        GROUP17BRIDGEGROUP: 'group17BridgeGroup',
        GROUP17BRIDGEDISTANCE: 'group17BridgeDistance',
        GROUP17BRIDGEDISTANCEROUNDED: 'group17BridgeDistanceRounded',
        GROUP17BRIDGEPERMISSIBLE: 'group17BridgePermissible',
        GROUP17BRIDGEACTUAL: 'group17BridgeActual',
        GROUP17BRIDGEOVERLOAD: 'group17BridgeOverload',
        GROUP1BRIDGEUNUSEDCAPACITY: 'group1BridgeUnusedCapacity',
        GROUP2BRIDGEUNUSEDCAPACITY: 'group2BridgeUnusedCapacity',
        GROUP3BRIDGEUNUSEDCAPACITY: 'group3BridgeUnusedCapacity',
        GROUP4BRIDGEUNUSEDCAPACITY: 'group4BridgeUnusedCapacity',
        GROUP5BRIDGEUNUSEDCAPACITY: 'group5BridgeUnusedCapacity',
        GROUP6BRIDGEUNUSEDCAPACITY: 'group6BridgeUnusedCapacity',
        GROUP7BRIDGEUNUSEDCAPACITY: 'group7BridgeUnusedCapacity',
        GROUP8BRIDGEUNUSEDCAPACITY: 'group8BridgeUnusedCapacity',
        GROUP9BRIDGEUNUSEDCAPACITY: 'group9BridgeUnusedCapacity',
        GROUP10BRIDGEUNUSEDCAPACITY: 'group10BridgeUnusedCapacity',
        GROUP11BRIDGEUNUSEDCAPACITY: 'group11BridgeUnusedCapacity',
        GROUP12BRIDGEUNUSEDCAPACITY: 'group12BridgeUnusedCapacity',
        GROUP13BRIDGEUNUSEDCAPACITY: 'group13BridgeUnusedCapacity',
        GROUP14BRIDGEUNUSEDCAPACITY: 'group14BridgeUnusedCapacity',
        GROUP15BRIDGEUNUSEDCAPACITY: 'group15BridgeUnusedCapacity',
        GROUP16BRIDGEUNUSEDCAPACITY: 'group16BridgeUnusedCapacity',
        GROUP17BRIDGEUNUSEDCAPACITY: 'group17BridgeUnusedCapacity',
        BRIDGEXDIMENSION: 'bridgeXDimension',
        BRIDGEYDIMENSION: 'bridgeYDimension',
        SUMMARYREPORTOPTION: 'summaryReportOption',
        SUMMARYREPORTVIEWDRAWINGOPTION: 'summaryReportViewOption',
        SUMMARYREPORTLOADPLANOPTION: 'summaryReportLoadPlanOption',
        SUMMARYREPORTWEIGHTANDCOGCALCULATIONOPTION: 'summaryReportWeightAndCogCalculationOption',
        HITCHLOAD: 'hitchLoad',
        KEEPCHASSISFLUSHWITHBODY: 'keepChassisFlushWithBody',
        WHEELBASESPECIFIED: 'wheelbaseSpecified',
        NOOFTYRESONAXLEFORCOSTING: 'noOfTyresOnAxleForCosting',
        AVERAGECOSTOFTYRE: 'averageCostOfTyre',
        AVERAGELIFEOFTYRE: 'averageLifeOfTyre',
        VEHICLEKINGPINLOAD: 'vehicleKingpinLoad',
        TRAILER1KINGPINLOAD: 'trailer1KingpinLoad',
        BODYCOMBINEDSUBFRAMEANDSUBSTRUCTUREHEIGHT: 'bodyCombinedSubframeAndSubstructureHeight',
        ITEMDRAWINGORIGINOFFSETX: 'itemDrawingOriginOffsetX',
        ITEMDRAWINGORIGINOFFSETY: 'itemDrawingOriginOffsetY',
        ITEMDRAWINGORIGINOFFSETZ: 'itemDrawingOriginOffsetZ',
        ITEMDRAWINGLENGTH: 'itemDrawingLength',
        ITEMDRAWINGHEIGHT: 'itemDrawingHeight',
        ITEMDRAWINGWIDTH: 'itemDrawingWidth',
        ITEMMINDRAWINGHORIZONTALAXIS: 'itemMinDrawingHorizontalAxis',
        ITEMMINDRAWINGVERTICALAXIS: 'itemMinDrawingVerticalAxis',
        ITEMLENGTH: 'itemLength',
        ITEMHEIGHT: 'itemHeight',
        ITEMMASS: 'itemMass',
        ITEMMASSTYPE: 'itemMassType',
        ITEMWIDTH: 'itemWidth',
        ITEMHORIZONTALCOG: 'itemHorizontalCOG',
        ITEMHORIZONTALCOGTYPE: 'itemHorizontalCOGType',
        ITEMVERTICALCOG: 'itemVerticalCOG',
        ITEMVERTICALCOGTYPE: 'itemVerticalCOGType',
        ITEMLATERALCOG: 'itemLateralCOG',
        ITEMLATERALCOGTYPE: 'itemLateralCOGType',
        ITEMDESCRIPTION: 'itemDescription',
        ITEMPROTRUSION: 'itemProtrusion',
        ITEMMECHANISMLENGTH: 'itemMechanismLength',
        ITEMMECHANISMHEIGHT: 'itemMechanismHeight',
        ITEMFRONTOVERHANG: 'itemFrontOverhang',
        ITEMMOUNTINGLENGTH: 'itemMountingLength',
        ITEMCOUPLINGOFFSET: 'itemCouplingOffset',
        ITEMCOUPLINGHEIGHT: 'itemCouplingHeight',
        ITEMPAYLOAD: 'itemPayload',
        ITEMDENSITY: 'itemDensity',
        ITEMUNITOFMEASURE: 'itemUnitOfMeasure',
        ITEMSUBFRAMELENGTH: 'itemSubframeLength',
        ITEMLENGTHTYPE: 'itemLengthType',
        ITEMHEADBOARDTHICKNESS: 'itemHeadboardThickness',
        ITEMTAILBOARDTHICKNESS: 'itemTailboardThickness',
        ITEMRAMPLENGTH: 'itemRampLength',
        ITEMRAMPPLATESPROTRUSION: 'itemRampPlatesProtrusion',
        ITEMBOTTOMOFBODYTOTOPOFCHASSIS: 'itemBottomOfBodyToTopOfChassis',
        ITEMSUBFRAMEHEIGHT: 'itemSubframeHeight',
        ITEMSUBSTRUCTUREHEIGHT: 'itemSubstructureHeight',
        ITEMHEIGHTTYPE: 'itemHeightType',
        ITEMROOFTHICKNESS: 'itemRoofThickness',
        ITEMHEADBOARDHEIGHT: 'itemHeadboardHeight',
        ITEMTAILBOARDHEIGHT: 'itemTailboardHeight',
        ITEMRAMPPLATESHEIGHT: 'itemRampPlatesHeight',
        ITEMWIDTHTYPE: 'itemWidthType',
        ITEMSIDEWALLLEFT: 'itemSidewallLeft',
        ITEMSIDEWALLRIGHT: 'itemSidewallRight',
        ITEMLEFTCOMPARTMENTWIDTH: 'itemLeftCompartmentWidth',
        ITEMRIGHTCOMPARTMENTWIDTH: 'itemRightCompartmentWidth',
        ITEMBODYPAYLOADHORIZONTALCOG: 'itemBodyPayloadHorizontalCOG',
        ITEMBODYPAYLOADVERTICALCOG: 'itemBodyPayloadVerticalCOG',
        ITEMBODYPAYLOADLATERALCOG: 'itemBodyPayloadLateralCOG',
        ITEMBODYPAYLOADHORIZONTALCOGTYPE: 'itemBodyPayloadHorizontalCOGType',
        ITEMBODYPAYLOADVERTICALCOGTYPE: 'itemBodyPayloadVerticalCOGType',
        ITEMBODYPAYLOADLATERALCOGTYPE: 'itemBodyPayloadLateralCOGType',
        ITEMBODYDROPSIDEHEIGHT: 'itemBodyDropSideHeight',
        ITEMAFRAME: 'itemAFrame',
        ITEMHOOKOFFSET: 'itemHookOffset',
        ITEMCENTREOFROLLER: 'itemCentreOfRoller',
        ITEMPLATFORMHEIGHT: 'itemPlatformHeight',
        COSTINGFINANCEDETAILLISTPRICE: 'costingFinanceDetailListPrice',
        COSTINGFINANCEDETAILDISCOUNT: 'costingFinanceDetailDiscount',
        COSTINGFINANCEDETAILPERIOD: 'costingFinanceDetailPeriod',
        COSTINGFINANCEDETAILINTERESTRATE: 'costingFinanceDetailInterestRate',
        COSTINGFINANCEDETAILRESIDUAL: 'costingFinanceDetailResidual',
        COSTINGFINANCEDETAILRESIDUALTYPE: 'costingFinanceDetailResidualType',
        BULLBARPROTRUSION: 'bullbarProtrusion',
        TOTALFRONTOVERHANG: 'totalFrontOverhang',
        AXLEDESIREDWEIGHT: 'axleDesiredWeight',
        AXLEPERCENTAGEOFWEIGHTCARRIED: 'axlePercentageOfWeightCarried',
        AXLEPERCENTAGEOFWEIGHTCARRIEDOVERRIDE: 'axlePercentageOfWeightCarriedOverride',
        AXLEBRIDGEFORMULAPERMISSIBLE: 'axleBridgeFormulaPermissible',
        AXLEPERMISSIBLEDETAILED: 'axlePermissibleDetailed',
        AXLEPERMISSIBLESIMPLIFIED: 'axlePermissibleSimplified',
        VEHICLEBRIDGEFORMULAPERMISSIBLE: 'vehicleBridgeFormulaPermissible',
        VEHICLETOTALPERMISSIBLE: 'vehicleTotalPermissible',
        COMBINATIONBRIDGEFORMULAPERMISSIBLE: 'combinationBridgeFormulaPermissible',
        COMBINATIONTOTALPERMISSIBLE: 'combinationTotalPermissible',
        GROSSAXLEWEIGHTRATING: 'grossAxleWeightRating',
        TAREWEIGHTORIGINAL: 'tareWeightOriginal',
        TAREWEIGHTMODIFIED: 'tareWeightModified',
        SAVEACCESSORYDESCRIPTION: 'saveAccessoryDescription',
        SAVEACCESSORYACCESSLEVEL: 'saveAccessoryAccessLevel',
        SAVEACCESSORYPUBLISHTOPUBLICLIBRARY: 'saveAccessoryPublishToPublicLibrary',
        SAVEACCESSORYPUBLISHTODISTRIBUTORLIBRARY: 'saveAccessoryPublishToDistributorLibrary',
        INTERNALSTANDARDMIN: 'internalStandardMin',
        INTERNALSTANDARDMAX: 'internalStandardMax',
        DEFAULTPERCENTAGEVALIDATION: 'defaultPercentageValidation',
        DEFAULTVALUEVALIDATION: 'defaultValueValidation',
        DEFAULTINCREMENTVALIDATION: 'defaultIncrementValidation',
        DEFAULTDEGREESVALIDATION: 'defaultDegreesValidation',
        UNUSEDCAPACITYLABEL: 'unusedCapacityLabel',
        UNUSEDCAPACITYFRONT: 'unusedCapacityFront',
        UNUSEDCAPACITYREAR: 'unusedCapacityRear',
        TRAILERFRONTAXLESMASSUNUSEDCAPACITY: 'TrailerFrontAxlesMassUnusedCapacity',
        TRAILERREARAXLESMASSUNUSEDCAPACITY: 'TrailerRearAxlesMassUnusedCapacity',
        UNUSEDCAPACITYTOTAL: 'unusedCapacityTotal',
        AXLEUNUSEDCAPACITYPUSHER: 'axleUnusedCapacityPusher',
        AXLEUNUSEDCAPACITYTAG: 'axleUnusedCapacityTag',
        AXLESUNUSEDCAPACITYREARREAR: 'axlesUnusedCapacityRearRear',
        HOOKLIFTDESCRIPTION: 'hookliftDescription',
        HOOKLIFTCABGAP: 'hookliftCabGap',
        HOOKLIFTFROMAXLE1FRONT: 'hookliftFromAxle1Front',
        HOOKLIFTABOVEBELOWCHASSIS: 'hookliftAboveBelowChassis',
        HOOKLIFTFROMLEFTOFVEHICLEREARWIDTH: 'hookliftFromLeftOfVehicleRearWidth',
        HOOKLIFTFROMCENTREOFVEHICLE: 'hookliftFromCentreOfVehicle',
        HOOKLIFTLENGTH: 'hookliftLength',
        HOOKLIFTHEIGHT: 'hookliftHeight',
        HOOKLIFTWIDTH: 'hookliftWidth',
        HOOKLIFTMASS: 'hookliftMass',
        HOOKLIFTFRONTAXLELOAD: 'hookliftFrontAxleLoad',
        HOOKLIFTREARAXLELOAD: 'hookliftRearAxleLoad',
        HOOKLIFTHORIZONTALCOG: 'hookliftHorizontalCOG',
        HOOKLIFTVERTICALCOG: 'hookliftVerticalCOG',
        HOOKLIFTLATERALCOG: 'hookliftLateralCOG',
        HOOKLIFTCOGTYPE: 'hookliftCOGType',
        HOOKLIFTVERTICALCOGTYPE: 'hookliftVerticalCOGType',
        HOOKLIFTLATERALCOGTYPE: 'hookliftLateralCOGType',
        HOOKLIFTAFRAME: 'hookliftAFrame',
        HOOKLIFTHOOKOFFSET: 'hookliftHookOffset',
        HOOKLIFTCENTREOFROLLER: 'hookliftCentreOfRoller',
        HOOKLIFTPLATFORMHEIGHT: 'hookliftPlatformHeight',
        HOOKLIFTREARMOSTAXLETOCENTREOFROLLER: 'hookliftRearmostAxleToCentreOfRoller',
        HOOKLIFTCENTREOFROLLERTOENDOFHOOKLIFT: 'hookliftCentreOfRollerToEndOfHooklift',
        HOOKLIFTCENTREOFROLLERTOEND: 'hookliftCentreOfRollerToEnd',
        HOOKLIFTGAPTOBODYFROMHOOKOFFSET: 'hookliftGapToBodyFromHookOffset',
        HOOKLIFTENDOFEQUIPMENTTOENDOFCHASSIS: 'hookliftEndOfEquipmentToEndOfChassis',
        COSTINGFUELANDADBLUECOSTPERMONTH: 'costingFuelAndAdBlueCostPerMonth',
        COSTINGFUELANDADBLUEDETAILCOSTPERMONTH: 'costingFuelAndAdBlueDetailCostPerMonth',
        // COSTINGFUELANDADBLUEDETAILCONSUMPTION: 'costingFuelAndAdBlueDetailConsumption',
        COSTINGFUELANDADBLUEDETAILFUELCONSUMPTION: 'costingFuelAndAdBlueDetailFuelConsumption',
        COSTINGFUELANDADBLUEDETAILADBLUECONSUMPTION: 'costingFuelAndAdBlueDetailAdBlueConsumption',
        COSTINGFUELANDADBLUEEQUIPMENTFUELCONSUMPTION: 'costingFuelAndAdBlueEquipmentFuelConsumption',
        COSTINGFUELANDADBLUEDETAILCOSTPERVOLUME: 'costingFuelAndAdBlueDetailCostPerVolume',
        COSTINGFUELANDADBLUEDETAILUTILISATION: 'costingFuelAndAdBlueDetailUtilisation',
    },

    SPEC_DATA_POINT = {
        AxleRatio: 'AxleRatio' 
    },
    
    SPEC_DATA_POINT_TO_CALCULATION_DATA_POINT_MAPPING = {
        [SPEC_DATA_POINT.AxleRatio]: 'StandardRatio' 
    },

    

    DDR_RULE_TYPE = {
        WARNING: 'WARNING',
        CAUTION: 'CAUTION',
        UNKNOWN: 'UNKNOWN',
        NOTE: 'NOTE',
        TIP: 'TIP'
    },

    DDR_RULE_CATEGORY = {
        WEIGHTS: 'WEIGHTS',
        BRIDGE: 'BRIDGE',
        DIMENSIONS: 'DIMENSIONS',
        TURNING_CIRCLE: 'TURNINGCIRCLE'
    },

    DDR_LEGISLATION_ATTRIBUTE_PREFIX = 'ddr',

    DDR_RULE_CONTEXT_PREFIX = {
        DIM: 'DIM',
        AXLE: 'AXLE',
        BRIDGE: 'BRIDGE'
    },

    DDR_MESSAGE_PARAMETER_VALUE_TYPE = {
        CALCULATION: 'CALCULATION',
        LEGISLATION: 'LEGISLATION'
    },

    EXTREME_BRIDGE_GROUPS = {
        COMBINATION: 'COMBINATION',
        VEHICLE: 'VEHICLE',
        VEHICLE_FRONT: 'VEHICLEFRONT',
        VEHICLE_REAR: 'VEHICLEREAR',
        VEHICLE_REAR_ALL: 'VEHICLEREARALL',
        VEHICLE_REAR_MAIN: 'VEHICLEREARMAIN',
        VEHICLE_REAR_MINUS_1: 'VEHICLEREARMINUS1',
        VEHICLE_REAR_MINUS_2: 'VEHICLEREARMINUS2',
        VEHICLE_REAR_MINUS_3: 'VEHICLEREARMINUS3',
        VEHICLE_REAR_PLUS_1: 'VEHICLEREARPLUS1',
        VEHICLE_REAR_PLUS_2: 'VEHICLEREARPLUS2',
        VEHICLE_REAR_PLUS_3: 'VEHICLEREARPLUS3',
        VEHICLE_FRONT_TRAILER1_FRONT: 'VEHICLEFRONTTRAILER1FRONT',
        VEHICLE_FRONT_TRAILER1_REAR: 'VEHICLEFRONTTRAILER1REAR',
        VEHICLE_REAR_TRAILER1_FRONT: 'VEHICLEREARTRAILER1FRONT',
        VEHICLE_REAR_TRAILER1_REAR: 'VEHICLEREARTRAILER1REAR',
        VEHICLE_FRONT_TRAILER2_FRONT: 'VEHICLEFRONTTRAILER2FRONT',
        VEHICLE_FRONT_TRAILER2_REAR: 'VEHICLEFRONTTRAILER2REAR',
        VEHICLE_REAR_TRAILER2_FRONT: 'VEHICLEREARTRAILER2FRONT',
        VEHICLE_REAR_TRAILER2_REAR: 'VEHICLEREARTRAILER2REAR',
        TRAILER1: 'TRAILER1',
        TRAILER1_FRONT: 'TRAILER1FRONT',
        TRAILER1_REAR: 'TRAILER1REAR',
        TRAILER1_FRONT_TRAILER2_FRONT: 'TRAILER1FRONTTRAILER2FRONT',
        TRAILER1_FRONT_TRAILER2_REAR: 'TRAILER1FRONTTRAILER2REAR',
        TRAILER1_REAR_TRAILER2_FRONT: 'TRAILER1REARTRAILER2FRONT',
        TRAILER1_REAR_TRAILER2_REAR: 'TRAILER1REARTRAILER2REAR',
        TRAILER2: 'TRAILER2',
        TRAILER2_FRONT: 'TRAILER2FRONT',
        TRAILER2_REAR: 'TRAILER2REAR',
    },

    OTHER_TOOLTIP = {
        PASSWORD_CRITERIA: '2924',
        PASSWORD_MISMATCH: '2926',
        OLD_PASSWORD_INCORRECT: '2927'
    },

    WIZARD_INCREMENT = {
        SELECTION: 20,
        ACCESSORY: 15
    },

    VALIDATION_OP_TYPE = {
        INC_BY_DELTA: 'incByDelta',
        DEC_BY_DELTA: 'decByDelta',
        APPLY_NEW_VALUE: 'applyNewValue',
        APPLY_OLD_VALUE: 'applyOldValue',
        SET_TO_RESULT_FUNC_WITH_PARAM: 'setToResultFuncWithParam',
        CUSTOM: 'custom',
        DUMMY_OP: 'dummyOp'
    },

    OP_UNDO_ORDER = {
        NORMAL: 'normal',
        REVERSE: 'reverse'
    },

    CALCULATION_OP_TYPES = {
        ADD_RIG_OBJECT: 'ADD_RIG_OBJECT',
        REMOVE_RIG_OBJECT: 'REMOVE_RIG_OBJECT',
        CHANGE_VALUE: 'CHANGE_VALUE',
        COMPOUND_CHANGE_VALUE: 'COMPOUND_CHANGE_VALUE',
        CHANGE_SELECT: 'CHANGE_SELECT',
        CHANGE_CHECKBOX: 'CHANGE_CHECKBOX',
        ADD_WAYPOINT: 'ADD_WAYPOINT',
        REMOVE_WAYPOINT: 'REMOVE_WAYPOINT',
        CHANGE_WAYPOINT_ORDER: 'CHANGE_WAYPOINT_ORDER',
        ADD_AXLE: 'ADD_AXLE',
        REMOVE_AXLE: 'REMOVE_AXLE'
    },

CHECKBOX_TYPE = {
    MENU_ITEM: 'MENU_ITEM',
    OVERRIDE: 'OVERRIDE'
},

YES_NO_OPTIONS = {
    YES: 'YES',
    NO: 'NO',
    NOT_SPECIFIED: 'NOTSPECIFIED'
},

    VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES = {
        YES: 'YES',
        NO: 'NO'
    },

    VEHICLE_ROLLOVER_PROTECTION_TYPES = {
        YES: 0,
        NO: 1
    },

    VEHICLE_FRONT_UNDERRUN_PROTECTION_TYPES = {
        YES: 0,
        NO: 1
    },

    TARE_DRIVER_INCLUDED_OPTIONS = {
        YES: 'YES',
        NO: 'NO'
    },

    VEHICLE_AXLE_POSITION_TYPES = {
        FRONT: 0,
        REAR: 1,
        PUSHER: 2,
        TAG: 3,
        SECONDSTEER: 4,
        NOTAPPLICABLE: 5
    },

    AXLE_LOCATIONS = {
        FRONT: 'FRONT',
        REAR: 'REAR'
    },

    AXLE_POSITIONS = {
        AXLE_1_FRONT: 'AXLE_1_FRONT',
        AXLE_2_FRONT: 'AXLE_2_FRONT',
        AXLE_3_FRONT: 'AXLE_3_FRONT',
        AXLE_1_REAR: 'AXLE_1_REAR',
        AXLE_2_REAR: 'AXLE_2_REAR',
        AXLE_3_REAR: 'AXLE_3_REAR',
        AXLE_4_REAR: 'AXLE_4_REAR'
    },

    AXLE_SECTIONS = {
        AXLE_1_FRONT_TO_AXLE_2_FRONT: 'AXLE_1_FRONT_TO_AXLE_2_FRONT',
        AXLE_2_FRONT_TO_AXLE_3_FRONT: 'AXLE_2_FRONT_TO_AXLE_3_FRONT',
        AXLE_1_REAR_TO_AXLE_2_REAR: 'AXLE_1_REAR_TO_AXLE_2_REAR',
        AXLE_2_REAR_TO_AXLE_3_REAR: 'AXLE_2_REAR_TO_AXLE_3_REAR',
        AXLE_3_REAR_TO_AXLE_4_REAR: 'AXLE_3_REAR_TO_AXLE_4_REAR'
    },

    VEHICLE_COMBINATION_TYPE = {
        Vehicle: 0,
        VehicleAndDrawbar: 1,
        VehicleAndSemi: 2,
        VehicleAndPup: 3,
        VehicleAndInterlinkAndSemi: 4,
        VehicleAndSemiAndDrawbar: 5,
        VehicleAndSemiAndPup: 6,
        Unknown: 7
    },

    OVERLOADING_STATUS = {
        OK: 'ok',
        OVERLOADED: 'overloaded',
        UNDERLOADED: 'underloaded',
        OVERLOADED_WITHIN_TOLERANCES: 'overloadedWithinTolerances'
    },

    COMPLIANCE_STATUS_ICON = {
        PASS: '\uf00c',
        FAIL: '\uf00d',
        WARNING: '\uf071',
        UNKNOWN: '\uf068'//'\uf128'
    },

    COMPLIANCE_IDS = {
        LEGISLATION: 'legislation',
        LEGISLATION_TEXT: 'LEGISLATION_TEXT',
        LEGISLATION_ICON: 'LEGISLATION_ICON',
        APPLICATION: 'application',
        PERMISSIBLE_WEIGHTS: 'PERMISSIBLE_WEIGHTS',
        PERMISSIBLE_WEIGHTS_TEXT: 'PERMISSIBLE_WEIGHTS_TEXT',
        PERMISSIBLE_WEIGHTS_ICON: 'PERMISSIBLE_WEIGHTS_ICON',
        DIMENSIONS: 'DIMENSIONS',
        DIMENSIONS_TEXT: 'DIMENSIONS_TEXT',
        DIMENSIONS_ICON: 'DIMENSIONS_ICON',
        TURNING_CIRCLE: 'TURNING_CIRCLE',
        TURNING_CIRCLE_TEXT: 'TURNING_CIRCLE_TEXT',
        TURNING_CIRCLE_ICON: 'TURNING_CIRCLE_ICON',
        BRIDGE_FORMULA: 'BRIDGE_FORMULA',
        BRIDGE_FORMULA_TEXT: 'BRIDGE_FORMULA_TEXT',
        BRIDGE_FORMULA_ICON: 'BRIDGE_FORMULA_ICON',
        MANUFACTURER_WEIGHTS: 'manufacturerWeights',
        MANUFACTURER_WEIGHTS_TEXT: 'MANUFACTURER_WEIGHTS_TEXT',
        MANUFACTURER_WEIGHTS_ICON: 'MANUFACTURER_WEIGHTS_ICON',
        TITLE: 'title',
        NAVIGATION: 'NAVIGATION',
        NAVIGATION_TITLE_TEXT: 'NAVIGATION_TITLE_TEXT',
        NAVIGATION_TITLE_DELIMITER: 'NAVIGATION_TITLE_DELIMITER',
        NAVIGATION_SETTINGS_ICON: 'NAVIGATION_SETTINGS_ICON',
        NAVIGATION_SPACER: 'NAVIGATION_SPACER',
        REGULATORY_COMPLIANCE: 'REGULATORY_COMPLIANCE',
        REGULATORY_COMPLIANCE_TEXT: 'REGULATORY_COMPLIANCE_TEXT',
        REGULATORY_COMPLIANCE_ICON: 'REGULATORY_COMPLIANCE_ICON',
        COMPLIANCE_SPACER_1: 'COMPLIANCE_SPACER_1',
        COMPLIANCE_SPACER_2: 'COMPLIANCE_SPACER_2',
        COMPLIANCE_SEPARATOR_LINE: 'COMPLIANCE_SEPARATOR_LINE',
        INTERNAL_STANDARDS: 'INTERNAL_STANDARDS',
        INTERNAL_STANDARDS_TEXT: 'INTERNAL_STANDARDS_TEXT',
        INTERNAL_STANDARDS_ICON: 'INTERNAL_STANDARDS_ICON',
    },

    COMPLIANCE_ROW_TEXT_POSITION_METHOD = {
        SEQUENTIAL_FROM_LEFT: 'SEQUENTIAL_FROM_LEFT',
        SUBSEQUENT_FROM_RIGHT: 'SUBSEQUENT_FROM_RIGHT'
    },

    DISPLAY_TYPE = {
        DETAILED: 'detailed',
        SIMPLIFIED: 'simplified'
    },

    VIEW_TYPE = {
        OVERVIEW: 'overview',
        COG: 'cog',
        TURNING_CIRCLE: 'turningCircle',
        ELEVATION: 'elevation',
    },

    PREVIEW_TYPE = {
        STANDARD: 'standard',
        ADJUST_ORIGIN: 'adjustOrigin',
        ADJUST_COG: 'adjustCOG',
        ADJUST_SCALE: 'adjustScale',
        REVIEW_AND_FINALISE: 'reviewAndFinalise'
    },

    LAYERING_ADJUSTMENT = {
        NONE: 'NONE',
        BRING_TO_FRONT: 'BRING_TO_FRONT',
        DASH_AND_BRING_TO_FRONT: 'DASH_AND_BRING_TO_FRONT'
    },

    VIEW_DETAIL = {
        SUMMARY: 'summary',
        EXTRAS: 'extras',
        TRAILER1: 'trailer1',
        TRAILER2: 'trailer2',
        TURNING_CIRCLE_REGULATION: 'regulation',
        TURNING_CIRCLE_SMALLEST: 'smallest',
        BRIDGE_VIEW: 'bridgeView',
        CHASSIS: 'chassis',
        VEHICLE: 'vehicle'
    },

    ELEVATION = {
        SIDE: 'side',
        TOP: 'top'
    },

    GRAPHICS_ENGINE_RENDER_MODE = {
        FULL_RIG: 'fullRig',
        ITEM_ONLY: 'itemOnly'
    },

    DIMENSION_LAYER_CHANGE = {
        LEFT_TO_RIGHT: 'LEFT_TO_RIGHT',
        RIGHT_TO_LEFT: 'RIGHT_TO_LEFT',
        TOP_TO_BOTTOM: 'TOP_TO_BOTTOM',
        BOTTOM_TO_TOP: 'BOTTOM_TO_TOP'
    },

    EMAIL_VERIFICATION_STATUS_OPTIONS = {
        NOT_VERIFIED: 'NOTVERIFIED',
        VERIFIED: 'VERIFIED'
    },

    EMAIL_VERIFIED_FROM_OPTIONS = {
        EXTERNAL: 'EXTERNAL',
        IN_APP: 'In App'
    },

    NUMBER_OF_TYRES_ON_AXLE_OPTIONS = {
        TWO: {
            id: 2,
            val: 2
        },
        FOUR: {
            id: 4,
            val: 4
        }
    },

    FUEL_TYPE_OPTIONS = {
        DIESEL: {
            CODE: 'DIESEL',
            VALUE: 0.84
        },
        PETROL: {
            CODE: 'PETROL',
            VALUE: 0.76
        },
        ETHANOL: {
            CODE: 'ETHANOL',
            VALUE: 0.79
        },
        LNG: {
            CODE: 'LNG',
            VALUE: 2.749
        },
        CNG: {
            CODE: 'CNG',
            VALUE: 2.567
        },
        BIODIESEL: {
            CODE: 'BIODIESEL',
            VALUE: 0.88
        },
        HYDROGEN_FUEL_CELL: {
            CODE: 'HYDROGENFUELCELL',
            VALUE: 1.0
        },
        ADBLUE: {
            CODE: 'ADBLUE',
            VALUE: 1.09
        },
        BATTERY_ELECTRIC: {
            CODE: 'BATTERYELECTRIC',
            VALUE: 0
        }
    },

    OFFERED_AS_OPTIONS = {
        STANDARD: 'STANDARD',
        OPTIONAL: 'OPTIONAL'
    },

    STEERING_ATTRIBUTE_TYPES = {
        STEERING_ANGLE_INNER: 'STEERINGANGLEINNER',
        TURNING_RADIUS_CURB_TO_CURB: 'RADIUSCURBTOCURB',
        TURNING_RADIUS_WALL_TO_WALL: 'RADIUSWALLTOWALL'
    },

    RENDERED_AS_OPTIONS = {
        DATAGRID: 'DATAGRID',
        QUICK_SEARCH_DATAGRID: 'QUICKSEARCHDATAGRID',
        IMAGE_CARD: 'IMAGE_CARD',
        TEXT_CARD: 'TEXT_CARD',
        CARD_VEHICLE_TYPE: 'VEHICLE_TYPE',
        SELECTION_WIZARD: 'SELECTIONWIZARD',
        REQUEST_A_VEHICLE: 'REQUESTAVEHICLE',
        MENU: 'MENU'
    },

    IMAGE_CARD_RENDERED_AS_OPTIONS = {
        AXLE_LAYOUT: 'AxleLayout',
        VEHICLE_MAKE: 'VehicleMake',
        BODY_TYPE: 'BodyType',
        ACCESSORY_TYPE: 'AccessoryType',
        TRAILER_TYPE: 'TrailerType',
        SELECTION_RESOURCE: 'SelectionResource'
    },

    SORT_OPTIONS = {
        ASC: 'ASC',
        DESC: 'DESC'
    },

    ACTION_FAILURE_REASONS = {
        NO_BODY: 'NO_BODY',
        ALREADY_ADDED: 'ALREADY_ADDED',
        INCOMPATIBLE_WITH_BODY: 'INCOMPATIBLE_WITH_BODY',
        NO_FIFTH_WHEEL: 'NO_FIFTH_WHEEL',
        HAS_FIFTH_WHEEL: 'HAS_FIFTH_WHEEL',
        HAS_BODY: 'HAS_BODY',
        NO_HITCH: 'NO_HITCH',
        MUST_DELETE_TRAILER_FIRST: 'MUST_DELETE_TRAILER_FIRST',
        MUST_DELETE_TRAILER2_FIRST: 'MUST_DELETE_TRAILER2_FIRST',
        NO_SPACE_SECOND_STEER: 'NO_SPACE_SECOND_STEER',
        NO_SPACE_PUSHER: 'NO_SPACE_PUSHER',
        NO_SPACE_TAG: 'NO_SPACE_TAG',
        INCORRECT_TAG_DELETE_ORDER: 'INCORRECT_TAG_DELETE_ORDER',
        INCORRECT_PUSHER_DELETE_ORDER: 'INCORRECT_PUSHER_DELETE_ORDER',
        INCORRECT_TAG_RAISE_ORDER: 'INCORRECT_TAG_RAISE_ORDER',
        INCORRECT_PUSHER_RAISE_ORDER: 'INCORRECT_PUSHER_RAISE_ORDER',
        INCORRECT_TAG_DROP_ORDER: 'INCORRECT_TAG_DROP_ORDER',
        INCORRECT_PUSHER_DROP_ORDER: 'INCORRECT_PUSHER_DROP_ORDER',
        ACTION_NOT_POSSIBLE_ON_PENDING: 'ACTION_NOT_POSSIBLE_ON_PENDING',
        INCOMPATIBLE_WITH_TRAILER: 'INCOMPATIBLE_WITH_TRAILER',
        NO_INTERLINK: 'NO_INTERLINK',
        TRAILER_1_NOT_INTERLINK: 'TRAILER_1_NOT_INTERLINK',
        TRAILER2_NOT_ALLOWED: 'TRAILER2_NOT_ALLOWED',
        COMPLETE_VEHICLE: 'COMPLETE_VEHICLE',
        NO_HOOKLIFT_ACCESSORY: 'NO_HOOKLIFT_ACCESSORY',
        MUST_DELETE_BODY_FIRST: 'MUST_DELETE_BODY_FIRST',
        USER_NEEDS_TO_UPGRADE: 'USER_NEEDS_TO_UPGRADE'
    },

    //APP_ACTION = {
    //    ADD: 'ADD',
    //    DELETE: 'DELETE',

    //},

    SELECTION_WIZARD_MENU_ITEMS = {
        QUICK_SEARCH: 'quickSearch',
        SAVED_OFFERS: 'savedOffers',
        GENERIC_OFFERS: 'genericOffers',
        MANUFACTURER_OFFERS: 'manufacturerOffers',
        REQUEST_A_VEHICLE: 'requestAVehicle',
        SHARED_OFFERS: 'sharedOffers',
        NEW_CALCULATIONS: 'newCalculations',
        RESOURCES: 'resources'
    },

    GET_STARTED_MODES = {
        SIGN_UP: 'signup',
        SIGN_IN: 'signin'
    },

    APP_OPTION_ITEMS = {
        WELCOME_FULL: {
            Code: 'WELCOME_FULL',
            Type: 'MESSAGE'
        },
        WELCOME_TRIAL: {
            Code: 'WELCOME_TRIAL',
            Type: 'MESSAGE'
        },
        SELECTION_OVERVIEW_TOUR: {
            Code: 'SELECTION',
            Type: 'OVERVIEW_TOUR'
        },
        SELECTION_NEW_FEATURES_TOUR: {
            Code: 'SELECTION',
            Type: 'NEW_FEATURES_TOUR'
        },
        CONFIGURATION_OVERVIEW_TOUR: {
            Code: 'CONFIGURATION',
            Type: 'OVERVIEW_TOUR'
        },
        CONFIGURATION_NEW_FEATURES_TOUR: {
            Code: 'CONFIGURATION',
            Type: 'NEW_FEATURES_TOUR'
        },
        COSTING_OVERVIEW_TOUR: {
            Code: 'COSTING',
            Type: 'OVERVIEW_TOUR'
        },
        COSTING_NEW_FEATURES_TOUR: {
            Code: 'COSTING',
            Type: 'NEW_FEATURES_TOUR'
        },
        PERFORMANCE_OVERVIEW_TOUR: {
            Code: 'PERFORMANCE',
            Type: 'OVERVIEW_TOUR'
        },
        PERFORMANCE_NEW_FEATURES_TOUR: {
            Code: 'PERFORMANCE',
            Type: 'NEW_FEATURES_TOUR'
        },
        SPECIFICATION_OVERVIEW_TOUR: {
            Code: 'SPECIFICATION',
            Type: 'OVERVIEW_TOUR'
        },
        SPECIFICATION_NEW_FEATURES_TOUR: {
            Code: 'SPECIFICATION',
            Type: 'NEW_FEATURES_TOUR'
        },
        SUMMARY_OVERVIEW_TOUR: {
            Code: 'SUMMARY',
            Type: 'OVERVIEW_TOUR'
        },
        SUMMARY_NEW_FEATURES_TOUR: {
            Code: 'SUMMARY',
            Type: 'NEW_FEATURES_TOUR'
        },
        REFERRAL_DONT_ASK_ME_AGAIN: {
            Code: 'REFERRAL_PROMPT',
            Type: 'MESSAGE'
        },
        RECOMMENDED_BROWSER_DONT_ASK_ME_AGAIN: {
            Code: 'BROWSER_PROMPT',
            Type: 'MESSAGE'
        },
        QUICK_SEARCH_HELPER_DONT_ASK_ME_AGAIN: {
            Code: 'QUICK_SEARCH_PROMPT',
            Type: 'MESSAGE'
        },
        STANDARD_ITEM_DONT_ASK_ME_AGAIN: {
            Code: 'STANDARD_ITEM_PROMPT',
            Type: 'MESSAGE'
        }
    },

    APP_OPTION_ITEM_STATUS = {
        ACTIVE: 'ACTIVE',
        DISMISSED: 'DISMISSED'
    },

    INTERCOM_USER = {
        APP_ID: 'app_id',
        EMAIL: 'email',
        USER_HASH: 'user_hash',
        NAME: 'name',
        LICENCE: 'licence',
        STATUS: 'status',
        EXPIRY_DATE: 'expiry_at',
        PLAN: 'plan',
        FIRST_LOGIN_DATE: 'first_login_at',
        LATEST_LOGIN_DATE: 'latest_login_at',
        COMPANY: 'company',
        CUSTOMER_DISTRIBUTION_GROUP: 'cdg',
        SUPPORT_PLAN: 'support_plan',
        APP_VERSION: 'app_version',
        COUNTRY_CODE: 'country_code',
        PHONE: 'phone',
        PRODUCT: 'product',
        EDITION: 'edition',
        SUBSCRIPTION_NEXT_BILLING_AT: 'subscription_next_billing_at',
        SUBSCRIPTION_STARTED_AT: 'subscription_started_at'
    },

    INTERCOM_USER_COMPANY = {
        ID: 'id',
        NAME: 'name'
    },

    INTERCOM_EVENT = {
        LOGGED_IN: 'logged-in',
        OPENED_OFFER: 'opened-offer',
        CLOSED_OFFER: 'closed-offer',
        DELETED_OFFER: 'deleted-offer',
        REQUESTED_VEHICLE: 'requested-vehicle',
        CHANGED_PASSWORD: 'changed-password',
        BUY_NOW_CLICKED: 'buy-now-clicked',
        BOUGHT_PLAN: 'bought-plan',
        LOGGED_OUT: 'logged-out',
        SAVED_OFFER: 'saved-offer',
        EXPORTED_OFFER_TO_PDF: 'exported-offer-to-pdf',
        DELETED_TEAM_BODY: 'deleted-team-body',
        DELETED_PERSONAL_BODY: 'deleted-personal-body',
        DELETED_TEAM_EQUIPMENT: 'deleted-team-equipment',
        DELETED_PERSONAL_EQUIPMENT: 'deleted-personal-equipment',
        REMOVED_BODY: 'removed-body',
        REMOVED_EQUIPMENT: 'removed-equipment',
        EMAILED_OFFER: 'emailed-offer',
        CHANGED_SETTINGS: 'changed-settings',
        ADDED_BODY: 'added-body',
        ADDED_EQUIPMENT: 'added-equipment',
        SAVED_TEAM_BODY: 'saved-team-body',
        SAVED_PERSONAL_BODY: 'saved-personal-body',
        SAVED_TEAM_EQUIPMENT: 'saved-team-equipment',
        SAVED_PERSONAL_EQUIPMENT: 'saved-personal-equipment',
        CHANGED_DEVICES: 'changed-devices',
        CHANGED_CONNECTIVITY: 'changed-connectivity',
        VERIFIED_EMAIL: 'verified-email',
        CHANGED_MODULE: 'changed-module',
        ERROR_RAISED: 'error-raised',
        REFRESHED_DATA: 'refreshed-data',
        VIEWED_PRODUCT_TOUR: 'viewed-product-tour',
        FORCED_DATA_REFRESH: 'forced-data-refresh',
        SYNC_FAILED: 'sync-failed',
        ADDED_TRAILER_1: 'added-trailer-1',
        REMOVED_TRAILER_1: 'removed-trailer-1',
        ADDED_TRAILER_2: 'added-trailer-2',
        REMOVED_TRAILER_2: 'removed-trailer-2',
        SAVED_TEAM_TRAILER: 'saved-team-trailer',
        SAVED_PERSONAL_TRAILER: 'saved-personal-trailer',
        DELETED_TEAM_TRAILER: 'deleted-team-trailer',
        DELETED_PERSONAL_TRAILER: 'deleted-personal-trailer',
        CHANGED_VIEW: 'changed-view',
        SEARCHED_FOR_VEHICLE: 'searched-for-vehicle',
        RENAMED_OTHER_EQUIPMENT: 'renamed-other-equipment',
        SELECTED_PLAN: 'selected-plan',
        CONSIDERED_VEHICLE_REQUEST: 'considered-vehicle-request',
        REQUESTED_EQUIPMENT: 'requested-equipment',
        REQUESTED_TRAILER: 'requested-trailer',
        REQUESTED_BODY: 'requested-body',
        REQUESTED_PAYLOAD: 'requested-payload',
        CONSIDERED_EQUIPMENT_REQUEST: 'considered-equipment-request',
        CONSIDERED_TRAILER_REQUEST: 'considered-trailer-request',
        CONSIDERED_BODY_REQUEST: 'considered-body-request',
        CONSIDERED_PAYLOAD_REQUEST: 'considered-payload-request',
        SEARCHED_FOR_EQUIPMENT: 'searched-for-equipment',
        ATTEMPTED_AXLE_ADDITION: 'attempted-axle-addition',
        SIMULATED_VEHICLE: 'simulated-vehicle',
        OVERWROTE_FUEL_CONSUMPTION: 'overwrote-fuel-consumption',
        OVERWROTE_TYRE_RATE: 'overwrote-tyre-rate',
        BUY_PLAN_FAILED: 'buy-plan-failed',
        CHANGED_PAYLOAD_TYPE: 'changed-payload-type',
        ADDED_PAYLOAD_ITEM: 'added-payload-item',
        REMOVED_PAYLOAD_ITEM: 'removed-payload-item',
        RENAMED_PAYLOAD_ITEM: 'renamed-payload-item',
        SAVED_TEAM_PAYLOAD_ITEM: 'saved-team-payload-item',
        SAVED_PERSONAL_PAYLOAD_ITEM: 'saved-personal-payload-item',
        DELETED_TEAM_PAYLOAD_ITEM: 'deleted-team-payload-item',
        DELETED_PERSONAL_PAYLOAD_ITEM: 'deleted-personal-payload-item',
        REFERRAL_INITIATED: 'referral-initiated',
        REFERRAL_COMPLETED: 'referral-completed',
        REFERRAL_DONT_ASK_AGAIN: 'referral-dont-ask-again',
        ADDED_AXLE: 'added-axle',
        REMOVED_AXLE: 'removed-axle',
        RAISED_AXLE: 'raised-axle',
        DROPPED_AXLE: 'dropped-axle',
        APP_UPDATE_DETECTED: 'app-update-detected',
        VEHICLE_SWAP_SUCCEEDED: 'vehicle-swap-succeeded',
        VEHICLE_SWAP_FAILED: 'vehicle-swap-failed',
        VEHICLE_SWAP_CANCELLED: 'vehicle-swap-cancelled',
        CSV_UPLOADED: 'csv-uploaded',
        PENDING_VEHICLE_UPDATED: 'pending-vehicle-updated',
        PENDING_VEHICLE_SUBMITTED: 'pending-vehicle-submitted',
        DOWNLOAD_VEHICLE_CSV: 'download-vehicle-csv',
        SHARED_OFFER: 'shared-offer',
        SHARED_OFFER_ACCEPTED: 'shared-offer-accepted',
        UNSHARED_OFFER: 'unshared-offer',
        CONSIDERED_NTEA_INTEGRATION: 'considered-ntea-integration',
        ADDED_NTEA_INTEGRATION: 'added-ntea-integration',
        REMOVED_NTEA_INTEGRATION: 'removed-ntea-integration',
        UPDATED_NTEA_INTEGRATION: 'updated-ntea-integration',
        ATTEMPTED_TRAILER_2_ADDITION: 'attempted-trailer-2-addition',
        CONSIDERED_DXF_IMPORT: 'considered-dxf-import',
        IMPORTED_DXF: 'imported-dxf',
        CREATED_FOLDER: 'created-folder',
        DELETED_ITEMS: 'deleted-items',
        MOVED_ITEMS: 'moved-items',
        RENAMED_ITEM: 'renamed-item',
        OPENED_RESOURCE: 'opened-resource',
        FAILED_TO_IMPORT_DXF: 'failed-to-import-dxf',
        UPGRADE_CONSIDERED: 'upgrade-considered',
        UPGRADE_REQUESTED: 'upgrade-requested',
        OPENED_REQUEST_UPGRADE_DIALOG: 'opened-request-upgrade-dialog',
        DATA_REFRESH_FAILED: 'data-refresh-failed'
    },

    INTERCOM_METADATA_ITEM = {
        DESCRIPTION: 'description',
        AXLE_LAYOUT: 'axle-layout',
        SOURCE_TYPE: 'source-type',
        OFFER_TYPE: 'offer-type',
        CONNECTIVITY_STATUS: 'connectivity-status',
        MAKE: 'make',
        MODEL: 'model',
        BODY_TYPE: 'body-type',
        EQUIPMENT_TYPE: 'equipment-type',
        EQUIPMENT_SUB_TYPE: 'equipment-sub-type',
        FROM_STATUS: 'from-status',
        TO_STATUS: 'to-status',
        SETTING_NAME: 'setting-name',
        VERIFIED_FROM: 'verified-from',
        FROM_MODULE: 'from-module',
        TO_MODULE: 'to-module',
        ORIGIN: 'origin',
        TOUR_NAME: 'tour-name',
        DATA_INTEGRITY_ISSUE: 'data-integrity-issue',
        FAILED_OPERATION: 'failed-operation',
        CS_TRANSACTION_MESSAGE: 'cs-transaction-message',
        CHASSIS_TYPE: 'chassis-type',
        OLD_DESCRIPTION: 'old-description',
        NEW_DESCRIPTION: 'new-description',
        TRAILER_TYPE: 'trailer-type',
        VIEW_TYPE: 'view-type',
        FROM_VIEW: 'from-view',
        TO_VIEW: 'to-view',
        SEARCH_TERM: 'search-term',
        PLAN_TYPE: 'plan-type',
        VEHICLE_TYPE: 'vehicle-type',
        TYPE: 'type',
        SOURCE: 'source',
        AUTHENTICATION_MODE: 'authentication-mode',
        ORIGIN_SOURCE: 'origin-source',
        VEHICLE_UNLADEN: 'vehicle-unladen',
        TRAILER_UNLADEN: 'trailer-unladen',
        TRAILER2_UNLADEN: 'trailer-unladen',
        FRONTAL_AREA: 'frontal-area',
        COEFFICIENT_OF_DRAG: 'coefficient-of-drag',
        DRIVE_AXLE_RATIO: 'drive-axle-ratio',
        MAXIMUM_SPEED: 'maximum-speed',
        NO_OF_WAYPOINTS: 'no-of-waypoints',
        CHARGEBEE_ERROR_TYPE: 'chargebee-error-type',
        CHARGEBEE_ERROR_MESSAGE: 'chargebee-error-message',
        FROM_TYPE: 'from-type',
        TO_TYPE: 'to-type',
        PROMPT_METHOD: 'prompt-method',
        AXLE_TYPE: 'axle-type',
        DATA_REFRESH_SCENARIO: 'data-refresh-scenario',
        OLD_APP_VERSION: 'old-app-version',
        NEW_APP_VERSION: 'new-app-version',
        SEARCH_LOCATION: 'search-location',
        FROM_VEHICLE: 'from-vehicle',
        TO_VEHICLE: 'to-vehicle',
        SHAREE_EMAIL_ADDRESS: 'sharee-email-address',
        SHARER_EMAIL_ADDRESS: 'sharer-email-address',
        FROM_ELEVATION_VIEW: 'from-elevation-view',
        TO_ELEVATION_VIEW: 'to-elevation-view',
        FROM_DISPLAY_VIEW: 'from-display-view',
        TO_DISPLAY_VIEW: 'to-display-view',
        FROM_DETAIL_VIEW: 'from-detail-view',
        TO_DETAIL_VIEW: 'to-detail-view',
        TRAILER_1_TYPE: 'trailer-1-type',
        WIZARD_OPERATION_TYPE: 'wizard-operation-type',
        SUBMIT_TO_PUBLIC_LIBRARY: 'submit-to-public-library',
        SHARED_BY: 'shared-by',
        INITIATED_BY: 'initiated-by',
        ACCESS_PERMISSION: 'access-permission',
        TITLE: 'title',
        MESSAGE_CODE: 'message-code',
        INTERCOM_MESSAGE: 'intercom-message',
        EDITION_NAME: 'edition-name'
    },

    GOOGLE_ANALYTICS_EVENTS = {
        // TRIAL_REGISTERED: 'application_trial_registered',
        // BUY_NOW_CLICKED: 'application_buy_now_clicked',
        // SELECTED_PLAN: 'application_selected_plan',
        // BOUGHT_PLAN: 'application_bought_plan',
        TRIAL_REGISTERED: 'Application - Trial Registered - Trigger',
        BUY_NOW_CLICKED: 'Application - Buy Now Clicked - Trigger',
        SELECTED_PLAN: 'Application - Selected Plan - Trigger',
        BOUGHT_PLAN: 'Application - Bought Plan - Trigger',
    },

    GOOGLE_ANALYTICS_EVENT_PARAMETERS = {
        // AUTHENTICATION_MODE: 'application_authentication_mode',
        // EDITION: 'application_edition',
        // BILLING_PERIOD: 'application_billing_period',
        AUTHENTICATION_MODE: 'Application - Variable - Authentication Mode',
        EDITION: 'Application - Variable - Edition',
        BILLING_PERIOD: 'Application - Variable - Billing Period',
    },

    DATA_INTEGRITY_CHECK_FAILURES = {
        LEGISLATIONS_NOT_AVAILABLE: 'LEGISLATIONS_NOT_AVAILABLE'
    },

    CHARGEBEE_ERROR_TYPES = {
        CARD_VALIDATION: 'CARD_VALIDATION',
        FORM_VALIDATION: 'FORM_VALIDATION',
        UNKNOWN_ERROR: 'UNKNOWN_ERROR'
    },

    SETTINGS_OPTIONS = {
        DESCRIPTION: 'DESCRIPTION',
        OVERALL_DESCRIPTION: 'OVERALL DESCRIPTION',
        ADDITIONAL_DESCRIPTION: 'ADDITIONAL DESCRIPTION',
        PREPARED_FOR_NAME: 'PREPARED FOR NAME',
        PREPARED_FOR_TELEPHONE: 'PREPARED FOR TELEPHONE',
        ADDITIONAL_NOTES: 'ADDITIONAL NOTES',
        COLOUR_DRAWINGS: 'COLOUR DRAWINGS',
        LEGISLATION: 'REGULATION',
        APPLY_HIGHER_MASS_LIMITS: 'APPLY HIGHER MASS LIMITS',
        VEHICLE_APPLICATION: 'VEHICLE APPLICATION',
        MEASUREMENT_SYSTEM: 'MEASUREMENT SYSTEM',
        LENGTH_INCREMENT: 'LENGTH INCREMENT',
        MASS_INCREMENT: 'MASS INCREMENT',
        PERCENTAGE_INCREMENT: 'PERCENTAGE INCREMENT',
        SHOW_COMPLIANCE_SCORECARD: 'SHOW COMPLIANCE SCORECARD',
        SPECIFY_WHEELBASE_AS: 'SPECIFY_WHEELBASE_AS',
        SPECIFY_CAB_DIMENSIONS_AS: 'SPECIFY_CAB_DIMENSIONS_AS',
        SPECIFY_CHASSIS_LENGTH_AS: 'SPECIFY_CHASSIS_LENGTH_AS',
        SPECIFY_FUEL_CONSUMPTION_AS: 'SPECIFY_FUEL_CONSUMPTION_AS',
        SPECIFY_LICENCING_REGION_AS: 'SPECIFY_LICENCING_REGION_AS',
        SPECIFY_AXLE_RATING_AS: 'SPECIFY_AXLE_RATING_AS',
        DEFAULT_PDF_PAGE_SIZE: 'DEFAULT_PDF_PAGE_SIZE',
        REPORT_LOGO: 'REPORT_LOGO',
        DASHBOARD_LEGISLATIONS: 'DASHBOARD_LEGISLATIONS',
        DASHBOARD_DATA_POINTS: 'DASHBOARD_DATA_POINTS',
        INTERNAL_STANDARDS: 'INTERNAL_STANDARDS',
        COMPANY_ADDITIONAL_NOTE: 'COMPANY_ADDITIONAL_NOTE',
        SPECIFY_BODY_POSITION_AS: 'SPECIFY_BODY_POSITION_AS',
        SPECIFY_EQUIPMENT_POSITION_AS: 'SPECIFY_EQUIPMENT_POSITION_AS',
        SHOW_TIPS: 'SHOW_TIPS',
        REPORT_VIEW_LAYOUT: 'REPORT_VIEW_LAYOUT',
        REPORT_VIEWS: 'REPORT_VIEWS',
    },

    CONNECTIVITY_STATUS = {
        ONLINE: 'ONLINE',
        OFFLINE: 'OFFLINE'
    },

    MODULE_TITLE = {
        GET_STARTED: 'get_started',
        LOGIN: 'login',
        OFFERS: 'offers',
        OVERVIEW: 'overview',
        SELECTION: 'selection',
        COSTING: 'costing',
        PERFORMANCE: 'performance',
        SPECIFICATION: 'specification',
        SUMMARY: 'summary',
        CONFIGURATION: 'configuration'
    },

    PRODUCT_TOUR_TYPE = {
        OVERVIEW_TOUR: 'OVERVIEW_TOUR',
        NEW_FEATURES_TOUR: 'NEW_FEATURES_TOUR'
    },

    COSTING_ROW_TYPE = {
        TITLE_ONLY: 'TITLE_ONLY',
        TITLE_WITH_HEADERS: 'TITLE_WITH_HEADERS',
        RANGE_3_COLUMNS: 'RANGE_3_COLUMNS',
        RANGE_5_COLUMNS: 'RANGE_5_COLUMNS',
        SEPARATOR: 'SEPARATOR'
    },

    ROW_TYPE = {
        OFFER: 'OFFER',
        FOLDER: 'FOLDER',
        VIRTUAL_FOLDER: 'VIRTUAL_FOLDER'
    },

    REFERRAL_MODAL_TYPE = {
        AUTO: 'AUTO',
        MANUAL: 'MANUAL'
    },

    ACTION_TYPE = {
        EQUIPMENT_ADDED: 'EQUIPMENT_ADDED',
        ATTRIBUTE_VALUE_CHANGED: 'ATTRIBUTE_VALUE_CHANGED',
        SWAP: 'SWAP',
        VEHICLE_DATA: 'VEHICLE_DATA',
        CUSTOM_SAVE: 'CUSTOM_SAVE',
        ADD_ITEM: 'ADD_ITEM',
        SAVE_OFFER: 'SAVE_OFFER'
    },

    ACTION_ID = {
        CHASSIS_BODY_ALIGNMENT_BROKEN: 'CHASSIS_BODY_ALIGNMENT_BROKEN',
        CHASSIS_BODY_ALIGNMENT_BROKEN_DUE_TO_VALIDATION_FAILURE: 'CHASSIS_BODY_ALIGNMENT_BROKEN_DUE_TO_VALIDATION_FAILURE',
        SWAP_COMPLETED: 'SWAP_COMPLETED',
        REMOVE_ADDED_FIFTHWHEEL_AFTER_SWAP: 'REMOVE_ADDED_FIFTHWHEEL_AFTER_SWAP',
        VEHICLE_SUBMITTED: 'VEHICLE_SUBMITTED',
        SAVE_WILL_OVERWRITE: 'SAVE_WILL_OVERWRITE',
        ACCESS_LEVEL_CHANGED: 'ACCESS_LEVEL_CHANGED',
        ALREADY_DELETED: 'ALREADY_DELETED',
        NAME_ALREADY_EXISTS_TEAM: 'NAME_ALREADY_EXISTS_TEAM',
        NAME_ALREADY_EXISTS_PERSONAL: 'NAME_ALREADY_EXISTS_PERSONAL',
        FIFTH_WHEEL_WHEN_BODY_PRESENT: 'FIFTH_WHEEL_WHEN_BODY_PRESENT',
        BODY_WHEN_FIFTH_WHEEL_PRESENT: 'BODY_WHEN_FIFTH_WHEEL_PRESENT',
        IMPORT_WIZARD_ASPECT_RATIO_BROKEN_LENGTH: 'IMPORT_WIZARD_ASPECT_RATIO_BROKEN_LENGTH',
        IMPORT_WIZARD_ASPECT_RATIO_BROKEN_HEIGHT: 'IMPORT_WIZARD_ASPECT_RATIO_BROKEN_HEIGHT',
        SHARE_ACCESS_PERMISSION_VIEW_ONLY: 'SHARE_ACCESS_PERMISSION_VIEW_ONLY'
    },

    TOASTR_TYPES = {
        SUCCESS: 'SUCCESS',
        ERROR: 'ERROR',
        INFO: 'INFO'
    },

    toastrOptionsDefault = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 2000,
        "extendedTimeOut": 2000,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut"
    },

    toastrOptionsOffline = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "preventDuplicates": true,
        "timeOut": 2000,
        "extendedTimeOut": 2000,
        "tapToDismiss": false
    },

    toastrOptionsSyncSuccess = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "timeOut": 2000,
        "extendedTimeOut": 2000,
        "tapToDismiss": false
    },

    toastrOptionsValidationFailed = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "preventDuplicates": true,
        "timeOut": 4000,
        "extendedTimeOut": 4000,
        "tapToDismiss": false
    },

    toastrOptionsScreenDimensions = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "preventDuplicates": true,
        "timeOut": 4000,
        "extendedTimeOut": 4000,
        "tapToDismiss": false
    },

    toastrOptionsAppUpdate = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 0,
        "extendedTimeOut": 0,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "tapToDismiss": false,
        "closeHtml": '<button type="button">&times;</button>'
    },

    toastrOptionsMissingValues = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 0,
        "extendedTimeOut": 0,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "tapToDismiss": false,
        "closeHtml": '<button type="button">&times;</button>'
    },

    toastrOptionsNewOffersInCalc = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "preventDuplicates": true,
        "timeOut": 15000,
        "extendedTimeOut": 3000,
        "tapToDismiss": false
    },

    toastrOptionsAuApplicationChange = {
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "closeButton": true,
        "preventDuplicates": true,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 7000,
        "extendedTimeOut": 3000,
        "tapToDismiss": false
    },

    toastrOptionsTip = {
        "closeButton": true,
        "debug": false,
        "newestOnTop": false,
        "progressBar": false,
        "positionClass": "toast-bottom-left",
        "backgroundpositionClass": "toast-bottom-left",
        "preventDuplicates": true,
        "onclick": null,
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": 0,
        "extendedTimeOut": 0,
        "showEasing": "swing",
        "hideEasing": "linear",
        "showMethod": "fadeIn",
        "hideMethod": "fadeOut",
        "tapToDismiss": false,
        "closeHtml": '<button type="button">&times;</button>',
        "background-color": "#ffffff"
    },

    defaultAppConfig = {
        LoggingLevel: LOGGING_LEVEL.DETAILED,
        LogRetentionPeriod: 604800, // 7 days in seconds
        LogCommunicationPeriod: 86400, // 1 day in seconds
        NoOfDaysLicenceExpiryMessageShows: 30,
        IsOnlineCheckPeriod: 5, // seconds
        IsOnlineTimeout: 20, //seconds
        IsOnlineLowPriorityValidityPeriod: 5, // seconds
        IsOnlineMediumPriorityValidityPeriod: 3, // seconds
        IsOnlineHighPriorityValidityPeriod: 2, // seconds
        PostRequestTimeout: 40, // seconds
        GetRequestTimeout: 40, // seconds
        UsageCommunicationPeriod: 3600, // 1 hour in seconds
        MaxNewOffersCached: 100,
        MaxSavedOffersCached: 100,
    },

    LOG_IN_REAUTH_STEPS = {
        DOWNLOADING_APPLICATION_DATA: {
            StepNum: 0,
            LoginDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.DOWNLOADING_APPLICATION_DATA,
            ReAuthDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.REFRESHING_APPLICATION_DATA
        },
        DOWNLOADING_SETTINGS: {
            StepNum: 1,
            LoginDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.DOWNLOADING_SETTINGS,
            ReAuthDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.REFRESHING_SETTINGS
        },
        DOWNLOADING_IMAGES: {
            StepNum: 2,
            LoginDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.DOWNLOADING_IMAGES,
            ReAuthDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.REFRESHING_IMAGES
        },
        CONFIGURING_APPLICATION: {
            StepNum: 3,
            LoginDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.CONFIGURING_APPLICATION,
            ReAuthDisplayText: LOGIN_REAUTH_STEP_TEXT_CODE.CONFIGURING_APPLICATION
        }

    },

    BUY_NOW_TYPE = {
        FOURTEEN_DAY_PASS: {
            Code: "14daypass",
            Analytics_Label: "14 Day Pass",
            Value_USD: 90
        },
        MONTHLY_RECURRING: {
            Code: "monthly",
            Analytics_Label: "Monthly",
            Value_USD: 45
        },
        ANNUAL_RECURRING: {
            Code: "annually",
            Analytics_Label: "Annually",
            Value_USD: 499
        }
    },

    GOOGLE_ANALYTICS = {
        CATEGORY: {
            TRIAL_USERS: 'Trial Users',
            FULL_USERS: 'Full Users'
        },
        ACTION: {
            GET_STARTED_CLICKED: 'Get Started Clicked',
            SUCCESSFUL_REGISTRATION: 'Successful Registration',
            BUY_NOW_CLICKED: 'Buy Now Clicked',
            BOUGHT: 'Bought',
            LOGIN_CLICKED: 'Login Clicked',
            REDIRECTED_TO_LOGIN_FOR_SHARE: 'Redirected To Login For Share',
            GET_STARTED_FOR_SHARE: 'Get Started For Share'
        }
    },

    PAYMENT_STATUS_STATES = {
        VALID: 'VALID',
        EXPIRING: 'EXPIRING',
        EXPIRED: 'EXPIRED',
        NOT_PRESENT: 'NOTPRESENT',
        PAYMENTS_OUTSTANDING: 'PAYMENTSOUTSTANDING',
        NOT_APPLICABLE: 'NOTAPPLICABLE'
    },

    defaultMenuPermissionObject = {
        //override: ko.observable(false),
        //readOnly: ko.observable(false),
        //visible: ko.observable(true)
        override: false,
        readOnly: false,
        visible: true
    },

    // defaultDatagridColumn = {
    //     name: '',
    //     nameDisplayed: false,
    //     sortable: false,
    //     sortType: '',
    //     headerClass: 'datagrid-header-icon',
    //     itemToSort: ''
    // },

    MENU_LOCATION = {
        LEFT: 'LEFT',
        RIGHT: 'RIGHT'
    },

    MENU_SECTION = {
        POSITION: 'POSITION',
        SPECIFICATION_DIMENSIONS: 'SPECIFICATION_DIMENSIONS',
        SPECIFICATION_WEIGHTS: 'SPECIFICATION_WEIGHTS',
        SPECIFICATION: 'SPECIFICATION'
    },

    // MENU_RIGHT_SIDE_DIRECTION = {
    //     SHOW: 'right',
    //     HIDE: 'left'
    // },

    WIZARD_ROW_TYPE = {
        SELECTION: 'selection',
        BODYTYPE: 'bodyType',
        ACCESSORYTYPE: 'accessoryType',
        TRAILERTYPE: 'trailerType',
        VEHICLETYPE: 'vehicleType'
    },

    // MENU_LEFT_SIDE_DIRECTION = {
    //     SHOW: 'left',
    //     HIDE: 'right'
    // },

    BREADCRUMB_BUTTON_TYPE = {
        BODY: 'body',
        ACCESSORY: 'accessory',
        TRAILER: 'trailer',
        VEHICLE: 'vehicle',
    },

    BROWSER_NAME = {
        INTERNETEXPLORER: 'Internet Explorer',
        OPERA: 'Opera',
        FIREFOX: 'Firefox',
        SAFARI: 'Safari',
        EDGE: 'Edge',
        CHROME: 'Chrome',
        CHROMEONANDROID: 'Chrome on Android'
    },

    dummyEmptyCustomer = {
        Id: 0,
        ContactName: '',
        Company: '',
        Mobile: '',
        DirectNumber: '',
        Email: '',
        UpdateCounter: 0,
        OverallUpdateCounter: 0
    },

    PAYMENT_MENU_OPTIONS = {
        BUY_NOW: 'buyNow',
        UPDATE_PAYMENT_DETAILS: 'updatePaymentDetails',
        PAYMENT_DETAILS_EXPIRED: 'paymentDetailsExpired',
        DO_NOT_DISPLAY: 'doNotDisplay'
    },

    LANGUAGE_PACK = {
        ENGLISH: {
            US: {
                cultureCode: 'en-US',
                imageFolder: 'US'
            },
            CA: {
                cultureCode: 'en-CA',
                imageFolder: 'US'
            },
            GB: {
                cultureCode: 'en-GB',
                imageFolder: 'EU'
            },
            AU: {
                cultureCode: 'en-AU',
                imageFolder: 'EU'
            },
            NZ: {
                cultureCode: 'en-NZ',
                imageFolder: 'EU'
            },
            SA: {
                cultureCode: 'en-ZA',
                imageFolder: 'EU'
            },
            VI: {
                cultureCode: 'en',
                imageFolder: 'EU'
            }
        },
        FRENCH: {
            FR: {
                cultureCode: 'fr',
                imageFolder: 'EU'
            },
            CA: {
                cultureCode: 'fr-CA',
                imageFolder: 'US'
            }            
        },
        PORTUGESE: {
            BRAZIL: {
                cultureCode: 'pt',
                imageFolder: 'EU'
            }
        },
        VIETNAMESE: {
            VI: {
                cultureCode: 'vi',
                imageFolder: 'EU'
            }
        }
    },

    INTERACTION_ID = {
        XXED_OUT_VALUE: 'xxedOutVal',
        DIMENSION_INFO: 'dimensionInfo',
        DIMENSION_EDIT: 'dimensionEdit',
        DRAWING_GROUP: 'drawingGroup',
        COG_ELEMENT: 'cogElement',
        TABLE_CELL: 'tableCell',
        DRAGGABLE_OBJECT: 'draggableObject',
        COMPLIANCE_DASHBOARD_SETTINGS: 'COMPLIANCE_DASHBOARD_SETTINGS',
        COG_DIMENSION: 'COG_DIMENSION',
        COMPLIANCE_DASHBOARD_CRUMB: 'COMPLIANCE_DASHBOARD_CRUMB',
        COMPLIANCE_DASHBOARD_DRILL_DOWN: 'COMPLIANCE_DASHBOARD_DRILL_DOWN'
    },

    INTERACTION_EVENT = {
        CLICK: 'click',
        MOUSE_OVER: 'mouseOver',
        MOUSE_OUT: 'mouseOut',
        MOUSE_MOVE: 'mouseMove',
        POINTER_DOWN: 'pointerDown',
        POINTER_UP: 'pointerUp',
        POINTER_UP_OUTSIDE: 'pointerUpOutside',
        POINTER_MOVE: 'pointerMove',
        DRAG_START: 'dragStart',
        DRAG_END: 'dragEnd',
        DRAG_MOVE: 'dragMove'
    },

    INTERACTION_BEHAVIOUR = {
        PINNABLE: 'pinnable',
        STANDARD: 'standard'
    },

    DRAG_INCREMENTS = {
        METRIC: 10,
        IMPERIALUK: 12.7,
        IMPERIALUS: 12.7
    },

    COSTING_CHART_COLOURS = {
        colour1: '#4990E2',
        colour2: '#BBD6F4',
        colour3: '#84B3EB'
    },

    APP_DATA_REFRESH_SCENARIO = {
        APP_UPDATE: 'applying_update',
        //USER_REFRESH: 'userRefresh',
        //ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED: 'errorUpgradingUserAlreadySubscribed',
        //ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE: 'errorUpgradingUserAfterSuccessfulSubscribe',
        //FORCED_REFRESH_MISSING_DATA: 'forcedRefreshMissingData',
        //CHANGING_LANGUAGE: 'changingLanguage'
        //APP_UPDATE: 'applying-update',
        USER_REFRESH: 'user-refresh',
        ERROR_UPGRADING_USER_ALREADY_SUBSCRIBED: 'error-upgrading-user-already-subscribed',
        ERROR_UPGRADING_USER_AFTER_SUCCESSFUL_SUBSCRIBE: 'error-upgrading-user-after-successful-subscribe',
        FORCED_REFRESH_MISSING_DATA: 'forced-refresh-missing-data',
        CHANGING_LANGUAGE: 'changing-language',
        FORCED_REFRESH_AFTER_BUY: 'forced-refresh-after-buy'
    },

    PDFMAKE_ALIGNMENT_OPTIONS = {
        LEFT: 'left',
        RIGHT: 'right',
        CENTRE: 'center',
        JUSTIFY: 'justify'
    },

    PDFMAKE_PAGE_SIZE_OPTIONS = {
        A4: 'A4',
        LETTER: 'LETTER'
    },

    REPORT_VIEW_LAYOUT_OPTIONS = {
        SIMPLIFIED: 'SIMPLIFIED',
        ADVANCED: 'ADVANCED'
    },

    NTEA_USER_VALIDATION_TYPES = {
        NTEA: 'NTEA',
        SALESFORCE: 'SALESFORCE'
    },

    SAVE_OFFER_FLAGS = {
        RESENDING_TO_DIFFERENT_CUSTOMER: 'resendingToDifferentCustomer',
        OVERWRITE: 'overwrite',
        SAVE_AS_NEW: 'saveAsNew',
        SAVE_AS_EXISTING: 'saveAsExisting',
        SAVE_AS_SHARE: 'saveAsShare',
        SAVE_NEW_TSV: 'saveNewTSV',
        SAVE_EXISTING_TSV: 'saveExistingTSV',
        SAVE_AS_EXISTING_TSV: 'saveAsExistingTSV'
    },

    CUSTOM_ITEM_ACCESS_LEVEL = {
        PERSONAL: 'PERSONAL',
        COMPANY: 'COMPANY'
    },

    CONFIGURATION_MENUS = {
        DISPLAY: 'DISPLAY',
        ELEVATION: 'ELEVATION',
        VIEW_TYPE: 'VIEW_TYPE',
        VIEW_DETAIL: 'VIEW_DETAIL',
        RIG_VIEW: 'RIG_VIEW'
    },

    VALIDATION_STEP_TYPES = {
        SKIP_MAIN_ATTR_VALIDATION_AND_OPS: 'SKIP_MAIN_ATTR_VALIDATION_AND_OPS',
        SKIP_MAIN_ATTR_VALIDATION_ONLY: 'SKIP_MAIN_ATTR_VALIDATION_ONLY',
        UI: 'UI',
        BACKEND: 'BACKEND',
        SKIP_VALIDATION_ALLOW_OPS: 'SKIP_VALIDATION_ALLOW_OPS'
    },

    VALIDATION_MODE = {
        ON: 'ON',
        OFF: 'OFF',
        ALLOW_OPS: 'ALLOW_OPS'
    },

    TYRE_GAPS = {
        GAP_BETWEEN_TYRES_IN_MM: 20,
        GAP_BETWEEN_TYRES_AND_CHASSIS: 325
    },

    AUTHENTICATION_METHOD = {
        TRUCKSCIENCE: 'TRUCKSCIENCE',
        TRUCKSCIENCE_API: 'TRUCKSCIENCEAPI',
        GOOGLE: 'GOOGLE',
        MICROSOFT: 'MICROSOFT',
        SHARE: 'SHARE'
    },

    TRACKER_STATE_KEY = {
        TYPE: 'type',
        SECTION: 'section'
    },

    TRACKER_TYPE = {
        NOTE: 'note',
        WARNING: 'warning',
        TIP: 'tip',
        DDR_NOTE: 'ddr_note' 
    },

    NOTES_HEADINGS = {
        MANUFACTURER_RATINGS: 'manufacturerRatings',
        REGULATIONS_APPLIED: 'regulationsApplied',
        GENERAL: 'general'
    },

    WARNINGS_HEADINGS = {
        MANUFACTURER_RATINGS: 'manufacturerRatings',
        REGULATIONS: 'regulations',
        INTERNAL_STANDARDS: 'internalStandards'
    },

    NOTE_ID = {
        INCLUDED_IN_UNLADEN: 'INCLUDED_IN_UNLADEN',
        EXCLUDED_FROM_UNLADEN: 'EXCLUDED_FROM_UNLADEN',
        NOT_SPECIFIED_UNLADEN: 'NOT_SPECIFIED_UNLADEN',
        CENTRE_OF_GRAVITY: 'CENTRE_OF_GRAVITY',
        LEGISLATION: 'LEGISLATION',
        SPEC_DATE: 'SPEC_DATE',
        MEASUREMENT_SYSTEM: 'MEASUREMENT_SYSTEM',
        BODY_AND_EQUIPMENT_ADDED: 'BODY_AND_EQUIPMENT_ADDED',
        EXTERNAL_BODY_WIDTH: 'EXTERNAL_BODY_WIDTH',
        SUBFRAME_HEIGHT: 'SUBFRAME_HEIGHT',
        SUBFRAME_LENGTH: 'SUBFRAME_LENGTH',
        ADDITIONAL_NOTE: 'ADDITIONAL_NOTE',
        TRAILER_DESCRIPTION: 'TRAILER_DESCRIPTION',
        TRAILER1_DESCRIPTION: 'TRAILER1_DESCRIPTION',
        TRAILER2_DESCRIPTION: 'TRAILER2_DESCRIPTION',
        TARE_MASS_MANUALLY_INCLUDED: 'TARE_MASS_MANUALLY_INCLUDED',
        BODY_AND_EQUIPMENT_ADDED_TRAILER: 'BODY_AND_EQUIPMENT_ADDED_TRAILER',
        TURNING_CIRCLE_REGULATION_WALL_TO_WALL: 'TURNING_CIRCLE_REGULATION_WALL_TO_WALL',
        TURNING_CIRCLE_REGULATION_CURB_TO_CURB: 'TURNING_CIRCLE_REGULATION_CURB_TO_CURB',
        TURNING_CIRCLE_SMALLEST: 'TURNING_CIRCLE_SMALLEST',
        WHEELBASE_CHANGED: 'WHEELBASE_CHANGED',
        WHEELBASE_CHANGED_APPROVAL: 'WHEELBASE_CHANGED_APPROVAL',
        REAR_OVERHANG_CHANGED: 'REAR_OVERHANG_CHANGED',
        THEORETICAL_WHEELBASE: 'THEORETICAL_WHEELBASE',
        CAB_TILT_CLEARANCE: 'CAB_TILT_CLEARANCE',
        CAB_TILT_RADIUS: 'CAB_TILT_RADIUS',
        TAILLIFT_CLEARANCE: 'TAILLIFT_CLEARANCE',
        CAB_GAP: 'CAB_GAP',
        OFF_ROAD_ONLY: 'OFF_ROAD_ONLY',
        ABNORMAL_PERMIT: 'ABNORMAL_PERMIT',
        COMPACTOR_ALLOWANCE: 'COMPACTOR_ALLOWANCE',
        ADMINISTRATOR_CHANGED_TARE: 'ADMINISTRATOR_CHANGED_TARE',
        ADMINISTRATOR_CHANGED_PERMISSIBLE: 'ADMINISTRATOR_CHANGED_PERMISSIBLE',
        VERTICAL_COG_ZERO: 'VERTICAL_COG_ZERO',
        VERTICAL_COG_ESTIMATED: 'VERTICAL_COG_ESTIMATED',
        MASSES_ADJUSTED: 'MASSES_ADJUSTED',
        VERTICAL_COG_ADJUSTED: 'VERTICAL_COG_ADJUSTED',
        HORIZONTAL_COG_ADJUSTED: 'HORIZONTAL_COG_ADJUSTED',
        VEHICLE_WIDTH_ADJUSTED: 'VEHICLE_WIDTH_ADJUSTED',
        TURNING_ABILITY_ADJUSTED: 'TURNING_ABILITY_ADJUSTED',
        PAYLOAD_COG_ADJUSTED: 'PAYLOAD_COG_ADJUSTED',
        BODY_COG_ADJUSTED: 'BODY_COG_ADJUSTED',
        SWING_CLEARANCE: 'SWING_CLEARANCE',
        SWING_CLEARANCE_CAB_PROTRUSION: 'SWING_CLEARANCE_CAB_PROTRUSION',
        SWING_CLEARANCE_LANDING_LEGS: 'SWING_CLEARANCE_LANDING_LEGS',
        SWING_CLEARANCE_TRAILERS: 'SWING_CLEARANCE_TRAILERS',
        TEMPLATE_VEHICLE: 'TEMPLATE_VEHICLE',
        VEHICLE_STATIC_ROLLOVER_ANGLE: 'VEHICLE_STATIC_ROLLOVER_ANGLE',
        TRAILER1_STATIC_ROLLOVER_ANGLE: 'TRAILER1_STATIC_ROLLOVER_ANGLE',
        TRAILER2_STATIC_ROLLOVER_ANGLE: 'TRAILER2_STATIC_ROLLOVER_ANGLE',
        APPLICATION: 'APPLICATION',
        APPLICATION_MESSAGE: 'APPLICATION_MESSAGE',
        CAB_GAP_NOT_PROVIDED: 'CAB_GAP_NOT_PROVIDED',
        MODEL_CODE: 'MODEL_CODE',
        MISSING_VALUES_VEHICLE: 'MISSING_VALUES_VEHICLE',
        MISSING_VALUES_TURNING_CIRCLE: 'MISSING_VALUES_TURNING_CIRCLE',
        CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE',
        CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE',
        CANT_CALCULATE_TRAILER2_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_TRAILER2_STATIC_ROLLOVER_ANGLE',
        MANUFACTURER_RATINGS: 'MANUFACTURER_RATINGS',
        BATTERY_CAPACITY: 'BATTERY_CAPACITY',
        INCLUDED_IN_CHASSIS_WEIGHT: 'INCLUDED_IN_CHASSIS_WEIGHT',
        INCLUDED_IN_FUEL_AND_CREW: 'INCLUDED_IN_FUEL_AND_CREW',
        SAFETY_FEATURES: 'SAFETY_FEATURES' 
    },

    WARNING_ID = {
        AXLES_OVERLOADED: 'AXLES_OVERLOADED',
        PERMISSIBLE_TOTAL_OVERLOADED: 'PERMISSIBLE_TOTAL_OVERLOADED',
        STEER_AXLE_MINIMUM: 'STEER_AXLE_MINIMUM',
        BRIDGE_FORMULA: 'BRIDGE_FORMULA',
        OVERALL_LENGTH: 'OVERALL_LENGTH',
        OVERALL_WIDTH: 'OVERALL_WIDTH',
        MISSING_VALUES_VEHICLE: 'MISSING_VALUES_VEHICLE',
        MISSING_VALUES_TURNING_CIRCLE: 'MISSING_VALUES_TURNING_CIRCLE',
        TURNING_RADIUS_INNER: 'TURNING_RADIUS_INNER',
        TURNING_RADIUS_OUTER: 'TURNING_RADIUS_OUTER',
        REAR_OVERHANG_EXCEEDED: 'REAR_OVERHANG_EXCEEDED',
        VEHICLE_WHEELBASE_THEORETICAL: 'VEHICLE_WHEELBASE_THEORETICAL',
        TRAILER_LENGTH: 'TRAILER_LENGTH',
        KINGPIN_TO_REAR_OF_TRAILER: 'KINGPIN_TO_REAR_OF_TRAILER',
        TRAILER_FRONT_OVERHANG: 'TRAILER_FRONT_OVERHANG',
        TRAILER_WHEELBASE_THEORETICAL: 'TRAILER_WHEELBASE_THEORETICAL',
        TRAILER_REAR_OVERHANG_LEGISLATION: 'TRAILER_REAR_OVERHANG_TOTAL',
        DRAWBAR_HEAVIER_THAN_VEHICLE: 'DRAWBAR_HEAVIER_THAN_VEHICLE',
        PUP_HEAVIER_THAN_VEHICLE: 'PUP_HEAVIER_THAN_VEHICLE',
        OVERALL_LENGTH_EXCEEDED: 'OVERALL_LENGTH_EXCEEDED',
        OVERALL_HEIGHT_EXCEEDED: 'OVERALL_HEIGHT_EXCEEDED',
        MANUFACTURER_WIDTH_EXCEEDED: 'MANUFACTURER_WIDTH_EXCEEDED',
        TURNTABLE_TO_REAR_OF_TRAILER: 'TURNTABLE_TO_REAR_OF_TRAILER',
        TRAILER_DRAWBAR_LENGTH: 'TRAILER_DRAWBAR_LENGTH',
        GAP_BETWEEN_VEHICLE_AND_TRAILER: 'GAP_BETWEEN_VEHICLE_AND_TRAILER',
        CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_VEHICLE_STATIC_ROLLOVER_ANGLE',
        CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_TRAILER1_STATIC_ROLLOVER_ANGLE',
        CANT_CALCULATE_TRAILER2_STATIC_ROLLOVER_ANGLE: 'CANT_CALCULATE_TRAILER2_STATIC_ROLLOVER_ANGLE',
        MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_OVERLOADED: 'MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_OVERLOADED',
        MANUFACTURERS_WEIGHTS_AXLES_OVERLOADED: 'MANUFACTURERS_WEIGHTS_AXLES_OVERLOADED',
        GAP_BETWEEN_TRAILER_AND_TRAILER: 'GAP_BETWEEN_TRAILER_AND_TRAILER',
        MANUFACTURERS_RATING_VEHICLE_FRONT_AXLES_EXCEEDED: 'MANUFACTURERS_RATING_VEHICLE_FRONT_AXLES_EXCEEDED',
        MANUFACTURERS_RATING_VEHICLE_REAR_AXLES_EXCEEDED: 'MANUFACTURERS_RATING_VEHICLE_REAR_AXLES_EXCEEDED',
        MANUFACTURERS_RATING_PUSHER_OR_TAG_AXLE_EXCEEDED: 'MANUFACTURERS_RATING_PUSHER_OR_TAG_AXLE_EXCEEDED',
        MANUFACTURERS_RATING_TRAILER1_REAR_AXLES_EXCEEDED: 'MANUFACTURERS_RATING_TRAILER1_REAR_AXLES_EXCEEDED',
        MANUFACTURERS_RATING_TRAILER2_REAR_AXLES_EXCEEDED: 'MANUFACTURERS_RATING_TRAILER1_REAR_AXLES_EXCEEDED',
        MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_EXCEEDED: 'MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_EXCEEDED',
        MANUFACTURERS_WEIGHTS_GVM_EXCEEDED: 'MANUFACTURERS_WEIGHTS_GVM_EXCEEDED',
        X_DIMENSION_EXCEEDED: 'X_DIMENSION_EXCEEDED',
        Y_DIMENSION_EXCEEDED: 'Y_DIMENSION_EXCEEDED',
        VEHICLE_LENGTH: 'VEHICLE_LENGTH',
        OVERALL_WHEELBASE: 'OVERALL_WHEELBASE',
        KINGPIN_TO_REARMOST_AXLE_SETTING_EXCEEDED: 'KINGPIN_TO_REARMOST_AXLE_SETTING_EXCEEDED',
        CARGO_CARRYING_LENGTH_EXCEEDED: 'CARGO_CARRYING_LENGTH_EXCEEDED',
        INTERNAL_STANDARD: 'INTERNAL_STANDARD',
        HOOKLIFT_BODY_OVERHANG_EXCEEDED: 'HOOKLIFT_BODY_OVERHANG_EXCEEDED',
        VEHICLE_FRONT_OVERHANG: 'VEHICLE_FRONT_OVERHANG',
        VEHICLE_AXLE_1_FRONT_TO_AXLE_2_FRONT: 'VEHICLE_AXLE_1_FRONT_TO_AXLE_2_FRONT',
        VEHICLE_REARMOST_AXLE_FRONT_TO_AXLE_1_REAR: 'VEHICLE_REARMOST_AXLE_FRONT_TO_AXLE_1_REAR',
        VEHICLE_AXLE_1_REAR_TO_AXLE_2_REAR: 'VEHICLE_AXLE_1_REAR_TO_AXLE_2_REAR',
        VEHICLE_AXLE_2_REAR_TO_AXLE_3_REAR: 'VEHICLE_AXLE_2_REAR_TO_AXLE_3_REAR',
        VEHICLE_AXLE_3_REAR_TO_AXLE_4_REAR: 'VEHICLE_AXLE_3_REAR_TO_AXLE_4_REAR',
        VEHICLE_AXLE_1_FRONT_TO_WHEELBASE_THEORETICAL_START: 'VEHICLE_AXLE_1_FRONT_TO_WHEELBASE_THEORETICAL_START',
        VEHICLE_REAR_PROTRUSION: 'VEHICLE_REAR_PROTRUSION',
        VEHICLE_REAR_OVERHANG_TOTAL: 'VEHICLE_REAR_OVERHANG_TOTAL',
        VEHICLE_BUMPER_TO_BACK_OF_CAB: 'VEHICLE_BUMPER_TO_BACK_OF_CAB',
        VEHICLE_BODY_CAB_GAP: 'VEHICLE_BODY_CAB_GAP',
        TRAILER_WHEELBASE_THEORETICAL_END_TO_REARMOST_AXLE: 'TRAILER_WHEELBASE_THEORETICAL_END_TO_REARMOST_AXLE'
    },

    CONSIDERED_REQUEST_SOURCES = {
        DIRECT: 'DIRECT',
        LINK: 'LINK',
        SHARE_LINK: 'SHARE_LINK'
    },

    SELECTION_MENU_ITEM_TYPE = {
        DASHBOARD: 'Dashboard',
        MANUFACTURER: 'Manufacturer',
        TEMPLATE: 'Template',
        SAVED: 'Saved',
        SHARED: 'Shared',
        NEW_CALCUTION: 'NewCalculation'
    },

    SAVE_TYPE = {
        SAVE: 'SAVE',
        SAVE_AS: 'SAVE_AS'
    },

    PAYLOAD_UNIT_OF_MEASURE_TYPES = {
        MASS: 'MASS',
        VOLUME: 'VOLUME',
        CUBIC_VOLUME: 'CUBIC'
    },

    BRIDGE_GROUPS = {
        GROUP1: 'GROUP1',
        GROUP2: 'GROUP2',
        GROUP3: 'GROUP3',
        GROUP4: 'GROUP4',
        GROUP5: 'GROUP5',
        GROUP6: 'GROUP6',
        GROUP7: 'GROUP7',
        GROUP8: 'GROUP8',
        GROUP9: 'GROUP9',
        GROUP10: 'GROUP10',
        GROUP11: 'GROUP11',
        GROUP12: 'GROUP12',
        GROUP13: 'GROUP13',
        GROUP14: 'GROUP14',
        GROUP15: 'GROUP15',
        GROUP16: 'GROUP16',
        GROUP17: 'GROUP17',
    },

    BUY_NOW_SOURCE = {
        FROM_LOGIN: 'FROM_LOGIN',
        CLICKED: 'CLICKED'
    },

    CONSIDERED_INTEGRATION_SOURCE = {
        WELCOME_MESSAGE: 'WELCOME_MESSAGE',
        BUY_NOW_MODAL: 'BUY_NOW_MODAL',
        PRODUCT_TOUR: 'PRODUCT_TOUR'
    },

    SUPPORT_PLAN_TYPE = {
        FULL: 'FULL',
        NONE: 'NONE'
    },

    MAP_PUSHPIN_TYPE = {
        TOLLS: 'TOLLS',
        CONTACTS: 'CONTACTS'
    },

    MULTISELECT_FILTER_BEHAVIOUR_OPTIONS = {
        TEXT: 'text',
        VALUE: 'value',
        BOTH: 'both'
    },

    SELECTION_DISTINCT_PROPERTY_NAME = {
        VEHICLE_MAKE: 'VehicleMake',
        VEHICLE_RANGE: 'VehicleRange',
        AXLE_LAYOUT: 'AxleLayout',
        VEHICLE_TYPE: 'VehicleTypeTranslation',
        STATUS: 'Status',
        COUNTRY: 'CountryName'
    },

    WIZARD_FILTER_OPTIONS = {
        DEFAULT: 'DEFAULT',
        GENERIC_VEHICLE_SELECTION: 'GENERIC_VEHICLE_SELECTION',
        MANUFACTURER_VEHICLE_SELECTION: 'MANUFACTURER_VEHICLE_SELECTION',
        MENU: 'MENU'
    },

    SHARE_STATUS_TYPES = {
        INVITED: 'INVITED',
        ACCEPTED: 'ACCEPTED',
        NOTIFIED: 'NOTIFIED',
        UPDATED: 'UPDATED'
    },

    SHARE_USER_TYPES = {
        SHARER: 'SHARER',
        SHAREE: 'SHAREE'
    },

    SEARCH_TYPES = {
        SAVED: 'SAVED',
        SHARED: 'SHARED'
    },

    SUMMARY_BUTTON_DISPLAY_OPTIONS = {
        APPLY_CHANGES: 'applyChanges',
        DOWNLOAD: 'download',
        NONE: 'none'
    },

    SPECIFY_WHEELBASE_AS_OPTIONS = {
        FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES: 'FMSACRDA',
        FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES: 'FMSACRA',
        CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES: 'CFACRA',
        FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE: 'FMSAFRDA'

    },

    SELECT_OPTION_TYPE = {
        MULTIPLE: 'multiple',
        SINGLE: 'single'
    },

    SETTINGS_MODAL_TABS = {
        PREFERENCES: 'preferences',
        MEASUREMENT: 'measurement',
        LEGISLATION: 'legislation',
        REPORTING: 'reporting',
        INTEGRATION: 'integration'
    },

    INTEGRATION_TYPES = {
        NTEA: 'NTEA'
    },

    OPERATION_TYPES = {
        UPDATE: 'UPDATE',
        DELETE: 'DELETE',
        DEFAULT: 'DEFAULT'
    },

    CONFIGURATION_MENU_SOURCE = {
        DISPLAY: 'display',
        ELEVATION: 'elevation',
        VIEW_TYPE: 'viewType',
        VIEW_DETAIL: 'viewDetail',
        CAB_COLOUR: 'cabColour'
    },

    CONFIGURATION_CHASSIS_MENU_TYPE = {
        CHASSIS: 'chassis',
        BODY: 'body',
        ACCESSORY: 'accessory',
        PAYLOAD: 'payload'
    },

    BING_MAPS_REST_URLS = {
        LOCATIONS: bingMapsBaseUrl + 'REST/v1/Locations',
        ROUTES: bingMapsBaseUrl + 'REST/v1/Routes'
    },

    WIZARD_TYPES = {
        ADD_NEW: 'ADD_NEW',
        EDIT_EXISTING: 'EDIT_EXISTING'
    },

    WIZARD_LAYOUT_TYPES = {
        BUTTONS_ONLY: 'BUTTONS_ONLY',
        DRAWING_ONLY: 'DRAWING_ONLY',
        DRAWING_AND_INPUT_PANES: 'DRAWING_AND_INPUT_PANES',
        INPUT_DXF: 'INPUT_DXF'
    },

    WIZARD_STEP_TYPES = {
        SELECT_EQUIPMENT_TYPE: 'SELECT_EQUIPMENT_TYPE',
        SPECIFY_SIDE_VIEW_ORIGIN: 'SPECIFY_SIDE_VIEW_ORIGIN',
        SPECIFY_TOP_VIEW_ORIGIN: 'SPECIFY_TOP_VIEW_ORIGIN',
        DRAWING_TYPE_SIDE_VIEW: 'DRAWING_TYPE_SIDE_VIEW',
        ADJUST_SCALE_SIDE_VIEW: 'ADJUST_SCALE_SIDE_VIEW',
        ADJUST_COG_SIDE_VIEW: 'ADJUST_COG_SIDE_VIEW',
        DRAWING_TYPE_TOP_VIEW: 'DRAWING_TYPE_TOP_VIEW',
        ADJUST_COG_TOP_VIEW: 'ADJUST_COG_TOP_VIEW',
        ADJUST_SCALE_TOP_VIEW: 'ADJUST_SCALE_TOP_VIEW',
        REVIEW_AND_FINALIZE: 'REVIEW_AND_FINALIZE'
    },

    WIZARD_DRAWING_TYPE_STEP_TYPES = {
        TEMPLATE: 'TEMPLATE',
        DXF: 'DXF'
    },

    WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES = {
        SIDE: 'SIDE',
        TOP: 'TOP'
    },

    VALID_FILE_INPUT_EXTENSIONS = {
        DXF: '.dxf',
        TSV: '.tsv',
        JPG: '.jpg',
        PNG: '.png'
    },

    NEW_CUSTOM_ITEM_OBJECT_JSON = {
        BODY: {
            "AccessoryType": "Body",
            "BottomOfBodyToTopOfChassis": 0,
            "CabGap": 0,
            "COG": 50,
            "COGType": "PERCENTAGE",
            "Description": "",
            "DoorHeight": 0,
            "FloorType": "STANDARD",
            "FrontToDoor": 0,
            "Graphic": null,
            "HeadboardHeight": 0,
            "HeadboardThickness": 0,
            "Height": 0,
            "HeightType": "EXTERNAL",
            "HingePoint": 0,
            "HorizontalCOGOverride": false,
            "KeepChassisFlushWithBody": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "LateralCOGType": null,
            "LeftCompartmentWidth": 0,
            "Length": 0,
            "LengthType": "EXTERNAL",
            "Mass": 0,
            "MassType": "KILOGRAM",
            "Material": "STEEL",
            "Name": "",
            "PayloadCOG": 50,
            "PayloadCOGType": "PERCENTAGE",
            "PayloadLateralCOG": 50,
            "PayloadLateralCOGType": null,
            "PayloadLateralCOGOverride": false,
            "PayloadVerticalCOG": 50,
            "PayloadVerticalCOGOverride": false,
            "PayloadVerticalCOGType": null,
            "RampAngle": 0,
            "RampLength": 0,
            "RampPlatesCOGType": "PERCENTAGE",
            "RampPlatesHeight": 0,
            "RampPlatesHorizontalCOG": 50,
            "RampPlatesHorizontalCOGOverride": false,
            "RampPlatesLateralCOG": 50,
            "RampPlatesLateralCOGOverride": false,
            "RampPlatesLateralCOGType": null,
            "RampPlatesMass": 0,
            "RampPlatesProtrusion": 0,
            "RampPlatesVerticalCOG": 0,
            "RampPlatesVerticalCOGOverride": false,
            "RampPlatesVerticalCOGType": null,
            "RightCompartmentWidth": 0,
            "RoofThickness": 0,
            "SideDoors": false,
            "SideDoorType": "",
            "SidewallLeft": 0,
            "SidewallRight": 0,
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": "CURRENT",
            "SubframeHeight": 0,
            "SubframeLength": 0,
            "SubstructureHeight": 0,
            "TailboardHeight": 0,
            "TailboardThickness": 0,
            "TarpCoverMass": 0,
            "TarpCoverMassType": "",
            "TopViewGraphicBlob": null,
            "Type": "VAN",
            "Uprights": null,
            "VerticalCOG": 33,
            "VerticalCOGOverride": false,
            "VerticalCOGType": null,
            "Width": 0,
            "WidthPerDoor": 0,
            "WidthType": "EXTERNAL"
        },
        BODY_BEAVERTAIL: {

        },
        CRANE: {
            "AboveBelowChassis": 0,
            "AccessoryType": ACCESSORY_TYPES.CRANE,
            "CabGap": 0,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromLeftOfVehicleRearWidth": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Length": 0,
            "Mass": 0,
            "Name": "",
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "SubframeHeight": 0,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 33,
            "VerticalCOGOverride": false,
            "Width": 0
        },
        HITCH: {
            //"AccessoryType": "Hitch",
            "AccessoryType": ACCESSORY_TYPES.HITCH,
            "COGType": COG_TYPES.PERCENTAGE,
            "CouplingHeight": 0,
            "CouplingOffset": 0,
            "Description": "",
            "FromLeftOfVehicleRearWidth": 0,
            "FunnelLength": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HeightFromGround": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Mass": 0,
            "MassOverride": false,
            "MountingLength": 0,
            "Name": "",
            "Offset": 0,
            "ParentType": null,
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "SourceDatabaseId": 0,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "Type": "",
            "UpdateCounter": 0,
            "VerticalCOG": 50,
            "VerticalCOGOverride": false,
            "Width": 0
        },
        FRIDGE: {
            //"AccessoryType": "Fridge",
            "AccessoryType": ACCESSORY_TYPES.FRIDGE,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromLeftOfBodyWidthExternal": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Mass": 0,
            "MountingPosition": 0,
            "Name": "",
            "Protrusion": 0,
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 50,
            "VerticalCOGOverride": false,
            "Width": 0
        },
        TAILLIFT: {
            "AboveOrBelowSubframe": 0,
            //"AccessoryType": "Taillift",
            "AccessoryType": ACCESSORY_TYPES.TAILLIFT,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromLeftOfBodyWidthExternal": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "InternalMountingLength": 0,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Mass": 0,
            "MechanismHeight": 0,
            "Name": "",
            "RearClearance": 0,
            "RearProtrusion": 0,
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "Type": "",
            "UpdateCounter": 0,
            "VerticalCOG": 25,
            "VerticalCOGOverride": true,
            "Width": 0
        },
        OTHER: {
            "AboveBelowChassis": 0,
            //"AccessoryType": "Other",
            "AccessoryType": ACCESSORY_TYPES.OTHER,
            "CabGap": 0,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromBackOfCab": 0,
            "FromLeftOfVehicleRearWidth": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Length": 0,
            "Mass": 0,
            "Name": "",
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 50,
            "VerticalCOGOverride": false,
            "VerticalGap": 0,
            "Width": 0
        },
        FIFTH_WHEEL: {
            //"AccessoryType": "FifthWheel",
            "AccessoryType": ACCESSORY_TYPES.FIFTH_WHEEL,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromLeftOfVehicleRearWidth": 0,
            "FrontOverhang": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Length": 0,
            "Mass": 0,
            "Name": "",
            "Offset": 0,
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "SubframeHeight": 0,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 40,
            "VerticalCOGOverride": false,
            "Width": 0
        },
        PAYLOAD: {
            "StartX": 0,
            "StartY": 0,
            "StartZ": 0,
            "AccessoryType": ACCESSORY_TYPES.PAYLOAD,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "FromBackOfCab": 0,
            "FromLeftOfVehicleRearWidth": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Length": 0,
            "Mass": 0,
            "Name": "",
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 50,
            "VerticalCOGOverride": false,
            "Width": 0,
            "Payload": 0,
            "Density": 1,
            "UnitOfMeasure": ""
        },
        HOOKLIFT: {
            "AboveBelowChassis": 0,
            //"AccessoryType": "Other",
            "AccessoryType": ACCESSORY_TYPES.HOOKLIFT,
            "CabGap": 0,
            "COGType": COG_TYPES.PERCENTAGE,
            "Description": "",
            "HorizontalGap": 0,
            "FromLeftOfVehicleRearWidth": 0,
            "GraphicBlob": null,
            "Height": 0,
            "HorizontalCOG": 50,
            "HorizontalCOGOverride": false,
            "LateralCOG": 50,
            "LateralCOGOverride": false,
            "Length": 0,
            "Mass": 0,
            "Name": "",
            "Source": ACCESSORY_SOURCE_TYPES.CUSTOM,
            "Status": ACCESSORY_STATUS_TYPES.CURRENT,
            "TopViewGraphicBlob": null,
            "UpdateCounter": 0,
            "VerticalCOG": 50,
            "VerticalCOGOverride": false,
            "VerticalGap": 0,
            "Width": 0,
            "AFrame": 0,
            "HookOffset": 0,
            "CentreOfRoller": 0,
            "PlatformHeight": 0
        }
    },

    COSTING_DETAIL_MODAL_TYPES = {
        FINANCE: 'FINANCE',
        LICENCE_FEES: 'LICENCE_FEES',
        TYRES: 'TYRES',
        TOLL_FEES: 'TOLL_FEES',
        FUEL_AND_ADBLUE: 'FUEL_AND_ADBLUE',
    },

    COSTING_DETAIL_MODAL_BODY_TYPES = {
        MENU: 'MENU'
    },

    MENU_TAB_DISPLAY_TYPES = {
        LABEL_ONLY: 'LABEL_ONLY',
        LABEL_AND_BUTTONS: 'LABEL_AND_BUTTONS',
        LABEL_AND_INPUT: 'LABEL_AND_INPUT',
        LABEL_AND_TOTAL: 'LABEL_AND_TOTAL'
    },

    MENU_TABLE_CELL_TYPES = {
        TEXT: 'TEXT',
        INPUT: 'INPUT',
        DELETE_ROW: 'DELETE_ROW',
        ALLOW_CHANGE_SORT: 'ALLOW_CHANGE_SORT',
    },

    RESIDUAL_RATE_TYPES = {
        NET_PRICE: 'NET_PRICE',
        LIST_PRICE: 'LIST_PRICE'
    },

    // https://api.jqueryui.com/accordion/#option-heightStyle
    ACCORDION_HEIGHT_STYLE_OPTIONS = {
        AUTO: 'auto',
        FILL: 'fill',
        CONTENT: 'content'
    },

    SELECT_ALL_CHECKBOX_OPTIONS = {
        CHECKED: 'checked',
        MINUS: 'minus',
        NOT_CHECKED: 'notChecked'
    },

    SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES = {
        BUTTON: 'BUTTON',
        PLACEHOLDER: 'PLACEHOLDER'
    },

    OFFER_MANAGEMENT_MODAL_TYPES = {
        CREATE_FOLDER: 'CREATE_FOLDER',
        MOVE_TO: 'MOVE_TO',
        SAVE: 'SAVE',
        SAVE_AS: 'SAVE_AS'
    },

    // Potential values for Specify Body Position As and Specify Equipment Position As drop downs on Settings
    SPECIFY_POSITION_AS_OPTIONS = {
        FROM_BACK_OF_CAB: 'FROM_BACK_OF_CAB',
        FROM_FRONTMOST_AXLE: 'FROM_FRONTMOST_AXLE'
    },

    /**
     * From JsTree documentation (https://www.jstree.com/plugins/)
     * */
    JSTREE_PLUGINS = {
        CHECKBOX: "checkbox",
        CONTEXTMENU: "contextmenu",
        DRAP_AND_DROP: "dnd",
        MASSLOAD: "massload",
        SEARCH: "search",
        SORT: "sort",
        STATE: "state",
        TYPES: "types",
        UNIQUE: "unique",
        WHOLEROW: "wholerow",
        CHANGED: "changed",
        CONDITIONAL_SELECT: "conditionalselect"
    },

    /**
     * From JsTree documentation (https://www.jstree.com/api/#/?q=.jstree%20Event)
     * */
    JSTREE_EVENTS = {
        CORE: {
            INIT: 'init.jstree', /* triggered after all events are bound */
            LOADING: 'loading.jstree', /* triggered after the loading text is shown and before loading starts */
            DESTROY: 'destroy.jstree', /* triggered before the tree is destroyed */
            LOADED: 'loaded.jstree', /* triggered after the root node is loaded for the first time */
            READY: 'ready.jstree', /* triggered after all nodes are finished loading */
            LOAD_NODE: 'load_node.jstree', /* triggered after a node is loaded */
            LOAD_ALL: 'load_all.jstree', /* triggered after a load_all call completes */
            NEW_DATA_INSERTED: 'model.jstree', /* triggered when new data is inserted to the tree model */
            REDRAW: 'redraw.jstree', /* triggered after nodes are redrawn */
            BEFORE_OPEN: 'before_open.jstree', /* triggered when a node is about to be opened (if the node is supposed to be in the DOM, it will be, but it won't be visible yet) */
            OPEN_NODE: 'open_node.jstree', /* triggered when a node is opened (if there is an animation it will not be completed yet) */
            AFTER_OPEN: 'after_open.jstree', /* triggered when a node is opened and the animation is complete */
            CLOSE_NODE: 'close_node.jstree', /* triggered when a node is closed (if there is an animation it will not be complete yet) */
            AFTER_CLOSE: 'after_close.jstree', /* triggered when a node is closed and the animation is complete */
            OPEN_ALL: 'open_all.jstree', /* triggered when an open_all call completes */
            CLOSE_ALL: 'close_all.jstree', /* triggered when an close_all call completes */
            ENABLE_NODE: 'enable_node.jstree', /* triggered when an node is enabled */
            DISABLE_NODE: 'disable_node.jstree', /* triggered when an node is disabled */
            HIDE_NODE: 'hide_node.jstree', /* triggered when an node is hidden */
            SHOW_NODE: 'show_node.jstree', /* triggered when an node is shown */
            HIDE_ALL: 'hide_all.jstree', /* triggered when all nodes are hidden */
            SHOW_ALL: 'show_all.jstree', /* triggered when all nodes are shown */
            ACTIVATE_NODE: 'activate_node.jstree', /* triggered when an node is clicked or intercated with by the user */
            HOVER_NODE: 'hover_node.jstree', /* triggered when an node is hovered */
            DEHOVER_NODE: 'dehover_node.jstree', /* triggered when an node is no longer hovered */
            SELECT_NODE: 'select_node.jstree', /* triggered when an node is selected */
            CHANGED: 'changed.jstree', /* triggered when selection changes */
            DESELECT_NODE: 'deselect_node.jstree', /* triggered when an node is deselected */
            SELECT_ALL: 'select_all.jstree', /* triggered when all nodes are selected */
            DESELECT_ALL: 'deselect_all.jstree', /* triggered when all nodes are deselected */
            SET_STATE: 'set_state.jstree', /* triggered when a set_state call completes */
            REFRESH: 'refresh.jstree', /* triggered when a refresh call completes */
            REFRESH_NODE: 'refresh_node.jstree', /* triggered when a node is refreshed */
            SET_ID: 'set_id.jstree', /* triggered when a node id value is changed */
            SET_TEXT: 'set_text.jstree', /* triggered when a node text value is changed */
            CREATE_NODE: 'create_node.jstree', /* triggered when a node is created */
            RENAME_NODE: 'rename_node.jstree', /* triggered when a node is renamed */
            DELETE_NODE: 'delete_node.jstree', /* triggered when a node is deleted */
            MOVE_NODE: 'move_node.jstree', /* triggered when a node is moved */
            COPY_NODE: 'copy_node.jstree', /* triggered when a node is copied */
            CUT: 'cut.jstree', /* triggered when nodes are added to the buffer for moving */
            COPY: 'copy.jstree', /* triggered when nodes are added to the buffer for copying */
            PASTE: 'paste.jstree', /* triggered when paste is invoked */
            CLEAR_BUFFER: 'clear_buffer.jstree', /* triggered when the copy / cut buffer is cleared */
            SET_THEME: 'set_theme.jstree', /* triggered when a theme is set */
            SHOW_STRIPES: 'show_stripes.jstree', /* triggered when stripes are shown */
            HIDE_STRIPES: 'hide_stripes.jstree', /* triggered when stripes are hidden */
            SHOW_DOTS: 'show_dots.jstree', /* triggered when dots are shown */
            HIDE_DOTS: 'hide_dots.jstree', /* triggered when dots are hidden */
            SHOW_ICONS: 'show_icons.jstree', /* triggered when icons are shown */
            HIDE_ICONS: 'hide_icons.jstree', /* triggered when icons are hidden */
            SHOW_ELLIPSIS: 'show_ellipsis.jstree', /* triggered when ellisis is shown */
            HIDE_ELLIPSIS: 'hide_ellipsis.jstree' /* triggered when ellisis is hidden */
        },
        PLUGINS: {
            CHECKBOX: {
                DISABLE_CHECKBOX: 'disable_checkbox.jstree', /* triggered when an node's checkbox is disabled */
                ENABLE_CHECKBOX: 'enable_checkbox.jstree', /* triggered when an node's checkbox is enabled */
                CHECK_NODE: 'check_node.jstree', /* triggered when an node is checked (only if tie_selection in checkbox settings is false) */
                UNCHECK_NODE: 'uncheck_node.jstree', /* triggered when an node is unchecked (only if tie_selection in checkbox settings is false) */
                CHECK_ALL: 'check_all.jstree', /* triggered when all nodes are checked (only if tie_selection in checkbox settings is false) */
                UNCHECK_ALL: 'uncheck_node' /* triggered when all nodes are unchecked (only if tie_selection in checkbox settings is false) */
            },
            CONTEXTMENU: {
                SHOW_CONTEXTMENU: 'show_contextmenu.jstree', /* triggered when the contextmenu is shown for a node */
                PARSED: 'context_parse.vakata', /* triggered on the document when the contextmenu is parsed (HTML is built) */
                SHOW: 'context_show.vakata', /* triggered on the document when the contextmenu is shown */
                HIDDEN: 'context_hide.vakata' /* triggered on the document when the contextmenu is hidden */
            },
            DRAG_AND_DROP: {
                SCROLL: 'dnd_scroll.vakata', /* triggered on the document when a drag causes an element to scroll */
                START: 'dnd_start.vakata', /* triggered on the document when a drag starts */
                MOVE: 'dnd_move.vakata', /* triggered on the document when a drag is in progress */
                STOP: 'dnd_stop.vakata', /* triggered on the document when a drag stops (the dragged element is dropped) */
            },
            SEARCH: {
                SEARCH_COMPLETE: 'search.jstree', /* triggered after search is complete */
                CLEAR_SEARCH: 'clear_search.jstree' /* triggered after search is complete */
            },
            STATE: {
                STATE_READY: 'state_ready.jstree' /* triggered when the state plugin is finished restoring the state (and immediately after ready if there is no state to restore). */
            }
        }
    },

    SHARE_ACCESS_LEVEL_OPTIONS = {
        EDIT: 'EDIT',
        VIEW_ONLY: 'VIEW_ONLY',
        OWNER: 'OWNER'
    },

    ITEM_EDIT_LEVEL_OPTIONS = {
        NO_EDIT: 'NO_EDIT',
        FULL_EDIT: 'FULL_EDIT'
    },

    RADIO_INPUT_ALIGNMENT_OPTIONS = {
        VERTICAL: 'vertical',
        HORIZONTAL: 'horizontal'
    },

    SUPPORT_EMAIL_ADDRESSES = {
        TRUCKSCIENCE: 'support@truckscience.com'
    },

    BILLING_CYCLE_OPTIONS = {
        MONTHLY: 'MONTHLY',
        ANNUALLY: 'ANNUALLY'
    },

    REQUEST_MODAL_TYPE = {
        TRIAL_EXTENSION: 'TRIAL_EXTENSION',
        SALES_TOOL: 'SALES_TOOL',
        REQUEST_UPGRADE: 'REQUEST_UPGRADE'
    },

    ESSENTIALS_CHECK_LOCATION = {
        CONFIGURATION_ADD_AXLES: 'CONFIGURATION_ADD_AXLES',
        CONFIGURATION_IMPORT_DXF_BODY: 'CONFIGURATION_IMPORT_DXF_BODY',
        CONFIGURATION_IMPORT_DXF_ACCESSORY: 'CONFIGURATION_IMPORT_DXF_ACCESSORY',
        CONFIGURATION_IMPORT_DXF_PAYLOAD: 'CONFIGURATION_IMPORT_DXF_PAYLOAD',
        CONFIGURATION_ADD_VIEW_TO_REPORT: 'CONFIGURATION_ADD_VIEW_TO_REPORT',
        SHARE_CALCULATION: 'SHARE_CALCULATION',
        SETTINGS_IMPORT_LOGO: 'SETTINGS_IMPORT_LOGO',
        SETTINGS_IMPORT_LOGO_TOOLTIP: 'SETTINGS_IMPORT_LOGO_TOOLTIP',
        SETTINGS_COMPANY_NOTES: 'SETTINGS_COMPANY_NOTES',
        SETTINGS_COMPANY_NOTES_TOOLTIP: 'SETTINGS_COMPANY_NOTES_TOOLTIP',
        SETTINGS_REGULATIONS: 'SETTINGS_REGULATIONS',
        SETTINGS_REGULATIONS_TOOLTIP: 'SETTINGS_REGULATIONS_TOOLTIP',
        SETTINGS_FAVOURITE_MEASUREMENTS: 'SETTINGS_FAVOURITE_MEASUREMENTS',
        SETTINGS_FAVOURITE_MEASUREMENTS_TOOLTIP: 'SETTINGS_FAVOURITE_MEASUREMENTS_TOOLTIP',
        SETTINGS_INTERNAL_STANDARDS_CHECKBOX: 'SETTINGS_INTERNAL_STANDARDS_CHECKBOX',
        SETTINGS_INTERNAL_STANDARDS_SELECT: 'SETTINGS_INTERNAL_STANDARDS_SELECT',
        SETTINGS_INTERNAL_STANDARDS_TOOLTIP: 'SETTINGS_INTERNAL_STANDARDS_TOOLTIP',
        SETTINGS_REPORT_VIEW_LAYOUT: 'SETTINGS_REPORT_VIEW_LAYOUT',
        SAVE_ACCESSORY_TEAM_LIBRARY: 'SAVE_ACCESSORY_TEAM_LIBRARY',
        SELECTION_DATA_REQUEST_VEHICLE: 'SELECTION_DATA_REQUEST_VEHICLE',
        CONFIGURATION_DATA_REQUEST_BODY: 'CONFIGURATION_DATA_REQUEST_BODY',
        CONFIGURATION_DATA_REQUEST_ACCESSORY: 'CONFIGURATION_DATA_REQUEST_ACCESSORY',
        CONFIGURATION_DATA_REQUEST_PAYLOAD: 'CONFIGURATION_DATA_REQUEST_PAYLOAD',
        CONFIGURATION_DATA_REQUEST_VEHICLE: 'CONFIGURATION_DATA_REQUEST_VEHICLE',
        CONFIGURATION_DATA_REQUEST_TRAILER1: 'CONFIGURATION_DATA_REQUEST_TRAILER1',
        CONFIGURATION_DATA_REQUEST_TRAILER2: 'CONFIGURATION_DATA_REQUEST_TRAILER2',
        SAVE_ACCESSORY_MODAL_ACCESSORY: 'SAVE_ACCESSORY_MODAL_ACCESSORY',
        SAVE_ACCESSORY_MODAL_BODY: 'SAVE_ACCESSORY_MODAL_BODY',
        SAVE_ACCESSORY_MODAL_TRAILER: 'SAVE_ACCESSORY_MODAL_TRAILER',
        SAVE_ACCESSORY_MODAL_PAYLOAD: 'SAVE_ACCESSORY_MODAL_PAYLOAD'
    },

    APP_EDITION = {
        ESSENTIALS: 'Essentials',
        PROFESSIONAL: 'Professional',
        ENTERPRISE: 'Enterprise',
        OPERATOR: 'Operator',
        DEALER: 'Dealer'
    },

    APP_PRODUCT = {
        SALES_TOOL: 'Sales Tool',
        AXLE_WEIGHT_CALCULATOR: 'Axle Weight Calculator'
    },

    MODAL_DISPLAY_MODE = {
        OVERLAY: 'OVERLAY',
        QUEUE: 'QUEUE'
    },

    COSTING_FUEL_AND_ADBLUE_ROW_TYPE = {
        VEHICLE_FUEL: 'VEHICLE_FUEL',
        VEHICLE_ADBLUE: 'VEHICLE_ADBLUE',
        VEHICLE_EQUIPMENT_FUEL: 'VEHICLE_EQUIPMENT_FUEL',
        TRAILER_EQUIPMENT_FUEL: 'TRAILER_EQUIPMENT_FUEL',
    },

    SERVER_TYPE = {
        CLOUD_SERVICES: 'CS',
        AZURE_FUNCTIONS: 'AF'
    },

    SERVER = {
        CLOUD_SERVICES: {
            Type: SERVER_TYPE.CLOUD_SERVICES,
            BaseURLFunc: getCloudServicesUrl
        },
        AZURE_FUNCTIONS: {
            Type: SERVER_TYPE.AZURE_FUNCTIONS,
            BaseURLFunc: getAzureFunctionsUrl
        }
    },

    AUTHENTICATION_STATE = {
        AUTHENTICATED: 'AUTHENTICATED',
        NEED_TO_AUTHENTICATE: 'NEED_TO_AUTHENTICATE',
        AUTHENTICATION_FAILED: 'AUTHENTICATION_FAILED',
        UNEXPECTED_SERVER_ERROR: 'UNEXPECTED_SERVER_ERROR',
        BAD_REQUEST_MISSING_USERNAME: 'BAD_REQUEST_MISSING_USERNAME',
        BAD_REQUEST_MISSING_AUTHENTICATION_METHOD: 'BAD_REQUEST_MISSING_AUTHENTICATION_METHOD',
        BAD_REQUEST_MISSING_PASSWORD: 'BAD_REQUEST_MISSING_PASSWORD',
        BAD_REQUEST_MISSING_SECURITY_TOKEN_API_USER: 'BAD_REQUEST_MISSING_SECURITY_TOKEN_API_USER',
        AUTHENTICATION_FAILED_API_USER_INVALID_SECURITY_TOKEN: 'AUTHENTICATION_FAILED_API_USER_INVALID_SECURITY_TOKEN',
        BAD_REQUEST_INVALID_APPLICATION_TYPE: 'BAD_REQUEST_INVALID_APPLICATION_TYPE',
        BAD_REQUEST_MISSING_MACHINE_CODE: 'BAD_REQUEST_MISSING_MACHINE_CODE',
    },

    DIALOG_RESULTS_OPTIONS = {
        OK: 'OK',
        CLOSE: 'CLOSE',
    },


    // Matches CoreCountries List in Globals on Cloud Services
    coreMarkets = ["ZA", "AU", "NZ", "US", "CA"];

function getTranslationTextWithCultureCode(textId, cultureCode, replacementTextArray) {
    var selectedMessage;

    if (cultureCode === undefined || cultureCode === '') {
        cultureCode = 'en';
    }
    cultureCode = cultureCode.toLowerCase();

    if (Array.isArray(replacementTextArray)) {
        return language.getTranslationTextWithReplacementText({
            Code: textId,
            CultureCode: cultureCode
        }, replacementTextArray);
    } else {
        selectedMessage = language.getTranslationText({
            Code: textId,
            CultureCode: cultureCode
        });
    }

    

    if (selectedMessage !== undefined) {
        return selectedMessage;
    } else {
        return '';
    }
}



var config = {
    BUY_NOW_TYPE: BUY_NOW_TYPE,
    dimensionLayerHeight: dimensionLayerHeight,
    dimensionLayerWidth: dimensionLayerWidth,
    dimensionDrawingBuffer: dimensionDrawingBuffer,
    DIMENSION_TYPE: DIMENSION_TYPE,
    dimensionFontSize: dimensionFontSize,
    dimensionFontSizePDF: dimensionFontSizePDF,
    dimensionFont: dimensionFont,
    dimensionTextDefaultColour: dimensionTextDefaultColour,
    dimensionTextHoverColour: dimensionTextHoverColour,
    dimensionTextClickColour: dimensionTextClickColour,
    dimensionDefaultColour: dimensionDefaultColour,
    dimensionHoverColour: dimensionHoverColour,
    dimensionClickColour: dimensionClickColour,
    dimensionLineDefaultWidth: dimensionLineDefaultWidth,
    dimensionLineHighlightedWidth: dimensionLineHighlightedWidth,
    dimensionObjectDefaultOpacity: dimensionObjectDefaultOpacity,
    dimensionObjectHighlightedOpacity: dimensionObjectHighlightedOpacity,
    dimensionObjectDefaultShadowBlur: dimensionObjectDefaultShadowBlur,
    dimensionObjectHighlightedShadowBlur: dimensionObjectHighlightedShadowBlur,
    dimensionObjectDefaultShadowOffset: dimensionObjectDefaultShadowOffset,
    dimensionObjectHighlightedShadowOffset: dimensionObjectHighlightedShadowOffset,
    dimensionObjectUnAlphadShadowColour: dimensionObjectUnAlphadShadowColour,
    boxRectOffsetX: boxRectOffsetX,
    boxRectOffsetY: boxRectOffsetY,
    boxRectHeight: boxRectHeight,
    boxRectWidth: boxRectWidth,
    boxFontSize: boxFontSize,
    boxTextDefaultColour: boxTextDefaultColour,
    boxFont: boxFont,
    boxArrayLength: boxArrayLength,
    boxLabelBuffer: boxLabelBuffer,
    // showDetails: showDetails,
    kineticObjectActionTypeMove: kineticObjectActionTypeMove,
    kineticObjectActionTypeUpdateStartX: kineticObjectActionTypeUpdateStartX,
    kineticObjectActionTypeUpdateStartY: kineticObjectActionTypeUpdateStartY,
    kineticObjectActionTypeUpdateEndX: kineticObjectActionTypeUpdateEndX,
    kineticObjectActionTypeUpdateEndY: kineticObjectActionTypeUpdateEndY,
    vehicleTypeStandard: vehicleTypeStandard,
    vehicleTypeTemplate: vehicleTypeTemplate,
    showDebugToast: showDebugToast,
    cloudServicesAddress: cloudServicesAddress,
    cdnAddress: cdnAddress,
    appVersionNumber: appVersionNumber,
    fillColour: fillColour,
    bingMapsKey: bingMapsKey,
    getMessageText: getMessageText,
    getValidationMessageText: getValidationMessageText,
    offerStatusInProgress: offerStatusInProgress,
    offerStatusCompleted: offerStatusCompleted,
    offerStatusOrderLost: offerStatusOrderLost,
    offerLostReasonEngine: offerLostReasonEngine,
    offerLostReasonBody: offerLostReasonBody,
    offerLostReasonChassis: offerLostReasonChassis,
    offerLostReasonTransmission: offerLostReasonTransmission,
    offerLostReasonOther: offerLostReasonOther,
    applicationTitle: applicationTitle,
    pixelsPerMillimetre: pixelsPerMillimetre,
    millimetresPerFontPoint: millimetresPerFontPoint,
    bingMapsMaxElevationPoints: bingMapsMaxElevationPoints,
    maximumSectionLength: maximumSectionLength,
    bingMapsMaxRoutableDistanceMetric: bingMapsMaxRoutableDistanceMetric,
    bingMapsMaxRoutableDistanceImperial: bingMapsMaxRoutableDistanceImperial,
    localStorageData: localStorageData,
    localStorageExpiry: localStorageExpiry,
    localStorageUser: localStorageUser,
    vehicleSelectionValidity: vehicleSelectionValidity,
    companyOverviewValidity: companyOverviewValidity,
    newOfferValidity: newOfferValidity,
    millisInDay: millisInDay,
    newOfferTransaction: newOfferTransaction,
    selectionApplicationDistribution: selectionApplicationDistribution,
    selectionApplicationConstruction: selectionApplicationConstruction,
    selectionApplicationLongDistance: selectionApplicationLongDistance,
    selectionApplicationOther: selectionApplicationOther,
    selectionVehicleTypeTipper: selectionVehicleTypeTipper,
    selectionVehicleTypeMixer: selectionVehicleTypeMixer,
    getAppName: getAppName,
    getAppShortcutName: getAppShortcutName,
    appHeaderURL: appHeaderURL,
    getAppHeaderURL: getAppHeaderURL,
    appFooterURL: appFooterURL,
    getAppFooterURL: getAppFooterURL,
    getHeaderURL: getHeaderURL,
    setHeaderURL: setHeaderURL,
    getFooterURL: getFooterURL,
    setFooterURL: setFooterURL,
    displayHeaderText: displayHeaderText,
    displayAppHeaderTextImage: displayAppHeaderTextImage,
    getCloudServicesUrl: getCloudServicesUrl,
    defaultReportHeaderURL: defaultReportHeaderURL,
    defaultReportFooterURL: defaultReportFooterURL,
    setDefaultReportFooterURL: setDefaultReportFooterURL,
    setDefaultReportHeaderURL: setDefaultReportHeaderURL,
    defaultColour1: defaultColour1,
    defaultColour2: defaultColour2,
    defaultColour3: defaultColour3,
    defaultColour4: defaultColour4,
    defaultCultureCode: defaultCultureCode,
    defaultOverviewPermission: defaultOverviewPermission,
    defaultCRMPermission: defaultCRMPermission,
    defaultBrochurePermission: defaultBrochurePermission,
    defaultConfigurationPermission: defaultConfigurationPermission,
    defaultPerformancePermission: defaultPerformancePermission,
    defaultCostingPermission: defaultCostingPermission,
    defaultSpecificationPermission: defaultSpecificationPermission,
    defaultTrainingPermission: defaultTrainingPermission,
    defaultTrainingUrl: defaultTrainingUrl,
    defaultDecimalsHighPricedItems: defaultDecimalsHighPricedItems,
    defaultDecimalsMediumPricedItems: defaultDecimalsMediumPricedItems,
    defaultDecimalsLowPricedItems: defaultDecimalsLowPricedItems,
    defaultDecimalsMinMaxLabel: defaultDecimalsMinMaxLabel,
    OPERATION: OPERATION,
    PERMISSIONS: PERMISSIONS,
    // ROUTES: ROUTES,
    toastrOptionsDefault: toastrOptionsDefault,
    toastrOptionsOffline: toastrOptionsOffline,
    toastrOptionsSyncSuccess: toastrOptionsSyncSuccess,
    onRoadCostTypeCost: onRoadCostTypeCost,
    onRoadCostTypeDiscount: onRoadCostTypeDiscount,
    onRoadCostValueTypeConstant: onRoadCostValueTypeConstant,
    onRoadCostValueTypePercentage: onRoadCostValueTypePercentage,
    databaseAttributeActionTextCode: databaseAttributeActionTextCode,
    databaseAttributeOnRoadCostCode: databaseAttributeOnRoadCostCode,
    databaseAttributesForTranslation: databaseAttributesForTranslation,
    minScreenHeight: minScreenHeight,
    minScreenWidth: minScreenWidth,
    minBrowserHeight: minBrowserHeight,
    minBrowserWidth: minBrowserWidth,
    validWindowsOperatingSystems: validWindowsOperatingSystems,
    touchWindowsOperatingSystems: touchWindowsOperatingSystems,
    minAndroidVersion: minAndroidVersion,
    osNameAndroid: osNameAndroid,
    osNameiOS: osNameiOS,
    osNameiOSMinimumVersion: osNameiOSMinimumVersion,
    osNameMac: osNameMac,
    osNameWindows10: osNameWindows10,
    osNameWindows81: osNameWindows81,
    osNameWindows8: osNameWindows8,
    osNameWindows7: osNameWindows7,
    // getMessageTextFromConfig: getMessageTextFromConfig,
    selectionMakeRangeTypeAxleLayout: selectionMakeRangeTypeAxleLayout,
    selectionTypeAxleLayoutMakeRange: selectionTypeAxleLayoutMakeRange,
    selectionVehicleType: selectionVehicleType,
    selectionAxleLayout: selectionAxleLayout,
    selectionVehicleMake: selectionVehicleMake,
    selectionVehicleRange: selectionVehicleRange,
    selectionVehicleSelection: selectionVehicleSelection,
    selectionVehicleMakeGeneric: selectionVehicleMakeGeneric,
    selectionVehicleTypeRigidImage: selectionVehicleTypeRigidImage,
    selectionVehicleTypeTruckTractorImage: selectionVehicleTypeTruckTractorImage,
    selectionVehicleTypeCompleteVehicleImage: selectionVehicleTypeCompleteVehicleImage,
    selectionAxleLayout4X2: selectionAxleLayout4X2,
    selectionAxleLayout4X4: selectionAxleLayout4X4,
    selectionAxleLayout6X2: selectionAxleLayout6X2,
    selectionAxleLayout6X4: selectionAxleLayout6X4,
    selectionAxleLayout6X6: selectionAxleLayout6X6,
    selectionAxleLayout8X2: selectionAxleLayout8X2,
    selectionAxleLayout8X4SS: selectionAxleLayout8X4SS,
    selectionAxleLayout8X4TS: selectionAxleLayout8X4TS,
    selectionAxleLayout8X8: selectionAxleLayout8X8,
    selectionAxleLayout10X4: selectionAxleLayout10X4,
    //selectionAxleLayout4X2Image: selectionAxleLayout4X2Image,
    //selectionAxleLayout4X4Image: selectionAxleLayout4X4Image,
    //selectionAxleLayout6X2Image: selectionAxleLayout6X2Image,
    //selectionAxleLayout6X4Image: selectionAxleLayout6X4Image,
    //selectionAxleLayout6X6Image: selectionAxleLayout6X6Image,
    //selectionAxleLayout8X2Image: selectionAxleLayout8X2Image,
    //selectionAxleLayout8X4SSImage: selectionAxleLayout8X4SSImage,
    //selectionAxleLayout8X4TSImage: selectionAxleLayout8X4TSImage,
    //selectionAxleLayout8X8Image: selectionAxleLayout8X8Image,
    //selectionAxleLayout10X4Image: selectionAxleLayout10X4Image,
    LOGGING_LEVEL: LOGGING_LEVEL,
    loggingLevel: loggingLevel,
    LOG_MESSAGE_TYPE: LOG_MESSAGE_TYPE,
    debugLogRetentionPeriod: debugLogRetentionPeriod,
    debugLogCommunicationPeriod: debugLogCommunicationPeriod,
    noOfDaysLicenceExpiryMessageShows: noOfDaysLicenceExpiryMessageShows,
    isOnlineCheckPeriod: isOnlineCheckPeriod,
    isOnlineTimeout: isOnlineTimeout,
    isOnlineLowPriorityValidityPeriod: isOnlineLowPriorityValidityPeriod,
    isOnlineMediumPriorityValidityPeriod: isOnlineMediumPriorityValidityPeriod,
    isOnlineHighPriorityValidityPeriod: isOnlineHighPriorityValidityPeriod,
    postRequestTimeout: postRequestTimeout,
    getRequestTimeout: getRequestTimeout,
    usageCommunicationPeriod: usageCommunicationPeriod,
    maxNewOffersCached: maxNewOffersCached,
    maxSavedOffersCached: maxSavedOffersCached,
    defaultAppConfig: defaultAppConfig,
    USAGE_TYPE: USAGE_TYPE,
    MEASUREMENT_SYSTEM: MEASUREMENT_SYSTEM,
    VEHICLE_APPLICATION_TYPES: VEHICLE_APPLICATION_TYPES,
    VEHICLE_TYPES: VEHICLE_TYPES,
    VEHICLE_SOURCE_TYPES: VEHICLE_SOURCE_TYPES,
    SIGNALR_MESSAGE_TYPE: SIGNALR_MESSAGE_TYPE,
    TABLE_HIGHLIGHT_COLOURS: TABLE_HIGHLIGHT_COLOURS,
    configurationTableFontSize: configurationTableFontSize,
    configurationDashboardAndTableTitleFontSize: configurationDashboardAndTableTitleFontSize,
    configurationNotesAndWarningsFontSize: configurationNotesAndWarningsFontSize,
    configurationDimensionFontSize: configurationDimensionFontSize,
    configurationLabelFontSize: configurationLabelFontSize,
    configurationAxleLetterFontSize: configurationAxleLetterFontSize,
    configurationDimensionLayerIncrement: configurationDimensionLayerIncrement,
    summaryDimensionLayerIncrement: summaryDimensionLayerIncrement,
    defaultTableLinkChar: defaultTableLinkChar,
    summaryTableFontSize: summaryTableFontSize,
    summaryDashboardAndTableTitleFontSize: summaryDashboardAndTableTitleFontSize,
    summaryNotesAndWarningsFontSize: summaryNotesAndWarningsFontSize,
    summaryDimensionFontSize: summaryDimensionFontSize,
    summaryLabelFontSize: summaryLabelFontSize,
    summaryAxleLetterFontSize: summaryAxleLetterFontSize,
    INPUT_TYPE: INPUT_TYPE,
    ITEM_TYPE: ITEM_TYPE,
    INCREMENT_TYPE: INCREMENT_TYPE,
    TOOLTIP_TYPES: TOOLTIP_TYPES,
    INPUT_GROUP_TYPES: INPUT_GROUP_TYPES,
    VALUE_TYPE: VALUE_TYPE,
    VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES: VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES,
    VEHICLE_ROLLOVER_PROTECTION_TYPES: VEHICLE_ROLLOVER_PROTECTION_TYPES,
    VEHICLE_FRONT_UNDERRUN_PROTECTION_TYPES: VEHICLE_FRONT_UNDERRUN_PROTECTION_TYPES,
    VEHICLE_AXLE_POSITION_TYPES: VEHICLE_AXLE_POSITION_TYPES,
    DIMENSION_STYLE: DIMENSION_STYLE,
    VEHICLE_COMBINATION_TYPE: VEHICLE_COMBINATION_TYPE,
    VALIDATION_MESSAGE_TYPE: VALIDATION_MESSAGE_TYPE,
    TABLE_COLUMNS: TABLE_COLUMNS,
    TABLE_ROWS: TABLE_ROWS,
    generalDrawingBufferSmall: generalDrawingBufferSmall,
    generalDrawingMargin: generalDrawingMargin,
    CONTROL_TYPES: CONTROL_TYPES,
    loadingSummaryDisclaimer: loadingSummaryDisclaimer,
    specificationSummaryDisclaimer: specificationSummaryDisclaimer,
    costingSummaryDisclaimer: costingSummaryDisclaimer,
    routingSummaryDisclaimer: routingSummaryDisclaimer,
    LOG_IN_REAUTH_STEPS: LOG_IN_REAUTH_STEPS,
    getLoginReAuthStepText: getLoginReAuthStepText,
    DISPLAY_TYPE: DISPLAY_TYPE,
    YES_NO_OPTIONS: YES_NO_OPTIONS,
    ACCESSORY_TYPES: ACCESSORY_TYPES,
    ACCESSORY_SUB_TYPES: ACCESSORY_SUB_TYPES,
    BODY_TYPES: BODY_TYPES,
    BODY_MEASUREMENT_TYPES: BODY_MEASUREMENT_TYPES,
    COG_TYPES: COG_TYPES,
    MASS_TYPES: MASS_TYPES,
    PAYLOAD_TYPES: PAYLOAD_TYPES,
    COG_ELEMENT_STYLE: COG_ELEMENT_STYLE,
    menuSlideDuration: menuSlideDuration,
    ARROW_STYLE: ARROW_STYLE,
    FUEL_TYPE_OPTIONS: FUEL_TYPE_OPTIONS,
    OFFERED_AS_OPTIONS: OFFERED_AS_OPTIONS,
    OBJECT_TYPES: OBJECT_TYPES,
    NUMBER_OF_TYRES_ON_AXLE_OPTIONS: NUMBER_OF_TYRES_ON_AXLE_OPTIONS,
    getTooltipText: getTooltipText,
    defaultMenuPermissionObject: defaultMenuPermissionObject,
    setupPolyfills: setupPolyfills,
    getValidationBaseText: getValidationBaseText,
    VALIDATION_OP_TYPE: VALIDATION_OP_TYPE,
    OP_UNDO_ORDER: OP_UNDO_ORDER,
    toastrOptionsScreenDimensions: toastrOptionsScreenDimensions,
    toastrOptionsAppUpdate: toastrOptionsAppUpdate,
    SIDE_DOOR_TYPES: SIDE_DOOR_TYPES,
    dummyEmptyCustomer: dummyEmptyCustomer,
    RENDERED_AS_OPTIONS: RENDERED_AS_OPTIONS,
    IMAGE_CARD_RENDERED_AS_OPTIONS: IMAGE_CARD_RENDERED_AS_OPTIONS,
    SORT_OPTIONS: SORT_OPTIONS,
    MENU_LOCATION: MENU_LOCATION,
    WIZARD_ROW_TYPE: WIZARD_ROW_TYPE,
    CHASSIS_OBJECT_TYPES: CHASSIS_OBJECT_TYPES,
    ACCESSORY_SOURCE_TYPES: ACCESSORY_SOURCE_TYPES,
    TAILLIFT_TYPES: TAILLIFT_TYPES,
    BREADCRUMB_BUTTON_TYPE: BREADCRUMB_BUTTON_TYPE,
    VEHICLE_INCLUSION_TYPES: VEHICLE_INCLUSION_TYPES,
    defaultSearchResultsLimit: defaultSearchResultsLimit,
    BROWSER_NAME: BROWSER_NAME,
    ACTION_FAILURE_REASONS: ACTION_FAILURE_REASONS,
    identity: identity,
    EMAIL_VERIFICATION_STATUS_OPTIONS: EMAIL_VERIFICATION_STATUS_OPTIONS,
    EMAIL_VERIFIED_FROM_OPTIONS: EMAIL_VERIFIED_FROM_OPTIONS,
    maxTrialChangesBeforeForcedEmailVerification: maxTrialChangesBeforeForcedEmailVerification,
    externalAPIKey: externalAPIKey,
    applicationIdentityType: applicationIdentityType,
    USER_TYPES: USER_TYPES,
    getMessageTextNoCode: getMessageTextNoCode,
    LANGUAGE_PACK: LANGUAGE_PACK,
    PAYMENT_MENU_OPTIONS: PAYMENT_MENU_OPTIONS,
    PAYMENT_STATUS_STATES: PAYMENT_STATUS_STATES,
    toastrOptionsMissingValues: toastrOptionsMissingValues,
    OTHER_TOOLTIP: OTHER_TOOLTIP,
    SELECTION_WIZARD_MENU_ITEMS: SELECTION_WIZARD_MENU_ITEMS,
    WIZARD_INCREMENT: WIZARD_INCREMENT,
    showTrialCalculationsRemainingMessageThreshold: showTrialCalculationsRemainingMessageThreshold,
    trailUserObfuscationText: trailUserObfuscationText,
    INTERACTION_ID: INTERACTION_ID,
    CONTEXT_ERROR_CODES: CONTEXT_ERROR_CODES,
    GET_STARTED_MODES: GET_STARTED_MODES,
    APP_OPTION_ITEMS: APP_OPTION_ITEMS,
    APP_OPTION_ITEM_STATUS: APP_OPTION_ITEM_STATUS,
    APP_DATA_REFRESH_SCENARIO: APP_DATA_REFRESH_SCENARIO,
    GOOGLE_ANALYTICS: GOOGLE_ANALYTICS,
    intercomAppId: intercomAppId,
    azureFunctionsServer: azureFunctionsServer,
    chargebeeSiteName: chargebeeSiteName,
    chargebeeProductVersion: chargebeeProductVersion,
    INTERCOM_EVENT: INTERCOM_EVENT,
    CONNECTIVITY_STATUS: CONNECTIVITY_STATUS,
    INTERCOM_METADATA_ITEM: INTERCOM_METADATA_ITEM,
    MODULE_TITLE: MODULE_TITLE,
    INTERCOM_USER: INTERCOM_USER,
    INTERCOM_USER_COMPANY: INTERCOM_USER_COMPANY,
    GOOGLE_ANALYTICS_EVENTS: GOOGLE_ANALYTICS_EVENTS,
    GOOGLE_ANALYTICS_EVENT_PARAMETERS: GOOGLE_ANALYTICS_EVENT_PARAMETERS,
    SETTINGS_OPTIONS: SETTINGS_OPTIONS,
    VIEW_TYPE: VIEW_TYPE,
    getAppVersion: getAppVersion,
    USER_STATUS: USER_STATUS,
    PRODUCT_TOUR_TYPE: PRODUCT_TOUR_TYPE,
    getProductTourText: getProductTourText,
    DATA_INTEGRITY_CHECK_FAILURES: DATA_INTEGRITY_CHECK_FAILURES,
    getMessageReference: getMessageReference,
    TARE_DRIVER_INCLUDED_OPTIONS: TARE_DRIVER_INCLUDED_OPTIONS,
    COSTING_CHART_COLOURS: COSTING_CHART_COLOURS,
    WAYPOINT_PUSHPIN_COLOURS: WAYPOINT_PUSHPIN_COLOURS,
    DRAWING_COLOURS: DRAWING_COLOURS,
    SAVE_OFFER_FLAGS: SAVE_OFFER_FLAGS,
    TRAILER_TYPES: TRAILER_TYPES,
    TRAILER_TARE_TYPES: TRAILER_TARE_TYPES,
    COMBINATION_TYPES: COMBINATION_TYPES,
    PDFMAKE_ALIGNMENT_OPTIONS: PDFMAKE_ALIGNMENT_OPTIONS,
    PDFMAKE_PAGE_SIZE_OPTIONS: PDFMAKE_PAGE_SIZE_OPTIONS,
    REPORT_VIEW_LAYOUT_OPTIONS: REPORT_VIEW_LAYOUT_OPTIONS,
    CONFIGURATION_MENUS: CONFIGURATION_MENUS,
    VALIDATION_STEP_TYPES: VALIDATION_STEP_TYPES,
    usingOemUrl: usingOemUrl,
    getAppObjectBasedOnAlias: getAppObjectBasedOnAlias,
    pdfDocumentColour: pdfDocumentColour,
    TYRE_GAPS: TYRE_GAPS,
    swingClearanceLimit: swingClearanceLimit,
    protrusionSwingClearanceLimit: protrusionSwingClearanceLimit,
    AUTHENTICATION_METHOD: AUTHENTICATION_METHOD,
    INTERACTION_EVENT: INTERACTION_EVENT,
    COG_ELEMENTS: COG_ELEMENTS,
    NOTE_ID: NOTE_ID,
    WARNING_ID: WARNING_ID,
    CONSIDERED_REQUEST_SOURCES: CONSIDERED_REQUEST_SOURCES,
    SELECTION_MENU_ITEM_TYPE: SELECTION_MENU_ITEM_TYPE,
    SAVE_TYPE: SAVE_TYPE,
    COSTING_ROW_TYPE: COSTING_ROW_TYPE,
    DRAWING_DIRECTION_OF_MOVEMENT: DRAWING_DIRECTION_OF_MOVEMENT,
    DRAG_INCREMENTS: DRAG_INCREMENTS,
    toastrOptionsValidationFailed: toastrOptionsValidationFailed,
    STEERING_ATTRIBUTE_TYPES: STEERING_ATTRIBUTE_TYPES,
    VIEW_DETAIL: VIEW_DETAIL,
    gapBetweenTyresInMm: gapBetweenTyresInMm,
    gapBetweenTyresAndChassis: gapBetweenTyresAndChassis,
    LINE_END_STYLE: LINE_END_STYLE,
    CHARGEBEE_ERROR_TYPES: CHARGEBEE_ERROR_TYPES,
    trailUserObfuscationShortText: trailUserObfuscationShortText,
    SPACER_TYPE: SPACER_TYPE,
    configurationTableAreaVerticalSeparatorThickness: configurationTableAreaVerticalSeparatorThickness,
    minimumComplianceDashboardAndTableBuffer: minimumComplianceDashboardAndTableBuffer,
    OVERLOADING_STATUS: OVERLOADING_STATUS,
    COMPLIANCE_STATUS_ICON: COMPLIANCE_STATUS_ICON,
    COMPLIANCE_IDS: COMPLIANCE_IDS,
    configurationTableInterColGap: configurationTableInterColGap,
    PAYLOAD_UNIT_OF_MEASURE_TYPES: PAYLOAD_UNIT_OF_MEASURE_TYPES,
    CORNER_TYPES: CORNER_TYPES,
    HITCH_TYPES: HITCH_TYPES,
    DRAWBAR_TYPES: DRAWBAR_TYPES,
    defaultTableBlankRowChar: defaultTableBlankRowChar,
    REFERRAL_MODAL_TYPE: REFERRAL_MODAL_TYPE,
    BRIDGE_GROUPS: BRIDGE_GROUPS,
    SUPPORT_PLAN_TYPE: SUPPORT_PLAN_TYPE,
    INTERACTION_BEHAVIOUR: INTERACTION_BEHAVIOUR,
    vehicleAxleDefaultAxleWeight: vehicleAxleDefaultAxleWeight,
    vehicleAxleDefaultMaxLiftingTravel: vehicleAxleDefaultMaxLiftingTravel,
    vehicleAxleDefaultTyreRadius: vehicleAxleDefaultTyreRadius,
    vehicleAxleDefaultTyreWidth: vehicleAxleDefaultTyreWidth,
    vehicleAxleDefaultManufacturerRating: vehicleAxleDefaultManufacturerRating,
    vehicleAxleDefaultSecondSteerAxleSpread: vehicleAxleDefaultSecondSteerAxleSpread,
    vehicleAxleDefaultAxleSpread: vehicleAxleDefaultAxleSpread,
    vehicleAxleDefaultAxleBuffer: vehicleAxleDefaultAxleBuffer,
    maxTotalAxles: maxTotalAxles,
    maxFrontAxles: maxFrontAxles,
    maxRearAxles: maxRearAxles,
    AXLE_LOCATIONS: AXLE_LOCATIONS,
    vehicleAxleDefaultLiftingMaxTravel: vehicleAxleDefaultLiftingMaxTravel,
    vehicleAxleDefaultSuspensionMassRating: vehicleAxleDefaultSuspensionMassRating,
    vehicleAxleDefaultTyreMassRating: vehicleAxleDefaultTyreMassRating,
    vehicleAxleDefaultDesiredWeight: vehicleAxleDefaultDesiredWeight,
    AXLE_POSITIONS: AXLE_POSITIONS,
    SUMMARY_REPORT_OPTIONS: SUMMARY_REPORT_OPTIONS,
    SUMMARY_REPORT_OFFER_OPTIONS: SUMMARY_REPORT_OFFER_OPTIONS,
    BUY_NOW_SOURCE: BUY_NOW_SOURCE,
    MAP_PUSHPIN_TYPE: MAP_PUSHPIN_TYPE,
    radiusOfTheEarth: radiusOfTheEarth,
    tollBuffer: tollBuffer,
    tollZoomLevel: tollZoomLevel,
    fifthWheelSubframeBaseLength: fifthWheelSubframeBaseLength,
    MULTISELECT_FILTER_BEHAVIOUR_OPTIONS: MULTISELECT_FILTER_BEHAVIOUR_OPTIONS,
    SELECTION_DISTINCT_PROPERTY_NAME: SELECTION_DISTINCT_PROPERTY_NAME,
    WIZARD_FILTER_OPTIONS: WIZARD_FILTER_OPTIONS,
    ACTION_ID: ACTION_ID,
    ACTION_TYPE: ACTION_TYPE,
    VEHICLE_STATUS_TYPES: VEHICLE_STATUS_TYPES,
    ACCESSORY_STATUS_TYPES: ACCESSORY_STATUS_TYPES,
    SHARE_STATUS_TYPES: SHARE_STATUS_TYPES,
    accessCode: accessCode,
    shareeTempName: shareeTempName,
    SUMMARY_BUTTON_DISPLAY_OPTIONS: SUMMARY_BUTTON_DISPLAY_OPTIONS,
    toastrOptionsNewOffersInCalc: toastrOptionsNewOffersInCalc,
    SPECIFY_WHEELBASE_AS_OPTIONS: SPECIFY_WHEELBASE_AS_OPTIONS,
    ELEVATION: ELEVATION,
    topViewHitchRadius: topViewHitchRadius,
    SELECT_OPTION_TYPE: SELECT_OPTION_TYPE,
    topViewFifthWheelRadius: topViewFifthWheelRadius,
    SETTINGS_MODAL_TABS: SETTINGS_MODAL_TABS,
    TAB_GROUP_DISPLAY_TYPE: TAB_GROUP_DISPLAY_TYPE,
    INTEGRATION_TYPES: INTEGRATION_TYPES,
    OPERATION_TYPES: OPERATION_TYPES,
    CONSIDERED_INTEGRATION_SOURCE: CONSIDERED_INTEGRATION_SOURCE,
    nteaLogoLocation: nteaLogoLocation,
    AXLE_SECTIONS: AXLE_SECTIONS,
    COG_AXIS_TYPES: COG_AXIS_TYPES,
    CONFIGURATION_CHASSIS_MENU_TYPE: CONFIGURATION_CHASSIS_MENU_TYPE,
    CONFIGURATION_MENU_SOURCE: CONFIGURATION_MENU_SOURCE,
    CUSTOM_ITEM_ACCESS_LEVEL: CUSTOM_ITEM_ACCESS_LEVEL,
    bingMapsMaxWaypoints: bingMapsMaxWaypoints,
    BING_MAPS_REST_URLS: BING_MAPS_REST_URLS,
    WIZARD_TYPES: WIZARD_TYPES,
    WIZARD_LAYOUT_TYPES: WIZARD_LAYOUT_TYPES,
    WIZARD_STEP_TYPES: WIZARD_STEP_TYPES,
    WIZARD_DRAWING_TYPE_STEP_TYPES: WIZARD_DRAWING_TYPE_STEP_TYPES,
    WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES: WIZARD_REVIEW_AND_FINALIZE_DRAWING_TYPES,
    GRAPHICS_ENGINE_RENDER_MODE: GRAPHICS_ENGINE_RENDER_MODE,
    PREVIEW_TYPE: PREVIEW_TYPE,
    LAYERING_ADJUSTMENT: LAYERING_ADJUSTMENT,
    VALID_FILE_INPUT_EXTENSIONS: VALID_FILE_INPUT_EXTENSIONS,
    maxDxfFileSizeInMB: maxDxfFileSizeInMB,
    maxReportLogoFileSizeInKb: maxReportLogoFileSizeInKb,
    SCALING_PLANE: SCALING_PLANE,
    toastrOptionsAuApplicationChange: toastrOptionsAuApplicationChange,
    NEW_CUSTOM_ITEM_OBJECT_JSON: NEW_CUSTOM_ITEM_OBJECT_JSON,
    trialRegistrationCompanyNameDefault: trialRegistrationCompanyNameDefault,
    VALIDATION_MODE: VALIDATION_MODE,
    SPECIFY_CAB_DIMENSIONS_AS_OPTIONS: SPECIFY_CAB_DIMENSIONS_AS_OPTIONS,
    SPECIFY_CHASSIS_LENGTH_AS_OPTIONS: SPECIFY_CHASSIS_LENGTH_AS_OPTIONS,
    SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS: SPECIFY_FUEL_CONSUMPTION_AS_OPTIONS,
    COSTING_DETAIL_MODAL_TYPES: COSTING_DETAIL_MODAL_TYPES,
    COSTING_DETAIL_MODAL_BODY_TYPES: COSTING_DETAIL_MODAL_BODY_TYPES,
    MENU_TAB_DISPLAY_TYPES: MENU_TAB_DISPLAY_TYPES,
    MENU_TABLE_CELL_TYPES: MENU_TABLE_CELL_TYPES,
    ACCORDION_HEIGHT_STYLE_OPTIONS: ACCORDION_HEIGHT_STYLE_OPTIONS,
    CHECKBOX_TYPE: CHECKBOX_TYPE,
    RESIDUAL_RATE_TYPES: RESIDUAL_RATE_TYPES,
    CALCULATION_OP_TYPES: CALCULATION_OP_TYPES,
    SCALING_DIMENSION_TYPES: SCALING_DIMENSION_TYPES,
    getTextUsingCode: function (code, cultureCode) {
        return language.getTextUsingCode(code, cultureCode);
    },
    setLanguageCultureCode: function (newValue) {
        language.setLanguageCultureCode(newValue);
    },
    /**
        * Get text from translations document
        * @param {string} text - code for text to be returned from translations file
        * @param {string[]} replacementTextArray - Array of strings to be inserted into text
        * @returns {string} - Text to be used
        */
    getTranslationText: function (text, replacementTextArray) {
        try {
            if (Array.isArray(replacementTextArray)) {
                return language.getTranslationTextWithReplacementText(text, replacementTextArray);
            } else {
                return language.getTranslationText(text);
            }
        } catch (ex) {
            window.appInsights.trackException({ exception: ex });
        }
    },
    DIMENSION_LAYER_CHANGE: DIMENSION_LAYER_CHANGE,
    SPECIFY_AXLE_RATING_AS_OPTIONS: SPECIFY_AXLE_RATING_AS_OPTIONS,
    CULTURE_CODES: language.CULTURE_CODES,
    SELECT_ALL_CHECKBOX_OPTIONS: SELECT_ALL_CHECKBOX_OPTIONS,
    SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES: SELECTION_WIZARD_TOOLBAR_BUTTON_TYPES,
    SHARE_USER_TYPES: SHARE_USER_TYPES,
    ROW_TYPE: ROW_TYPE,
    OFFER_MANAGEMENT_MODAL_TYPES: OFFER_MANAGEMENT_MODAL_TYPES,
    JSTREE_PLUGINS: JSTREE_PLUGINS,
    JSTREE_EVENTS: JSTREE_EVENTS,
    TOASTR_TYPES: TOASTR_TYPES,
    SHARE_ACCESS_LEVEL_OPTIONS: SHARE_ACCESS_LEVEL_OPTIONS,
    SEARCH_TYPES: SEARCH_TYPES,
    TABLE_ROW_TYPE: TABLE_ROW_TYPE,
    PAYLOAD_WEIGHT_TYPES: PAYLOAD_WEIGHT_TYPES,
    codeMissingFromTranslationsObjectMessage: language.codeMissingFromTranslationsObjectMessage,
    roundingPrecisionForTableAndWarnings: roundingPrecisionForTableAndWarnings,
    ITEM_EDIT_LEVEL_OPTIONS: ITEM_EDIT_LEVEL_OPTIONS,
    MENU_SECTION: MENU_SECTION,
    BODY_SUB_TYPES: BODY_SUB_TYPES,
    RADIO_INPUT_ALIGNMENT_OPTIONS: RADIO_INPUT_ALIGNMENT_OPTIONS,
    tableAndDashboardTitleHeight: tableAndDashboardTitleHeight,
    COMPLIANCE_DASHBOARD_ITEM_TYPES: COMPLIANCE_DASHBOARD_ITEM_TYPES,
    tableAndDashboardTitleToDataGap: tableAndDashboardTitleToDataGap,
    RANGE_TYPES: RANGE_TYPES,
    SUPPORT_EMAIL_ADDRESSES: SUPPORT_EMAIL_ADDRESSES,
    allowOpenOfferByDistributorFlagString: allowOpenOfferByDistributorFlagString,
    COMPLIANCE_DASHBOARD_VIEWS: COMPLIANCE_DASHBOARD_VIEWS,
    COMPLIANCE_ROW_TEXT_POSITION_METHOD: COMPLIANCE_ROW_TEXT_POSITION_METHOD,
    SPECIFY_POSITION_AS_OPTIONS: SPECIFY_POSITION_AS_OPTIONS,
    BILLING_CYCLE_OPTIONS: BILLING_CYCLE_OPTIONS,
    REQUEST_MODAL_TYPE: REQUEST_MODAL_TYPE,
    ESSENTIALS_CHECK_LOCATION: ESSENTIALS_CHECK_LOCATION,
    getTrialUserReportLogoURL: getTrialUserReportLogoURL,
    APP_EDITION: APP_EDITION,
    APP_PRODUCT: APP_PRODUCT,
    MODAL_DISPLAY_MODE: MODAL_DISPLAY_MODE,
    ipapiKey: ipapiKey,
    COSTING_FUEL_AND_ADBLUE_ROW_TYPE: COSTING_FUEL_AND_ADBLUE_ROW_TYPE,
    getAzureFunctionsUrl: getAzureFunctionsUrl,
    setAzureFunctionsUrl: setAzureFunctionsUrl,
    SERVER: SERVER,
    SERVER_TYPE: SERVER_TYPE,
    TRAILER_BODY_TYPES: TRAILER_BODY_TYPES,
    DDR_RULE_TYPE: DDR_RULE_TYPE,
    DDR_RULE_CATEGORY: DDR_RULE_CATEGORY,
    DDR_LEGISLATION_ATTRIBUTE_PREFIX: DDR_LEGISLATION_ATTRIBUTE_PREFIX,
    DDR_RULE_CONTEXT_PREFIX: DDR_RULE_CONTEXT_PREFIX,
    DDR_MESSAGE_PARAMETER_VALUE_TYPE: DDR_MESSAGE_PARAMETER_VALUE_TYPE,
    EXTREME_BRIDGE_GROUPS: EXTREME_BRIDGE_GROUPS,
    toastrOptionsTip: toastrOptionsTip,
    TRACKER_STATE_KEY: TRACKER_STATE_KEY,
    TRACKER_TYPE: TRACKER_TYPE,
    NOTES_HEADINGS: NOTES_HEADINGS,
    WARNINGS_HEADINGS: WARNINGS_HEADINGS,
    allowedNumberOfFuelTanksOrBatteries: allowedNumberOfFuelTanksOrBatteries,
    AUTHENTICATION_STATE: AUTHENTICATION_STATE,
    SPEC_DATA_POINT: SPEC_DATA_POINT,
    SPEC_DATA_POINT_TO_CALCULATION_DATA_POINT_MAPPING: SPEC_DATA_POINT_TO_CALCULATION_DATA_POINT_MAPPING,
    appEnvironment: appEnvironment,
    NTEA_USER_VALIDATION_TYPES: NTEA_USER_VALIDATION_TYPES,
    DIALOG_RESULTS_OPTIONS: DIALOG_RESULTS_OPTIONS,
    coreMarkets: coreMarkets
};

export default config

   